import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';

import { useCustomer } from 'hooks/customer';

import Wrapper from '../../wrapper';

import ActiveSDKsCard from './components/ActiveSDKsCard/index';
import CustomerUpdateCard from './components/CustomerUpdateCard';
import DocumentsUploadCard from './components/DocumentsUploadCard';

import './styles.less';

const CustomerUpdate = () => {
  const { tenantId } = useParams();
  const { customer, loadingCustomer, loadCustomer } = useCustomer();

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!tenantId || !firstLoad.current) return;
    firstLoad.current = false;

    loadCustomer({ tenantId, clearData: true });
  }, [loadCustomer, tenantId]);

  return (
    <Wrapper>
      {loadingCustomer && !customer ? (
        <Spin />
      ) : (
        <Row>
          <Col span={15}>
            <CustomerUpdateCard />
          </Col>
          <Col span={9}>
            <DocumentsUploadCard />
            <ActiveSDKsCard />
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default CustomerUpdate;
