import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Upload,
  Typography,
  Progress,
  Divider,
  Spin,
  Button,
  Dropdown,
  Menu,
} from 'antd';

// import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';

import Card from 'components/Card';

import uploadImg from 'assets/images/upload.svg';
import { MoreVertical, Trash } from '@combateafraude/icons/general';

const { Text } = Typography;
const { Dragger } = Upload;

const ImagesUpload = ({ data }) => {
  // const { get } = useFetch();
  // const { loadCustomer } = useCustomer();

  const handleDeleteDocument = useCallback(async (_id) => {}, []);

  const handleDownloadDocument = useCallback(async (_id) => {}, []);

  const renderDropdownItems = useCallback(
    (_id) => (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => handleDeleteDocument(_id)}
          className="gx-text-danger"
        >
          <Trash width={20} height={20} />
          Excluir
        </Menu.Item>
      </Menu>
    ),
    [handleDeleteDocument]
  );

  return (
    <Row key={data._id} className="documents-list-item no-mrg-horizon">
      <Col className="documents-list-item-content">
        <aside>
          <Button type="link" onClick={() => handleDownloadDocument(data._id)}>
            {data.fileName}
          </Button>
        </aside>
        <Dropdown
          overlay={() => renderDropdownItems(data._id)}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      </Col>
    </Row>
  );
};

Document.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  }).isRequired,
  fetch: PropTypes.func.isRequired,
};

const ImagesUploadCard = ({ setImagesUrlsList }) => {
  const { post } = useFetch();
  const { get: getImages, loading: loadingImages } = useFetch();

  const [progress, setProgress] = useState([]);
  const [images, setImages] = useState([]);

  const fetch = useCallback(async () => {
    const { docs } = await getImages({
      // url: `/clients/${tenantId}/upload`,
    });

    setImages(docs || []);
  }, [getImages]);

  const updateProgress = useCallback(({ name, percent, error }) => {
    setProgress((state) => {
      const elementPosition = state.map((p) => p.name).indexOf(name);

      if (elementPosition === -1) return [...state, { name, percent, error }];

      const newArray = [...state];

      if (percent === 0 && !newArray[elementPosition].error)
        return newArray.filter((obj) => obj.name !== name);

      newArray[elementPosition] = {
        ...newArray[elementPosition],
        percent: !newArray[elementPosition].error ? percent : 100,
        error: newArray[elementPosition].error || error,
      };
      return [...newArray];
    });
  }, []);

  const fileToArrayBuffer = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const customRequest = useCallback(
    async ({ onSuccess, onError, file, onProgress }) => {
      const { name } = file;

      const config = {
        // headers: { 'content-type': 'multipart/form-data' },
        headers: { 'Content-Type': file.type },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          updateProgress({ name, percent });

          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      // const data = new FormData();
      // data.append('file', file);
      const data = await fileToArrayBuffer(file);

      try {
        // pega urls para mandar pro bucket temporário
        const { uploadUrl, getUrl } = await post({
          url: '/upload',
        });

        // envia o arquivo para o bucket temporário
        await cleanApi.put(uploadUrl, data, config);

        setImagesUrlsList((oldState) => [...oldState, getUrl]);
        // envia a referência da aws pra nossa base
        // await post({
        //   url: `/clients/${tenantId}/upload`,
        //   payload: {
        //     fileName: name,
        //     file: getUrl,
        //   },
        // });

        // fetch();

        onSuccess('Ok');
      } catch (err) {
        updateProgress({ name, percent: 100, error: true });

        onError({ err });
      }
    },
    [updateProgress, post, fileToArrayBuffer, setImagesUrlsList]
  );

  const progressList = useMemo(
    () =>
      progress.length > 0 &&
      progress.map((p) => (
        <React.Fragment key={p.name}>
          <Text>{p.name}</Text>
          <Progress status={p.error && 'exception'} key={p.name} percent={p.percent} />
        </React.Fragment>
      )),
    [progress]
  );

  const imagesList = useMemo(() => {
    if (!images.length) return <></>;

    return images.map((d) => <ImagesUpload data={d} fetch={fetch} />);
  }, [images, fetch]);

  // const firstLoad = useRef(true);
  // useEffect(() => {
  //   if (!customer || !firstLoad.current) return;

  //   firstLoad.current = false;
  //   fetch();
  // }, [customer, fetch]);

  return (
    <Card id="files-upload-card-component">
      <Dragger
        className="dragger"
        name="contracts"
        multiple
        customRequest={customRequest}
        showUploadList={false}
      >
        <Row>
          <Col span={24}>
            <img src={uploadImg} alt="Imagem de upload" />
          </Col>
          <Col className="mrg-top-20" span={24}>
            <Text>Arraste os items para cá ou clique aqui para adicionar imagens.</Text>
          </Col>
        </Row>
      </Dragger>
      {progressList}
      {images && (
        <>
          <Divider />
          {loadingImages && !images.length ? (
            <Spin className="flex center no-mrg-right" />
          ) : (
            imagesList
          )}
        </>
      )}
    </Card>
  );
};

export default ImagesUploadCard;
