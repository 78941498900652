import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Radio, Select } from 'antd';

import PageTitle from 'components/PageTitle';
import InputMask from 'components/Form/InputMask';
import SelectCountry from 'components/SelectCountry';
import { ArrowDown, Company, EMail } from '@combateafraude/icons/general';

import nifValidator from 'utils/nifValidator';
import { CNPJisValid } from 'utils/cnpjValidator';

const { Option } = Select;

const CHECKBOX_GROUP_OPTIONS = [
  { label: 'TRUST', value: 'trust' },
  { label: 'IDENTITY', value: 'identity' },
];

const V2UserForm = ({
  formRef,
  isMigrationClient,
  setIsMigrationClient,
  loading,
  clients,
}) => {
  const onChangeCustomerType = useCallback(
    (event) => {
      setIsMigrationClient(() => event.target.value === 'migrationCustomer');
    },
    [setIsMigrationClient]
  );

  return (
    <>
      <PageTitle title="Novo cliente" subtitle="Preencha os dados do novo cliente." />
      <Form
        form={formRef}
        layout="vertical"
        className="mrg-top-10"
        autoComplete="new-customer"
      >
        <Form.Item name="isMigration" valuePropName="value">
          <Radio.Group onChange={onChangeCustomerType} defaultValue="newCustomer">
            <Radio value="newCustomer">Novo cliente</Radio>
            <Radio value="migrationCustomer">Cliente já existente</Radio>
          </Radio.Group>
        </Form.Item>

        {isMigrationClient && (
          <Form.Item name="tenantId" label="Cliente CAF (migração)">
            <Select
              loading={loading}
              optionFilterProp="label"
              placeholder="Selecione um da CAF para migrar"
              showSearch
              allowClear
            >
              {clients?.map(
                (customer) =>
                  customer?.product !== 'trust' && (
                    <Option value={customer.tenantId} label={customer.fantasyName}>
                      {customer.fantasyName || '-'}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
        )}

        {!isMigrationClient && (
          <>
            <Form.Item
              name="companyName"
              label="Nome da empresa"
              rules={[{ required: true }]}
            >
              <Input
                autoComplete="new-name"
                className="text-dark"
                prefix={<Company />}
                placeholder="Nome da empresa"
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="country"
              label="País da empresa"
              rules={[{ required: true }]}
            >
              <SelectCountry
                placeholder="País da empresa"
                // className="multi-select-green"
              />
            </Form.Item>

            <Form.Item
              name="cnpj"
              label="CNPJ"
              rules={[
                {
                  validator: CNPJisValid,
                },
              ]}
            >
              <InputMask
                autoComplete="new-cnpj"
                mask="99.999.999/9999-99"
                className="text-dark"
                prefix={<Company />}
                placeholder="CNPJ da empresa"
                allowClear
              />
            </Form.Item>

            <Form.Item name="nif" label="NIF" rules={[{ validator: nifValidator }]}>
              <InputMask
                mask="999999999"
                className="text-dark"
                prefix={<Company />}
                placeholder="NIF da empresa"
                allowClear
              />
            </Form.Item>

            <Form.Item name="domain" label="Domínio">
              <Select defaultValue="trust" suffixIcon={<ArrowDown />}>
                <Option value="trust">Trust</Option>
              </Select>
            </Form.Item>

            <Form.Item name="language" label="Idioma" rules={[{ required: true }]}>
              <Select
                placeholder="Selecione o idioma"
                suffixIcon={<ArrowDown />}
                options={[
                  { value: 'en-US', label: 'Inglês' },
                  { value: 'es-MX', label: 'Espanhol' },
                  { value: 'pt-BR', label: 'Português' },
                ]}
              />
            </Form.Item>

            <Form.Item name="name" label="Nome do Usuário" rules={[{ required: true }]}>
              <Input
                autoComplete="new-name"
                className="text-dark"
                prefix={<Company width={20} height={20} />}
                placeholder="Nome do usuário"
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="username"
              label="E-mail"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input
                autoComplete="new-email"
                className="text-dark"
                prefix={<EMail />}
                placeholder="E-mail do usuário"
                allowClear
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          required
          label="Ativar produtos ao criar conta"
          rules={[
            {
              required: true,
              message: 'É necessário selecionar pelo menos um produto',
            },
          ]}
          name="products"
          initialValue={[CHECKBOX_GROUP_OPTIONS[0].value]}
        >
          <Checkbox.Group
            defaultValue={[CHECKBOX_GROUP_OPTIONS[0].value]}
            options={CHECKBOX_GROUP_OPTIONS}
          />
        </Form.Item>
      </Form>
    </>
  );
};

V2UserForm.propTypes = {
  isMigrationClient: PropTypes.bool.isRequired,
  setIsMigrationClient: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default V2UserForm;
