import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, message } from 'antd';

import ZoomedImage from 'components/Image/ZoomedImage';

import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

const LicenseConfirmation = () => {
  const { tenantId, reportId, executionId } = useParams();
  const { loading, post } = useFetch();
  const { executionData, reloadData } = useExecution();

  const [form] = Form.useForm();

  const [manualLicenseNumber, setLicenseProcessNumber] = useState(null);
  const [action, setAction] = useState(null);

  const [licenseImg, setLicenseImg] = useState(null);

  const handleSubmit = useCallback(
    async (brbLicenseProcessNumber) => {
      try {
        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/BRB_LICENSE`,
          payload: {
            action,
            ...brbLicenseProcessNumber,
          },
        });

        message.success('Ação realizada com sucesso.');
        reloadData(false);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [post, tenantId, reportId, executionId, action, reloadData]
  );

  useEffect(() => {
    setLicenseImg(executionData?.executions?.images?.brbLicense);
  }, [executionData, licenseImg]);

  return (
    <div id="license-confirmation-component">
      <PageTitle
        title="Ação requerida: Informe o número de processo"
        subtitle="Avalie a imagem e confirme o número do processo."
      />
      <Card>
        <Row align="middle" justify="center">
          <Col className="document-col" span={12}>
            <ZoomedImage src={licenseImg} alt="Imagem do alvará" height={320} />
          </Col>
          <Col span={12}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <Row align="middle" className="fd-row">
                <Col span={12} className="flex center">
                  <Form.Item
                    name="brbLicenseProcessNumber"
                    label="Número do Processo"
                    className="flex-1"
                  >
                    <Input
                      className="custom-input"
                      value={manualLicenseNumber}
                      onChange={(e) => setLicenseProcessNumber(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="mrg-top-20">
                <Button
                  htmlType="submit"
                  className="btn-custom btn-custom-danger"
                  loading={loading}
                  onClick={() => setAction('CONFIRM')}
                >
                  Confirmar
                </Button>
                <Button
                  htmlType="submit"
                  type="danger"
                  onClick={() => setAction('NOT_CONFIRM')}
                  disabled={loading}
                >
                  Reprovar
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LicenseConfirmation;
