import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Typography } from 'antd';
import Button from 'components/Button';

import { useFetch } from 'services/hooks';

import './styles.less';

const { Title, Text } = Typography;

const DeleteFilterModal = ({ isVisible, onClose, filter }) => {
  const { delete: deleteFilterRequest, loading: deletingFilter } = useFetch();
  const closeHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  const DeleteFilterHandler = useCallback(async () => {
    await deleteFilterRequest({
      url: `/query-filters/${filter.id}`,
    }).then(() => onClose());
  }, [deleteFilterRequest, onClose, filter]);

  return (
    <Modal
      visible={isVisible}
      wrapClassName="caf-modal confirm-action"
      destroyOnClose
      closable
      onCancel={closeHandler}
      footer={null}
      width={500}
    >
      <div align="center">
        <Title className="title">Deletar Filtro</Title>
        <Text className="text">
          Tem certeza que deseja deletar o filtro <b>{filter?.name}</b>?
        </Text>
        <div align="center" className="actions">
          <Button onClick={closeHandler} type="link" className="link-button-delete">
            Cancelar
          </Button>
          <Button
            onClick={DeleteFilterHandler}
            loading={deletingFilter}
            className="btn-custom btn-custom-danger"
          >
            Sim, deletar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteFilterModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape.isRequired,
};

export default DeleteFilterModal;
