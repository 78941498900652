import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import { Trash } from '@combateafraude/icons/general';
import useSharedFacesetDeleteModal from './SharedFacesetDeleteModal';

const ListDropdown = ({ data, refreshListRef }) => {
  const { sharedFacesetDeleteModal } = useSharedFacesetDeleteModal({
    data,
    type: 'Face',
    refreshListRef,
  });

  return (
    <>
      <Menu>
        <Menu.Item key="1" onClick={sharedFacesetDeleteModal} className="gx-text-danger">
          <Trash width={20} height={20} />
          Excluir
        </Menu.Item>
      </Menu>
    </>
  );
};

ListDropdown.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default ListDropdown;
