export const ALL_PRODUCTS_KEYS = ['SDK', 'MANAGEMENT', 'IDENTITY', 'TRUST'];

export const addFixedProductsKeysIfNeeded = (productsKeys) => {
  if (productsKeys) {
    const upperKeys = productsKeys.map((key) => key.toUpperCase());

    ALL_PRODUCTS_KEYS.forEach(
      (key) => !upperKeys.includes(key) && productsKeys.push(key)
    );
  }
};
