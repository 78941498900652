import React, { createContext, useCallback, useContext, useState } from 'react';
import { useFetch } from 'services/hooks';

const CustomerContext = createContext({});

const CustomerProvider = ({ children }) => {
  const {
    data: customerData,
    clearData: clearCustomer,
    loading: loadingCustomer,
    get: getCustomer,
  } = useFetch();

  const [loadDataParams, setLoadDataParams] = useState({});

  const loadCustomer = useCallback(
    async ({ tenantId, clearData: clearDataParam = false }) => {
      setLoadDataParams({
        tenantId,
      });

      await getCustomer({
        url: `/clients/${tenantId}`,
        clearData: clearDataParam,
      });
    },
    [getCustomer]
  );

  const reloadCustomer = useCallback(
    (clearData = true) => {
      if (!loadDataParams) return;

      loadCustomer({ ...loadDataParams, clearData });
    },
    [loadDataParams, loadCustomer]
  );

  return (
    <CustomerContext.Provider
      value={{
        customerData,
        customer: customerData?.docs,
        customerProducts: customerData?.products,
        loadingCustomer,
        loadCustomer,
        clearCustomer,
        reloadCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error('useCustomer must be used within an CustomerContext');
  }

  return context;
}

export { CustomerProvider, useCustomer };
