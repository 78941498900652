/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form,
  message,
  Spin,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Switch,
  Tooltip,
  Tag,
  Button,
} from 'antd';
import {
  CircleFalse,
  Checkmark,
  CheckCircle2,
  Plus,
  File,
} from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';

import currency from 'utils/currencyFormatter';
import { toMaskedCnpj } from 'utils/formatters';

import statusList from 'pages/private/Customers/CustomerList/utils/statusList.json';

import MarkdownEditor from 'components/MarkdownEditor';
import SelectCountry from 'components/SelectCountry';
import Subheader from './components/Subheader';
import Timeline from './components/Timeline';

import Wrapper from '../wrapper';

import './styles.less';

const { Option } = Select;

const DatasourceEdit = () => {
  const { source } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();

  const [loadingEdit, setLoadingEdit] = useState(true);

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [hasChangedDatasource, setHasChangedDatasource] = useState(false);

  const {
    get: getDatasourceEdit,
    data: datasourceData,
    loading: loadingUpdate,
    put: putDatasource,
  } = useFetch();

  const {
    data: validationsData,
    get: getValidations,
    loading: loadingValidations,
  } = useFetch();

  const { data: customers, get: getCustomers, loading: loadingCustomers } = useFetch();

  const openDrawer = useCallback(() => {
    if (!isDrawerOpen) {
      setIsDrawerOpen(true);
    }
  }, [isDrawerOpen]);

  const closeDrawer = useCallback(() => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen]);

  const setFormValues = useCallback(
    (data) => {
      if (!data) return;

      const formValues = {
        productType: data?.productType,
        type: data?.type,
        title: data?.title,
        sectionAttr: data?.sectionAttr,
        isDeprecated: data?.status === 'DEPRECATED',
        description: data?.description,
        price: data?.price || 0,
        category: data?.category,
        documentationUrl: data?.documentationUrl,
        _profileMapping: data?._profileMapping,
        availableOn: data?.availableOn,
        relatedRules: data?.relatedRules?.map((ruleObj) => ruleObj?.rule),
        restrictedTo: data?.restrictedTo?.map((tenantId) => ({ value: tenantId })),
        _onlyTransaction: data?._onlyTransaction,
        _isLatam: data?._isLatam,
        documentationBr: data?.documentationBr,
        documentationEn: data?.documentationEn,
        country: data?.country,
        cacheTTL: data?.cacheTTL,
      };

      form.setFieldsValue(formValues);
    },
    [form]
  );

  const getDatasource = useCallback(async () => {
    try {
      const response = await getDatasourceEdit({
        url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/services/${source}`,
        config: {},
      });

      await getValidations({
        url: '/reports/validations',
      });

      await getCustomers({
        url: '/clients',
        config: {
          params: {
            _offset: 0,
            _sort: 'fantasyName',
            _order: 1,
            _returnDocuments: false,
          },
        },
      });

      setFormValues(response?.data);
    } catch (error) {
      message.error(`Houve um problema ao obter dados para edição de fonte de dados.`);

      history.goBack();
    }

    setLoadingEdit(false);
  }, [getDatasourceEdit, source, setFormValues, history, getValidations, getCustomers]);

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setCustomersSearched(allCustomers);
      }

      const customersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.tenantId?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const handleFinish = useCallback(
    async (payload) => {
      try {
        const newPayload = {};

        const payloadKeys = Object.keys(payload);

        for (let i = 0; i < payloadKeys.length; i += 1) {
          const payloadKey = payloadKeys[i];

          if (payloadKey === 'relatedRules') {
            newPayload.relatedRules = payload?.relatedRules?.map((rule) => ({ rule }));
          } else if (payloadKey === 'isDeprecated') {
            newPayload.status = payload?.isDeprecated ? 'DEPRECATED' : '';
          } else if (payloadKey === 'restrictedTo') {
            newPayload.restrictedTo = payload?.restrictedTo?.map(
              (customer) => customer?.value
            );
          } else {
            newPayload[payloadKey] = payload[payloadKey];
          }
        }

        await putDatasource({
          url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/services/${source}`,
          payload: newPayload,
        });

        message.success('Fonte de dados alterada com sucesso!');
        setHasChangedDatasource(false);
      } catch (error) {
        message.error(`Houve um problema ao editar esta fonte de dados.`);
      }
    },
    [putDatasource, source]
  );

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (source && !datasourceData) {
      getDatasource();
    }
  }, [getDatasource, datasourceData, source]);

  useEffect(() => {
    const customersFiltered = customers?.docs?.filter(
      (customer) => customer?.fantasyName || customer?.companyName
    );

    setAllCustomers(customersFiltered || []);
    setCustomersSearched(customersFiltered || []);
  }, [customers]);

  const handleValueFormatting = (value) => {
    let newValue = '';
    if (`${value}`?.includes('-')) {
      newValue = currency.toCurrencyTwoDigits(
        `${value}`.replaceAll(`-`, ``) || 0.0,
        'R$'
      );
      return `${newValue}`.replace(`R$`, `R$ -`);
    }

    return currency.toCurrencyTwoDigits(value || 0.0, 'R$');
  };

  const handleValueParsing = (value) => {
    let newValue = '';
    if (`${value}`?.includes('-')) {
      newValue = currency.toNumberTwoDigits(`${value}`.replaceAll(`-`, ``));

      return `-${newValue || 0.0}`;
    }

    return currency.toNumberTwoDigits(value || 0.0);
  };

  return (
    <Wrapper
      subheader={
        <Subheader
          title={source}
          loading={loadingEdit || loadingUpdate}
          formRef={form}
          disabledSaveButton={!hasChangedDatasource}
        />
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {loadingEdit ? (
          <Spin className="flex center mrg-top-50" />
        ) : (
          <div>
            <Row>
              <Col span={3}>
                <Form.Item
                  label="Tipo do produto"
                  name="productType"
                  rules={[{ required: true }]}
                >
                  <Select
                    optionLabelProp="label"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    className="text-dark"
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      <Option key="source" value="source" label="Source">
                        Source
                      </Option>
                      <Option key="service" value="service" label="Service">
                        Service
                      </Option>
                      <Option key="template" value="template" label="Template">
                        Template
                      </Option>
                    </>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Tipo" name="type">
                  <Select
                    allowClear
                    optionLabelProp="label"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    className="text-dark"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      <Option key="PF" label="PF">
                        PF
                      </Option>
                      <Option key="PJ" label="PJ">
                        PJ
                      </Option>
                    </>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Título" name="title" rules={[{ required: true }]}>
                  <Input
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Section Attr"
                  name="sectionAttr"
                  rules={[{ required: true }]}
                >
                  <Input
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className="deprecated-switch">
                <Form.Item label="Depreciado" name="isDeprecated" valuePropName="checked">
                  <Switch
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={17}>
                <Form.Item
                  label="Descrição"
                  name="description"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    className="textarea-resize-none"
                    rows={2}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Preço" name="price">
                  <InputNumber
                    className="no-arrow"
                    formatter={handleValueFormatting}
                    parser={handleValueParsing}
                    min={0}
                    disabled={loadingUpdate}
                    onChange={(e) => {
                      setHasChangedDatasource(true);

                      if (!e) {
                        form.setFieldsValue({ price: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Categoria" name="category" rules={[{ required: true }]}>
                  <Select
                    optionLabelProp="label"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    className="text-dark"
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      <Option key="basic_info" value="basic_info" label="Basic Info">
                        Basic Info
                      </Option>
                      <Option key="contact" value="contact" label="Contact">
                        Contact
                      </Option>
                      <Option
                        key="criminal_background"
                        value="criminal_background"
                        label="Criminal background"
                      >
                        Criminal background
                      </Option>
                      <Option key="driver" value="driver" label="Driver">
                        Driver
                      </Option>
                      <Option key="finance" value="finance" label="Finance">
                        Finance
                      </Option>
                      <Option key="justice" value="justice" label="Justice">
                        Justice
                      </Option>
                      <Option key="media" value="media" label="Media">
                        Media
                      </Option>
                      <Option key="onboarding" value="onboarding" label="Onboarding">
                        Onboarding
                      </Option>
                      <Option
                        key="restrictive_lists"
                        value="restrictive_lists"
                        label="Restrictive Lists"
                      >
                        Restrictive Lists
                      </Option>
                      <Option key="work" value="work" label="Work">
                        Work
                      </Option>
                    </>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="URL da documentação" name="documentationUrl">
                  <Input
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Profile Mapping" name="_profileMapping">
                  <Select
                    allowClear
                    optionLabelProp="label"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    className="text-dark"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      <Option
                        key="background_checking"
                        value="background_checking"
                        label="Background Checking"
                      >
                        Background Checking
                      </Option>
                      <Option
                        key="identity_history"
                        value="identity_history"
                        label="Identity History"
                      >
                        Identity History
                      </Option>
                    </>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Disponível em" name="availableOn">
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    className="multi-select-green"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      <Option key="panel" label="Panel">
                        Panel
                      </Option>
                      <Option key="trust" label="Trust">
                        Trust
                      </Option>
                    </>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Regras relacionadas" name="relatedRules">
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    className="multi-select-green"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark />}
                    disabled={loadingUpdate}
                    loading={loadingValidations}
                    optionFilterProp="label"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    <>
                      {validationsData?.validations?.docs
                        ?.map((v) => (
                          <Option key={v.rule} value={v.rule} label={v.title}>
                            {v.title}
                          </Option>
                        ))
                        .sort((a, b) => a.props.label.localeCompare(b.props.label))}
                    </>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="flex align-center">
              <Col span={15}>
                <Form.Item label="Restrito à" name="restrictedTo">
                  <Select
                    mode="multiple"
                    labelInValue
                    placeholder="Busque por nome ou e-mail..."
                    optionLabelProp="label"
                    className="multi-select-green"
                    dropdownClassName="multiselect-filters"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark />}
                    loading={loadingCustomers}
                    notFoundContent={
                      !loadingCustomers && !customers?.docs?.length ? (
                        <span>
                          {searchCustomer
                            ? `Nenhum resultado encontrado para "${searchCustomer}"`
                            : 'Busque clientes por nome ou e-mail'}
                        </span>
                      ) : null
                    }
                    onSearch={onCustomerSearch}
                    onClear={() => setCustomersSearched(allCustomers)}
                    onBlur={() => {
                      setSearchCustomer(null);
                      setCustomersSearched(allCustomers);
                    }}
                    onChange={() => setHasChangedDatasource(true)}
                  >
                    {customersSearched?.map((c) => (
                      <Option
                        key={c._id}
                        label={c.fantasyName || c.companyName}
                        value={c.tenantId}
                      >
                        <div className="multiselect-option">
                          <span className="option-title">
                            {c.fantasyName || c.companyName} - {c.tenantId}
                          </span>
                          <div className="flex start-center">
                            <span className="option-subtitle">
                              {toMaskedCnpj(c.cnpj)}
                            </span>
                            <section className="mrg-left-10">
                              <>
                                {c?.verified && (
                                  <Tooltip title="Em produção">
                                    <CheckCircle2
                                      with={20}
                                      height={20}
                                      className="contract-icon gx-text-success"
                                      style={{ MarginLeft: 5 }}
                                    />
                                  </Tooltip>
                                )}
                                {c?.hasContract && (
                                  <Tooltip title="Possui contrato">
                                    <File
                                      width={20}
                                      height={20}
                                      className="gx-text-primary"
                                    />
                                  </Tooltip>
                                )}
                                {c?.status && (
                                  <Tag
                                    className="mrg-left-5 font-size-10"
                                    color={statusList[c?.status || 'lead'].color}
                                  >
                                    {c?.status === 'internal-customer'
                                      ? 'Interno'
                                      : statusList[c?.status || 'lead'].name}
                                  </Tag>
                                )}
                              </>
                            </section>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3} className="centralized-switch">
                <Form.Item
                  label="Somente transação"
                  name="_onlyTransaction"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
              <Col span={5} className="centralized-switch">
                <Form.Item
                  label="Suporte à documentos latinos"
                  name="_isLatam"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <Form.Item label="Países" name="country">
                  <SelectCountry
                    isMultiple
                    className="multi-select-green"
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Tooltip title="Deixe o campo vazio ou com 0 para desabilitar o cache">
                  <Form.Item label="Cache (em segundos)" name="cacheTTL">
                    <InputNumber
                      disabled={loadingUpdate}
                      className="no-arrow"
                      allowClear
                      onChange={() => setHasChangedDatasource(true)}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Documentação (PT-BR)" name="documentationBr">
                  <MarkdownEditor
                    name="documentationBr"
                    form={form}
                    disabled={loadingUpdate}
                    onTextAreaChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Documentação (Inglês)" name="documentationEn">
                  <MarkdownEditor
                    name="documentationEn"
                    form={form}
                    disabled={loadingUpdate}
                    onTextAreaChange={() => setHasChangedDatasource(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        {
          <>
            <Timeline closeDrawer={closeDrawer} isDrawerVisible={isDrawerOpen} />
            <div className="timeline-button-wrapper">
              <Button
                disabled={loadingEdit || loadingUpdate}
                onClick={openDrawer}
                className="timeline-button"
              >
                Linha do tempo <Plus width={20} height={20} />
              </Button>
            </div>
          </>
        }
      </Form>
    </Wrapper>
  );
};

export default DatasourceEdit;
