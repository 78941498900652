import React, { useMemo, useState, useCallback } from 'react';
import { Modal, Form, Dropdown, Menu, message } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import SearchInput from 'components/Form/SearchInput';
import { useDynamoTable } from 'components/List';
import PageTitle from 'components/PageTitle';

import { Close, Key, MoreVertical } from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';

const ManagementUsers = () => {
  const { tenantId } = useParams();
  const { post: postForgotPassword } = useFetch();

  const handleClick = useCallback(
    async (data) => {
      try {
        postForgotPassword({
          url: `/clients-auth/${tenantId}/users/forgot-password`,
          payload: {
            email: data?.username,
          },
        });
      } catch (error) {
        message.error('Erro ao solicitar recuperação de senha');
      }
    },
    [postForgotPassword, tenantId]
  );

  const columns = [
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name',
      render: (__, record) => (
        <span className="flex align-center text-dark">{record?.name}</span>
      ),
    },
    {
      key: 'username',
      title: 'Email',
      dataIndex: 'username',
      render: (__, record) => (
        <span className="flex align-center text-dark">{record?.username}</span>
      ),
    },
    {
      key: 'deletedAt',
      title: 'Status',
      dataIndex: 'deletedAt',
      render: (__, record) => (
        <span className="flex align-center text-dark">
          {record?.deletedAt ? 'Inativo' : 'Ativo'}
        </span>
      ),
    },
    {
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleClick(record)}>
                <Key width={20} height={20} style={{ MarginRight: 4 }} />
                Recuperar senha
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    columns,
    getParams: {
      url: `/clients-auth/${tenantId}/users`,
      config: {},
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setFilterData({
      _search: undefined,
    });
    form.resetFields();
  }, [form, setFilterData]);

  const openModal = useCallback(() => {
    setModalVisible(true);
    refreshList();
  }, [refreshList]);

  const ManagementUsersModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        destroyOnClose
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <PageTitle
          title="Usuários do management"
          subtitle="Gerencie os usuários da base"
        />
        <Form
          form={form}
          layout="vertical"
          className="mrg-top-10"
          onFinish={() => {}}
          onValuesChange={handleFormChange}
        >
          <SearchInput
            className="mrg-btm-10"
            placeholder="Pesquise por nome ou e-mail..."
            style={{ width: 300 }}
          />
          {tableContent}
          <div className="flex end fd-row space-between mrg-top-35" />
        </Form>
      </Modal>
    );
  }, [form, modalVisible, closeModal, handleFormChange, tableContent]);

  return {
    openModal,
    closeModal,
    ManagementUsersModal,
  };
};

export default ManagementUsers;
