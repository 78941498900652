import React, { useEffect } from 'react';

import Wrapper from '../wrapper';

const DatasourcesWrapper = ({ children, ...rest }) => {
  useEffect(() => {}, []);
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default DatasourcesWrapper;
