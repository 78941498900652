import React from 'react';
import PropTypes from 'prop-types';

const MenuItemDropDown = ({ text, icon }) => {
  return (
    <>
      {icon}
      {text}
    </>
  );
};

MenuItemDropDown.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default MenuItemDropDown;
