import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { CheckCircle2 } from '@combateafraude/icons/general';
import './styles.less';

const { Title, Text } = Typography;

const ExportFilterModal = ({ isVisible, onClose, filters }) => {
  if (isVisible) {
    navigator.clipboard.writeText(JSON.stringify(filters));
  }

  return (
    <Modal
      visible={isVisible}
      wrapClassName="caf-modal confirm-action"
      destroyOnClose
      closable
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <div align="middle" justify="center">
        <CheckCircle2 className="tooltip" width={60} height={60} />
        <Title className="title">Filtro copiado com sucesso!</Title>
        <Text>
          Clique no botão de importar o filtro e ele será automaticamente copiado para a
          área de transferência. Você também pode compartilhá-lo com outros colaboradores
          (clicando em Ctrl + V).
        </Text>
      </div>
    </Modal>
  );
};

ExportFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  filters: PropTypes.shape.isRequired,
};

export default ExportFilterModal;
