import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin, DatePicker, Row, Col, Form, Select } from 'antd';
import { Search, Calendar, ArrowRight1, ArrowDown } from '@combateafraude/icons/general';
import { getDate, getMonth, getYear, lastDayOfMonth, subMonths } from 'date-fns';
import moment from 'moment';
import _ from 'lodash';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';

import { toMaskedCnpj } from 'utils/formatters';
import formattedDate from 'utils/formattedDate';

const { RangePicker } = DatePicker;
const { Option } = Select;

const TabComponent = ({ title, dashboardNumber }) => {
  const { post, loading } = useFetch();
  const {
    get: getCustomers,
    data: customers,
    loading: loadingCustomers,
    clearData: clearCustomers,
  } = useFetch();

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [src, setSrc] = useState(undefined);

  const getPreviousMonthInfo = useCallback((today) => {
    const todayInLastMonth = subMonths(today, 1);
    const lastDay = formattedDate(getDate(lastDayOfMonth(todayInLastMonth)));
    const month = formattedDate(getMonth(todayInLastMonth) + 1);
    const year = getYear(todayInLastMonth);
    return {
      lastDay,
      month,
      year,
      de: `${year}-${month}-01`,
      at_: `${year}-${month}-${lastDay}`,
    };
  }, []);

  const fetch = useCallback(
    async (params = {}) => {
      const { de, at_ } = getPreviousMonthInfo(new Date());

      const response = await post({
        url: '/dashboard/metabase',
        payload: {
          dashboardNumber,
          bordered: true,
          params: {
            de,
            at_,
            ...params,
          },
        },
      });

      setSrc(response.iframeUrl);
    },
    [post, dashboardNumber, getPreviousMonthInfo]
  );

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetch();
  }, [fetch]);

  const getInitialDate = useCallback(() => {
    const { de, at_ } = getPreviousMonthInfo(new Date());

    return [moment(new Date(`${de} 03:00`)), moment(new Date(`${at_} 03:00`))];
  }, [getPreviousMonthInfo]);

  const onFinishHandler = useCallback(
    (values) => {
      const getDateFormatted = (date) => {
        if (!date) return '';

        const jsDate = moment(date).toDate();
        const day = formattedDate(getDate(jsDate));
        const month = formattedDate(getMonth(jsDate) + 1);
        const year = getYear(jsDate);
        return `${year}-${month}-${day}`;
      };

      fetch({
        de: getDateFormatted(values.dates[0]),
        at_: getDateFormatted(values.dates[1]),
        tenant: values.tenant,
      });
    },
    [fetch]
  );

  const onCustomerSearch = useCallback(
    _.debounce(async (searchTerm) => {
      if (searchTerm.trim().length < 2) {
        setSearchCustomer(null);
        clearCustomers();
        return;
      }

      setSearchCustomer(searchTerm);
      await getCustomers({
        url: '/clients',
        config: {
          params: {
            _limit: 20,
            _offset: 0,
            _search: searchTerm,
            _sort: 'fantasyName',
            _order: 1,
          },
        },
      });
    }, 500),
    [getCustomers]
  );

  return loading && !src ? (
    <Spin />
  ) : (
    <>
      <Form
        layout="vertical"
        className="mrg-top-10"
        onFinish={onFinishHandler}
        autoComplete="new-data"
        initialValues={{ dates: getInitialDate() }}
      >
        <Row className="flex fd-row mrg-btm-15">
          <Col span={8} className="gx-w-100">
            <Form.Item name="dates">
              <RangePicker
                className="gx-w-100"
                format="DD/MM/YYYY"
                suffixIcon={<Calendar width={20.5} height={20.5} />}
                separator={<ArrowRight1 />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tenant">
              <Select
                showSearch
                dropdownClassName="multiselect-filters"
                loading={loadingCustomers}
                placeholder="Busque por nome do cliente"
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={onCustomerSearch}
                suffixIcon={<ArrowDown />}
                notFoundContent={
                  !loadingCustomers &&
                  !customers?.docs?.length && (
                    <span>
                      {searchCustomer
                        ? `Nenhum resultado encontrado para "${searchCustomer}"`
                        : 'Busque clientes por nome'}
                    </span>
                  )
                }
              >
                {customers?.docs?.map((d) => (
                  <Option
                    key={d._id}
                    label={d.fantasyName || d.companyName}
                    value={d.tenantId}
                  >
                    <div className="multiselect-option">
                      <span className="option-title">
                        {d.fantasyName || d.companyName}
                      </span>
                      <span className="option-subtitle">{toMaskedCnpj(d.cnpj)}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Button
            htmlType="submit"
            className="btn-custom btn-custom-primary btn-search"
            loading={loading}
          >
            <Search width={20} height={20} />
          </Button>
        </Row>
      </Form>
      <iframe title={title} src={src} frameBorder="0" width="100%" height="100%" />
    </>
  );
};

TabComponent.propTypes = {
  title: PropTypes.string.isRequired,
  dashboardNumber: PropTypes.number.isRequired,
};

export default TabComponent;
