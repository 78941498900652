import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import { ArrowDown } from '@combateafraude/icons/general';

const { Option } = Select;

const SortSelect = ({ ...rest }) => {
  const [query, setQuery] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
  });

  const handleOnChange = useCallback(
    (value) => {
      if (value === '1') {
        setQuery({ _order: value, _sort: 'createdAt' });
      } else {
        setQuery({ _order: undefined, _sort: undefined });
      }
    },
    [setQuery]
  );

  return (
    <Select
      bordered={false}
      style={{ minWidth: 100 }}
      placeholder="Mais recente"
      onChange={handleOnChange}
      value={query?._order}
      className="text-dark"
      suffixIcon={<ArrowDown />}
      {...rest}
    >
      <Option value="">Mais recente</Option>
      <Option value="1">Mais antigo</Option>
    </Select>
  );
};

export default SortSelect;
