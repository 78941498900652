import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'antd';

const { Link } = Anchor;

const DynamicCardsList = ({
  dynamicCards,
  containsImages,
  containsPendingProcess,
  isWorkflowBuilder,
}) => {
  const dynamicCardsList = useMemo(() => {
    return (
      <>
        {dynamicCards?.liveness && <Link href="#liveness-component" title="Liveness" />}
        {dynamicCards?.officialDataReprove && (
          <Link
            href="#official-data-reprove-component"
            title="Reprovado no Official Data"
          />
        )}
        {dynamicCards?.sanctionsReprove && (
          <Link href="#sanctions-reprove-component" title="Sanções" />
        )}
        {dynamicCards?.customDataAvailable && (
          <Link href="#custom-data-available-component" title="Custom Data" />
        )}
        {dynamicCards?.confirmDocuments && (
          <Link
            href="#documents-confirmation-component"
            title="Confirmação de documentos"
          />
        )}
        {dynamicCards?.suspectFromSharedFaceset && (
          <Link
            href="#shared-faceset-confirmation-component"
            title="Banco compartilhado de suspeitos"
          />
        )}
        {dynamicCards?.suspectFromPrivateFaceset && (
          <Link
            href="#private-faceset-confirmation-component"
            title="Banco privado de suspeitos"
          />
        )}
        {dynamicCards?.confirmData && (
          <Link
            href="#execution-data-confirmation-component"
            title="Confirmação de dados"
          />
        )}
        {dynamicCards?.confirmLicense && (
          <Link href="#license-confirmation-component" title="Confirmação de Alvará" />
        )}
        {dynamicCards?.rgIssueLessThan25 && (
          <Link href="#rg-issue-date-component" title="Confirme a data de emissão" />
        )}
        {containsImages && !isWorkflowBuilder && (
          <Link
            className="is-dynamic-card-fixed"
            href="#ocr-details-component"
            title="Detalhes do OCR"
          />
        )}
        {containsImages && (
          <Link
            className="is-dynamic-card-fixed"
            href="#sended-images-component"
            title="Imagens dos documentos"
          />
        )}
        {containsPendingProcess && (
          <Link
            className="is-dynamic-card-fixed"
            href="#process-information-component"
            title="Processos"
          />
        )}
        {isWorkflowBuilder && (
          <Link
            className="is-dynamic-card-fixed"
            href="#services-status-component"
            title="Status dos serviços"
          />
        )}
        <Link
          className="is-dynamic-card-fixed"
          href="#processed-infos-component"
          title="Execução completa"
        />
        {!isWorkflowBuilder && (
          <Link
            className="is-dynamic-card-fixed"
            href="#compliance-rules-component-table"
            title="Regras de compliance"
          />
        )}
      </>
    );
  }, [dynamicCards, containsImages, containsPendingProcess, isWorkflowBuilder]);

  return <Anchor affix={false}>{dynamicCardsList}</Anchor>;
};

DynamicCardsList.propTypes = {
  dynamicCards: PropTypes.shape({
    confirmData: PropTypes.bool.isRequired,
    confirmDataDescription: PropTypes.string.isRequired,
    confirmDocuments: PropTypes.bool.isRequired,
    customDataAvailable: PropTypes.bool.isRequired,
    liveness: PropTypes.bool.isRequired,
    confirmLicense: PropTypes.bool.isRequired,
    officialDataReprove: PropTypes.bool.isRequired,
    rgIssueLessThan25: PropTypes.bool.isRequired,
    sanctionsReprove: PropTypes.bool.isRequired,
    suspectFromSharedFaceset: PropTypes.bool.isRequired,
    suspectFromPrivateFaceset: PropTypes.bool.isRequired,
  }).isRequired,
  containsImages: PropTypes.bool.isRequired,
  containsPendingProcess: PropTypes.bool.isRequired,
  isWorkflowBuilder: PropTypes.bool.isRequired,
};

export default DynamicCardsList;
