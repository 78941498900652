import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Switch, Typography } from 'antd';
import ReactJson from 'react-json-view';

import { useExecution } from 'hooks/execution';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

const { Text } = Typography;

const ResultComponent = ({ label }) => ({ label });

ResultComponent.propTypes = {
  label: PropTypes.string.isRequired,
};

const ProcessedInfos = () => {
  const { executionData } = useExecution();

  const [isFormatted, setIsFormatted] = useState(false);

  const cardContent = useMemo(() => {
    return isFormatted ? (
      <ReactJson
        name={false}
        src={executionData?.executions || {}}
        iconStyle="triangle"
        indentWidth={2}
        collapsed={1}
        theme="shapeshifter:inverted"
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
      />
    ) : (
      <pre className="execution-json">
        {JSON.stringify(executionData?.executions, null, 2)}
      </pre>
    );
  }, [isFormatted, executionData]);

  return (
    <div id="processed-infos-component">
      <Row align="middle">
        <Col span={16}>
          <PageTitle title="Informações do processamento" />
        </Col>
        <Col span={8} className="formatted-switch-col">
          <Switch checked={isFormatted} onChange={setIsFormatted} />
          <Text strong className="no-mrg mrg-left-10">
            Formatação
          </Text>
        </Col>
      </Row>

      <Card>{cardContent}</Card>
    </div>
  );
};

export default ProcessedInfos;
