import React, { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown, Modal, Row, Col, Typography, Form, Select } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import { useCustomer } from 'hooks/customer';
import { useTimeline } from 'hooks/timeline';

import Button from 'components/Button';
import { useTable } from 'components/List';
import Tag from 'components/Tag';
import UserHasPermissions from 'components/UserHasPermissions';

import currency from 'utils/currencyFormatter';
import formattedDate from 'utils/formattedDate';

import { ArrowDown, MoreVertical, Reorder } from '@combateafraude/icons/general';
import Wrapper from '../../wrapper';
import ListDropdown from '../components/ListDropdown';
import useInvoiceCreate from '../InvoiceCreate';

const { Option } = Select;
const { Title, Text } = Typography;

const InvoiceList = () => {
  const { tenantId } = useParams();
  const refreshListRef = useRef(() => {});
  const [query] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
    status: StringParam,
  });

  const { customer } = useCustomer();
  const { openModalMandatory } = useTimeline();

  const { openModal: openCreateModal, InvoiceCreateModal } = useInvoiceCreate({
    refreshListRef,
  });

  const columns = [
    {
      key: 'createdAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Emissão</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'createdAt',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (text) => format(new Date(text), "dd/MM/yyyy 'às' HH:mm"),
    },
    {
      key: 'referenceDate',
      title: 'Data de Referência',
      render: (data) =>
        data.monthReference && data.yearReference
          ? `${formattedDate(data.monthReference)}/${data.yearReference}`
          : '',
    },
    {
      key: 'due_date',
      title: (
        <div className="flex center row">
          <span className="column-title">Vencimento</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'due_date',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
      render: (text) => format(new Date(parseISO(text)), 'dd/MM/yyyy'),
    },
    {
      key: 'total_cents', // total
      title: (
        <div className="flex center row">
          <span className="column-title">Valor Total</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'total_cents',
      sorter: {
        compare: () => 0,
        multiple: 3,
      },
      render: (total_cents) => currency.numberToFormattedString(total_cents / 100 || 0),
    },
    {
      key: 'paid_cents', // paid
      title: (
        <div className="flex center row">
          <span className="column-title">Valor Pago</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'paid_cents',
      sorter: {
        compare: () => 0,
        multiple: 4,
      },
      render: (paid_cents) => currency.numberToFormattedString(paid_cents / 100 || 0),
    },
    {
      key: 'status',
      title: (
        <div className="flex center row">
          <span className="column-title">Status</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'status',
      sorter: {
        compare: () => 0,
        multiple: 5,
      },
      render: (text = undefined) => {
        let color = 'default';
        switch (text?.toUpperCase()) {
          case 'PENDING':
            color = 'warning';
            break;
          case 'PAID':
            color = 'success';
            break;
          case 'EXPIRED':
          case 'CANCELED':
            color = 'error';
            break;
          default:
            break;
        }
        const label = text
          ?.toUpperCase()
          .replace('PENDING', 'Pendente')
          .replace('PAID', 'Pago')
          .replace('EXPIRED', 'Expirado')
          .replace('CANCELED', 'Cancelado');
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown invoice={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const openModalAndTimeline = useCallback(() => {
    if (!customer?.address || _.isEmpty(customer?.address)) {
      Modal.error({
        title: 'Cliente não possui endereço',
        content:
          'Para gerar uma cobrança, o cliente precisa estar cadastrado com endereço completo',
        okText: 'Fechar',
      });
      return;
    }
    openCreateModal();
    openModalMandatory();
  }, [customer, openCreateModal, openModalMandatory]);

  const { tableContent, refreshList, setFilterData } = useTable({
    columns,
    getParams: {
      url: '/invoices',
      config: {
        params: {
          _tenantId: tenantId,
        },
      },
    },
    queryParams: {
      status: StringParam,
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      {customer && (
        <>
          {InvoiceCreateModal}

          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} className="page-title">
              <Title className="title">Cobranças</Title>
              <Text className="subtitle">Gerencie as cobranças de contas.</Text>
            </Col>
            <Col>
              <Form
                layout="inline"
                onValuesChange={handleFormChange}
                initialValues={{ status: query?.status }}
              >
                <Form.Item name="status" className="no-mrg-btm">
                  <Select
                    defaultValue=""
                    bordered={false}
                    style={{ width: 200, fontWeight: 500 }}
                    className="text-dark"
                    suffixIcon={<ArrowDown />}
                  >
                    <Option value="">Todas as cobranças</Option>
                    <Option value="paid">Pagas</Option>
                    <Option value="expired">Expiradas</Option>
                    <Option value="canceled">Canceladas</Option>
                    <Option value="pending">Pendentes</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col className="mrg-left-20">
              <UserHasPermissions permissions={['finances']}>
                <Button onClick={openModalAndTimeline} className="btn-custom-secondary">
                  Nova cobrança
                </Button>
              </UserHasPermissions>
            </Col>
          </Row>

          {tableContent}
        </>
      )}
    </Wrapper>
  );
};

export default InvoiceList;
