/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Modal } from 'antd';
import Button from 'components/Button';
import { NavLink } from 'react-router-dom';

import { Edit, Trash } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';

const ListDropdown = ({ data, hub, refreshListRef }) => {
  const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { put: putHub } = useFetch();

  const handleCancel = () => {
    setIsModalRemoveVisible(false);
  };

  const handleRemove = async () => {
    setLoading(true);
    await putHub({
      url: `/hubs-auth/${hub?.hubData?.id}`,
      payload: {
        hubName: hub?.hubData?.hubName,
        tenantsId: [data?.tenantId],
        active: hub?.hubData?.active,
        remove: true,
      },
    });
    window.location.reload();
    refreshListRef.current();
    setLoading(false);
    setIsModalRemoveVisible(false);
  };

  return (
    <>
      <Menu>
        <Menu.Item key="1">
          <NavLink to={`/customers/${data?.tenantId}/registration/`}>
            <Edit width={24} height={24} style={{ marginRight: 4 }} />
            Editar
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="2"
          className="gx-text-danger"
          onClick={() => {
            setIsModalRemoveVisible(true);
          }}
        >
          <Trash width={20} height={20} style={{ marginRight: 4 }} />
          Remover
        </Menu.Item>
      </Menu>

      <Modal
        visible={isModalRemoveVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="flex-column center">
          <h1 className="mrg-vertical-20 text-bold text-center">
            Deseja remover esse tenant do HUB?
          </h1>
          <span className="width-80 text-center">
            Esta ação removerá o tenantId do HUB e os usuários não terão acesso ao trust
            em diferentes tenants
          </span>
        </div>
        <div className="flex space-between mrg-top-50">
          <div />
          <div>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button type="danger" onClick={handleRemove} loading={loading}>
              Remover
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ListDropdown.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    hasContract: PropTypes.bool,
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default ListDropdown;
