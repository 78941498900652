/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { Card } from 'antd';
import { useExecution } from 'hooks/execution';
import PageTitle from 'components/PageTitle';
import { uuid } from 'uuidv4';
import { toFormatDate } from 'utils/formatters';
import ProcessTag from './ProcessTag';

const ProcessesInformation = () => {
  const { executionData } = useExecution();

  const createUniqueKeys = useCallback(
    (array) =>
      array.map((item) => ({
        ...item,
        _id: uuid(),
      })),
    []
  );

  const createList = useCallback((array) => {
    if (!array || array.length === 0) return <></>;
    const list = array.map((row) => {
      return (
        <div key={row._id} id="process-information-item">
          <div className="process-information-data">
            <ProcessTag title={row.authorType} type="warning" />
            <strong>{`${row.lawsuitId} - ${row.lawsuitClass} -`}</strong>
            <span className="process-main-subject">{row.lawBranch}</span>
            <span className="mrg-left-5">
              | Status:
              <small className="mrg-left-5 font-size-14">
                {row?.observationStatus || row?.complementaryStatus || 'indefinido'}
              </small>
            </span>
          </div>
          <p className="process-information-data-footer">
            {row?.moves?.length > 0 && (
              <span>
                Distribuído em {toFormatDate(row?.distributionDate)} e última atualização
                em
                {row?.moves[0]?.date}
              </span>
            )}
            • Tribunal
            {row?.lawBranch || ''} - {row?.judgingBody} - {row?.court} ({row?.state})
          </p>
        </div>
      );
    });

    return <div id="process-information-list">{list}</div>;
  }, []);

  const ruleList = useMemo(() => {
    if (!executionData) return [];

    const data = executionData?.executions?.sections?.pfProcessesAlt?.data?.results || [];

    const processesWithKeys = createUniqueKeys(data);

    return createList(processesWithKeys);
  }, [executionData, createUniqueKeys, createList]);

  return (
    <div id="process-information-component">
      <PageTitle title="Ação requerida: esta pessoa possui processos vinculados ao seu nome" />
      <Card>{ruleList}</Card>
    </div>
  );
};

export default ProcessesInformation;
