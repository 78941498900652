/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Form, Input, List, Select, Typography } from 'antd';
import PageTitle from 'components/PageTitle';
import { Close, Company } from '@combateafraude/icons/general';
import Button from 'components/Button';
import { useFetch } from 'services/hooks';
import PropTypes from 'prop-types';
import './style.less';

const { Option } = Select;
const { Text } = Typography;
let queryParams = {};

const HubForm = ({ formRef, hubs }) => {
  const { get: getCustomers, data: customers, loading: loadingCustomers } = useFetch();

  const [dataFetched, setDataFetched] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const blockedTenants = useMemo(() => {
    return [].concat(...hubs.map((hub) => hub?.tenants));
  }, [hubs]);

  const fetchClients = async () => {
    queryParams = {
      _offset: 0,
      _limit: 10,
      _sort: 'fantasyName',
      _order: 1,
      _returnDocuments: false,
    };

    if (searchValue) {
      queryParams._search = searchValue.toString();
    }
    setLoading(true);
    await getCustomers({
      url: '/clients',
      config: {
        params: queryParams,
      },
    });
    setLoading(false);
    setDataFetched(true);
  };

  useEffect(() => {
    const handleHubCreated = () => {
      setSelectedItems([]);
      setSelectedItem([]);
      queryParams._search = '';
      fetchClients();
    };
    document.addEventListener('closedModal', handleHubCreated);
    return () => {
      document.removeEventListener('closedModal', handleHubCreated);
    };
  }, []);

  useEffect(() => {
    fetchClients();
  }, [dataFetched, getCustomers, searchValue, blockedTenants]);

  useEffect(() => {
    if (customers?.docs) {
      setFilteredCustomers(customers.docs);
    }
  }, [customers]);

  const handleSearch = (value) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => {
      setSearchValue(value);
    }, 500);

    setDebounceTimer(newDebounceTimer);
  };

  const handleAddItem = (item) => {
    setSelectedItems([...selectedItems, item]);
    setSelectedItem([]);
  };

  useEffect(() => {
    const tenantsId = selectedItems?.map((item) => {
      const infos = item?.split('tenantId:');
      return infos[1]?.trim();
    });
    formRef?.setFieldsValue({ tenantsId });
  }, [selectedItems, formRef]);

  return (
    <>
      <PageTitle
        title="Novo HUB de contas"
        subtitle="Preencha os dados da nova conta principal"
      />
      <Form
        form={formRef}
        layout="vertical"
        className="mrg-top-10"
        autoComplete="new-customer"
      >
        <>
          <Form.Item name="hubName" label="Nome" rules={[{ required: true }]}>
            <Input
              autoComplete="new-name"
              className="text-dark"
              prefix={<Company />}
              placeholder="Digite o nome..."
              disabled={loadingCustomers}
              allowClear
              maxLength={50}
            />
          </Form.Item>
          <Divider className="mrg-50" />
          <h3 className="text-bold">Vincular contas</h3>
          <Form.Item className="flex">
            <div className="flex space-between">
              <Select
                style={{ width: '80%' }}
                placeholder="Selecione um cliente"
                showSearch
                allowClear
                onSearch={handleSearch}
                loading={loading}
                disabled={loadingCustomers && !searchValue}
                notFoundContent={loading ? 'Carregando...' : 'Nenhum cliente encontrado'}
                onSelect={(e) => setSelectedItem(e)}
                value={selectedItem}
              >
                {filteredCustomers.map((customer) => (
                  <Option
                    key={`${customer?.tenantId}${customer?.fantasyName}`}
                    value={`${customer?.fantasyName || '-'}tenantId:${
                      customer?.tenantId
                    }`}
                    disabled={blockedTenants.includes(customer?.tenantId)}
                    className={
                      blockedTenants.includes(customer?.tenantId) && 'gx-text-danger'
                    }
                  >
                    {`${customer?.fantasyName || '-'} - ${customer?.tenantId}`}
                  </Option>
                ))}
              </Select>
              <Button
                className="btn-primary"
                onClick={() => {
                  handleAddItem(selectedItem);
                }}
                disabled={
                  selectedItem?.length === 0 ||
                  selectedItems?.includes(selectedItem) ||
                  loadingCustomers
                }
              >
                Adicionar
              </Button>
            </div>
          </Form.Item>
          <Form.Item className="flex" name="tenantsId">
            {selectedItems?.length > 0 && (
              <>
                <Text className="text-bold text-dark">Contas selecionadas</Text>
                <List
                  className="demo-loadmore-list"
                  itemLayout="horizontal"
                  dataSource={selectedItems}
                  key={selectedItems}
                  pagination={{
                    pageSize: 5,
                  }}
                  renderItem={(item) => {
                    const infos = item?.split('tenantId:');
                    return (
                      <>
                        <div className="padding-10 border flex space-between mrg-vertical-5 hover-effect">
                          <div>
                            <span className="text-dark text-bold">{infos[0]}</span>
                          </div>
                          <div className="flex">
                            <span>
                              <span className="text-dark text-bold">TenantId: </span>
                              {infos[1]}
                            </span>
                            <div className="center mrg-left-10 pointer">
                              <Close
                                width={18}
                                height={18}
                                onClick={() => {
                                  selectedItems.splice(selectedItems.indexOf(item), 1);
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              </>
            )}
          </Form.Item>
        </>
      </Form>
    </>
  );
};

HubForm.propTypes = {
  hubs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HubForm;
