import React from 'react';
import { Button } from 'antd';

import './styles.less';

const SquaredButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} id="squared-button-component">
      {children}
    </Button>
  );
};

export default SquaredButton;
