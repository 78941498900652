import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Card, Divider } from 'antd';

const SDKCard = ({ icon, title, sdksList }) => {
  const sdkCard = useMemo(() => {
    return (
      <Card>
        <main className="flex start-center">
          {icon}
          <h4>{title}</h4>
        </main>

        <Divider />

        {sdksList?.map((item) => (
          <section key={item?.description}>
            <p>{item?.description}</p>
            <div
              className={classNames({
                active: item?.active,
                inactive: !item?.active,
              })}
            />
          </section>
        ))}
      </Card>
    );
  }, [icon, sdksList, title]);

  return <>{sdkCard}</>;
};

SDKCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  sdksList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SDKCard;
