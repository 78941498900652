import React, { useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Spin } from 'antd';

import FAButton from 'components/Button/FloatingActionButton';
import EmptyMessage from 'components/EmptyMessage';
import Loader from 'components/Loader';

import getDegreesFromString from 'utils/getDegreesFromString';

import './styles.less';
import '../common.less';
import { Less, Plus, RotateCcw, RotateCw } from '@combateafraude/icons/general';

const ZOOM_PIXELS = 100;

const ZoomedImage = ({ src, alt, label, height: defaultHeight }) => {
  const imgRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [defaultImgHeight] = useState(defaultHeight);

  const [style, setStyle] = useState({
    width: '100%',
    maxHeight: '100%',
    margin: '0 auto',
    transform: 'rotate(0deg)',
    opacity: 0,
  });

  const srcExtension = useMemo(() => {
    if (!src) return false;

    const imgUrl = src.split('s3.amazonaws.com/');
    if (imgUrl.length < 2) return false;

    const imgName = imgUrl[1].split('?')[0];
    const imgExtension = imgName.split('.');

    return imgExtension[imgExtension.length - 1];
  }, [src]);

  const calculateNewMargin = useCallback(
    (degrees, zoom = null) => {
      let margin = '0 auto';

      if (!imgRef.current) return margin;

      const { height: imgHeight, width: imgWidth } = imgRef.current;

      const width = zoom ? imgWidth + zoom : imgWidth;
      const height = zoom ? imgHeight + zoom : imgHeight;

      if (degrees === 90 || degrees === 270) {
        const marginCalculated = (height - width) / 2;
        margin = `${-marginCalculated}px ${marginCalculated}px`;

        if (width < defaultImgHeight) {
          const marginCalculated2 = (defaultImgHeight - height) / 2;
          margin = `${marginCalculated2}px ${marginCalculated}px`;
        }
      } else if (height < defaultImgHeight) {
        const marginCalculated = (defaultImgHeight - height) / 2;
        margin = `${marginCalculated}px auto`;
      }

      // if ((degrees === 90 || degrees === 270) && width > height) {
      //   const marginCalculated = (width - height) / 2;
      //   margin = `${marginCalculated}px auto`;
      // }

      return margin;
    },
    [defaultImgHeight]
  );

  const onLoadImage = useCallback(() => {
    setLoading(false);
    setStyle((state) => {
      const margin = calculateNewMargin(0);
      return {
        ...state,
        height: imgRef?.current?.height,
        margin,
        opacity: 1,
        width: 'auto',
        maxHeight: null,
      };
    });
  }, [calculateNewMargin]);

  const zoom = useCallback(
    (px) => {
      setStyle((state) => {
        const degrees = getDegreesFromString(state?.transform || '');
        const margin = calculateNewMargin(degrees, px);
        const height = (state?.height || 0) + px;

        return {
          ...state,
          margin,
          height: height > ZOOM_PIXELS ? height : 100,
        };
      });
    },
    [calculateNewMargin]
  );

  const rotate = useCallback(
    (direction) => {
      setStyle((state) => {
        const degrees = getDegreesFromString(state?.transform);
        let newDegrees = degrees === 270 ? 0 : degrees + 90;

        if (direction === 'counter-clockwise') {
          newDegrees = degrees === 0 ? 270 : degrees - 90;
        }

        const transform = `rotate(${newDegrees}deg)`;
        const margin = calculateNewMargin(newDegrees);

        return {
          ...state,
          margin,
          transform,
        };
      });
    },
    [calculateNewMargin]
  );

  const handleOnWheel = useCallback(
    (e) => {
      if (e.deltaY >= 0) {
        zoom(ZOOM_PIXELS * -1);
      } else {
        zoom(ZOOM_PIXELS);
      }
    },
    [zoom]
  );

  return (
    <div
      id="zoomed-image-component"
      style={{ height: defaultImgHeight + 2 }}
      onWheel={handleOnWheel}
    >
      {label && (
        <span className="zoomed-image-label">
          {label}
          {srcExtension && <strong>{` (${srcExtension})`}</strong>}
        </span>
      )}

      {src && !error ? (
        <>
          <ScrollContainer className="scroll-container">
            <img
              ref={imgRef}
              src={src}
              alt={alt || label}
              style={style}
              onError={() => setError(true)}
              onLoad={() => onLoadImage()}
            />
          </ScrollContainer>

          {loading ? (
            <div className="loading">
              <Spin
                indicator={<Loader size="25px" color="#bdbdbd" />}
                tip="Carregando..."
              />
            </div>
          ) : (
            <div className="zoomed-controls">
              <FAButton className="left-bottom" onClick={() => zoom(ZOOM_PIXELS * -1)}>
                <Less width={20} height={20} />
              </FAButton>
              <FAButton className="left-bottom num-2" onClick={() => zoom(ZOOM_PIXELS)}>
                <Plus width={20} height={20} />
              </FAButton>

              <FAButton
                className="right-bottom"
                onClick={() => rotate('counter-clockwise')}
              >
                <RotateCcw width={20} height={20} />
              </FAButton>
              <FAButton className="right-bottom num-2" onClick={rotate}>
                <RotateCw width={20} height={20} />
              </FAButton>
            </div>
          )}
        </>
      ) : (
        <div className="error">
          <EmptyMessage show type="image" description="Imagem não encontrada" />
        </div>
      )}
    </div>
  );
};

ZoomedImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.number,
};

ZoomedImage.defaultProps = {
  alt: null,
  label: null,
  height: 400,
};

export default ZoomedImage;
