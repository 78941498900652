import React from 'react';

import { AuthProvider } from './auth';
import { CustomerProvider } from './customer';
import { ExecutionProvider } from './execution';
import { ExecutionDetailsDoubleCheckFaceAuthenticatorProvider } from './executionDetailsDoubleCheckFaceAuthenticator';
import { ScrollProvider } from './scroll';
import { TimelineProvider } from './timeline';
import { AccountProductsProvider } from './accountProducts';
import { CustomerConfigurationsProvider } from './customerConfigs';
import { MultipleInvoicesProvider } from './useMultipleInvoices';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <ScrollProvider>
      <CustomerProvider>
        <CustomerConfigurationsProvider>
          <MultipleInvoicesProvider>
            <AccountProductsProvider>
              <ExecutionProvider>
                <ExecutionDetailsDoubleCheckFaceAuthenticatorProvider>
                  <TimelineProvider>{children}</TimelineProvider>
                </ExecutionDetailsDoubleCheckFaceAuthenticatorProvider>
              </ExecutionProvider>
            </AccountProductsProvider>
          </MultipleInvoicesProvider>
        </CustomerConfigurationsProvider>
      </CustomerProvider>
    </ScrollProvider>
  </AuthProvider>
);

export default AppProvider;
