import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Typography, Input, Alert } from 'antd';
import { WrongCircle, CheckCircle2 } from '@combateafraude/icons/general';
import Button from 'components/Button';

import './styles.less';

const { Title, Text } = Typography;

const ImportFilterModal = ({
  isVisible,
  onClose,
  onApplyFilters,
  onUpdateExecutions,
  onSave,
}) => {
  const [filterData, setFilterData] = useState(undefined);
  const [isValid, setIsValid] = useState(true);
  const [imported, setImported] = useState(false);

  const inputHandler = useCallback((event) => {
    setFilterData(event.target.value);
  }, []);

  const closeHandler = useCallback(() => {
    setImported(false);
    onClose();
  }, [onClose]);

  const saveHandler = useCallback(() => {
    closeHandler();
    onSave(true);
  }, [closeHandler, onSave]);

  const applyFilters = useCallback(async () => {
    onApplyFilters({ type: 'IMPORT_FILTERS', value: JSON.parse(filterData) });
    onUpdateExecutions();
    setImported(true);
  }, [filterData, onApplyFilters, onUpdateExecutions]);

  const getFromClipboard = useCallback(async () => {
    const dataFromClipBoard = await navigator.clipboard.readText();
    try {
      const filterObject = JSON.parse(dataFromClipBoard);
      if (
        Object.hasOwn(filterObject, 'customCreatedAt') &&
        Object.hasOwn(filterObject, 'creationDate') &&
        Object.hasOwn(filterObject, 'status') &&
        filterObject.status.length > 0
      ) {
        setIsValid(true);
        setFilterData(dataFromClipBoard);
      } else {
        setIsValid(false);
        setFilterData(undefined);
      }
    } catch {
      setIsValid(false);
      setFilterData(undefined);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      getFromClipboard();
    }
  }, [isVisible, getFromClipboard]);

  useEffect(() => {
    const verifyTimer = setTimeout(() => {
      try {
        const filterObject = JSON.parse(filterData);
        if (
          Object.hasOwn(filterObject, 'customCreatedAt') &&
          Object.hasOwn(filterObject, 'creationDate') &&
          Object.hasOwn(filterObject, 'status') &&
          filterObject.status.length > 0
        ) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch {
        setIsValid(false);
      }
    }, 300);

    return () => {
      clearTimeout(verifyTimer);
    };
  }, [filterData]);

  return (
    <Modal
      visible={isVisible}
      wrapClassName="caf-modal confirm-action"
      destroyOnClose
      closable
      onCancel={closeHandler}
      footer={null}
      width={600}
    >
      {!imported && (
        <>
          <div align="left">
            <Title className="title">Importar filtro</Title>
            <Text className="text">
              O código copiado foi colado para área de transferência.
            </Text>
          </div>
          <div align="center" className="main-div">
            <Input.TextArea
              style={{ height: 90, resize: 'none' }}
              autoFocus
              value={filterData}
              onChange={inputHandler}
            />
          </div>
          {!isValid && (
            <Alert
              className="error-alert"
              message="A informação inserida não é a esperada."
              type="error"
              icon={<WrongCircle width={25} height={25} />}
              showIcon
            />
          )}
          <div align="right">
            <Button
              disabled={!isValid}
              onClick={applyFilters}
              className="btn-custom btn-custom-primary"
            >
              Importar
            </Button>
          </div>
        </>
      )}
      {imported && (
        <>
          <div align="middle" justify="center">
            <CheckCircle2 className="tooltip" width={60} height={60} />
            <Title className="title">Filtro importado! Deseja salvá-lo?</Title>
            <Text>
              O filtro foi importado e está pronto para ser usado. Salvando-o você o terá
              disponível de forma rápida e fácil sempre que necessário.
            </Text>
          </div>
          <div align="right" className="actions">
            <Button onClick={closeHandler} type="link" className="link-button">
              Não
            </Button>
            <Button onClick={saveHandler} className="btn-custom btn-custom-primary">
              Sim
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

ImportFilterModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onUpdateExecutions: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ImportFilterModal;
