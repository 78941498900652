import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';

import { useTable } from 'components/List';

import Button from 'components/Button';

import { Reorder } from '@combateafraude/icons/general';
import Wrapper from '../../wrapper';
import useTrackingModal from '../../../components/TrackingModal';

const EventsList = () => {
  const refreshListRef = useRef(() => {});

  const { openModal, TrackingModal } = useTrackingModal();

  const columns = [
    {
      key: 'eventType',
      title: (
        <div className="flex center row">
          <span className="column-title">Tipo</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'eventType',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
    },
    {
      key: 'eventName',
      title: (
        <div className="flex center row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'eventName',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'eventDescription',
      title: (
        <div className="flex center row">
          <span className="column-title">Descrição</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'eventDescription',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
    },
    {
      key: 'createdAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Data de Criação</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'createdAt',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (text) => {
        try {
          return format(new Date(text), "dd/MM/yyyy 'às' HH:mm");
        } catch (error) {
          return '';
        }
      },
    },
    {
      render: (__, record) => {
        return (
          <Button
            className="no-mrg btn-custom btn-custom-primary"
            onClick={() => {
              openModal(record.trackingObjectId);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  const { tableContent, refreshList } = useTable({
    columns,
    getParams: {
      url: '/analytics/events',
      config: {
        params: {
          _users: true,
        },
      },
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      {TrackingModal}
      {tableContent}
    </Wrapper>
  );
};

export default EventsList;
