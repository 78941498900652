import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Modal, Form, message, Input } from 'antd';

import { useFetch } from 'services/hooks';
import { Close, EMail } from '@combateafraude/icons/general';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const SendInviteModal = ({ data, refreshListRef }) => {
  const [form] = Form.useForm();
  const { loading, post } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => {
    setModalVisible(true);
    form.setFieldsValue(data);
  }, [form, data]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    // se não fizer isso, aparece o modal sendo reinicializado ao fechar
    setTimeout(() => {
      form.resetFields();
    }, 250);
  }, [form]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await post({
          url: `/clients/${data.tenantId}/users-invite`,
          payload: {
            emails: values.email,
            language: data.language || 'en-US',
          },
        });
        refreshListRef.current();
        message.success('Convite enviado com sucesso.');
        closeModal();
      } catch (err) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [closeModal, data, post, refreshListRef]
  );

  const sendInviteModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={500}
      >
        <Row>
          <Col span={24}>
            <PageTitle
              title="Enviar um novo e-mail de convite para o cliente"
              subtitle="Informe um e-mail para onde devemos enviar um novo convite"
              align="center"
            />
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          className="mrg-top-10"
        >
          <Form.Item name="email" label="E-mail" rules={[{ required: true }]}>
            <Input
              autoComplete="new-email"
              className="text-dark"
              prefix={<EMail />}
              placeholder="Seu e-mail"
              allowClear
            />
          </Form.Item>
          <Row className="mrg-top-40">
            <Col span={24} className="text-center">
              <Button onClick={closeModal} disabled={loading}>
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                onClick={form.submit}
                loading={loading}
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    ),
    [modalVisible, closeModal, form, handleSubmit, loading]
  );

  return { openModal, sendInviteModal };
};

export default SendInviteModal;
