import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

const OpenAllButton = ({ data }) => {
  const handleOpenAll = useCallback(() => {
    const docs = data || [];

    docs.forEach((d) => {
      window.open(
        `/executions/details/user/${d.tenantId}/report/${d.reportId}/execution/${d.id}`,
        '_blank'
      );
    });
  }, [data]);

  return (
    <Button
      className="btn-custom-secondary"
      onClick={handleOpenAll}
      disabled={!data || data.length === 0}
    >
      Abrir todos
    </Button>
  );
};

OpenAllButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OpenAllButton;
