import React, { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Form, Dropdown } from 'antd';
import _ from 'lodash';
import { format } from 'date-fns';
import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';

import { useCustomer } from 'hooks/customer';

import { useTable } from 'components/List';
import SearchInput from 'components/Form/SearchInput';
import Button from 'components/Button';

import { MoreVertical, Reorder } from '@combateafraude/icons/general';
import Wrapper from '../wrapper';
import ListDropdown from './components/ListDropdown';
import MenuDropdown from './components/MenuDropdown';

const { Title, Text } = Typography;

const UserList = () => {
  const { tenantId } = useParams();
  const refreshListRef = useRef(() => {});
  const { customer } = useCustomer();

  const columns = [
    {
      key: 'identification',
      title: (
        <div className="flex center row">
          <span className="column-title">CPF/CNPJ</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'identification',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (record) =>
        record?.length === 11 ? toMaskedCpf(record) : toMaskedCnpj(record),
    },
    {
      key: 'name',
      title: (
        <div className="flex center row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'name',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'reason',
      title: (
        <div className="flex center row">
          <span className="column-title">Motivo de inclusão</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'reason',
      sorter: {
        compare: () => 0,
        multiple: 3,
      },
    },
    {
      key: 'updatedAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Inserido em</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'updatedAt',
      sorter: {
        compare: () => 0,
        multiple: 4,
      },
      render: (text) => format(new Date(text), "dd/MM/yyyy 'às' HH:mm"),
    },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown data={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];
  const { tableContent, setFilterData, refreshList } = useTable({
    columns,
    getParams: {
      url: `/clients/${tenantId}/deny-list`,
      config: {
        params: {
          _users: true,
          _tenantId: tenantId,
        },
      },
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      {customer && (
        <>
          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} className="page-title">
              <Title className="title">Deny list</Title>
              <Text className="subtitle">
                Adicione documentos que não podem ser cadastrados. <br />* Só terá efeitos
                para relatórios com a fonte Deny List configurada.
              </Text>
            </Col>
            <Col>
              <Form layout="inline" onValuesChange={handleFormChange}>
                <SearchInput
                  placeholder="Pesquise por nome ou CPF/CNPJ..."
                  style={{ width: 300 }}
                />
              </Form>
            </Col>
            <Col>
              <Dropdown
                overlay={<MenuDropdown data={tenantId} refreshListRef={refreshListRef} />}
                trigger={['click']}
              >
                <Button className="btn-custom-secondary">Adicionar</Button>
              </Dropdown>
            </Col>
          </Row>
          {tableContent}
        </>
      )}
    </Wrapper>
  );
};

export default UserList;
