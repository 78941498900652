import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { Form, message, Row, Col } from 'antd';
import InputMask from 'components/Form/InputMask';

import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import moment from 'moment';

import { useAuth } from 'hooks/auth';

const RGIssueLessThan25 = ({
  children,
  // setWhichComponentIsActive
}) => {
  const [form] = Form.useForm();
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData } = useExecution();
  const { loading, post, put } = useFetch();
  const { user } = useAuth();

  const { sections } = executionData?.executions || {};
  const { ocr } = sections || {};

  const onSubmitReviewFace = useCallback(
    async (values) => {
      try {
        if (!values.issueDate) {
          message.error('Por favor, indique a data de emissão');
          return;
        }

        const parsedDate = moment(values.issueDate, 'DDMMYYYY');
        const today = moment(new Date());

        const isPastDate = parsedDate.isBefore(today);

        if (!isPastDate) {
          message.error('Data de emissao invalida!');
          return;
        }

        await put({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/manual-ocr`,
          payload: {
            oldData: {
              issueDate: ocr.issueDate,
            },
            newData: {
              ...ocr,
              ...values,
            },
          },
        });

        message.success('Data de emissão atualizada com sucesso.');

        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/RG_ISSUE_LESS_THAN_25`,
          payload: {
            action:
              today.diff(parsedDate, 'years') >= 25
                ? 'IS_GREATER_THAN_25'
                : 'IS_LESS_THAN_25',
            _id: user?.id,
            email: user?.email,
          },
        });

        message.success('Ação realizada com sucesso.');
        reloadData(true);
      } catch (err) {
        message.error('Falha reportar o documentos.');
      }
    },
    [executionData, executionId, ocr, post, put, reloadData, reportId, tenantId, user]
  );

  return (
    <div id="rg-issue-date-component">
      <PageTitle
        title="Ação requerida: confirme a data de emissão do RG"
        subtitle="Avalie as imagens e confirme a data de emissão."
      />
      <Form
        form={form}
        onFinish={onSubmitReviewFace}
        layout="vertical"
        labelAlign="left"
        autoComplete="off"
      >
        <Card>
          <div id="container">
            <div id="images-container">{children}</div>
            <div id="inputs-container">
              <div className="flex-column flex-1 center" style={{ width: '320px' }}>
                <div className="flex-column gx-w-100 contain-width">
                  <Row align="middle" className="fd-row" justify="center">
                    <Col span={20}>
                      <Form.Item name="issueDate" label="Data de emissão">
                        <InputMask mask="99/99/9999" className="custom-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="mrg-top-30"
                >
                  Confirmar data de emissão
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Form>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('rg-issue-date-component')}
      /> */}
    </div>
  );
};

// RGIssueLessThan25.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default RGIssueLessThan25;
