import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { Row, Col, Form, Input, message, Tooltip } from 'antd';
import { Checkmark, Close } from '@combateafraude/icons/general';

import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';

import { toFormatDate } from 'utils/formatters';

import Button from 'components/Button';
import Card from 'components/Card';
import InputMask from 'components/Form/InputMask';
import PageTitle from 'components/PageTitle';

import useReprocessModal from 'components/Header/components/ExecutionSubheader/components/ReprocessModal';
import useRejectExecutionModal from 'components/Header/components/ExecutionSubheader/components/RejectExecutionModal';

const DataConfirmation = ({
  children,
  // setWhichComponentIsActive
}) => {
  const { tenantId, reportId, executionId } = useParams();
  const { loading, put } = useFetch();
  const { executionData, reloadData } = useExecution();
  const { openModal: openReprocessModal, reprocessModal } = useReprocessModal();
  const { openModal: openRejectExecutionModal, rejectExecutionModal } =
    useRejectExecutionModal();

  const [form] = Form.useForm();

  const [manualCpf, setManualCpf] = useState(null);
  const [showBasicDataFields, setShowBasicDataFields] = useState(false);
  const [showInfoconvFields, setShowInfoconvFields] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        delete values.parameters;
        delete values.basicData;
        delete values.infoconv;

        const { sections } = executionData.executions;
        const { ocr } = sections;

        await put({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/manual-ocr`,
          payload: {
            oldData: ocr,
            newData: {
              ...ocr,
              ...values,
            },
          },
        });

        message.success('Ação realizada com sucesso.');
        reloadData(false);
        openReprocessModal({ birthDate: values.birthDate });
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [put, tenantId, reportId, executionId, executionData, reloadData, openReprocessModal]
  );

  useEffect(() => {
    if (!executionData) return;

    const { parameters, sections } = executionData.executions;
    const { ocr, pfBasicData, pfInfoconv } = sections;

    // conteúdo extra para análise de dados BasicData
    const basicDataCpf = pfBasicData?.query?.data?.cpf;
    const basicDataData = pfBasicData?.data || {};
    const basicDataBirthDate = toFormatDate(
      `${basicDataData?.birthDate?.split('T')[0]}T23:59:59Z`,
      'dd/MM/yyyy'
    );

    // conteúdo extra para análise de documento da BRB
    const infoconvReturn = pfInfoconv?.data?.retornoConsultada || [];
    const infoconvData = infoconvReturn[0] || {};

    setManualCpf(ocr?.cpf);
    setShowBasicDataFields(() => pfBasicData?.statusCode === '01');
    setShowInfoconvFields(() => pfInfoconv?.statusCode === '01');

    form.setFieldsValue({
      ...ocr,
      infoconv: infoconvData,
      basicData: { ...basicDataData, cpf: basicDataCpf, birthDate: basicDataBirthDate },
      parameters,
    });
  }, [form, executionData]);

  const renderEqualityIcon = useMemo(() => {
    if (!executionData) return <></>;

    const { parameters } = executionData.executions;

    const ocrCPF = `${manualCpf}`.replace(/\D/g, '');
    const parametersCPF = `${parameters?.cpf}`.replace(/\D/g, '');

    if (ocrCPF === undefined || parametersCPF === undefined) {
      return (
        <Tooltip title="Algum dado está faltando">
          <Close width={30} height={30} className="gx-text-danger" />
        </Tooltip>
      );
    }

    return ocrCPF === parametersCPF ? (
      <Tooltip title="Os CPFs indicados são iguais">
        <Checkmark width={30} height={30} className="gx-text-primary" />
      </Tooltip>
    ) : (
      <Tooltip title="Os CPFs indicados não são iguais">
        <Close width={30} height={30} className="gx-text-danger" />
      </Tooltip>
    );
  }, [executionData, manualCpf]);

  return (
    <div id="execution-data-confirmation-component">
      <PageTitle
        title="Ação requerida: confirme os dados abaixo"
        subtitle="Avalie as imagens e confirme os dados abaixo."
      />
      <Card>
        <Row align="middle" justify="center">
          <Col className="document-col" span={12}>
            {children}
          </Col>
          <Col span={12}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <Row align="middle" className="fd-row">
                <Col span={12} className="flex center">
                  <Form.Item name="cpf" label="CPF" className="flex-1">
                    <Input
                      className="custom-input"
                      value={manualCpf}
                      onChange={(e) => setManualCpf(e.target.value)}
                    />
                  </Form.Item>
                  {renderEqualityIcon}
                </Col>
                <Col span={12}>
                  <Form.Item name={['parameters', 'cpf']} label="Parâmetro (CPF)">
                    <Input className="custom-input" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" className="fd-row">
                <Col span={12}>
                  <Form.Item name="birthDate" label="Data de nascimento">
                    <InputMask mask="99/99/9999" className="custom-input" />
                  </Form.Item>
                </Col>

                {showBasicDataFields && (
                  <>
                    <Col span={12}>
                      <Form.Item name={['basicData', 'cpf']} label="CPF (BasicData)">
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['basicData', 'birthDate']}
                        label="Data de nascimento (BasicData)"
                      >
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={['basicData', 'name']} label="Nome (BasicData)">
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['basicData', 'motherName']}
                        label="Nome da mãe (BasicData)"
                      >
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['basicData', 'fatherName']}
                        label="Nome do pai (BasicData)"
                      >
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {showInfoconvFields && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name={['infoconv', 'dataNascimento']}
                        label="Data de nascimento (Infoconv)"
                      >
                        <InputMask mask="99/99/9999" className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['infoconv', 'nomeContribuinte']}
                        label="Nome do contribuinte (Infoconv)"
                      >
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['infoconv', 'cpfContribuinte']}
                        label="CPF do contribuinte (Infoconv)"
                      >
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <div className="mrg-top-20">
                <Button
                  htmlType="submit"
                  className="btn-custom btn-custom-primary"
                  loading={loading}
                >
                  Salvar e reprocessar CPF
                </Button>
                <Button
                  className="btn-custom btn-custom-danger"
                  onClick={openRejectExecutionModal}
                  disabled={loading}
                >
                  Reprovar
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
      {reprocessModal}
      {rejectExecutionModal}
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('execution-data-confirmation-component')}
      /> */}
    </div>
  );
};

// DataConfirmation.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default DataConfirmation;
