import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import Cookies from 'js-cookie';

import Loading from './components/Loading';

const RouteWrapper = ({ isPrivate, component: Component, permissions, ...rest }) => {
  const { user, getLoggedUser } = useAuth();
  const authenticated = useAuth().isAuthenticated();

  useEffect(() => {
    if (user || !authenticated) return;

    getLoggedUser();
  }, [authenticated, getLoggedUser, user]);

  return user || !authenticated ? (
    <Route
      {...rest}
      render={({ location }) => {
        const { policyGroup } = user || [];

        if (!permissions) return <Component />;

        if (isPrivate) {
          if (!authenticated) {
            return <Redirect to="/login" />;
          }
        }

        // Verifies if user has permission to be on this selected route
        const authorized = permissions?.some((p) =>
          policyGroup?.products?.backoffice?.policies?.find(
            (polices) => polices?.name === p
          )
        );

        if (!authorized || !user.active) {
          let pathname = '/unauthorized';
          let search = '';

          if (Cookies.get('REDIRECT')) {
            Cookies.remove('REDIRECT');
            if (
              policyGroup?.products?.backoffice?.policies?.find(
                (polices) => polices?.name === 'bi'
              )
            ) {
              pathname = '/dashboard';
            } else if (
              policyGroup?.products?.backoffice?.policies?.find(
                (polices) => polices?.name === 'executions'
              )
            ) {
              pathname = '/executions';
            } else if (
              policyGroup?.products?.backoffice?.policies?.find(
                (polices) => polices?.name === 'clients'
              )
            ) {
              pathname = '/customers';
              search = '?_order=1&_sort=fantasyName';
            }

            // Redirecting to its respective landing page
          }
          return <Redirect to={{ pathname, search, state: { from: location } }} />;
        }

        return <Component />;
      }}
    />
  ) : (
    <Loading />
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  permissions: undefined,
};

export default RouteWrapper;
