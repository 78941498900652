import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import * as Icons from '@combateafraude/icons/general';

import './styles.less';

const ViewMore = ({ onClick, title, type, icon, text, loading, ...rest }) => (
  <div id="view-more-component" {...rest}>
    <Button type={type} onClick={onClick} loading={loading} rounded={false}>
      {!text && Object.keys(Icons || {}).includes(icon) && createElement(Icons[icon])}
      {title}
    </Button>
  </div>
);

ViewMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.bool,
  loading: PropTypes.bool,
};

ViewMore.defaultProps = {
  title: 'Ver mais',
  type: 'default',
  icon: 'Plus',
  text: false,
  loading: false,
};

export default ViewMore;
