/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Radio, Select, DatePicker, Tooltip, Tag } from 'antd';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import _ from 'lodash';
import moment from 'moment';

import {
  Calendar,
  ArrowRight1,
  CircleFalse,
  CheckSquare,
  ArrowDown,
  ArrowRight,
  CheckCircle2,
  File,
} from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';

import { toMaskedCnpj } from 'utils/formatters';
import statusList from 'pages/private/Customers/CustomerList/utils/statusList.json';

import { creationDateDates, convertForTimestamp } from './utils';
import './styles.less';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ExecutionFilter = ({ isLoadingList, setErrorFilter }) => {
  const [query, setQuery] = useQueryParams({
    creationDate: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    status: withDefault(ArrayParam, []),
    customers: withDefault(ArrayParam, []),
    queryTemplates: withDefault(ArrayParam, []),
    search: withDefault(ArrayParam, []),
    _documentscopyState: withDefault(ArrayParam, []),
    _order: StringParam,
    _sort: StringParam,
  });

  const { get: getCustomers, loading: loadingCustomers } = useFetch();
  const { get: getQueryTemplates, loading: loadingQueryTemplates } = useFetch();

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);
  const [allQueryTemplates, setAllQueryTemplates] = useState([]);
  const [queryTemplatesSearched, setQueryTemplatesSearched] = useState([]);
  const [searchQueryTemplate, setSearchQueryTemplate] = useState(null);

  // filterOptions
  const [searchFilter, setSearchFilter] = useState(() => query.search || null);
  const [customersFilter, setCustomersFilter] = useState(
    () => query.customers.map((c) => ({ key: c, value: c })) || null
  );
  const [queryTemplatesFilter, setQueryTemplatesFilter] = useState(
    () => query.queryTemplates.map((c) => ({ key: c, value: c })) || null
  );
  const [createdAtFilter, setCreatedAtFilter] = useState(
    () => query.creationDate || 'last24Hours'
  );
  const [customCreatedAtFilter, setCustomCreatedAtFilter] = useState(
    () => query.creationDate || 'custom'
  );
  const [statusFilter, setStatusFilter] = useState(() =>
    Object.keys(query.status).length !== 0
      ? query.status?.map((c) => ({ key: c, value: c }))
      : [
          { key: 'APROVADO|APPROVED', value: 'APROVADO|APPROVED' },
          { key: 'REPROVADO|REPROVED', value: 'REPROVADO|REPROVED' },
        ]
  );
  const [documentscopyStateFilter, setDocumentscopyStateFilter] = useState(
    () => query._documentscopyState?.map((c) => ({ key: c, value: c })) || null
  );

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if ((createdAtFilter || customCreatedAtFilter) && filterName === 'creationDate') {
        const threeHours = 3 * 60 * 60 * 1000;
        if (Array.isArray(event)) {
          let { startDate, endDate } = event[0];

          endDate += threeHours;
          startDate += threeHours;

          queryParams = {
            [filterName]: `custom:${startDate}-${endDate}`,
            startDate,
            endDate,
          };
        } else {
          const date = creationDateDates(event);
          const { startDate, endDate } = date;

          queryParams = {
            [filterName]: event && !_.isEmpty(event) ? event : undefined,
            startDate,
            endDate,
          };
        }
      }

      if (filterName === 'customers') {
        queryParams = {
          [filterName]: event && !_.isEmpty(event) ? event[0].key : undefined,
        };
      }

      if (filterName === 'queryTemplates') {
        queryParams = {
          [filterName]: event && !_.isEmpty(event) ? event : undefined,
        };
      }

      if (filterName === 'status') {
        queryParams = {
          [filterName]:
            event && !_.isEmpty(event) ? event.map((val) => val.key) : undefined,
        };
      }

      if (filterName === '_documentscopyState') {
        queryParams = {
          [filterName]:
            event && !_.isEmpty(event) ? event.map((val) => val.key) : undefined,
        };
      }

      setQuery(queryParams);
    },
    [createdAtFilter, setQuery, customCreatedAtFilter]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const data = await getCustomers({
        url: '/clients',
        config: {
          params: {
            _offset: 0,
            _sort: 'fantasyName',
            _order: 1,
            _returnDocuments: false,
          },
        },
      });

      const customersFiltered = data?.docs?.filter(
        (customer) => customer?.fantasyName || customer?.companyName
      );

      setAllCustomers(customersFiltered || []);
      setCustomersSearched(customersFiltered || []);
    } catch (e) {
      // SILENCER
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const tenantId = customersFilter.value || customersFilter[0]?.value;

    setAllQueryTemplates([]);
    setQueryTemplatesSearched([]);

    if (!tenantId) {
      return;
    }

    const customerToSearch = allCustomers.find(
      (customer) => customer.tenantId === tenantId
    );

    if (!customerToSearch?.product?.includes('trust')) {
      return;
    }

    const data = await getQueryTemplates({
      url: `/users/${tenantId}/profile-templates`,
      config: {
        params: {
          _sort: 'name',
          _order: 1,
        },
      },
    });

    const queryTemplatesFiltered = data?.docs?.map((queryTemplate) => {
      return { name: queryTemplate?.name, id: queryTemplate?._id };
    });

    setAllQueryTemplates(queryTemplatesFiltered || []);
    setQueryTemplatesSearched(queryTemplatesFiltered || []);
  }, [getQueryTemplates, customersFilter, allCustomers]);

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim() === '') {
        setCustomersSearched(allCustomers);
        return;
      }

      const customersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setSearchCustomer(searchTerm);
      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const onQueryTemplateSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim() === '') {
        setQueryTemplatesSearched(allQueryTemplates);
        return;
      }

      const queryTemplatesFiltered = allQueryTemplates?.filter((queryTemplate) => {
        if (queryTemplate?.name?.toUpperCase().includes(searchTerm?.toUpperCase())) {
          return queryTemplate;
        }
        return false;
      });
      setSearchQueryTemplate(searchTerm);
      setQueryTemplatesSearched(queryTemplatesFiltered || []);
    },
    [allQueryTemplates]
  );

  const updateFilters = useCallback(() => {
    let intervalExceeded = false;
    if (Array.isArray(customCreatedAtFilter) && createdAtFilter === 'custom') {
      const { startDate, endDate } = customCreatedAtFilter[0];
      if (startDate && endDate) {
        const dateStart = new Date(parseInt(startDate, 10));
        const dateEnd = new Date(parseInt(endDate, 10));
        const days = Math.round(
          (dateEnd.getTime() - dateStart.getTime()) / (1000 * 60 * 60 * 24)
        );
        if (days > 180) {
          intervalExceeded = true;
        }
      }
    }
    if (createdAtFilter === 'custom' && customCreatedAtFilter === 'custom') {
      setErrorFilter('Selecione uma Data');
    } else if (intervalExceeded) {
      setErrorFilter('Intervalo máximo de 180 dias');
    } else if (statusFilter.length === 0) {
      setErrorFilter('Selecione um Status');
    } else if (customersFilter.length === 0) {
      setErrorFilter('Selecione um Cliente');
    } else {
      setErrorFilter(false);

      setFilter('customers', customersFilter);
      setFilter('queryTemplates', queryTemplatesFilter);
      setSearchCustomer(null);
      setSearchQueryTemplate(null);
      setFilter('search', searchFilter);
      setFilter('creationDate', createdAtFilter);
      setFilter('status', statusFilter);
      setFilter('_documentscopyState', documentscopyStateFilter);

      if (createdAtFilter?.substr(0, 6) === 'custom') {
        setFilter('creationDate', customCreatedAtFilter);
      } else {
        setFilter('creationDate', createdAtFilter);
      }
    }
  }, [
    createdAtFilter,
    customCreatedAtFilter,
    setFilter,
    searchFilter,
    statusFilter,
    documentscopyStateFilter,
    customersFilter,
    setErrorFilter,
    queryTemplatesFilter,
  ]);

  const resetFilters = useCallback(() => {
    setQuery({
      creationDate: 'last24Hours',
      startDate: undefined,
      endDate: undefined,
      status: ['APROVADO|APPROVED', 'REPROVADO|REPROVED'],
      customers: undefined,
      queryTemplates: undefined,
      search: undefined,
      _documentscopyState: undefined,
    });

    setCustomersFilter(() => []);
    setQueryTemplatesFilter(() => []);
    setSearchFilter(() => []);
    setCreatedAtFilter('last24Hours');
    setStatusFilter(() => [
      { key: 'APROVADO|APPROVED', value: 'APROVADO|APPROVED' },
      { key: 'REPROVADO|REPROVED', value: 'REPROVADO|REPROVED' },
    ]);
    setDocumentscopyStateFilter(() => []);
  }, [setQuery]);

  const isCustomCreationDate = () => createdAtFilter?.substr(0, 6) === 'custom';

  const getDatePickerValue = () => {
    if (!isCustomCreationDate() || createdAtFilter === 'custom') return;
    let startDateFinal;
    let endDateFinal;

    if (Array.isArray(customCreatedAtFilter)) {
      const { startDate, endDate } = customCreatedAtFilter[0];

      startDateFinal = startDate;
      endDateFinal = endDate;
    } else {
      const [startDate, endDate] = customCreatedAtFilter.substr(7).split('-');
      startDateFinal = startDate;
      endDateFinal = endDate;
    }
    // eslint-disable-next-line consistent-return
    return [
      moment(new Date(parseInt(startDateFinal, 10))),
      moment(new Date(parseInt(endDateFinal, 10))),
    ];
  };

  return (
    <div id="custom-filter-component">
      <Select
        mode="tags"
        value={searchFilter}
        placeholder="Busque por nome, CPF ou CNPJ..."
        dropdownClassName="gx-d-none"
        className="execution-select"
        removeIcon={<CircleFalse />}
        onChange={(values) => {
          setSearchFilter(values);
        }}
      />

      <Collapse
        defaultActiveKey={['customer', 'queryTemplates', 'creationDate', 'status']}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <ArrowDown width={24} height={24} />
          ) : (
            <ArrowRight width={24} height={24} />
          )
        }
      >
        <Panel header="Por cliente*" key="customer">
          <Select
            showSearch
            defaultActiveFirstOption={false}
            optionFilterProp="label"
            style={{ width: 265 }}
            labelInValue
            value={customersFilter || []}
            placeholder="Busque por nome ou e-mail..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            loading={loadingCustomers}
            notFoundContent={
              !loadingCustomers && !customersSearched.length ? (
                <span>
                  {searchCustomer
                    ? `Nenhum resultado encontrado para "${searchCustomer}"`
                    : 'Busque clientes por nome ou e-mail'}
                </span>
              ) : null
            }
            onSearch={onCustomerSearch}
            onClear={() => setCustomersSearched(allCustomers)}
            onBlur={() => {
              setSearchCustomer(null);
              setCustomersSearched(allCustomers);
            }}
            onChange={(values) => {
              setCustomersFilter([values]);
              setQueryTemplatesFilter([]);
            }}
          >
            {customersSearched?.map((c) => (
              <Option
                key={c._id}
                label={c.fantasyName || c.companyName}
                value={c.tenantId}
              >
                <div className="multiselect-option">
                  <span className="option-title">{c.fantasyName || c.companyName}</span>
                  <div className="flex start-center">
                    <span className="option-subtitle">{toMaskedCnpj(c.cnpj)}</span>
                    <section className="mrg-left-10">
                      <>
                        {c?.verified && (
                          <Tooltip title="Em produção">
                            <CheckSquare
                              width={15}
                              height={15}
                              className="gx-text-success"
                            />
                          </Tooltip>
                        )}
                        {c?.hasContract && (
                          <Tooltip title="Possui contrato">
                            <File width={20} height={20} className="gx-text-primary" />
                          </Tooltip>
                        )}
                        {c?.status && (
                          <Tag
                            className="mrg-left-5 font-size-10"
                            color={statusList[c?.status || 'lead'].color}
                          >
                            {c?.status === 'internal-customer'
                              ? 'Interno'
                              : statusList[c?.status || 'lead'].name}
                          </Tag>
                        )}
                      </>
                    </section>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </Panel>

        <Panel header="Por modelo de Consulta" key="queryTemplates">
          <Select
            mode="multiple"
            optionFilterProp="label"
            disabled={loadingQueryTemplates}
            defaultActiveFirstOption={false}
            style={{ width: 265 }}
            value={queryTemplatesFilter || []}
            placeholder="Busque por modelo de consulta..."
            dropdownClassName="multiselect-filters"
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            loading={loadingQueryTemplates}
            notFoundContent={
              !loadingQueryTemplates && customersFilter.length ? (
                <span>
                  {searchQueryTemplate
                    ? `Nenhum resultado encontrado para "${searchQueryTemplate}"`
                    : 'Nenhum template encontrado para esse cliente!'}
                </span>
              ) : (
                <span>Selecione um cliente para visualizar os modelos!</span>
              )
            }
            onSearch={onQueryTemplateSearch}
            onBlur={() => {
              setSearchQueryTemplate(null);
              setQueryTemplatesSearched(allQueryTemplates);
            }}
            onChange={(values) => {
              setQueryTemplatesFilter(values);
            }}
          >
            {queryTemplatesSearched?.map((queryTemplate) => (
              <Option key={queryTemplate.id} label={queryTemplate.name}>
                {queryTemplate.name.length > 30
                  ? queryTemplate.name.substr(0, 27).concat('...')
                  : queryTemplate.name}
              </Option>
            ))}
          </Select>
        </Panel>

        <Panel header="Criado em*" key="creationDate">
          <Radio.Group
            defaultValue=""
            value={isCustomCreationDate() ? 'custom' : createdAtFilter || ''}
            onChange={(e) => setCreatedAtFilter(e.target.value)}
          >
            <Radio value="last24Hours">Últimas 24 horas</Radio>
            <Radio value="last7Days">Últimos 7 dias</Radio>
            <Radio value="last30Days">Últimos 30 dias</Radio>
            <Radio value="custom">Intervalo personalizado</Radio>
          </Radio.Group>
          {isCustomCreationDate() && (
            <RangePicker
              className="gx-w-100 mrg-top-5 gx-bg-transparent"
              id="openOneCalendar"
              format="DD/MM/YYYY"
              value={getDatePickerValue()}
              suffixIcon={<Calendar width={20.5} height={20.5} />}
              separator={<ArrowRight1 />}
              onChange={(value) => {
                if (value) {
                  const { startDate, endDate } = convertForTimestamp(value);
                  setCustomCreatedAtFilter([{ startDate, endDate }]);
                } else {
                  setCustomCreatedAtFilter('custom');
                }
              }}
            />
          )}
        </Panel>
        <Panel header="Status*" key="status">
          <Select
            mode="multiple"
            labelInValue
            value={statusFilter}
            placeholder="Selecione um status..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              let newValues = values;

              const fraudSelected = values && values.some((v) => v.key === 'FRAUD');

              if (fraudSelected) {
                newValues = newValues.filter((v) => v.key === 'FRAUD');
              }

              setStatusFilter(newValues);
            }}
          >
            <>
              <Option
                key="APROVADO|APPROVED"
                label="Aprovados"
                disabled={statusFilter?.some((v) => v.key === 'FRAUD')}
              >
                Aprovados
              </Option>
              <Option
                key="REPROVADO|REPROVED"
                label="Reprovados"
                disabled={statusFilter?.some((v) => v.key === 'FRAUD')}
              >
                Reprovados
              </Option>
              <Option key="FRAUD" label="Reprovados por fraude">
                Reprovados por fraude
              </Option>
            </>
          </Select>
        </Panel>
        <Panel header="Documentoscopia" key="_documentscopyState">
          <Select
            mode="multiple"
            labelInValue
            value={documentscopyStateFilter}
            placeholder="Selecione..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              setDocumentscopyStateFilter(values);
            }}
          >
            <>
              <Option key="WAITING" label="Aguardando">
                Aguardando
              </Option>
              <Option key="APPROVED" label="Aprovada">
                Aprovada
              </Option>
              <Option key="REPROVED" label="Reprovada">
                Reprovada
              </Option>
              <Option key="UNSOLICITED" label="Não solicitada">
                Não solicitada
              </Option>
            </>
          </Select>
        </Panel>
      </Collapse>
      <div className="btn-filters mrg-right-15">
        <Button
          disabled={isLoadingList}
          block
          onClick={updateFilters}
          className="btn-custom btn-custom-primary"
        >
          Consultar
        </Button>
        <Button
          type="default"
          disabled={isLoadingList}
          block
          onClick={resetFilters}
          className="btn-custom-secondary"
        >
          Limpar filtros
        </Button>
      </div>
    </div>
  );
};

export default ExecutionFilter;
