import React, { useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, message, Input, Checkbox } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useTimeline } from 'hooks/timeline';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

import './styles.less';
import { areValuesValid, clearTokenFields, setTestTokenFields } from '../utils/utils';

const IproovEdit = () => {
  const { tenantId } = useParams();
  const { closeModalMandatory } = useTimeline();
  const [form] = Form.useForm();

  const { put: editIproovToken, loading: loadingEdit } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [generateKeyButtonDisabled, setGenerateKeyButtonDisabled] = useState(true);
  const [tokenData, setTokenData] = useState({});
  const [isTokenTest, setIsTokenTest] = useState();
  const [enableTokenTypeOptions, setEnableTokenTypeOptions] = useState(true);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setGenerateKeyButtonDisabled(true);
    form.resetFields();
  }, [closeModalMandatory]); // eslint-disable-line

  const tokenTestFromSecretAndKey = useMemo(() => {
    const secret = process.env.REACT_APP_IPROOV_SECRET ?? '';
    const key = process.env.REACT_APP_IPROOV_API_KEY ?? '';

    return tokenData?.secret === secret && tokenData?.api_key === key;
  }, [tokenData]);

  const openModal = useCallback(
    (data) => {
      setTokenData(data);
      setEnableTokenTypeOptions(data?.tokenType === 'test' || tokenTestFromSecretAndKey);
      setIsTokenTest(data?.tokenType === 'test' || tokenTestFromSecretAndKey);
      setModalVisible(true);
    },
    [tokenTestFromSecretAndKey]
  );

  const editToken = useCallback(
    async (payload) => {
      try {
        let newPayload = {};
        if (payload && payload?.tokenType && payload?.tokenType?.length > 0) {
          const firstTokenType = payload.tokenType[0];
          newPayload = {
            ...payload,
            tokenType: firstTokenType,
          };
        } else {
          newPayload = {
            ...payload,
            tokenType: tokenData.tokenType,
          };
        }

        await editIproovToken({
          url: `/clients/${tenantId}/iproov-tokens/${tokenData._id}`,
          payload: newPayload,
        });

        closeModal();

        const event = new Event('finishEditIproovToken');
        document.dispatchEvent(event);

        message.success('Nova chave gerada com sucesso.');
      } catch (err) {
        message.error('Houve um problema ao gerar nova chave.');
      }
    },
    [closeModal, tenantId, tokenData, editIproovToken]
  );

  const handleOnValuesChange = useCallback(() => {
    const values = form.getFieldsValue();
    delete values.description;
    delete values.tokenType;

    setGenerateKeyButtonDisabled(areValuesValid(values, 5));
  }, [form]);

  const testCredentials = useMemo(() => {
    const secret = process.env.REACT_APP_IPROOV_SECRET ?? '';
    const key = process.env.REACT_APP_IPROOV_API_KEY ?? '';

    return { secret, key };
  }, []);

  const handleCheckboxChange = useCallback(
    (checkedValues) => {
      if (checkedValues.length > 1) {
        const lastChecked = checkedValues[checkedValues.length - 1];
        form.setFieldsValue({ tokenType: [lastChecked] });
        if (lastChecked === 'test') {
          setIsTokenTest(true);
          setTestTokenFields(form, testCredentials);
        } else {
          setIsTokenTest(false);
          clearTokenFields(form);
          handleOnValuesChange();
        }
      } else {
        setIsTokenTest(true);
        form.setFieldsValue({
          tokenType: ['test'],
        });
        setTestTokenFields(form, testCredentials);
      }
    },
    [form, testCredentials, handleOnValuesChange]
  );

  const IproovEditModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <div id="iproov-modal-component" className="no-mrg no-pdd">
          <PageTitle title="Editar token iProov" />

          <Form
            form={form}
            layout="vertical"
            className="mrg-top-5"
            onFinish={editToken}
            onValuesChange={handleOnValuesChange}
          >
            {enableTokenTypeOptions && (
              <Form.Item
                name="tokenType"
                label=""
                className="mrg-top-5"
                initialValue={
                  tokenData?.tokenType || tokenData?.tokenBeta ? ['test'] : ['production']
                }
              >
                <Checkbox.Group onChange={handleCheckboxChange} className="flex">
                  <Checkbox
                    value="test"
                    onChange={() => {
                      setIsTokenTest(true);
                    }}
                  >
                    Token de teste
                  </Checkbox>
                  <Checkbox
                    value="production"
                    onChange={() => {
                      setIsTokenTest(false);
                    }}
                  >
                    Token de produção
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            )}
            <Form.Item
              name="name"
              label="Nome*"
              className="mrg-top-5"
              initialValue={tokenData?.name}
              required
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              name="link"
              label="Link*"
              className="mrg-top-15"
              initialValue={
                tokenData?.link ||
                (isTokenTest ? 'https://portal.iproov.com/service-providers/1372' : '')
              }
              required
            >
              <Input maxLength={150} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item
              name="spName"
              label="SP Name*"
              className="mrg-top-15"
              initialValue={tokenData?.spName || (isTokenTest ? 'CAF_DEV' : '')}
              required
            >
              <Input maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item
              name="apiSecret"
              label="API Secret*"
              className="mrg-top-15"
              initialValue={tokenData?.secret}
              required
              tooltip="Informação para uso interno Caf. Não compartilhar com o cliente ou externos."
            >
              <Input.Password maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item
              name="apiKey"
              label="API Key*"
              className="mrg-top-15"
              initialValue={tokenData?.api_key}
              required
              tooltip="Informação para uso interno Caf. Não compartilhar com o cliente ou externos."
            >
              <Input.Password maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descrição"
              className="mrg-top-15"
              initialValue={tokenData?.description}
            >
              <Input maxLength={200} />
            </Form.Item>

            <div className="btn-container flex-end">
              <Button onClick={closeModal} className="btn">
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                htmlType="submit"
                disabled={loadingEdit || generateKeyButtonDisabled}
                loading={loadingEdit}
              >
                {loadingEdit ? 'Salvando...' : 'Salvar'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      handleCheckboxChange,
      isTokenTest,
      enableTokenTypeOptions,
      editToken,
      loadingEdit,
      handleOnValuesChange,
      generateKeyButtonDisabled,
      tokenData,
    ]
  );

  return { openModal, closeModal, IproovEditModal };
};

export default IproovEdit;
