import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, message, Alert, Col } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import ImagesUploadCard from './components/ImagesUploadCard';

const SharedFacesetCreate = ({ refreshList }) => {
  const { post, loading, error: errorCreate } = useFetch();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [imagesUrlsList, setImagesUrlsList] = useState([]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setErrorMessage(undefined);
    imagesUrlsList.length = 0;
    form.resetFields();
  }, [form, imagesUrlsList]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const createUser = useCallback(
    async (values) => {
      if (imagesUrlsList.length < 1) {
        message.warning('Envie pelo menos 1 imagem');
        return;
      }
      try {
        const payload = {
          ...values,
          images: imagesUrlsList,
        };

        await post({
          url: '/shared-faceset',
          payload,
        });

        message.success('Face cadastrado com sucesso.');

        refreshList();
        closeModal();
      } catch (error) {
        message.error('Erro ao cadastrar face.');
      }
    },
    [imagesUrlsList, post, refreshList, closeModal]
  );

  useEffect(() => {
    if (!errorCreate || errorCreate === true) return;

    const error = errorCreate.message?.split(' - ');
    const errorFormatted =
      error?.length > 1
        ? { ...errorCreate, error: JSON.parse(error[1]) }
        : { ...errorCreate };

    setErrorMessage(errorFormatted?.error?.message);
  }, [errorCreate]);

  const SharedFacesetCreateModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={650}
      >
        <PageTitle title="Nova face para a base de suspeitos" />
        <Form
          form={form}
          layout="vertical"
          className="mrg-top-10"
          onFinish={createUser}
          autoComplete="new-customer"
        >
          <Form.Item name="description" label="Descrição" rules={[{ required: true }]}>
            <Input
              autoComplete="new-name"
              className="text-dark"
              placeholder="Descrição"
              required
              allowClear
            />
          </Form.Item>
          <Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
            <Input
              autoComplete="new-name"
              className="text-dark"
              required
              placeholder="CPF"
              allowClear
            />
          </Form.Item>
          <Col>
            <ImagesUploadCard setImagesUrlsList={setImagesUrlsList} />
          </Col>

          {errorMessage && (
            <Alert
              type="error"
              showIcon
              closable
              message="Houve um problema ao criar a face."
              description={errorMessage}
            />
          )}

          <div className="flex fd-row space-between mrg-top-35">
            <Button type="default" onClick={closeModal} className="btn-cancel">
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              htmlType="submit"
              loading={loading}
            >
              {loading ? 'Criando ...' : 'Criar'}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }, [form, modalVisible, closeModal, createUser, errorMessage, loading]);

  return {
    openModal,
    closeModal,
    SharedFacesetCreateModal,
  };
};

export default SharedFacesetCreate;
