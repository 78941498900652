import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import classNames from 'classnames';

import './TimelineIcon.less';
import { PenEdit } from '@combateafraude/icons/general';

const typeIcon = {
  edit_datasource: <PenEdit />,
};

const TimelineIcon = ({ date, type }) => {
  const formattedDay = useMemo(() => {
    if (!date) return undefined;

    const newDate = date.replace('T', ' ').replace('Z', '');

    const formattedDate = format(parseISO(newDate), 'dd', {
      locale: ptBR,
    });

    return formattedDate;
  }, [date]);

  const formattedMonth = useMemo(() => {
    if (!date) return undefined;

    const newDate = date.replace('T', ' ').replace('Z', '');

    const formattedDate = format(parseISO(newDate), 'MMM', {
      locale: ptBR,
    });
    return formattedDate;
  }, [date]);

  const dateClass = useMemo(() => (date ? 'date' : ''), [date]);

  return (
    <div id="timeline-icon-component" className={classNames(dateClass)}>
      {date ? (
        <>
          <h3>{formattedDay}</h3>
          <h6>{formattedMonth}</h6>
        </>
      ) : (
        typeIcon[type]
      )}
    </div>
  );
};

TimelineIcon.propTypes = {
  date: PropTypes.string,
  type: PropTypes.string,
};

TimelineIcon.defaultProps = {
  date: undefined,
  type: undefined,
};

export default TimelineIcon;
