import React from 'react';
import { Row, Spin } from 'antd';

import Loader from 'components/Loader';

const Loading = () => {
  return (
    <Row className="flex center full-height">
      <Spin
        indicator={<Loader size="40px" color="#bdbdbd" />}
        tip="Carregando dados do usuário..."
      />
    </Row>
  );
};

export default Loading;
