import React, { useEffect, useMemo, useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { Row, Col, Popconfirm, message } from 'antd';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import { CircleFalse, CircleTrue } from '@combateafraude/icons/general';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';

const DocumentsConfirmation = () => {
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData } = useExecution();
  const { user } = useAuth();
  const { loading, post } = useFetch();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (!executionData) return;

    setDocuments(executionData.executions?.documents || []);
  }, [executionData]);

  const displayedDocuments = useMemo(() => {
    return (
      documents?.length > 0 &&
      documents?.map((doc) => {
        const docExtensionArray = doc.key.split('.');
        const docExtension = docExtensionArray[docExtensionArray.length - 1];

        return (
          <Col span={8} key={doc?.url} className="text-center">
            <span className="text-dark">
              <span className="text-bold">{doc?.name || '-'}</span> ({docExtension})
            </span>
            {docExtension.toLocaleLowerCase() === 'pdf' ? (
              <object width="100%" height="400" data={doc?.url} type="application/pdf">
                Carregando...
              </object>
            ) : (
              <ZoomedImage src={doc?.url} />
            )}
          </Col>
        );
      })
    );
  }, [documents]);

  const submitDocuments = useCallback(
    async (status) => {
      try {
        const newDocuments = documents?.map((doc) => {
          delete doc?.url;
          return {
            ...doc,
            verified: status,
          };
        });

        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/VALID_DOCUMENTS`,
          payload: {
            action: 'true',
            documents: newDocuments,
            _id: user?._id,
            email: user?.email,
          },
        });

        message.success(`Os documentos foram ${status ? 'confirmados' : 'rejeitados'}.`);
        reloadData(false);
      } catch (err) {
        message.error(`Falha ao ${status ? 'confirmar' : 'rejeitar'} os documentos.`);
      }
    },
    [post, reloadData, tenantId, reportId, executionId, documents, user]
  );

  return (
    <div id="documents-confirmation-component">
      <PageTitle title="Ação requerida: confirmação de documentos" />
      <Card>
        <Row align="middle" justify="center">
          {displayedDocuments}
        </Row>
        <Row className="mrg-top-25 flex center">
          <Col className="pdd-horizon-10 center space-between">
            <Popconfirm
              title="Deseja realmente confirmar os documentos?"
              okText="Sim"
              cancelText="Não"
              onConfirm={() => submitDocuments(true)}
            >
              <Button
                htmlType="button"
                type="primary"
                loading={loading}
                disabled={loading}
              >
                <CircleTrue />
                Confirmar
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Deseja realmente rejeitar os documentos?"
              okText="Sim"
              okType="danger"
              cancelText="Não"
              onConfirm={() => submitDocuments(false)}
            >
              <Button
                htmlType="button"
                type="danger"
                loading={loading}
                disabled={loading}
              >
                <CircleFalse />
                Rejeitar
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Card>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('documents-confirmation-component')}
      /> */}
    </div>
  );
};

// DocumentsConfirmation.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default DocumentsConfirmation;
