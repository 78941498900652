import React, { useCallback, useMemo, useState } from 'react';
import { Select } from 'antd';
import { useExecution } from 'hooks/execution';
import { ArrowDown } from '@combateafraude/icons/general';

import AWS_LOGO from 'assets/images/aws.svg';

const OpenAwsExecution = () => {
  const { executionData } = useExecution();
  const [isAwsSelectOpen, setIsAwsSelectOpen] = useState(false);

  const options = useMemo(() => {
    const steps = executionData?.executions?.steps;
    const optionsData = [{ label: 'OCR', value: 'default_state_machine' }];

    if (!steps) return optionsData;

    if (steps.customData)
      optionsData.push({ label: 'Custom data', value: 'custom_data' });
    if (steps.combateAFraude)
      optionsData.push({ label: 'Combate a fraude', value: 'combate_a_fraude' });

    return optionsData;
  }, [executionData]);

  const openExecutionAWS = useCallback(
    (stateMachine) => {
      const { _id, report, arnDefault } = executionData.executions || {};
      const arnAws = report?.arn ? report?.arn : arnDefault;

      if (arnAws) {
        const arn = `${arnAws
          .split(':')
          .slice(0, -1)
          .join(':')
          .replace('stateMachine', 'execution')}:${
          process.env.REACT_APP_ENV
        }_${stateMachine}`;

        const url = `https://console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${arn}:${_id}`;
        window.open(url, '_blank');
      }
    },
    [executionData]
  );

  const checkStateMachinesUsed = useCallback(() => {
    if (options.length > 1) setIsAwsSelectOpen(true);
    else openExecutionAWS(options[0].value);
  }, [options, openExecutionAWS]);

  const showArrow = options.length > 1;

  return (
    <Select
      suffixIcon={showArrow ? <ArrowDown /> : ''}
      onClick={checkStateMachinesUsed}
      onChange={openExecutionAWS}
      open={isAwsSelectOpen}
      onBlur={() => setIsAwsSelectOpen(false)}
      options={options}
      dropdownClassName="aws-select-dropdown"
      className={`btn-aws ${showArrow && 'show-arrow'}`}
      value={<img src={AWS_LOGO} alt="Logo AWS" height="18px" />}
    />
  );
};

export default OpenAwsExecution;
