/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import _ from 'lodash';

import {
  Attach,
  CheckD,
  CircleFalse,
  Company,
  ProcessingLine,
  OpenWindow,
} from '@combateafraude/icons/general';

import Tag from 'components/Tag';
import { Person, UserActive } from '@combateafraude/icons/users';
import ExecutionStatusTag from 'components/ExecutionStatusTag';

const unavailableServicesArray = [
  'official_data',
  'cpf',
  'facematch',
  'authentic_document',
];

const CardExecution = ({ data }) => {
  const isReportCnpj = useMemo(() => !!data?.parameters?.cnpj, [data]);
  const isReportCpf = useMemo(
    () =>
      (!data?.images || _.isEmpty(data?.images)) && !data?.type && !data?.sections?.ocr,
    [data]
  );

  const invalidValidations = useMemo(() => {
    return data?.validations?.filter((v) => v.status === 'INVALID') || [];
  }, [data]);

  const unavailableServicesJoined = useMemo(() => {
    const unavailableServicesFiltered = data?.unavailableServices?.filter((service) =>
      unavailableServicesArray?.includes(service)
    );

    const unavailableServicesJoinedContent = unavailableServicesFiltered?.join(', ');

    return {
      content: unavailableServicesJoinedContent,
      length: unavailableServicesFiltered?.length,
    };
  }, [data]);

  const unavailableSourcesJoined = useMemo(() => {
    const unavailableSourcesFiltered = data?.unavailableServices?.filter((service) => {
      if (unavailableServicesArray?.includes(service)) {
        return false;
      }

      return service;
    });

    const unavailableSourcesJoinedContent = unavailableSourcesFiltered?.join(', ');
    return {
      content: unavailableSourcesJoinedContent,
      length: unavailableSourcesFiltered?.length,
    };
  }, [data]);

  return (
    <Link
      className="overflow-hidden document-card mrg-btm-20"
      to={`/executions/details/user/${data.tenantId}/report/${
        data.report || data.reportId
      }/execution/${data.execution || data.id || data._id}`}
    >
      <Card>
        <div className="card-body">
          <div className="flex-1 flex-column center-start">
            {/* <p className="card-report-title">Combate à Fraude</p> */}
            <div className="flex gx-w-100">
              {!isReportCnpj ? (
                <p className="card-title max-lines-2 gx-w-100">
                  {data?.data?.name ||
                    data?.sections?.cpf?.name ||
                    data?.sections?.pfBasicData?.data?.name ||
                    data?.sections?.ocr?.name ||
                    data?.sections?.ocrRedundancy?.name ||
                    data?.sections?.pfData?.data?.name ||
                    data?.parameters?.name ||
                    '-'}
                </p>
              ) : (
                <p className="card-title max-lines-2">
                  {data?.sections?.pjData?.data?.officialName ||
                    data?.sections?.pjBasicData?.data?.officialName ||
                    data?.sections?.pjBasicData?.data?.tradeName ||
                    data?.sections?.pjBoaVista?.data?.DefineLimite?.IdentificacaoCompleto
                      ?.RazaoSocial ||
                    '-'}
                </p>
              )}

              {data?.status && (
                <div className="float-tag">
                  <ExecutionStatusTag isFraud={data.fraud} status={data?.status} />
                </div>
              )}
            </div>
            <div className="flex">
              {!isReportCnpj ? (
                <div className="card-info">
                  <Person />
                  <span
                    className={`${classNames({
                      'gx-font-italic':
                        !data?.data?.cpf &&
                        !data?.parameters?.cpf &&
                        !data?.sections?.ocr?.cpf,
                    })} mrg-left-5`}
                  >
                    {data?.data?.cpf ||
                      data?.parameters?.cpf ||
                      data?.sections?.ocr?.cpf ||
                      'CPF indisponível'}
                  </span>
                </div>
              ) : (
                <div className="card-info">
                  <Company />
                  <span
                    className={`${classNames({
                      'gx-font-italic': !data?.data?.cnpj && !data?.parameters?.cnpj,
                    })} mrg-left-5`}
                  >
                    {data?.data?.cnpj || data?.parameters?.cnpj || 'CNPJ indisponível'}
                  </span>
                </div>
              )}

              {!isReportCpf && !isReportCnpj && (
                <div className="card-info">
                  <Attach />
                  <span className="mrg-left-5">{data?.type?.toUpperCase() || '-'}</span>
                </div>
              )}

              {data?.reviews && !_.isEmpty(data?.reviews) && (
                <div
                  className={`card-info ${classNames({
                    'double-check-reviewed':
                      data?.reviews &&
                      !_.isEmpty(data?.reviews) &&
                      data?.documentscopyRequestDate,
                  })}`}
                >
                  <CheckD />
                  <span className="mrg-left-5">Double-check</span>
                </div>
              )}

              {data?.documentscopy && data?.documentscopy?.reviewDate && (
                <div className="card-info">
                  <UserActive
                    width={16}
                    height={16}
                    className={`${classNames({
                      'gx-text-primary': data?.sectionsDocumentscopyStatus === 'APROVADO',
                      'gx-text-danger': data?.sectionsDocumentscopyStatus === 'REPROVADO',
                    })}`}
                  />
                  <span className="mrg-left-5">
                    Documentoscopia realizada em{' '}
                    {data?.documentscopy?.reviewDate
                      ? format(new Date(data?.documentscopy?.reviewDate), 'dd/MM/yyyy')
                      : '-'}
                  </span>
                </div>
              )}

              {data?.documentscopy?.requestDate && !data?.documentscopy?.reviewDate && (
                <div className="card-info">
                  <ProcessingLine />
                  <span className="mrg-left-5">Aguardando documentoscopia</span>
                </div>
              )}
            </div>
            {data?.client && (
              <>
                <div className="flex mrg-top-15 card-info">
                  <span className="no-mrg text-dark font-size-13">
                    <Company /> <span className="mrg-left-5">Cliente: </span>
                    <span className="text-bold mrg-left-5">
                      {data?.client?.fantasyName || data?.client?.companyName}
                    </span>
                    {' - '}Relatório:{' '}
                    <span className="text-bold">
                      {data?.client?.reports?.find((r) => r?._id === data.reportId)
                        ?.name || '-'}
                    </span>
                    <span className="mrg-left-5">- ID:</span>{' '}
                    <span className="text-bold mrg-left-5">{data?.id || '-'}</span>
                  </span>
                </div>
              </>
            )}
            {invalidValidations?.length > 0 && (
              <div className="mrg-top-10">
                {invalidValidations?.map((v) => (
                  <div key={v.rule} className="mrg-top-5 text-dark">
                    <CircleFalse className="text-danger" />
                    <small className="font-size-13 mrg-left-5">{v.description}</small>
                  </div>
                ))}
              </div>
            )}
            {unavailableServicesJoined?.length > 0 && (
              <div className="mrg-top-5">
                <div className="mrg-top-5 text-dark">
                  <CircleFalse className="text-danger" />
                  <small className="font-size-13 mrg-left-5">
                    {unavailableServicesJoined?.length === 1
                      ? `Serviço Pendente: ${unavailableServicesJoined?.content}`
                      : `Serviços Pendentes: ${unavailableServicesJoined?.content}`}
                  </small>
                </div>
              </div>
            )}
            {unavailableSourcesJoined?.length > 0 && (
              <div className="mrg-top-5">
                <div className="mrg-top-5 text-dark">
                  <CircleFalse className="text-danger" />
                  <small className="font-size-13 mrg-left-5">
                    {unavailableSourcesJoined?.length === 1
                      ? `Fonte Pendente: ${unavailableSourcesJoined?.content}`
                      : `Fontes Pendentes: ${unavailableSourcesJoined?.content}`}
                  </small>
                </div>
              </div>
            )}
          </div>
          {/* <div className="float-tag">
            <Tag
              color={ALL_TAGS[execution?.status?.toUpperCase()].color}
              icon={
                <i
                  className={`${ALL_TAGS[execution?.status?.toUpperCase()].icon} icon-right`}
                />
              }
            >
              {ALL_TAGS[execution?.status?.toUpperCase()].title(execution)}
            </Tag>
          </div> */}
        </div>
        <div className="card-footer">
          <span className="gx-font-italic font-size-11 gx-font-weight-light">
            Consulta em{' '}
            {data?.createdAt
              ? format(new Date(data?.createdAt), "dd/MM/yyyy 'às' HH:mm")
              : '-'}
            {data?.lastModification && (
              <>
                <b className="separator">•</b>
                Última modificação por {data?.lastModification?.user?.email} em{' '}
                {format(
                  new Date(data?.lastModification?.changedDate),
                  "dd/MM/yyyy 'às' HH:mm"
                )}
              </>
            )}
          </span>
          {data?.status?.toUpperCase() === 'PENDENTE OCR' &&
            data?.client?.pendingOcrInternally && (
              <div className="float-tag" style={{ marginLeft: 250 }}>
                <Tag color="warning">Necessário Atuação do Suporte</Tag>
              </div>
            )}
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/executions/details/user/${data.tenantId}/report/${
                  data.report || data.reportId
                }/execution/${data.execution || data.id || data._id}`,
                '_blank'
              );
            }}
            title="Abrir em outra aba"
            className="btn-open"
          >
            <OpenWindow />
          </button>
        </div>
      </Card>
    </Link>
  );
};

export default CardExecution;
