import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const TooltipInfo = ({ title, size, color, placement, ...rest }) => (
  <Tooltip {...rest} placement={placement} title={title}>
    <InfoCircleOutlined
      style={{
        display: 'flex',
        color,
        fontSize: size,
        marginLeft: '8px',
      }}
    />
  </Tooltip>
);

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  placement: PropTypes.string,
};

TooltipInfo.defaultProps = {
  size: 16,
  color: '#004AF7',
  placement: 'top',
};
export default TooltipInfo;
