import { message } from 'antd';
import api from 'services/api';
import { useAuth } from '../hooks/auth';

const HttpInterceptor = () => {
  const { setLoggedUser, refreshUserToken, signOut } = useAuth();

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error?.response?.config?.url ===
        `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/users/refresh`
      ) {
        return null;
      }

      if (error.response) {
        if (error.response.status === 403) {
          const userData = error?.response?.data?.docs;
          setLoggedUser(userData);
        }

        if (error.response.status === 401) {
          const userData = error?.response?.data?.docs;
          if (userData) {
            setLoggedUser(userData);
          } else {
            try {
              const res = await refreshUserToken();

              const { idToken } = res || {};
              error.config.headers.Authorization = idToken;

              return api.request(error.config);
            } catch (err) {
              message.error('Sessão expirada. Faça login novamente');
              signOut();

              return null;
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return null;
};

export default HttpInterceptor;
