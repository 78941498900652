import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Typography, Switch } from 'antd';

import Card from 'components/Card';
import { HiddenParagraph } from 'components/Text';

// import { findMobileProduct } from 'pages/private/Customers/pages/Mobile/utils/mobileProducts';

import './CardLinks.less';

const { Text } = Typography;

const CardLinks = ({ data }) => {
  // const deleteToken = useCallback(async () => {
  //   if (!tenantId) {
  //     message.error('Houve um problema ao exluir a chave.');
  //     return;
  //   }

  //   try {
  //     await deleteAPI({
  //       url: `/clients/${tenantId}/mobile-tokens/${data.clientId}`,
  //     });

  //     refreshList();

  //     message.success('Chave excluída com sucesso.');
  //   } catch (err) {
  //     message.error('Houve um problema ao exluir a chave.');
  //   }
  // }, [tenantId, data, deleteAPI, refreshList]);

  return (
    data && (
      <Card id="card-links-component">
        <Row className="no-pdd no-mrg">
          <Col span={12} className="cells">
            <Text className="no-mrg">Link de compartilhamento público</Text>
            <HiddenParagraph text={`Link: ${data.clientId}`} copyable />
          </Col>
          <Col span={3} className="cells border-left">
            <Text className="no-mrg">Relatório:</Text>
            <Text />
          </Col>
          <Col span={3} className="cells border-left">
            <Text className="no-mrg">Status:</Text>
            <Text />
          </Col>
          <Col span={3} className="cells border-left">
            <Text className="no-mrg">Criado em:</Text>
            <Text />
          </Col>
          <Col span={3} className="cells border-left">
            <Form.Item
              className="no-mrg"
              name="cpfGroup"
              label={
                <Text strong className="no-mrg">
                  Grupo de CPF
                </Text>
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          {/* <Col span={2} className="border-left flex center">
            <Popconfirm
              title="Você deseja mesmo exluir essa chave?"
              onConfirm={deleteToken}
              okText="Sim"
              cancelText="Não"
            >
              <Button type="danger" loading={loadingDelete}>
                <Trash />
              </Button>
            </Popconfirm>
          </Col> */}
        </Row>
      </Card>
    )
  );
};

CardLinks.propTypes = {
  data: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    createdAt: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.string),
  }),
};

CardLinks.defaultProps = {
  data: undefined,
};

export default CardLinks;
