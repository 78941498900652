import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Typography } from 'antd';
import InputMask from 'components/Form/InputMask';
import SelectCountry from 'components/SelectCountry';
import { ArrowDown } from '@combateafraude/icons/general';

const { Text } = Typography;

const EditAddress = ({ isEditing, loading }) => {
  const highlightClass = isEditing ? 'editing-highlight' : '';

  const renderIcon = useCallback((icon) => (!isEditing ? null : icon), [isEditing]);

  const renderPlaceholder = useCallback(
    (placeholder) => (!isEditing ? '' : placeholder),
    [isEditing]
  );

  return (
    <>
      <div className="mrg-top-25 mrg-btm-15">
        <Text className="text-bold text-dark font-size-20">Endereço</Text>
      </div>

      <Row className="fd-row">
        <Col span={12}>
          <Form.Item name="country" label="País da empresa" rules={[{ required: true }]}>
            <SelectCountry
              placeholder={renderPlaceholder('País da empresa')}
              className={highlightClass}
              suffixIcon={renderIcon(<ArrowDown />)}
              disabled={!isEditing || loading}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['address', 'zip_code']}
            label="CEP"
            rules={[{ required: true }]}
          >
            <InputMask
              disabled={!isEditing || loading}
              mask="99999-999"
              allowClear
              placeholder={renderPlaceholder('Digite o CEP')}
              className={highlightClass}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['address', 'district']}
            label="Bairro"
            rules={[{ required: true }]}
          >
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite o bairro')}
              className={highlightClass}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['address', 'street']}
            label="Rua"
            rules={[{ required: true }]}
          >
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite a rua')}
              className={highlightClass}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name={['address', 'number']}
            label="Número"
            rules={[{ required: true }]}
          >
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite o número')}
              className={highlightClass}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

EditAddress.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditAddress;
