import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';

import Button from 'components/Button';

import './styles.less';

const { Title } = Typography;

const Subheader = ({ title, loading, formRef, disabledSaveButton }) => {
  const history = useHistory();

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link className="gx-link" to="/datasources">
            Fontes de dados
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar Fonte de dados</Breadcrumb.Item>
      </Breadcrumb>
    ),
    []
  );

  return (
    <section id="subheader-datasources-component">
      <div className="gx-header-horizontal-top">
        <div className="gx-container">
          <div className="subheader-content">
            <div className="subheader-info">
              {standardBreadcrumbs}
              <div className="flex align-center">
                <Title level={5} className="subheader-name max-lines-2">
                  {title}
                </Title>
              </div>
            </div>
            <Button
              type="default"
              className="btn-cancel"
              onClick={() => history.push(`/datasources`)}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              onClick={async () => {
                await formRef.validateFields();
                await formRef.submit();
              }}
              loading={loading}
              disabled={loading || disabledSaveButton}
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

Subheader.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  formRef: PropTypes.shape().isRequired,
  disabledSaveButton: PropTypes.bool.isRequired,
};

export default Subheader;
