import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Close } from '@combateafraude/icons/general';
import { Modal, Typography } from 'antd';

import './styles.less';

const { Text } = Typography;

const EmptyListModal = ({ modalVisible, onCloseModal }) => {
  return (
    <Modal
      visible={modalVisible}
      className="invoices-modal-component"
      closable
      closeIcon={<Close width={18} height={18} />}
      centered
      onCancel={onCloseModal}
      footer={null}
      destroyOnClose
      width={500}
    >
      <div className="content">
        <Text className="text">
          Para gerar cobrança, selecione uma ou mais empresas, após isso, clique no botão{' '}
          <b>gerar cobrança.</b>
        </Text>
      </div>
      <div className="actions">
        <Button className="btn-custom btn-custom-primary" onClick={onCloseModal}>
          Entendi
        </Button>
      </div>
    </Modal>
  );
};

EmptyListModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default EmptyListModal;
