import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import * as iconOthers from '@combateafraude/icons/others';
import Tag from '../Tag';
import TAGS from '../Tag/utils/tags';

import './styles.less';

const reproveArray = ['REPROVADO', 'REPROVE', 'REPROVED'];

const ExecutionStatusTag = ({ status, isFraud }) => {
  let statusObject = TAGS[status];

  if (reproveArray.includes(status) && isFraud) statusObject = TAGS.FRAUDE;

  return (
    <Tag className={statusObject.className}>
      {createElement(iconOthers[statusObject.icon], { className: 'icon' })}
      {statusObject.title}
    </Tag>
  );
};

ExecutionStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
  isFraud: PropTypes.bool.isRequired,
};
export default ExecutionStatusTag;
