/* eslint-disable react/prop-types */

import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import _ from 'lodash';

import {
  Attach,
  CheckD,
  CircleFalse,
  Company,
  Edit,
  OpenWindow,
  ProcessingLine,
} from '@combateafraude/icons/general';

import Tag from 'components/Tag';
import { Person, UserActive } from '@combateafraude/icons/users';
import ExecutionStatusTag from 'components/ExecutionStatusTag';

const CardExecution = ({ data, customers, socket }) => {
  const [operators, setOperators] = useState([]);
  const operatorsRef = useRef([]);

  const url_location = window.location.href;

  const isFacematch = url_location.includes('doubleCheckFacematch');

  useEffect(() => {
    operatorsRef.current = operators;
  }, [operators]);

  const isReportCnpj = useMemo(() => !!data?.cnpj, [data]);
  const isReportCpf = useMemo(
    () =>
      (!data?.images || _.isEmpty(data?.images)) && !data?.type && !data?.sections?.ocr,
    [data]
  );

  const client = useMemo(() => {
    return customers?.docs?.find((customer) => customer?.tenantId === data?.tenantId);
  }, [customers.docs, data.tenantId]);

  const url = useMemo(() => {
    if (isFacematch) {
      return `/executions/details/user/${data.tenantId}/report/${data.reportId}/execution/${data.executionId}/double-check-facematch`;
    }
    return data?.executionId
      ? `/executions/details/user/${data.tenantId}/report/${data.reportId}/execution/${data.executionId}`
      : `/executions/details/id/${data._id}/double-check-face-authenticator`;
  }, [data, isFacematch]);

  const invalidValidations = useMemo(() => {
    return data?.validations?.filter((v) => v.status === 'INVALID') || [];
  }, [data]);

  const unavailableSources = useMemo(() => {
    const unavailableSourcesArray = [];
    let hasPreviousSource = false;
    if (data?.sections) {
      Object.keys(data?.sections).forEach((key) => {
        if (data?.sections[key].statusCode === '50') {
          if (hasPreviousSource) {
            unavailableSourcesArray.push(', ');
          }
          unavailableSourcesArray.push(key);
          hasPreviousSource = true;
        }
      });
    }
    return unavailableSourcesArray;
  }, [data]);

  const beingEditedByOperator = useMemo(() => {
    if (!operators || !operators?.length) return undefined;

    const mainOperator = operators[0];

    return mainOperator;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operators, data]);

  useEffect(() => {
    try {
      setOperators([]);

      if (socket !== null) {
        socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);

          let newOperators = [];
          if (message.type === 'disconnect') {
            const disconnectedClient = message.content;

            newOperators = operatorsRef.current.filter(
              (operator) => operator.socketId !== disconnectedClient.id
            );

            setOperators(() => [...newOperators]);
          } else if (message.type !== 'error') {
            const executionId = data.executionId || data._id;

            const messageContentByExecutionId = message.content.filter(
              (socketClient) =>
                socketClient.infoData?.executionId === executionId &&
                socketClient.topics.find(
                  (t) => t === `backoffice-execution-${executionId}`
                )
            );
            if (messageContentByExecutionId && messageContentByExecutionId.length > 0) {
              newOperators = messageContentByExecutionId
                .map((socketClient) => ({
                  socketId: socketClient.id,
                  executionId: socketClient.infoData.executionId,
                  name: socketClient.infoData.name,
                  userId: socketClient.infoData.userId,
                  createdAt: socketClient.createdAt,
                }))
                .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

              setOperators(() => [...newOperators]);
            }
          }
        });
      }
    } catch {
      // SILENCER
    }

    return () => {};
  }, [data, socket]); // eslint-disable-line

  return (
    <Link className="overflow-hidden document-card mrg-btm-20" to={url}>
      <Card className={beingEditedByOperator && 'execution-card-being-edited'}>
        <div className="card-body">
          <div className="flex-1 flex-column center-start">
            <div className="flex gx-w-100">
              {!isReportCnpj ? (
                <p className="card-title max-lines-2 gx-w-100">
                  {data?.name ||
                    data?.sections?.cpf?.name ||
                    data?.sections?.pfBasicData?.data?.name ||
                    data?.sections?.ocr?.name ||
                    data?.sections?.ocrRedundancy?.name ||
                    data?.sections?.pfData?.data?.name ||
                    '-'}
                </p>
              ) : (
                <p className="card-title max-lines-2">
                  {data?.sections?.pjData?.data?.officialName ||
                    data?.sections?.pjBasicData?.data?.officialName ||
                    data?.sections?.pjBasicData?.data?.tradeName ||
                    data?.sections?.pjBoaVista?.data?.DefineLimite?.IdentificacaoCompleto
                      ?.RazaoSocial ||
                    '-'}
                </p>
              )}

              <span className="editing-data">
                {JSON.stringify({
                  userId: beingEditedByOperator?.userId,
                  executionId: data.executionId,
                })}
              </span>
              {operators && beingEditedByOperator && (
                <div className="float-tag">
                  <Tag color="processing" icon={<Edit width={20} height={20} />}>
                    {beingEditedByOperator?.name}
                  </Tag>
                </div>
              )}
              {data?.status && (
                <div className="float-tag">
                  <ExecutionStatusTag isFraud={data.fraud} status={data?.status} />
                </div>
              )}
            </div>
            <div className="flex">
              {!isReportCnpj ? (
                <div className="card-info">
                  <Person />
                  <span
                    className={`${classNames({
                      'gx-font-italic':
                        !data?.cpf && !data?.parameters?.cpf && !data?.sections?.ocr?.cpf,
                    })}mrg-left-5`}
                  >
                    {data?.cpf ||
                      data?.parameters?.cpf ||
                      data?.sections?.ocr?.cpf ||
                      'CPF indisponível'}
                  </span>
                </div>
              ) : (
                <div className="card-info">
                  <Company />
                  <span
                    className={`${classNames({
                      'gx-font-italic': !data?.cnpj && !data?.parameters?.cnpj,
                    })} mrg-left-5`}
                  >
                    {data?.cnpj || data?.parameters?.cnpj || 'CNPJ indisponível'}
                  </span>
                </div>
              )}

              {!isReportCpf && !isReportCnpj && (
                <div className="card-info">
                  <Attach />
                  <span className="mrg-left-5">{data?.type?.toUpperCase() || '-'}</span>
                </div>
              )}

              {data?.reviews && !_.isEmpty(data?.reviews) && (
                <div
                  className={`card-info ${classNames({
                    'double-check-reviewed':
                      data?.reviews &&
                      !_.isEmpty(data?.reviews) &&
                      data?.documentscopyRequestDate,
                  })}`}
                >
                  <CheckD />
                  <span className="mrg-left-5">Double-check</span>
                </div>
              )}

              {data?.documentscopyRequest > 0 && (
                <div className="card-info">
                  <UserActive
                    className={`${classNames({
                      'gx-text-primary': data?.sectionsDocumentscopyStatus === 'APROVADO',
                      'gx-text-danger': data?.sectionsDocumentscopyStatus === 'REPROVADO',
                    })}`}
                  />

                  <span className="mrg-left-5">
                    Documentoscopia realizada em{' '}
                    {format(new Date(data?.documentscopyReviewDate), 'dd/MM/yyyy')}
                  </span>
                </div>
              )}

              {data?.documentscopyRequestDate && !data?.documentscopyRequest && (
                <div className="card-info">
                  <ProcessingLine />
                  <span className="mrg-left-5">Aguardando documentoscopia</span>
                </div>
              )}
            </div>
            {client && (
              <>
                <div className="flex center mrg-top-15 card-info">
                  <span className="no-mrg text-dark font-size-13 flex center">
                    <Company />
                    <span className="mrg-left-5">Cliente: </span>
                    <span className="text-bold mrg-left-5">
                      {client.fantasyName || client.companyName}
                    </span>
                    <span className="mrg-left-5">- ID:</span>
                    <span className="text-bold mrg-left-5">
                      {data?.executionId || '-'}
                    </span>
                  </span>
                </div>
              </>
            )}
            {invalidValidations?.length > 0 && (
              <div className="mrg-top-10">
                {invalidValidations?.map((v) => (
                  <div key={v.rule} className="mrg-top-5 text-dark">
                    <CircleFalse className="text-danger" />
                    <small className="font-size-13 mrg-left-5">{v.description}</small>
                  </div>
                ))}
              </div>
            )}
            {unavailableSources?.length > 0 && (
              <div className="mrg-top-10">
                <div className="mrg-top-5 text-dark">
                  <CircleFalse className="text-danger" />
                  <small className="font-size-13 mrg-left-5">{unavailableSources}</small>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card-footer">
          <span className="gx-font-italic font-size-11 gx-font-weight-light">
            Consulta em{' '}
            {data?.createdAt
              ? format(new Date(data?.createdAt), "dd/MM/yyyy 'às' HH:mm")
              : '-'}
            {data?.lastModification && (
              <>
                <b className="separator">•</b>
                Última modificação por {data?.lastModification?.user?.email} em{' '}
                {format(
                  new Date(data?.lastModification?.changedDate),
                  "dd/MM/yyyy 'às' HH:mm"
                )}
              </>
            )}
          </span>
          {data?.status?.toUpperCase() === 'PENDENTE OCR' &&
            data?.client?.pendingOcrInternally && (
              <div className="float-tag" style={{ marginLeft: 250 }}>
                <Tag color="warning">Necessário Atuação do Suporte</Tag>
              </div>
            )}
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.open(url);
            }}
            className="btn-open"
          >
            <OpenWindow />
          </button>
        </div>
      </Card>
    </Link>
  );
};

export default CardExecution;
