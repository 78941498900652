import React, { useEffect, useMemo, useRef } from 'react';
import { Row, Col, Alert, Spin } from 'antd';

import { useCustomer } from 'hooks/customer';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { useComponentNextList } from 'components/List';

import { AlertTriangle } from '@combateafraude/icons/general';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import CardIproov from './components/CardIproov';

import useIproovCreate from '../IproovCreate';
import Wrapper from '../../wrapper';

import './styles.less';

const Iproov = () => {
  const refreshListRef = useRef(() => {});
  const { tenantId } = useParams();
  const { customer } = useCustomer();

  useEffect(() => {
    const handleFinishEdit = () => {
      refreshListRef.current();
    };

    document.addEventListener('finishEditIproovToken', handleFinishEdit);

    return () => {
      document.removeEventListener('finishEditIproovToken', handleFinishEdit);
    };
  }, []);

  const { componentList, refreshList, data, loading } = useComponentNextList({
    component: CardIproov,
    rowKey: 'secret',
    hidePagination: true,
    getParams: {
      url: `/clients/${tenantId}/iproov-tokens`,
      config: {
        params: {},
      },
    },
  });

  const { openModal, IproovCreateModal } = useIproovCreate({ refreshListRef });

  const hasTokenIproov = useMemo(() => !data?.length > 0, [data]);

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      <div id="iproov-list-component">
        {IproovCreateModal}
        <Row align="middle" className="no-mrg">
          <Col flex={1} className="page-title">
            <PageTitle title="Tokens iProov" />
          </Col>
          {hasTokenIproov && !loading && (
            <Col>
              <Button
                className="btn-custom btn-custom-primary"
                onClick={() => {
                  openModal();
                }}
                disabled={!customer || loading}
              >
                Novo token
              </Button>
            </Col>
          )}
        </Row>
        <Alert
          style={{ border: '1px solid #F16E00', backgroundColor: '#F8B23920' }}
          icon={
            <AlertTriangle
              width={20}
              height={20}
              color="#FF9"
              style={{ color: '#FF9500' }}
            />
          }
          showIcon
          type="warning"
          message="Este token é para uso exclusivo do destinatário autorizado. Por favor, mantenha-o em segurança e não o divulgue."
        />
        {customer && !loading ? (
          componentList
        ) : (
          <Col className="spinner-container">
            <Spin size="large" />
          </Col>
        )}
      </div>
    </Wrapper>
  );
};

export default Iproov;
