import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

const INITIAL_LIMIT = 2;
const INITIAL_OFFSET = 0;

const TimelineContext = createContext({});

const TimelineProvider = ({ children }) => {
  const { customer } = useCustomer();
  const {
    data: annotationListNewData,
    loading: loadingAnnotationList,
    get: getAnnotationList,
  } = useFetch();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerVisibleMandatory, setDrawerVisibleMandatory] = useState(false);

  const [annotationList, setAnnotationList] = useState([]);
  const [starredAnnotationList, setStarredAnnotationList] = useState([]);

  const [pagination, setPagination] = useState({
    _limit: INITIAL_LIMIT,
    _offset: INITIAL_OFFSET,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    _search: '',
  });

  const fetch = useCallback(
    (forceReload = false) => {
      if (!customer || (!pagination.hasMore && !forceReload)) return;

      getAnnotationList({
        url: '/clients-timeline',
        config: {
          params: {
            _tenantId: customer?.tenantId,
            _dated: true,
            _limit: pagination._limit,
            _offset: forceReload ? 0 : pagination._offset,
            ...filter,
          },
        },
        clearData: pagination._offset === 0,
      });
    },
    [customer, pagination, getAnnotationList, filter]
  );

  const reload = useCallback(() => {
    setAnnotationList([]);
    setStarredAnnotationList([]);

    setPagination({
      _limit: INITIAL_LIMIT,
      _offset: INITIAL_OFFSET,
      totalItems: 0,
      hasMore: true,
    });

    setTimeout(() => {
      fetch(true);
    }, 250);
  }, [fetch]);

  useEffect(() => {
    reload();
  }, [filter]); // eslint-disable-line

  const openModal = useCallback(() => {
    reload();
    setDrawerVisible(true);
  }, [reload]);

  const closeModal = useCallback(() => {
    setDrawerVisible(false);
  }, []);

  const openModalMandatory = useCallback(() => {
    reload();
    setDrawerVisibleMandatory(true);
  }, [reload]);

  const closeModalMandatory = useCallback(() => {
    setDrawerVisibleMandatory(false);
  }, []);

  // reinicializa caso o customer é alterado
  useEffect(() => {
    if (customer) return;

    setPagination({
      _limit: INITIAL_LIMIT,
      _offset: INITIAL_OFFSET,
      totalItems: 0,
      hasMore: true,
    });
  }, [customer]);

  // coloca itens na lista
  useEffect(() => {
    if (!annotationListNewData) return;

    if (annotationListNewData.docs[0].no_stars.length === 0) {
      // caso não retorne mais nenhuma data, não vai continuar carregando
      setPagination({
        _limit: INITIAL_LIMIT,
        _offset: INITIAL_OFFSET,
        totalItems: 0,
        hasMore: false,
      });

      return;
    }

    setPagination((oldState) => ({
      ...oldState,
      _offset: oldState._offset + oldState._limit,
      totalItems: annotationListNewData.totalItems,
      hasMore: true,
    }));

    const { stars, no_stars } = annotationListNewData.docs[0];

    setAnnotationList((oldState) => [...oldState, ...no_stars]);
    setStarredAnnotationList(stars);
  }, [annotationListNewData]);

  return (
    <TimelineContext.Provider
      value={{
        drawerVisible,
        drawerVisibleMandatory,
        annotationList,
        starredAnnotationList,
        loadingAnnotationList,
        fetch,
        reload,
        openModal,
        closeModal,
        openModalMandatory,
        closeModalMandatory,
        setFilter,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

function useTimeline() {
  const context = useContext(TimelineContext);

  if (!context) {
    throw new Error('useTimeline must be used within an TimelineContext');
  }

  return context;
}

export { TimelineProvider, useTimeline };
