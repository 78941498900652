import React, { useCallback, useState } from 'react';
import { Row, Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import InputMask from 'components/Form/InputMask';
import { Duplicate } from '@combateafraude/icons/general';
import OcrFormItem from './OcrFormItem';

const TypeRNM = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem name="name" label="Nome" errorText={invalidParams.name} span={14}>
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="birthDate"
          label="Data de nascimento"
          errorText={invalidParams.birthDate}
          isImportant
          span={10}
        >
          <InputMask
            mask="99/99/9999"
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate
                  className="copy-input"
                  onClick={() => copyHandler('birthDate')}
                />
              </Tooltip>
            }
          />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem name="mrz" label="MRZ" errorText={invalidParams.mrz} span={24}>
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="legalProtection"
          label="Proteção legal"
          errorText={invalidParams.legalProtection}
          span={14}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="gender"
          label="Gênero"
          errorText={invalidParams.gender}
          span={4}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="nationality"
          label="Nacionalidade"
          errorText={invalidParams.nationality}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="motherName"
          label="Nome da mãe"
          errorText={invalidParams.motherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="classification"
          label="Classificação"
          errorText={invalidParams.classification}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="fatherName"
          label="Nome do pai"
          errorText={invalidParams.fatherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="residenceTerm"
          label="Termo de residência"
          errorText={invalidParams.residenceTerm}
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="validity"
          label="Validade"
          errorText={invalidParams.validity}
          isImportant
          span={6}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issueDate"
          label="Data de emissão"
          errorText={invalidParams.issueDate}
          isImportant
          span={6}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issuingAuthority"
          label="Autoridade emissora"
          errorText={invalidParams.issuingAuthority}
          isImportant
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="cpf"
          label="CPF"
          errorText={invalidParams.cpf}
          isImportant
          span={8}
        >
          <Input
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate className="copy-input" onClick={() => copyHandler('cpf')} />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="rnm"
          label="RNM"
          errorText={invalidParams.rnm}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="protocol"
          label="Protocolo"
          errorText={invalidParams.protocol}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypeRNM.propTypes = {
  invalidParams: PropTypes.shape,
  onCopy: PropTypes.func.isRequired,
};

TypeRNM.defaultProps = {
  invalidParams: {},
};

export default TypeRNM;
