import React, { useRef, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Typography, Dropdown, Form, Avatar } from 'antd';
import _ from 'lodash';
import { useQueryParams, StringParam } from 'use-query-params';

import Button from 'components/Button';
import { useTable } from 'components/List';
import SearchInput from 'components/Form/SearchInput';

import { toMaskedCpf } from 'utils/formatters';

import { MoreVertical } from '@combateafraude/icons/general';
import ListDropdown from '../components/ListDropdown';
import useSharedFacesetCreateModal from '../SharedFacesetCreate/SharedFacesetCreateModal';
import Wrapper from '../../wrapper';

import './styles.less';

const { Title } = Typography;

const SharedFaceset = () => {
  const refreshListRef = useRef(() => {});
  const [query] = useQueryParams({
    cpf: StringParam,
    executionId: StringParam,
  });

  const columns = [
    {
      key: 'face',
      title: 'Face',
      dataIndex: 'images',
      render: (__, record) => {
        const image = record?.images.length > 1 ? record?.images[1] : record?.images[0];
        return <Avatar shape="square" size={64} src={image} />;
      },
    },
    {
      key: 'cpf',
      title: 'CPF',
      dataIndex: 'cpf',
      render: (record) => toMaskedCpf(record),
    },
    {
      key: 'description',
      title: 'Descrição',
      dataIndex: 'description',
    },
    {
      render: (__, record) => {
        return (
          <NavLink
            to={`executions/details/user/${record?.tenantId}/report/${record?.reportId}/execution/${record?.executionId}`}
          >
            Execução
          </NavLink>
        );
      },
    },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown data={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { tableContent, refreshList, setFilterData } = useTable({
    getParams: {
      url: '/shared-faceset',
      config: { params: {} },
    },
    queryParams: {
      cpf: StringParam,
      executionId: StringParam,
    },
    columns,
  });

  const { openModal, SharedFacesetCreateModal } = useSharedFacesetCreateModal({
    refreshList,
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        cpf: values.cpf ? values.cpf.trim() : undefined,
        executionId: values.executionId ? values.executionId.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      {SharedFacesetCreateModal}
      <Row id="shared-faceset-list-component">
        <Col span={24}>
          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} className="page-title">
              <Title className="title">Banco de Faces</Title>
            </Col>
            <Col>
              <Form
                layout="inline"
                onValuesChange={handleFormChange}
                initialValues={{ cpf: query?.cpf, executionId: query?.executionId }}
              >
                <SearchInput
                  name="executionId"
                  placeholder="Pesquise por ID da execução..."
                  style={{ width: 275 }}
                />
                <SearchInput
                  name="cpf"
                  placeholder="Pesquise por CPF..."
                  style={{ width: 250 }}
                />
              </Form>
            </Col>
            <Col className="mrg-left-20">
              <Button className="btn-custom btn-custom-primary" onClick={openModal}>
                Novo
              </Button>
            </Col>
          </Row>

          {tableContent}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SharedFaceset;
