import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useMultipleInvoices } from 'hooks/useMultipleInvoices';
import { Modal, Table, Typography, Checkbox } from 'antd';
import InputMask from 'components/Form/InputMask';
import Button from 'components/Button';
import { Close, WrongCircle } from '@combateafraude/icons/general';

import MonthPicker from '../MonthPicker/MonthPicker';
import YearPicker from '../YearPicker/YearPicker';

import './styles.less';

const { Text, Title } = Typography;

const AdjustClientsModal = ({ modalVisible, onCloseModal, onGenerate }) => {
  const {
    checkClient,
    changeDate,
    removeUnchecked,
    month,
    year,
    setMonth,
    setYear,
    invoiceClientsTenants,
    invoiceClients,
  } = useMultipleInvoices();

  const [checkedTerms, setCheckedTerms] = useState(false);
  const [allClientsHaveDate, setAllClientsHaveDate] = useState(false);

  const tableData = useMemo(() => {
    const data = invoiceClientsTenants.map((tenantId) => {
      return {
        key: tenantId,
        fantasyName: invoiceClients[tenantId].fantasyName,
        date: invoiceClients[tenantId].date,
        cnpj: invoiceClients[tenantId].cnpj,
        checked: invoiceClients[tenantId].checked,
      };
    });

    return data;
  }, [invoiceClientsTenants, invoiceClients]);

  const checkAllHaveDates = useCallback(() => {
    const data = invoiceClientsTenants.filter(
      (client) =>
        invoiceClients[client]?.checked &&
        (invoiceClients[client]?.date?.trim().length !== 10 ||
          invoiceClients[client]?.date === undefined)
    );

    setAllClientsHaveDate(data.length === 0);
    return data.length === 0;
  }, [invoiceClientsTenants, invoiceClients]);

  const checkTermsHandler = useCallback(
    (e) => {
      setCheckedTerms(e?.target?.checked);
      checkAllHaveDates();
    },
    [checkAllHaveDates]
  );

  const closeModalHandler = useCallback(() => {
    setCheckedTerms(false);
    onCloseModal();
  }, [onCloseModal]);

  const dateChangeHandler = useCallback(
    (client, event) => changeDate(client.key, event?.target?.value),
    [changeDate]
  );

  const onSubmit = useCallback(() => {
    if (!checkAllHaveDates()) return;

    removeUnchecked();
    onGenerate();
  }, [checkAllHaveDates, onGenerate, removeUnchecked]);

  const amountToGenerate = useMemo(
    () => tableData?.filter((client) => client.checked === true).length,
    [tableData]
  );
  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      className: 'checkbox-col',
      render: (__, client) => {
        return (
          <span className="checkbox">
            <Checkbox
              checked={client?.checked}
              onChange={(e) => checkClient(client.key, e?.target?.checked)}
            />
          </span>
        );
      },
    },
    {
      title: 'Nome fantasia',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      className: 'fantasy-col',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
    },
    {
      title: 'Vencimento',
      dataIndex: 'date',
      key: 'date',
      render: (__, client) => {
        return (
          <div style={{ display: 'flex' }}>
            <span>
              <InputMask
                className={`date-input ${
                  checkedTerms &&
                  client?.checked &&
                  (client?.date?.trim().length !== 10 || client?.date === undefined)
                    ? 'date-error'
                    : ''
                }`}
                mask="99/99/9999"
                disabled={!client?.checked}
                value={client?.date}
                onChange={(e) => dateChangeHandler(client, e)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      visible={modalVisible}
      className="invoices-modal-component"
      closable
      closeIcon={<Close width={18} height={18} />}
      centered
      onCancel={closeModalHandler}
      footer={null}
      destroyOnClose
      width={1000}
    >
      <>
        <div>
          <div className="header">
            <Title className="title">Geração de cobrança</Title>
            <Text className="subtitle">
              Você está gerando a cobrança de <b>{amountToGenerate} clientes.</b>
            </Text>
          </div>

          <div className="date-picker">
            <span>
              <MonthPicker value={month} onChange={setMonth} />
            </span>
            <span>
              <YearPicker value={year} onChange={setYear} />
            </span>
          </div>
          <div>
            <Table
              className="invoices-table"
              dataSource={tableData}
              pagination={false}
              columns={columns}
              rowKey={tableData?.tenantId}
            />
            <Checkbox
              className="checkbox terms"
              checked={checkedTerms}
              onChange={checkTermsHandler}
            >
              Li e estou ciente de que nenhum cliente selecionado possui ajuste manual.
            </Checkbox>

            {!allClientsHaveDate && checkedTerms && (
              <span className="missing-date">
                <WrongCircle /> Você possui clientes sem a data de vencimento definida.{' '}
                <b>Para continuar, preencha a data de vencimento de todos os clientes.</b>
              </span>
            )}
          </div>
        </div>

        <div className="actions">
          <Button type="link" onClick={closeModalHandler}>
            Cancelar
          </Button>

          <Button
            className="btn-custom btn-custom-primary"
            disabled={!checkedTerms || amountToGenerate === 0}
            onClick={onSubmit}
          >
            Gerar cobrança
          </Button>
        </div>
      </>
    </Modal>
  );
};

AdjustClientsModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func.isRequired,
};

export default AdjustClientsModal;
