import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Select } from 'antd';
import { ArrowDown } from '@combateafraude/icons/general';
import { range } from 'lodash';

import './styles.less';

const { Text } = Typography;

const date = new Date();

const YearPicker = ({ value, onChange, ...rest }) => {
  const options = range(date.getFullYear() - 5, date.getFullYear() + 5).map((option) => {
    return { label: option, value: option };
  });

  return (
    <>
      <Text className="picker-text">Ano</Text>
      <Select
        className="year-picker"
        value={value}
        onChange={onChange}
        options={options}
        placeholder="Selecione o ano"
        suffixIcon={<ArrowDown />}
        {...rest}
      />
    </>
  );
};

YearPicker.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default YearPicker;
