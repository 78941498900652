import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Switch, Divider, message } from 'antd';
import { ArrowRight1, Settings } from '@combateafraude/icons/general';
import { Users } from '@combateafraude/icons/users';

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useAccountProducts } from 'hooks/accountProducts';

import Card from 'components/Card';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import TooltipInfo from 'components/TooltipInfo';

import useManagementUsersModal from './ManagementUsersModal';
import useUpdateConfigurationsModal from './UpdateConfigurationsModal';
import './styles.less';

const ProductCard = ({ product, hasProducts }) => {
  const { post } = useFetch();
  const { tenantId } = useParams();
  const { user } = useAuth();

  const { openModal: openUpdateConfigurationsModal, UpdateConfigurationsModal } =
    useUpdateConfigurationsModal();
  const { openModal: openManagementUsersModal, ManagementUsersModal } =
    useManagementUsersModal();

  const [loading, setLoading] = useState(false);
  const {
    loading: loadingAccountProducts,
    changeProductActivation,
    accountsProducts,
  } = useAccountProducts();

  const currentProductKey = useMemo(() => product?.key, [product]);

  const preventSwitchStateChange = useMemo(() => {
    if (product?.alwaysActive) return true;

    if (currentProductKey?.toString()?.toLowerCase() === 'trust') {
      return product.active && !accountsProducts?.identity?.active;
    }

    if (currentProductKey?.toString()?.toLowerCase() === 'identity') {
      return product.active && !accountsProducts?.trust?.active;
    }

    return false;
  }, [accountsProducts, currentProductKey, product]);

  const preventDisable = useMemo(() => {
    if (!product.active) return false;

    if (currentProductKey?.toString()?.toLowerCase() === 'trust') {
      return !accountsProducts?.identity?.active;
    }

    if (currentProductKey?.toString()?.toLowerCase() === 'identity') {
      return !accountsProducts?.trust?.active;
    }

    return false;
  }, [accountsProducts, currentProductKey, product.active]);

  const handleChange = useCallback(
    async (productKey, active, event) => {
      event.preventDefault();
      setLoading(true);
      try {
        await changeProductActivation({ tenantId, productKey, active });
      } catch (error) {
        message.error('Ocorreu um erro ao realizar a ação');
      }
      setLoading(false);
    },
    [changeProductActivation, tenantId]
  );

  const productActivationIsDisabled = useCallback(
    (productToVerify) => {
      return (
        !productToVerify?.comingSoon &&
        !hasProducts &&
        !productToVerify?.alwaysActive &&
        !productToVerify?.active
      );
    },
    [hasProducts]
  );

  const handleRequestAccess = useCallback(async () => {
    try {
      message.info('Criando usuário.');
      await post({
        url: `/users`,
        payload: {
          username: user.email,
          name: user.name,
          tenantId,
        },
        showMessage: false,
      });
      message.success(
        'Usuário criado com sucesso. Verifique seu e-mail para informações de acesso.'
      );
    } catch (error) {
      message.error('Ocorreu um problema ao criar usuário.');
    }
  }, [post, tenantId, user.email, user.name]);

  const tooltipMessage = useMemo(() => {
    if (currentProductKey === 'trust' && !accountsProducts?.identity?.active) {
      return 'É necessário habilitar o Identity para desabilitar o Trust';
    }

    if (currentProductKey === 'identity' && !accountsProducts?.trust?.active) {
      return 'É necessário habilitar o Trust para desabilitar o Identity';
    }

    return 'Este produto não pode ser desabilitado';
  }, [accountsProducts, currentProductKey]);

  return (
    <>
      {product.key === 'management' && (
        <>
          {ManagementUsersModal}
          {UpdateConfigurationsModal}
        </>
      )}
      <Card>
        <Row align="middle" justify="space-between" className="mrg-btm-15">
          <Col span={19}>
            <PageTitle
              title={
                <div className="flex pdd-btm-10">
                  <img alt="" src={product?.icon} />
                  <span className="mrg-left-10" style={{ fontSize: 28 }}>
                    {product?.title || '-'}
                  </span>
                </div>
              }
              subtitle={<span>{product?.description || '-'}</span>}
            />
          </Col>
          <Col span={5} className="flex end-center">
            <div className="flex center">
              <Switch
                className="mrg-right-5"
                defaultChecked={
                  (product?.alwaysActive || product?.active) && !product?.comingSoon
                }
                checked={
                  ((product?.alwaysActive || product?.active) && !product?.comingSoon) ||
                  preventDisable
                }
                disabled={
                  preventSwitchStateChange ||
                  product?.comingSoon ||
                  !hasProducts ||
                  loading ||
                  loadingAccountProducts
                }
                loading={loading || loadingAccountProducts}
                onClick={(value, event) => handleChange(product.key, value, event)}
              />
              {product?.comingSoon && (
                <TooltipInfo title="Em breve" className="product-card-tooltip-info" />
              )}
              {(product?.alwaysActive || preventSwitchStateChange) && (
                <TooltipInfo
                  title={tooltipMessage}
                  className="product-card-tooltip-info"
                />
              )}
              {productActivationIsDisabled(product) && (
                <TooltipInfo
                  title="O produto não pode ser ativado pois a conta do usuário não foi migrada para o trust"
                  className="product-card-tooltip-info"
                />
              )}
            </div>
          </Col>
        </Row>
        {product.key === 'management' && (
          <Row align="middle" justify="start">
            <Divider className="product-card-divider" />
            <Col>
              <Button type="link" onClick={handleRequestAccess} className="flex center">
                <ArrowRight1 width={24} height={24} />
                <span className="mrg-left-5">SOLICITAR ACESSO</span>
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={openManagementUsersModal}
                className="flex center"
              >
                <Users width={24} height={24} />
                <span className="mrg-left-5">USUÁRIOS</span>
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={openUpdateConfigurationsModal}
                className="product-card-execution-limit-button flex center"
              >
                <Settings width={24} height={24} />
                <span className="mrg-left-5">CONFIGURAÇÕES</span>
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.oneOfType({}).isRequired,
  hasProducts: PropTypes.bool,
};
ProductCard.defaultProps = {
  hasProducts: false,
};

export default ProductCard;
