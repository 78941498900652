import React from 'react';
import { NavLink } from 'react-router-dom';
import Wrapper from 'pages/private/wrapper';

import './styles.less';
import { Settings } from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';

const AnalyticsWrapper = ({ children }) => {
  return (
    <Wrapper id="analytics-wrapper-component">
      <div className="tabs flex">
        <NavLink
          to="/analytics/tracking"
          className="flex center"
          activeClassName="active"
        >
          <Settings width={24} height={24} />
          <span className="mrg-left-5">Tracking</span>
        </NavLink>
        <NavLink to="/analytics/events" className="flex center" activeClassName="active">
          <Person width={24} height={24} />
          <span className="mrg-left-5">Eventos</span>
        </NavLink>
      </div>

      {children}
    </Wrapper>
  );
};

export default AnalyticsWrapper;
