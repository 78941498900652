import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const ExecutionContext = createContext({});

const ExecutionProvider = ({ children }) => {
  const {
    data: executionData,
    loading: loadingExecution,
    get,
    clearData: clearExecution,
    error,
  } = useFetch();

  const [loadDataParams, setLoadDataParams] = useState({});

  const loadData = useCallback(
    ({ tenantId, reportId, executionId, clearData }) => {
      setLoadDataParams({
        tenantId,
        reportId,
        executionId,
      });

      get({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}`,
        clearData,
      });
    },
    [get]
  );

  const reloadData = useCallback(
    (clearData = true) => {
      if (!loadDataParams) return;

      loadData({ ...loadDataParams, clearData });
    },
    [loadDataParams, loadData]
  );

  return (
    <ExecutionContext.Provider
      value={{
        executionData,
        loadingExecution,
        loadData,
        reloadData,
        clearExecution,
        error,
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
};

function useExecution() {
  const context = useContext(ExecutionContext);

  if (!context) {
    throw new Error('useExecution must be used within an TimelineContext');
  }

  return context;
}

export { ExecutionProvider, useExecution };
