/* eslint-disable react/prop-types */

import React, { useMemo, useCallback, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col, Typography, Breadcrumb, Spin, message, Popconfirm } from 'antd';
import _ from 'lodash';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import customDataCategories from 'pages/private/Customers/pages/Reports/ReportCreate/utils/customDataCategories.json';

import './styles.less';

const { Title } = Typography;

const CustomerSubheader = ({ formRef, customDataRef, ...rest }) => {
  const { tenantId, reportId } = useParams();
  const history = useHistory();

  const { customer, loadingCustomer } = useCustomer();
  const { post, put, loading } = useFetch();

  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(false);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/customers?_order=1&_sort=fantasyName" className="gx-link">
            Clientes
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/customers/${tenantId}/registration`} className="gx-link">
            {customer ? (
              customer?.fantasyName || customer?.companyName || 'Cliente'
            ) : (
              <Spin size="small" className="no-mrg" />
            )}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/customers/${tenantId}/reports`} className="gx-link">
            Relatórios
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [customer, tenantId]
  );

  const handleSaveButton = useCallback(async () => {
    if (!formRef) return;

    await formRef.validateFields();

    setIsPopConfirmVisible(false);

    const values = formRef.getFieldsValue();

    const { parts = null, searchAllFields = null } = values.processesWithKeywords || {};
    if (parts) {
      values.processesWithKeywords.parts = parts.map((part) => part.value);
    }
    if (searchAllFields) {
      values.processesWithKeywords.searchAllFields = [
        ...new Set(searchAllFields.map((field) => field.toLowerCase())),
      ];
    }

    const validationsFormatted = values.validations?.map((v) => ({
      actionWhenInvalid: v.actionWhenInvalid,
      actionWhenPending: v.actionWhenPending,
      rule: v.rule,
      enabled: !!v.enabled,
    }));

    const customDataFormatted = values.customData?.filter((cd) => cd);

    const payload = {
      ...values,
      validations: validationsFormatted || [],
      customData: customDataFormatted || [],
    };

    try {
      if (reportId) {
        await put({
          url: `/clients/${tenantId}/reports/${reportId}`,
          payload,
        });
        message.success('Relatório alterado com sucesso.');
      } else {
        await post({
          url: `/clients/${tenantId}/reports`,
          payload,
        });
        message.success('Relatório criado com sucesso.');
      }

      history.push(`/customers/${tenantId}/reports`);
    } catch (err) {
      message.error('Erro ao salvar relatório.');
    }
  }, [tenantId, formRef, post, put, reportId, history]);

  const checkDeprecatedValues = useCallback(async () => {
    if (!formRef) return;

    await formRef.validateFields();

    const values = formRef.getFieldsValue();

    const originalCustomData = customDataRef?.current || [];
    const formValuesCustomData = values.customData || [];

    const deprecatedDocs = originalCustomData?.find(
      (doc) => doc.category.title === customDataCategories.deprecated.title
    );

    if (deprecatedDocs) {
      const pfDocs = deprecatedDocs.docs.pfDocs.reduce(
        (acc, val) => acc.concat(val.source),
        []
      );
      const pjDocs = deprecatedDocs.docs.pjDocs.reduce(
        (acc, val) => acc.concat(val.source),
        []
      );
      const deprecatedCustomData = [...pfDocs, ...pjDocs];
      const intersection = _.intersection(formValuesCustomData, deprecatedCustomData);

      if (intersection.length > 0) {
        setIsPopConfirmVisible(true);

        return;
      }
    }

    handleSaveButton();
  }, [customDataRef, formRef, handleSaveButton]);

  return (
    (customer || loadingCustomer) && (
      <div id="customer-subheader-component" {...rest}>
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <Row align="middle" className="no-mrg">
              <Col flex="auto">
                {standardBreadcrumbs}
                <Title className="title">
                  {reportId ? 'Edição de relatório' : 'Cadastro de relatório'}
                </Title>
              </Col>
              <Col>
                <Link to={`/customers/${tenantId}/reports`} className="gx-link">
                  <Button
                    className="no-mrg mrg-right-10 btn-custom-secondary"
                    loading={loading}
                  >
                    Cancelar
                  </Button>
                </Link>
                <Popconfirm
                  title="Você possui fontes descontinuadas. Deseja atualizar relatório?"
                  okText="Sim"
                  cancelText="Não"
                  visible={isPopConfirmVisible}
                  onCancel={() => setIsPopConfirmVisible(false)}
                  onConfirm={handleSaveButton}
                >
                  <Button
                    className="no-mrg btn-custom btn-custom-primary"
                    onClick={checkDeprecatedValues}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomerSubheader;
