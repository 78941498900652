import React, { useCallback, useState, useMemo } from 'react';
import { Row, Col, Modal, Form, Typography } from 'antd';
import { Close, News } from '@combateafraude/icons/general';

import SquaredButton from 'components/Button/SquaredButton';

import { useTimeline } from 'hooks/timeline';

import './styles.less';

const { Title } = Typography;

const LinksCreate = ({ refreshListRef }) => {
  const { closeModalMandatory } = useTimeline();
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    form.resetFields();
  }, [closeModalMandatory, refreshListRef]); // eslint-disable-line

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const LinksCreateModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <Row>
          <Col span={24}>
            <Title className="text-center modal-title pdd-horizon-20">
              Qual o tipo de link que deseja criar?
            </Title>
          </Col>
        </Row>
        <Row className="pdd-vertical-30 mrg-horizon-10">
          <Col span={16} className="flex end-center">
            <SquaredButton
            // onClick={() => handleReprocess('CUSTOM_DATA')}
            >
              <div>
                <News width={40} height={40} />
              </div>
              Link compartilhado
            </SquaredButton>
          </Col>
        </Row>
      </Modal>
    ),
    [modalVisible, closeModal, form]
  );

  return { openModal, closeModal, LinksCreateModal };
};

export default LinksCreate;
