/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDynamoTable } from 'components/List';
import { StringParam } from 'use-query-params';

import './styles.less';
import {
  Checkmark,
  Close,
  Edit,
  MoreVertical,
  Plus1,
  Reorder,
} from '@combateafraude/icons/general';
import {
  Col,
  Dropdown,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
  Button as ButtonAntd,
} from 'antd';
import { useFetch } from 'services/hooks';
import Button from 'components/Button';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import ListDropdown from './components/ListDropdown';
import Wrapper from '../wrapper';
import useEditHubModal from '../Components/EditHubModal';
import HubTimeline from './components/Timeline';

const { Title, Text } = Typography;

const HubAccountsEdit = () => {
  const refreshListRef = useRef(() => {});
  const [loading, setLoading] = useState(false);
  const [isModalActiveVisible, setIsModalActiveVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalInactiveVisible, setIsModalInactiveVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { put: putHub } = useFetch();
  const { id } = useParams();
  const { data: allHubData, get: getHubEdit } = useFetch();

  const { openHubModal, EditHubModal } = useEditHubModal();

  const openDrawer = useCallback(() => {
    if (!isDrawerOpen) {
      setIsDrawerOpen(true);
    }
  }, [isDrawerOpen]);

  const closeDrawer = useCallback(() => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalInactiveVisible(false);
    setIsModalActiveVisible(false);
  };

  const handleInactivate = () => {
    setIsModalInactiveVisible(true);
    setIsModalVisible(false);
  };

  const getHub = useCallback(async () => {
    setLoading(true);
    const response = await getHubEdit({
      url: `/hubs-auth/${id}`,
      config: {},
    });
    setLoading(false);
    return response;
  }, [getHubEdit, id]);

  const columns = [
    {
      key: 'companyName',
      title: (
        <div className="flex row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'hubName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.fantasyName?.localeCompare(b?.fantasyName),
      render: (__, record) => (
        <span className="flex align-center">
          {record?.fantasyName || record?.companyName}
        </span>
      ),
    },
    {
      key: 'tenant',
      title: (
        <div className="flex row">
          <span className="column-title">Tenant</span>
        </div>
      ),
      dataIndex: 'accountsCount',
      render: (__, record) => <span>{record?.tenantId}</span>,
    },
    {
      key: 'tenant',
      dataIndex: 'accountsCount',
      render: (__, record) => (
        <NavLink
          to={`/customers/${record?.tenantId}/products`}
          className="nav-link text-bold"
          activeClassName="active"
        >
          Acessar usuários no Backoffice
        </NavLink>
      ),
    },
    {
      render: (__, record) => (
        <Dropdown
          overlay={
            <ListDropdown
              data={record}
              hub={allHubData}
              refreshListRef={refreshListRef}
            />
          }
          trigger={['click']}
          placement="bottomLeft"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { tableContent, refreshList } = useDynamoTable({
    getParams: {
      url: `/hubs-auth/${id}`,
      config: { params: {} },
    },
    queryParams: {
      _search: StringParam,
      _status: StringParam,
    },
    columns,
  });

  const isActive = useMemo(() => {
    return allHubData?.hubData?.active;
  }, [allHubData]);

  const hubData = useMemo(() => {
    return allHubData?.hubData;
  }, [allHubData]);

  const firstLoad = useRef(true);

  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (id && !allHubData) {
      getHub();
    }
  }, [getHub, allHubData, id]);

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleConfirmPutHub = async (status) => {
    const newPayload = {
      ...hubData,
      tenantsId: allHubData?.docs?.map((item) => item?.tenantId),
      active: status,
    };
    setLoading(true);
    await putHub({
      url: `/hubs-auth/${hubData?.id}`,
      payload: newPayload,
    });
    getHub();
    setLoading(false);
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalInactiveVisible(false);
    setIsModalActiveVisible(false);
  };

  useEffect(() => {
    const handleHubCreated = () => {
      refreshList();
      getHub();
    };
    document.addEventListener('hubEdited', handleHubCreated);
    return () => {
      document.removeEventListener('hubEdited', handleHubCreated);
    };
  }, [refreshList, getHub]);

  return (
    <Wrapper>
      <Row id="hub-list-component">
        {EditHubModal}
        <Col span={24}>
          {!loading ? (
            <Row className="flex space-between">
              <Col className="page-title">
                <Col className="flex">
                  <Title className="title">{hubData?.hubName}</Title>
                  <Col className="mrg-left-15 center flex">
                    <Tag color={isActive ? 'green' : 'red'}>
                      {isActive ? (
                        <Checkmark className="mrg-right-5" />
                      ) : (
                        <Close className="mrg-right-5" />
                      )}
                      {isActive ? 'Ativo' : 'Inativo'}
                    </Tag>
                  </Col>
                </Col>
                <Text className="subtitle">
                  Vincule e gerencie tenants ID a essa conta
                </Text>
              </Col>
              <Col className="flex">
                <Col className="mrg-left-20">
                  <Button
                    className="btn-custom btn-custom-primary"
                    disabled={loading}
                    onClick={() => {
                      openHubModal(allHubData);
                    }}
                  >
                    <Edit width={20} height={20} className="mrg-right-5" />
                    Editar HUB
                  </Button>
                </Col>
                {!isActive ? (
                  <Col className="mrg-left-20">
                    <Button
                      className="btn-custom btn-custom-green"
                      disabled={loading}
                      onClick={() => {
                        setIsModalActiveVisible(true);
                      }}
                    >
                      <Checkmark width={20} height={20} className="mrg-right-5" />
                      Ativar HUB
                    </Button>
                  </Col>
                ) : (
                  <Col className="mrg-left-20">
                    <Button
                      className="btn-custom btn-custom-danger"
                      disabled={loading}
                      onClick={() => {
                        setIsModalInactiveVisible(true);
                      }}
                    >
                      <Close width={20} height={20} className="mrg-right-5" />
                      Inativar HUB
                    </Button>
                  </Col>
                )}
              </Col>
            </Row>
          ) : (
            <div className="mrg-top-30 mrg-btm-30">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
      {tableContent}
      <Modal
        visible={isModalActiveVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="flex-column center">
          <h1 className="mrg-vertical-20 text-bold">Deseja Ativar esse HUB?</h1>
          <span>Está ação irá ativar o HUB de contas.</span>
        </div>
        <div className="flex space-between mrg-top-50">
          <div />
          <div>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              onClick={() => {
                handleConfirmPutHub(true);
              }}
              loading={loading}
            >
              Ativar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={isModalVisible} onCancel={handleCancel} footer={null} centered>
        <div className="flex-column center">
          <h1 className="mrg-vertical-20 text-bold">Deseja excluir esse HUB?</h1>
          <span>Você pode optar em desativar ele nesse momento.</span>
        </div>
        <div className="flex space-between mrg-top-50">
          <Button
            type="cancel"
            onClick={handleInactivate}
            className="gx-text-danger"
            disabled={!hubData?.active || loading}
          >
            Inativar
          </Button>
          <div>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button type="danger" disabled loading={loading}>
              Excluir
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalInactiveVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="flex-column center">
          <h1 className="mrg-vertical-20 text-bold">Deseja inativar esse HUB?</h1>
          <span className="text-center" style={{ width: '60%' }}>
            Você poderá torná-lo ativo editando ele na listagem de HUBs
          </span>
        </div>
        <div className="flex space-between mrg-top-50">
          <div />
          <div>
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button
              type="danger"
              onClick={() => {
                handleConfirmPutHub(false);
              }}
              loading={loading}
            >
              Inativar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalDeleteVisible}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: '#E21B45',
          borderRadius: 8,
        }}
        centered
      >
        <div className="flex-column center mrg-auto gx-text-white">
          <h1 className="mrg-vertical-20 gx-text-white">ATENÇÃO!</h1>
          <span className="text-center" style={{ width: '80%' }}>
            Você está prestes a excluir esse HUB definitivamente, deseja mesmo prosseguir?
          </span>
        </div>
        <div className="flex center mrg-top-50">
          <Button onClick={handleCancel} disabled={loading}>
            Cancelar
          </Button>
          <Button type="secondary" className="gx-text-danger" loading={loading}>
            Sim, excluir
          </Button>
        </div>
      </Modal>
      <>
        <HubTimeline closeDrawer={closeDrawer} isDrawerVisible={isDrawerOpen} />
        <div className="timeline-button-wrapper">
          <ButtonAntd disabled={loading} onClick={openDrawer} className="timeline-button">
            Linha do tempo <Plus1 width={20} height={20} />
          </ButtonAntd>
        </div>
      </>
    </Wrapper>
  );
};

export default HubAccountsEdit;
