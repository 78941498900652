import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from 'antd';

import './styles.less';

const { Title } = Typography;

const SimpleTitleDivider = ({ title, level, customTop, customBottom, ...rest }) => (
  <div
    id="title-divider-component"
    style={{ marginTop: customTop, marginBottom: customBottom }}
    {...rest}
  >
    <Title level={level}>{title}</Title>
    <Divider />
  </div>
);

SimpleTitleDivider.propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.number,
  customTop: PropTypes.number,
  customBottom: PropTypes.number,
};

SimpleTitleDivider.defaultProps = {
  level: 5,
  customTop: 30,
  customBottom: 30,
};

export default SimpleTitleDivider;
