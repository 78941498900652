import { useCallback } from 'react';
import { Modal, message } from 'antd';

import { useFetch } from 'services/hooks';

const UserDeleteModal = ({ data, type = '', refreshListRef }) => {
  const { delete: deleteAPI } = useFetch();

  const userDeleteModal = useCallback(() => {
    Modal.confirm({
      title: `Você realmente deseja excluir esse ${String(type).toLowerCase()}?`,
      okText: 'Sim, excluir',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          let id;

          if (type === 'Cliente') {
            id = data.tenantId;
          } else if (type === 'Usuário') {
            id = data._id;
          }

          if (!id) {
            message.error('Erro ao definir parâmetro.');
            return;
          }

          await deleteAPI({
            url: `/clients/${id}`,
          });

          message.success(`${type} excluído com sucesso! Recarregando a lista.`);
          refreshListRef.current();
        } catch (err) {
          message.error(`Houve um erro ao excluir esse ${String(type).toLowerCase()}.`);
        }
      },
    });
  }, [refreshListRef, deleteAPI, data, type]);

  return { userDeleteModal };
};

export default UserDeleteModal;
