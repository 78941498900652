import React, { useRef, useEffect, useCallback } from 'react';
import { Row, Col, Typography, Tag } from 'antd';
import { format } from 'date-fns';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';
import { useTable } from 'components/List';
import { Reorder } from '@combateafraude/icons/general';

const { Title, Text } = Typography;

const SourcesList = () => {
  const [query] = useQueryParams({
    available: StringParam,
    search: StringParam,
  });

  const columns = [
    {
      key: 'sectionAttr',
      title: (
        <div className="flex center row">
          <span className="column-title">Sessão</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'sectionAttr',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'title',
      title: (
        <div className="flex center row">
          <span className="column-title">Nome da fonte</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'title',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'updatedAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Última atualização</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'updatedAt',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
      render: (text) => {
        try {
          return format(new Date(text), "dd/MM/yyyy 'às' HH:mm");
        } catch (error) {
          return '';
        }
      },
    },
    {
      key: 'available',
      title: (
        <div className="flex center row">
          <span className="column-title">Status</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'available',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
      render: (__, record) => (
        <Tag color={record?.available ? 'green' : 'red'}>
          {record?.available ? 'Disponível' : 'Indisponível'}
        </Tag>
      ),
    },
  ];

  const { tableContent, updateParams } = useTable({
    getParams: {
      url: '/sources/availability',
      config: {
        params: {},
      },
    },
    queryParams: {
      _available: StringParam,
      _search: StringParam,
    },
    columns,
  });

  const querySnapshot = useRef({});

  const fetch = useCallback(() => {
    const params = {};

    if (query.available) {
      params._available = query.available;
    }

    if (query.search && !_.isEmpty(query.search)) {
      params._search = query.search;
    }

    updateParams({
      url: '/sources/availability',
      config: {
        params,
      },
    });
  }, [query, updateParams]);

  useEffect(() => {
    if (_.isEqual(querySnapshot.current, query)) return;
    querySnapshot.current = query;

    fetch();
  }, [query, fetch]);

  return (
    <Row>
      <Col span={24}>
        <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
          <Col flex={1} className="page-title">
            <Title className="title">Fontes do Produto</Title>
            <Text className="subtitle">
              Exibindo resultado com base em seus filtros aplicados.
            </Text>
          </Col>
        </Row>

        {tableContent}
      </Col>
    </Row>
  );
};

export default SourcesList;
