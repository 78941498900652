import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Alert, message, Modal, Form } from 'antd';

import { useFetch } from 'services/hooks';
import { Close } from '@combateafraude/icons/general';

import Button from 'components/Button';

import ManagementUserForm from './components/ManagementUserForm';

import './styles.less';

const UserCreateV2 = () => {
  const { post, loading, clearData, error, data: postData } = useFetch();
  const { get, data, loading: loadingClients } = useFetch();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [isMigrationClient, setIsMigrationClient] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    get({
      url: '/clients',
      config: {
        params: {
          _search: searchTerm,
          _order: 1,
          _sort: 'fantasyName',
          _returnDocuments: false,
        },
      },
    });
  }, [get, searchTerm]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    form.resetFields();
    clearData({ shouldClearError: true });
    setIsMigrationClient(false);
    setSearchTerm(undefined);
  }, [clearData, form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const migrateAccount = useCallback(
    async (payload) => {
      const closeMessage = message.loading(
        'Migrando conta do cliente para o trust...',
        0
      );

      try {
        const customers = data?.docs;
        const customer = customers.find((c) => c.tenantId === payload.tenantId);

        payload.companyName = customer?.fantasyName;
        payload.cnpj = customer?.cnpj;

        await post({
          url: '/migrate-clients',
          payload,
        });

        message.success('Conta migrada com sucesso');
      } catch (err) {
        message.error('Erro ao migrar a conta');
      }

      closeMessage();
    },
    [data, post]
  );

  const createAccount = useCallback(async () => {
    await form.validateFields();

    const payload = form.getFieldsValue();
    delete payload.confirmPassword;

    if (payload.domain === 'trust') {
      delete payload.domain;
    }

    delete payload.createAccounts;

    if (payload.isMigration === 'migrationCustomer') {
      return migrateAccount(payload);
    }

    delete payload.isMigration;

    try {
      await post({
        url: '/clients-auth',
        payload,
      });

      message.success('Cliente criado com sucesso.');

      return setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (err) {
      message.error('Erro ao criar cliente.');
      return () => {};
    }
  }, [closeModal, form, migrateAccount, post]);

  const CreateManagementUserModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <ManagementUserForm
          formRef={form}
          modalVisible={modalVisible}
          isMigrationClient={isMigrationClient}
          setIsMigrationClient={setIsMigrationClient}
          loading={loadingClients}
          clients={data?.docs || []}
        />

        {error && (
          <Alert
            type="error"
            showIcon
            closable
            message="Houve um problema ao criar o cliente."
            description={`${error?.error?.message}`}
          />
        )}

        {postData?.notMigratedUsers?.length > 0 && (
          <Alert
            type="warning"
            showIcon
            closable
            message={postData?.message}
            description={`${postData?.notMigratedUsers?.join(', ')}`}
          />
        )}

        <div className="flex fd-row space-between mrg-top-35">
          <Button type="link" onClick={closeModal} className="btn-cancel">
            Cancelar
          </Button>
          <Button
            className="btn-custom btn-custom-primary"
            htmlType="button"
            onClick={createAccount}
            loading={loading}
          >
            Confirmar
          </Button>
        </div>
      </Modal>
    );
  }, [
    error,
    form,
    modalVisible,
    closeModal,
    isMigrationClient,
    setIsMigrationClient,
    loadingClients,
    createAccount,
    loading,
    data,
    postData,
  ]);

  return {
    openV2Modal: openModal,
    closeModal,
    CreateManagementUserModal,
  };
};

export default UserCreateV2;
