import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InformationBanner = ({ icon, bannerData, bannerType }) => {
  const informationBanner = useMemo(() => {
    if (bannerType === 'socket-operator') {
      return (
        <div
          id="socket-banner"
          className={classNames({
            hideBanner: bannerData?.hideBanner,
          })}
        >
          <div className="content">
            {icon}
            Este documento está sendo editado por {bannerData?.operator?.name}.
          </div>
        </div>
      );
    }

    return <></>;
  }, [bannerData, bannerType, icon]);

  return <>{informationBanner}</>;
};

InformationBanner.propTypes = {
  icon: PropTypes.node.isRequired,
  bannerType: PropTypes.string.isRequired,
  bannerData: PropTypes.shape({
    hideBanner: PropTypes.bool,
    operator: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

InformationBanner.defaultProps = {
  bannerData: null,
};

export default InformationBanner;
