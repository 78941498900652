import React, { useMemo, useCallback, useState } from 'react';
import { Row, Col, Typography, Modal, Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
import {
  Close,
  WrongCircle,
  CheckCircle2,
  AlertTriangle,
} from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';

import Button from 'components/Button';

const { Title, Paragraph } = Typography;

const ApproveExecution = () => {
  const { loading, post, error } = useFetch();
  const { tenantId, reportId, executionId } = useParams();
  const { reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [thisIsFraud, setThisIsFraud] = useState(false);
  const [selectedOption, setSelectedOption] = useState('reanalise');

  const handleCheckboxChange = (option) => {
    setSelectedOption(option);
  };
  const [confirmationModal, setConfirmationModal] = useState(false);

  const openModal = useCallback((isFraud) => {
    setThisIsFraud(isFraud);
    setRequestFinished(false);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await post({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/approve`,
        payload: {
          status: 'APROVADO',
          _id: user?._id,
          email: user?.email,
        },
      });
    } catch (e) {
      // sillencer
    }
    setRequestFinished(true);
    reloadExecutionData(true);
  }, [post, tenantId, reportId, executionId, reloadExecutionData, user]);

  const handleSubmitRemoveFraud = useCallback(async () => {
    try {
      await post({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/approve`,
        payload: {
          status: 'APROVADO',
          _id: user?._id,
          email: user?.email || user?.username,
          removeFraudFlag: true,
          requestByCustomer: selectedOption === 'cliente',
        },
      });
    } catch (e) {
      // sillencer
    }
    setSelectedOption('reanalise');
    setConfirmationModal(false);
    setRequestFinished(true);
    reloadExecutionData(true);
  }, [post, tenantId, reportId, executionId, reloadExecutionData, selectedOption, user]);

  let resultContent = (
    <div className="text-center">
      <CheckCircle2 className="modal-icon success" />
      <Title className="text-center modal-title">Execução aprovada com sucesso!</Title>
    </div>
  );

  if (error) {
    resultContent = (
      <div className="text-center">
        <WrongCircle className="modal-icon error" />
        <Title className="text-center modal-title">
          Houve um problema ao tentar aprovar a execução.
        </Title>
      </div>
    );
  }

  const approveExecutionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={
          <Close
            style={{ display: 'flex-center', verticalAlign: 'middle' }}
            width={24}
            height={24}
          />
        }
        bodyStyle={{
          backgroundColor: confirmationModal ? '#E21B45' : '',
          borderRadius: '8px',
        }}
        centered
        onCancel={() => {
          setConfirmationModal(false);
          closeModal();
        }}
        footer={null}
        className="reprocess-modal-component"
        width={600}
      >
        {requestFinished ? (
          resultContent
        ) : (
          <>
            {thisIsFraud ? (
              <>
                {confirmationModal ? (
                  <>
                    <Row className="text-center">
                      <Row className="text-center">
                        <Col span={24}>
                          <Title className="modal-title text-center gx-text-white">
                            ATENÇÃO
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph
                            className="no-mrg-btm text-center modal-subtitle font-size-15 mrg-top-10 gx-text-white"
                            style={{ width: '350px', margin: 'auto' }}
                          >
                            Você está prestes a{' '}
                            <span className="text-bold">
                              aprovar uma execução que foi considerada fraude
                            </span>
                            , deseja mesmo prosseguir?
                          </Paragraph>
                        </Col>
                      </Row>
                      <Col span={24} className="text-center mrg-top-40">
                        <Button
                          className="cancel-button-red-modal gx-text-white"
                          type="link"
                          onClick={() => {
                            setConfirmationModal(false);
                          }}
                          disabled={loading}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="secondary"
                          className="gx-text-danger"
                          onClick={() => {
                            handleSubmitRemoveFraud();
                          }}
                          loading={loading}
                        >
                          Sim, aprovar
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="text-center">
                      <Row className="text-center">
                        <Col span={24}>
                          <AlertTriangle
                            width={56}
                            height={56}
                            style={{ color: '#F16E00' }}
                            className="text-center mrg-btm-20"
                          />
                        </Col>
                        <Col span={24}>
                          <Title className="modal-title text-center">
                            Aprovar execução que é fraude?
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph
                            className="no-mrg-btm text-center modal-subtitle font-size-15 mrg-top-10"
                            style={{ width: '350px', margin: 'auto' }}
                          >
                            Essa execução foi reprovada por ser uma fraude. Você realmente
                            deseja aprovar essa execução e remover os indícios de fraude?
                          </Paragraph>
                        </Col>
                      </Row>
                      <Col className="mrg-top-20" style={{ margin: 'auto' }}>
                        <Row className="text-center">
                          <Checkbox
                            className="text-center text-dark"
                            checked={selectedOption === 'reanalise'}
                            onChange={() => handleCheckboxChange('reanalise')}
                          >
                            Ação realizada por reanálise interna
                          </Checkbox>
                        </Row>
                        <Row className="text-center mrg-top-10">
                          <Checkbox
                            className="text-center text-dark"
                            checked={selectedOption === 'cliente'}
                            onChange={() => handleCheckboxChange('cliente')}
                          >
                            Ação realizada a pedido do cliente
                          </Checkbox>
                        </Row>
                      </Col>
                      <Col span={24} className="text-right mrg-top-40">
                        <Button
                          className="cancel-button"
                          type="link"
                          onClick={() => {
                            setConfirmationModal(false);
                            closeModal();
                          }}
                          disabled={loading}
                        >
                          Cancelar
                        </Button>
                        <Button
                          className="btn-custom btn-custom-warning"
                          onClick={() => {
                            setConfirmationModal(true);
                          }}
                          loading={loading}
                        >
                          Sim, aprovar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <Title className="text-left modal-title">Aprovar execução</Title>
                  </Col>
                  <Col span={24}>
                    <Paragraph className="no-mrg-btm text-left modal-subtitle font-size-15">
                      Você realmente deseja aprovar essa execução?
                    </Paragraph>
                  </Col>
                </Row>
                <Row className="mrg-top-40">
                  <Col span={24} className="text-right">
                    <Button
                      className="cancel-button"
                      type="link"
                      onClick={closeModal}
                      disabled={loading}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className="btn-custom btn-custom-primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Sim, aprovar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Modal>
    ),
    [modalVisible, handleSubmit, closeModal, requestFinished, loading, resultContent]
  );

  return { openModal, approveExecutionModal };
};

export default ApproveExecution;
