import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Button } from 'antd';

import { toFormatDate } from 'utils/formatters';

import useTimelineDataModal from './TimelineDataModal';

import './TimelineContent.less';

const TimelineContent = ({
  title,
  description,
  createdAt,
  updatedAt,
  user,
  data,
  blank,
}) => {
  const blankClass = useMemo(() => (blank ? 'blank' : ''), [blank]);

  const { openModal, TimelineDataModal } = useTimelineDataModal({
    title,
    user,
    data,
  });

  return (
    <>
      {TimelineDataModal}
      <div id="timeline-content-component" className={`${blankClass}`}>
        {blank ? (
          <Divider />
        ) : (
          <>
            <h2>{title}</h2>

            {description && <p>{description}</p>}
            {data && (
              <span className="flex open-modal">
                <Button type="link" onClick={openModal}>
                  Ver dados da edição
                </Button>
              </span>
            )}

            <small>
              Editado no dia {toFormatDate(updatedAt || createdAt)} por {user.name}
            </small>
          </>
        )}
      </div>
    </>
  );
};

TimelineContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({}),
  blank: PropTypes.bool,
};

TimelineContent.defaultProps = {
  title: '',
  description: undefined,
  createdAt: new Date().toISOString(),
  updatedAt: undefined,
  user: undefined,
  blank: false,
  data: undefined,
};

export default TimelineContent;
