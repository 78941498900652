import React from 'react';
import Wrapper from 'pages/private/wrapper';

import SourcesList from './SourcesList';
import SourcesFilter from './SourcesFilter';

import './styles.less';

const Sources = () => {
  // eslint-disable-next-line func-names
  window.setTimeout(function () {
    window.location.reload();
  }, 60000);
  return (
    <Wrapper id="sources-component">
      <div className="gx-flex-row">
        <div id="filter" className="hide-scroll">
          <SourcesFilter />
        </div>

        <div id="list">
          <SourcesList />
        </div>
      </div>
    </Wrapper>
  );
};

export default Sources;
