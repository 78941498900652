import React, { useCallback, useMemo } from 'react';
import { Row, Drawer, Timeline, Divider, Spin, Form } from 'antd';
import { Waypoint } from 'react-waypoint';

import { useTimeline } from 'hooks/timeline';

import FAB from 'components/Button/FloatingActionButton';
import SearchInput from 'components/Form/SearchInput';

import { Plus, RotateCcw } from '@combateafraude/icons/general';
import useAnnotationCreate from './components/AnnotationCreate';
import TimelineContent from './components/TimelineContent';
import TimelineIcon from './components/TimelineIcon';
import './styles.less';

const CustomTimeline = () => {
  const {
    drawerVisible,
    drawerVisibleMandatory,
    annotationList,
    starredAnnotationList,
    loadingAnnotationList,
    fetch: fetchAnnotationData,
    reload: reloadAnnotationList,
    closeModal,
    closeModalMandatory,
    setFilter,
  } = useTimeline();

  const {
    openDrawer: openAnnotationDrawer,
    closeDrawer: closeAnnotationDrawer,
    annotationCreateDrawer,
  } = useAnnotationCreate();

  const handleClose = useCallback(() => {
    closeAnnotationDrawer();
    closeModal();
    closeModalMandatory();
  }, [closeAnnotationDrawer, closeModal, closeModalMandatory]);

  const handleFormChange = useCallback(
    (value) => {
      setFilter(value);
    },
    [setFilter]
  );

  const handleReloadData = useCallback(() => {
    reloadAnnotationList();
  }, [reloadAnnotationList]);

  const createTimeline = useCallback(
    (list) =>
      list.map((annotationDate) => (
        <React.Fragment key={annotationDate._id}>
          <Timeline.Item dot={<TimelineIcon date={annotationDate._id} />}>
            <TimelineContent blank />
          </Timeline.Item>

          {annotationDate.docs.map((annotationItem) => (
            <Timeline.Item
              key={annotationItem._id}
              dot={<TimelineIcon type={annotationItem.type} />}
            >
              <TimelineContent {...annotationItem} />
            </Timeline.Item>
          ))}
        </React.Fragment>
      )),
    []
  );

  const annotations = useMemo(() => {
    if (!annotationList || annotationList.length === 0) return <></>;

    return (
      <>
        <Divider orientation="left">Todas anotações</Divider>

        <Timeline
          className="every-annotation"
          pending={loadingAnnotationList && 'carregando...'}
        >
          {createTimeline(annotationList)}
          <Waypoint onEnter={() => fetchAnnotationData()} />
        </Timeline>
      </>
    );
  }, [annotationList, createTimeline, fetchAnnotationData, loadingAnnotationList]);

  const starredAnnotations = useMemo(() => {
    if (!starredAnnotationList || starredAnnotationList.length === 0) return <></>;

    return (
      <>
        <Divider orientation="left">Anotações em destaque</Divider>
        <Timeline>{createTimeline(starredAnnotationList)}</Timeline>
      </>
    );
  }, [starredAnnotationList, createTimeline]);

  return (
    <Drawer
      key="timeline"
      visible={drawerVisible || drawerVisibleMandatory}
      closable={drawerVisible && !drawerVisibleMandatory}
      maskClosable={drawerVisible && !drawerVisibleMandatory}
      title="Linha do tempo"
      onClose={handleClose}
      width={false}
      id="timeline-component"
    >
      <header className="search-box">
        <Form onFinish={handleFormChange}>
          <SearchInput disabled={loadingAnnotationList} />
        </Form>
      </header>

      <div className="main-content">
        {starredAnnotationList.length === 0 &&
          annotationList.length === 0 &&
          loadingAnnotationList && (
            <Row align="middle" justify="center">
              <Spin />
            </Row>
          )}

        {starredAnnotations}
        {annotations}

        {annotationCreateDrawer}
        <FAB onClick={handleReloadData} placement="left-bottom">
          <RotateCcw width={20} height={20} />
        </FAB>
        <FAB onClick={openAnnotationDrawer}>
          <Plus width={20} height={20} />
        </FAB>
      </div>
    </Drawer>
  );
};

export default CustomTimeline;
