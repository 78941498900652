import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, Form, InputNumber, Spin, Pagination, message } from 'antd';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

import { useFetch } from 'services/hooks';

import EmptyMessage from 'components/EmptyMessage';
import scrollToTop from 'utils/scrollToTop';

import { initialFilterData } from '../utils';

const useComponentList = ({
  component: Component,
  rowKey,
  getParams,
  queryParams,
  method = 'GET',
  allowEmptyListWorkaround = false,
  showQuickJumper = false,
  socket = null,
}) => {
  const [form] = Form.useForm();
  const { data, loading, get, post, error } = useFetch();
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const { get: getCustomers, data: customers } = useFetch();

  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || 1,
    pageSize: 15,
    total: 0,
    showTotal: (total, range) => `Exibindo ${range[0]} a ${range[1]} de ${total} itens`,
  });
  const [filterData, setFilterData] = useState(() => {
    return initialFilterData({ query, queryParams });
  });

  const fetch = useCallback(
    async (current) => {
      if (!params) return;

      try {
        scrollToTop();

        const pageSize = pagination.pageSize || 20;

        let res;
        if (method === 'GET') {
          res = await get({
            url: params.url,
            config: {
              params: {
                _limit: pageSize,
                _offset: pageSize * (current - 1),
                ...filterData,
                ...params.config.params,
              },
            },
          });
        } else if (method === 'POST') {
          res = await post({
            url: params.url,
            payload: {
              _limit: pageSize,
              _offset: pageSize * (current - 1),
              ...filterData,
              ...params.config.params,
            },
          });
        }
        setPagination((oldState) => ({
          ...oldState,
          current,
          pageSize,
          total: res.totalItems,
        }));
      } catch (e) {
        // SILENCER
      }
    },
    [get, post, params, pagination.pageSize, filterData, method]
  );

  const refreshList = useCallback(() => {
    fetch(pagination.current || 1);
  }, [fetch, pagination]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data) return;

    fetch(pagination.current || 1);
    setQuery({ page: pagination.current });
  }, [pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      allowEmptyListWorkaround &&
      !loading &&
      query.page &&
      data?.docs?.length === 0 &&
      pagination?.current > 1 &&
      pagination?.current === query.page &&
      pagination?.total > 0
    ) {
      setPagination((state) => ({
        ...state,
        current: query?.page > 1 ? query?.page - 1 : 1,
      }));

      message.info(`Página (${query.page}) vazia, redirecionando para a anterior.`);
    }
  }, [loading, query, pagination, data, allowEmptyListWorkaround]);

  useEffect(() => {
    if (pagination.current === 1 || !data) {
      refreshList();
      setQuery({ page: pagination.current });
      return;
    }

    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setQuery({ page: 1 });
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const onFinishGoToPage = useCallback(
    (values) => {
      const { newPage = 1 } = values || {};

      setPagination((oldState) => ({ ...oldState, current: newPage }));
      form.resetFields();
    },
    [form]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getCustomers({
      url: '/clients',
      config: {
        params: {
          _offset: 0,
          _sort: 'fantasyName',
          _order: 1,
          _returnDocuments: false,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentList = useMemo(
    () => (
      <div className="gx-position-relative mrg-top-30">
        {loading && (
          <div className="loading">
            <Spin className="mrg-top-30" />
          </div>
        )}
        {data?.docs?.length > 0 && customers?.docs?.length > 0 ? (
          <>
            <div className="custom-footer-pagination" style={{ marginBottom: 10 }}>
              {showQuickJumper && (
                <div className="go-to-form">
                  <Form form={form} onFinish={onFinishGoToPage}>
                    <Form.Item
                      className="new-page-input"
                      name="newPage"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Button type="text" htmlType="submit">
                      Ir para página &#10095;
                    </Button>
                  </Form>
                </div>
              )}
              <Pagination
                total={pagination.total}
                pageSize={pagination.pageSize}
                current={pagination.current}
                showTotal={(total, range) =>
                  `Exibindo ${range[0]} à ${range[1]} de ${total} itens`
                }
                onChange={(page) =>
                  setPagination((state) => ({ ...state, current: page }))
                }
                showSizeChanger={false}
              />
            </div>
            {data?.docs?.map((d) => (
              <Component
                key={d[rowKey]}
                data={d}
                customers={customers}
                socket={socket}
                refreshList={refreshList}
              />
            ))}
            <div className="custom-footer-pagination">
              {showQuickJumper && (
                <div className="go-to-form">
                  <Form form={form} onFinish={onFinishGoToPage}>
                    <Form.Item
                      className="new-page-input"
                      name="newPage"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Button type="text" htmlType="submit">
                      Ir para página &#10095;
                    </Button>
                  </Form>
                </div>
              )}
              <Pagination
                total={pagination.total}
                pageSize={pagination.pageSize}
                current={pagination.current}
                showTotal={(total, range) =>
                  `Exibindo ${range[0]} à ${range[1]} de ${total} itens`
                }
                onChange={(page) =>
                  setPagination((state) => ({ ...state, current: page }))
                }
                showSizeChanger={false}
              />
            </div>
          </>
        ) : (
          <EmptyMessage
            show={!loading}
            description="Nenhum resultado encontrado."
            withCard
          />
        )}
      </div>
    ),
    [
      loading,
      customers,
      socket,
      data,
      pagination,
      rowKey,
      refreshList,
      showQuickJumper,
      form,
      onFinishGoToPage,
    ]
  );

  return {
    loading,
    componentList,
    customers,
    data,
    setFilterData,
    refreshList,
    updateParams,
    error,
  };
};

export default useComponentList;
