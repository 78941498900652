import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Col, Row, Typography, Switch } from 'antd';
import ReactJson from 'react-json-view';
import Button from 'components/Button';
import { useParams } from 'react-router-dom';
import { useFetch } from 'services/hooks';
import PageTitle from 'components/PageTitle';
import Card from 'components/Card';

const { Text } = Typography;
const ServicesDetails = () => {
  const { tenantId, executionId } = useParams();
  const { data: servicesData, get, loading } = useFetch();
  const [isFormatted, setIsFormatted] = useState(false);

  useEffect(() => {
    get({
      url: `/executions-services-requested/users/${tenantId}/executions/${executionId}`,
    });
  }, [tenantId, executionId, get]);

  const refreshData = useCallback(() => {
    get({
      url: `/executions-services-requested/users/${tenantId}/executions/${executionId}`,
    });
  }, [get, tenantId, executionId]);

  const cardContent = useMemo(() => {
    return isFormatted ? (
      <ReactJson
        name={false}
        src={servicesData?.servicesRequested || {}}
        iconStyle="triangle"
        indentWidth={2}
        collapsed={1}
        theme="shapeshifter:inverted"
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
      />
    ) : (
      <pre className="execution-json">
        {JSON.stringify(servicesData?.servicesRequested, null, 2)}
      </pre>
    );
  }, [isFormatted, servicesData]);

  return (
    <div id="services-status-component">
      <Row align="middle">
        <Col span={16}>
          <PageTitle title="Status dos serviços" />
        </Col>
        <Col span={8} className="formatted-switch-col">
          <Switch checked={isFormatted} onChange={setIsFormatted} />
          <Text strong className="no-mrg mrg-left-10">
            Formatação
          </Text>
        </Col>
      </Row>

      <Card className="card">
        <Button
          className="refresh"
          onClick={refreshData}
          loading={loading}
          type="primary"
        >
          Recarregar dados
        </Button>
        {cardContent}
      </Card>
    </div>
  );
};

export default ServicesDetails;
