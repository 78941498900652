import React from 'react';

import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { MoneyDollar } from '@combateafraude/icons/financial';

import './styles.less';

const CurrencyTag = ({ currency, className, ...rest }) => {
  let label = 'Real (BRL)';

  if (currency === 'USD') label = 'Dólar (USD)';
  if (currency === 'EUR') label = 'Euro (EUR)';
  if (currency === 'GBP') label = 'Libra (GBP)';

  return (
    <Tag className={`${currency.toLowerCase()}-tag ${className}`} {...rest}>
      <MoneyDollar className="currency-sign" />
      {label}
    </Tag>
  );
};

CurrencyTag.propTypes = {
  currency: PropTypes.string,
  className: PropTypes.string,
};

CurrencyTag.defaultProps = {
  currency: 'BRL',
  className: undefined,
};

export default CurrencyTag;
