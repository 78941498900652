import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, message, Alert } from 'antd';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

import { Close, Company, EMail, Eye, Hidden, Lock } from '@combateafraude/icons/general';

import Button from 'components/Button';
import InputMask from 'components/Form/InputMask';
import PageTitle from 'components/PageTitle';

const UserCreate = ({ refreshList, refreshListRef, refUserId, type }) => {
  const { customer } = useCustomer();
  const { post, loading, error: errorCreate } = useFetch();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setErrorMessage(undefined);
    form.resetFields();
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const createUser = useCallback(
    async (values) => {
      delete values.confirmPassword;

      try {
        let payload = { ...values };

        // Caso seja um usuário do cliente precisa passar uns parâmetros a mais
        if (type === 'child') {
          if (!customer || !customer?.tenantId || !customer?.cnpj || !refUserId) {
            throw new Error('Sem dados necessários');
          }

          payload = {
            ...payload,
            type: 'common',
            refUserId,
            isUser: true,
            tenantId: customer?.tenantId,
            cnpj: customer?.cnpj,
            verified: !!customer?.verified,
          };
        }

        await post({
          url: '/clients',
          payload,
        });

        message.success('Cliente cadastrado com sucesso.');

        if (type === 'child') {
          refreshListRef.current();
        } else {
          refreshList();
        }
        closeModal();
      } catch (error) {
        message.error('Erro ao cadastrar cliente.');
      }
    },
    [post, closeModal, refreshList, customer, type, refreshListRef, refUserId]
  );

  useEffect(() => {
    if (!errorCreate || errorCreate === true) return;

    const error = errorCreate.message?.split(' - ');
    const errorFormatted =
      error?.length > 1
        ? { ...errorCreate, error: JSON.parse(error[1]) }
        : { ...errorCreate };

    setErrorMessage(errorFormatted?.error?.message);
  }, [errorCreate]);

  const UserCreateModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <PageTitle
          title="Novo cliente"
          subtitle="Preencha os dados do novo cliente para gerar um acesso."
        />
        <Form
          form={form}
          layout="vertical"
          className="mrg-top-10"
          onFinish={createUser}
          autoComplete="new-customer"
        >
          <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
            <Input
              autoComplete="new-name"
              className="text-dark"
              prefix={<Company />}
              placeholder="Seu nome"
              allowClear
            />
          </Form.Item>

          <Form.Item name="email" label="E-mail" rules={[{ required: true }]}>
            <Input
              autoComplete="new-email"
              className="text-dark"
              prefix={<EMail />}
              placeholder="Seu e-mail"
              allowClear
            />
          </Form.Item>

          {type === 'common' && (
            <Form.Item name="cnpj" label="CNPJ" rules={[{ required: true }]}>
              <InputMask
                autoComplete="new-cnpj"
                mask="99.999.999/9999-99"
                className="text-dark"
                prefix={<Company />}
                placeholder="Seu CNPJ"
                allowClear
              />
            </Form.Item>
          )}

          <Form.Item
            name="password"
            label="Digite sua senha"
            rules={[{ required: true }]}
          >
            <Input.Password
              autoComplete="new-password"
              className="text-dark"
              placeholder="Digite sua senha"
              prefix={<Lock />}
              iconRender={(visible) =>
                visible ? (
                  <Eye width={20} height={20} />
                ) : (
                  <Hidden width={20} height={20} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas devem ser idênticas!'));
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="new-confirm-password"
              className="text-dark"
              placeholder="Confirme sua senha"
              prefix={<Lock />}
              iconRender={(visible) =>
                visible ? (
                  <Eye width={20} height={20} />
                ) : (
                  <Hidden width={20} height={20} />
                )
              }
            />
          </Form.Item>

          {errorMessage && (
            <Alert
              type="error"
              showIcon
              closable
              message="Houve um problema ao criar o usuário."
              description={errorMessage}
            />
          )}

          <div className="flex fd-row space-between mrg-top-35">
            <Button type="default" onClick={closeModal} className="btn-cancel">
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              htmlType="submit"
              loading={loading}
            >
              {loading ? 'Criando cliente...' : 'Criar novo cliente'}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }, [form, modalVisible, closeModal, createUser, errorMessage, loading, type]);

  return {
    openModal,
    closeModal,
    UserCreateModal,
  };
};

export default UserCreate;
