import React, { useCallback, useState, useMemo } from 'react';
import { Row, Col, Typography, Modal, Form, Divider, message } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';
import { useCustomer } from 'hooks/customer';

import Button from 'components/Button';

import currency from 'utils/currencyFormatter';

const { Text, Title } = Typography;

const ConfirmCustomPriceModal = ({
  payload,
  setAvailableServices,
  tenantId,
  fetch,
  setIsEditing,
}) => {
  const { post, loading, put } = useFetch();
  const { customer, loadingCustomer } = useCustomer();
  const [modalVisible, setModalVisible] = useState(false);
  const [items, setItems] = useState(false);

  const currencySign = currency.getCurrencySign(customer?.currency);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setItems(payload?.docs);
    setModalVisible(true);
  }, [payload]);

  const handleValueFormatting = useCallback(
    (value) => {
      let newValue = '';
      if (`${value}`?.includes('-')) {
        newValue = currency.toCurrencyTwoDigits(
          `${value}`.replaceAll(`-`, ``) || '',
          currencySign
        );
        return `${newValue}`.replace(currencySign, `${currencySign} -`);
      }

      return currency.toCurrencyTwoDigits(value || '', currencySign);
    },
    [currencySign]
  );

  const handleSubmit = useCallback(async () => {
    try {
      await Promise.all([
        post({
          url: `/clients/${tenantId}/custom-price`,
          payload: {
            docs: [...payload.docs],
          },
        }),
        payload.availableServices !== null &&
          put({
            url: `/clients/${tenantId}`,
            payload: { availableServices: [...new Set(payload.availableServices)] },
          }) &&
          setAvailableServices([...new Set(payload.availableServices)]),
      ]);

      closeModal();
      fetch();
      setIsEditing(false);
      message.success('Valores atualizados com sucesso!');
    } catch (error) {
      message.error('Houve um problema ao editar os valores.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, payload, post, tenantId]);

  const CustomPriceModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        width={1000}
        footer={null}
        maskClosable={false}
        destroyOnClose
        zIndex={1001}
        mask={false}
      >
        <div className="modal-header">
          <Title className="modal-title">Cotação de Produtos e Serviços</Title>
          <Text className="modal-subtitle">
            Os seguintes valores foram modificados, deseja confirmar?
          </Text>

          <Form layout="vertical" className="mrg-top-20">
            <>
              {items &&
                items.map((item, index) => (
                  <Row key={item.source}>
                    <Col span={11}>
                      <Form.Item
                        name={item.title}
                        label={index === 0 && 'Nome'}
                        rules={[{ required: true, whitespace: true }]}
                        className="no-error"
                      >
                        <Text>{item.title}</Text>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={item.serviceIsAvailable}
                        label={index === 0 && 'Serviço disponível'}
                        rules={[{ required: true }]}
                        className="no-error"
                      >
                        <Text>{item.serviceIsAvailable ? 'Sim' : 'Não'}</Text>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={item.customPrice}
                        label={index === 0 && 'Valor Cobrado'}
                        rules={[{ required: true }]}
                        className="no-error"
                      >
                        <Text>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            !item.customPrice
                              ? 'O valor sugerido será cobrado para este serviço/fonte.'
                              : parseFloat(item.customPrice) === 0
                              ? 'Este serviço/fonte não será cobrado'
                              : handleValueFormatting(item.customPrice)
                          }
                        </Text>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

              <Divider />
            </>

            <div className="gx-flex-row gx-justify-content-between mrg-top-50">
              <Button type="default" onClick={closeModal} className="btn-cancel">
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                onClick={handleSubmit}
                loading={loading || loadingCustomer}
              >
                {loading || loadingCustomer ? 'Enviando dados...' : 'Enviar'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      items,
      handleSubmit,
      loading,
      loadingCustomer,
      handleValueFormatting,
    ]
  );

  return { openModal, closeModal, CustomPriceModal };
};

export default ConfirmCustomPriceModal;
