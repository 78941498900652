import { useCallback } from 'react';
import { Modal, message } from 'antd';

import { useFetch } from 'services/hooks';

const SharedFacesetDeleteModal = ({ data, type = '', refreshListRef }) => {
  const { delete: deleteAPI } = useFetch();

  const sharedFacesetDeleteModal = useCallback(() => {
    Modal.confirm({
      title: `Você realmente deseja excluir essa ${String(type).toLowerCase()}?`,
      okText: 'Sim, excluir',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          const id = data._id;

          if (!id) {
            message.error('Erro ao definir parâmetro.');
            return;
          }

          await deleteAPI({
            url: `/shared-faceset/${id}`,
          });

          message.success(`${type} excluida com sucesso! Recarregando a lista.`);
          refreshListRef.current();
        } catch (err) {
          message.error(`Houve um erro ao inativar esse ${String(type).toLowerCase()}.`);
        }
      },
    });
  }, [refreshListRef, deleteAPI, data, type]);

  return { sharedFacesetDeleteModal };
};

export default SharedFacesetDeleteModal;
