import React from 'react';
import PropTypes from 'prop-types';
import { CicleImportantInfo, CicleError } from '@combateafraude/icons/others';
import { Tooltip, Form, Col } from 'antd';

const OcrFormItem = ({ name, label, errorText, isImportant, children, span }) => {
  const error = (
    <>
      <b>Diverge do dado informado no parâmetro:</b>
      {errorText && <p>{`"${name}": ${errorText}`}</p>}
    </>
  );

  let colClass = '';

  if (errorText) {
    colClass = 'field-error';
  } else if (!errorText && isImportant) {
    colClass = 'field-important';
  }

  return (
    <Col span={span} className={colClass}>
      <Form.Item
        name={name}
        id={name}
        label={label}
        tooltip={{
          icon: (
            <>
              {errorText && (
                <Tooltip className="tooltip-error" title={error}>
                  <CicleError />
                </Tooltip>
              )}
              {isImportant && (
                <Tooltip className="tooltip-important" title="Campo importante">
                  <CicleImportantInfo />
                </Tooltip>
              )}
            </>
          ),
        }}
      >
        {children}
      </Form.Item>
    </Col>
  );
};

OcrFormItem.propTypes = {
  errorText: PropTypes.string,
  isImportant: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  span: PropTypes.number.isRequired,
};

OcrFormItem.defaultProps = {
  errorText: undefined,
  isImportant: false,
};
export default OcrFormItem;
