import React, { useCallback, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Input, message, Divider, Modal } from 'antd';
import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';
import Button from 'components/Button';
import CurrencyTag from 'components/CurrencyTag';
import { PenEdit } from '@combateafraude/icons/general';
import EditAddress from './components/EditAddress';
import EditInvoice from './components/EditInvoice';
import EditIncome from './components/EditIncome';
import EditAdditionalInfo from './components/EditAdditionalInfo';
import EditAdditionalSettings from './components/EditAdditionalSettings';

const { Title, Text } = Typography;

const CustomerUpdateCard = () => {
  const { tenantId } = useParams();
  const { customer, reloadCustomer } = useCustomer();
  const { put, loading } = useFetch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [payloadWarningModal, setPayloadWarningModal] = useState({});

  const inactiveText =
    'Quando um cliente é inativado, todas as suas credenciais, incluindo tokens de API, chaves mobile e usuários, serão desativados. Isso significa que o cliente não terá mais acesso ao Trust, não poderá fazer envios via API e/ou utilizar o SDK. Deseja prosseguir?';
  const activeText =
    'Será necessário reativar manualmente tokens de API, chaves mobile e usuários. Lembre-se de conferir todas as configurações do cliente e garantir que estão funcionais.';

  const [currencyUsed, setCurrencyUsed] = useState(customer?.currency);

  const renderPlaceholder = useCallback(
    (placeholder) => (!isEditing ? '' : placeholder),
    [isEditing]
  );

  const toggleEdit = useCallback(() => {
    setIsEditing((state) => !state);
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      const payload = data;

      const shouldRedirect = payload.currency !== customer.currency;

      if (
        payload.evaluationRulesEnabled &&
        payload.approveEvaluationRules.length === 0 &&
        payload.reproveEvaluationRules.length === 0
      ) {
        payload.evaluationRulesEnabled = false;
      }

      if (!payload.invoicePayerId) {
        payload.invoicePayerId = null;
      }

      if (payload.wecheckDocumentscopyAutomation?.automation) {
        const documents = [];
        payload.wecheckDocumentscopyAutomation?.documents?.forEach((document) => {
          document.documentType.forEach((type) => {
            documents.push({
              documentType: type,
              country: 'BR',
              states: document.states,
            });
          });
        });
        payload.wecheckDocumentscopyAutomation.documents = documents;
      } else {
        payload.wecheckDocumentscopyAutomation = { automation: false };
      }

      const oldStatus = customer.status;
      const newStatus = payload.status;
      const statusValidation = ['inactive', 'active'];

      if (
        statusValidation.includes(newStatus) &&
        statusValidation.includes(oldStatus) &&
        newStatus !== oldStatus
      ) {
        setPayloadWarningModal(payload);
        setStatus(newStatus);
        setWarningModal(true);
      } else {
        try {
          await put({
            url: `/clients/${tenantId}`,
            payload,
          });

          message.success('Cliente atualizado com sucesso.');

          if (shouldRedirect) history.push(`/customers/${tenantId}/custom-price`);
          reloadCustomer(true);
        } catch (err) {
          message.error('Erro ao atualizar cliente.');
          reloadCustomer(true);
        }

        toggleEdit();
      }
    },
    [put, toggleEdit, tenantId, reloadCustomer, customer, history]
  );

  const handleFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length > 0) {
      // scrolls back to the first field with error
      form.scrollToField(errorInfo.errorFields[0].name);
    }
    message.error(`Preencha todos os campos obrigatórios para salvar.`);
  };

  const editActions = useMemo(
    () => (
      <div className="gx-flex-row gx-justify-content-end mrg-top-20">
        <Button
          type="link"
          className="gx-text-danger"
          disabled={loading}
          onClick={() => {
            setIsEditing(false);
            reloadCustomer(true);
          }}
        >
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          className="btn-custom btn-custom-primary"
          disabled={loading}
          loading={loading}
        >
          Salvar
        </Button>
      </div>
    ),
    [loading, reloadCustomer]
  );

  return (
    <>
      <div id="customer-update-card-component">
        <Modal
          visible={warningModal}
          closable
          onCancel={() => {
            setWarningModal(false);
          }}
          footer={null}
          width={500}
          centered
        >
          <Col span={24}>
            <Row justify="center" align="middle">
              <Title className="title">ATENÇÃO</Title>
            </Row>
            <Row justify="center" align="middle" className="mrg-top-20 mrg-btm-20">
              <p style={{ textAlign: 'center', maxWidth: '350px' }}>
                {status === 'inactive' ? inactiveText : activeText}
              </p>
            </Row>
            <Row justify="end">
              <Button
                className="btn-custom-primary mrg-right-10"
                onClick={() => {
                  setWarningModal(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-danger"
                onClick={async () => {
                  try {
                    await put({
                      url: `/clients/${tenantId}`,
                      payload: payloadWarningModal,
                    });

                    message.success('Cliente atualizado com sucesso.');

                    setWarningModal(false);
                    reloadCustomer(true);
                  } catch (err) {
                    message.error('Erro ao atualizar cliente.');
                    reloadCustomer(true);
                  }

                  toggleEdit();
                }}
                loading={loading}
              >
                Sim, prosseguir
              </Button>
            </Row>
          </Col>
        </Modal>
        <Form
          className={`${isEditing ? '' : 'disabled'}`}
          layout="vertical"
          initialValues={customer}
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Row align="middle" justify="space-between" className="mrg-btm-15">
            <Col span={14}>
              <Row className="title-row">
                <Title className="title">Informações cadastrais</Title>
                <span className="currency-span">
                  <CurrencyTag className="currency" currency={currencyUsed} />
                </span>
              </Row>
              <Text>
                Atualize as informações cadastrais do cliente, as mesmas ficam visíveis em
                emails e cobranças.
              </Text>
            </Col>
            <Col span={10} className="flex end-center">
              {isEditing && editActions}
              {!isEditing && (
                <Button
                  onClick={toggleEdit}
                  className="mrg-right-5 flex center edit-button"
                >
                  <PenEdit width={20} height={20} style={{ marginRight: 4 }} />
                  Editar
                </Button>
              )}
            </Col>
          </Row>
          <Row className="fd-row">
            <Col span={24}>
              <Form.Item
                name="fantasyName"
                label="Nome visível/fantasia"
                rules={[{ required: true }]}
              >
                <Input
                  disabled={!isEditing || loading}
                  allowClear
                  placeholder={renderPlaceholder('Digite o nome visível/fantasia')}
                  className={isEditing ? 'editing-highlight' : ''}
                />
              </Form.Item>
            </Col>
          </Row>

          <EditAddress isEditing={isEditing} loading={loading} />
          <Divider />
          <EditInvoice
            isEditing={isEditing}
            setCurrencyUsed={setCurrencyUsed}
            loading={loading}
          />
          <Divider />
          <EditIncome
            isEditing={isEditing}
            form={form}
            currencyUsed={currencyUsed}
            loading={loading}
          />
          <Divider />
          <EditAdditionalInfo isEditing={isEditing} form={form} loading={loading} />
          <Divider />
          <EditAdditionalSettings isEditing={isEditing} form={form} loading={loading} />
          {isEditing && editActions}
        </Form>
      </div>
    </>
  );
};

export default CustomerUpdateCard;
