import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form } from 'antd';

import { useCustomer } from 'hooks/customer';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { useComponentList } from 'components/List';

import SearchInput from 'components/Form/SearchInput';

import CardLinks from './components/CardLinks';

import useLinksCreate from '../LinksCreate';
import Wrapper from '../../wrapper';

import './styles.less';

const Links = () => {
  const refreshListRef = useRef(() => {});
  const { tenantId } = useParams();
  const { customer } = useCustomer();
  const { componentList, refreshList } = useComponentList({
    component: CardLinks,
    rowKey: 'clientSecret',
    getParams: {
      url: `/clients/${tenantId}/mobile-tokens`,
      config: {
        params: {},
      },
    },
  });
  const { openModal, LinksCreateModal } = useLinksCreate({ refreshListRef });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      <div id="links-list-component">
        {LinksCreateModal}
        <Row align="middle" className="no-mrg">
          <Col flex={1} className="page-title">
            <PageTitle
              title="Links de compartilhamento"
              subtitle="Crie links dedicados para o preenchimento de consultas."
            />
          </Col>
          <Col className="flex end-center documentation mrg-right-20">
            <Form>
              <Form.Item name="find" label="Selecione..." labelCol={{ span: 0 }}>
                <SearchInput
                  placeholder="Selecione..."
                  name="find"
                  className="no-mrg mrg-right-25"
                  style={{ width: 250 }}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Button
              block
              rounded
              type="primary"
              onClick={openModal}
              className="no-mrg "
              disabled={!customer}
            >
              Criar um link
            </Button>
          </Col>
        </Row>

        {customer && componentList}
      </div>
    </Wrapper>
  );
};

export default Links;
