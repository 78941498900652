import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { Row, Col, Typography } from 'antd';
import { Hold, Close, Checkmark } from '@combateafraude/icons/general';
import { uuid } from 'uuidv4';

import { useExecution } from 'hooks/execution';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';

const { Title } = Typography;

const validationStatusIcons = {
  VALID: <Checkmark width={16} height={16} className="gx-text-primary" />,
  INVALID: <Close width={16} height={16} className="gx-text-danger" />,
  PENDING: <Hold width={16} height={16} className="gx-text-warning" />,
};

const ComplianceRules = ({
  ocrRules,
  // setWhichComponentIsActive
}) => {
  const { executionData } = useExecution();
  const [ocrRulesList, setOcrRulesList] = useState([]);

  const createUniqueKeys = useCallback(
    (array) =>
      array.map((item) => ({
        ...item,
        _id: uuid(),
      })),
    []
  );

  const createList = useCallback((array, listName = '') => {
    if (!array || array.length === 0) return <></>;
    const list = array.map((row) => {
      const ruleTitle = row.validation ? row.validation.title : row.rule.rule;
      const ruleStatus = row.rule?.actionWhenInvalid;
      const validationDescription = row.validation?.description;
      const validationStatus = validationStatusIcons[row.validation?.status];

      return (
        <Row key={row._id}>
          <Col span={7}>{ruleTitle}</Col>
          <Col span={2} className="center">
            {validationStatus}
          </Col>
          <Col span={10}>{validationDescription}</Col>
          <Col span={5}>{ruleStatus}</Col>
        </Row>
      );
    });

    return (
      <>
        {listName && (
          <Title level={4} className="table-title">
            {listName}
          </Title>
        )}
        <Row>
          <Col span={7}>
            <p className="rule">Regra</p>
          </Col>
          <Col span={2} className="center">
            <p className="rule">Status</p>
          </Col>
          <Col span={10}>
            <p className="rule">Descrição</p>
          </Col>
          <Col span={5}>
            <p className="rule">Quando inválida</p>
          </Col>
        </Row>
        {list}
      </>
    );
  }, []);

  const createListPreValidations = useCallback((array, listName = '') => {
    if (!array || array.length === 0) return <></>;
    const list = array.map((row) => {
      const ruleTitle = row.validation ? row.validation.title : row.rule;
      const validationDescription = row.validation?.description;
      const validationStatus = validationStatusIcons[row.validation?.status];

      return (
        <Row key={row._id}>
          <Col span={7}>{ruleTitle}</Col>
          <Col span={2} className="center">
            {validationStatus}
          </Col>
          <Col span={10}>{validationDescription}</Col>
        </Row>
      );
    });

    return (
      <>
        {listName && (
          <Title level={4} className="table-title">
            {listName}
          </Title>
        )}
        <Row>
          <Col span={7}>
            <p className="rule">Regra</p>
          </Col>
          <Col span={2} className="center">
            <p className="rule">Status</p>
          </Col>
          <Col span={10}>
            <p className="rule">Descrição</p>
          </Col>
        </Row>
        {list}
      </>
    );
  }, []);

  const isVisible = useMemo(() => {
    if (!executionData) return true;

    const { complianceRules } = executionData;
    const { disabledRules, rulesNotValidated, validatedRules } = complianceRules;

    return !!(
      disabledRules.length ||
      rulesNotValidated.length ||
      validatedRules.length ||
      ocrRules
    );
  }, [executionData, ocrRules]);

  const disabledRulesList = useMemo(() => {
    if (!executionData) return [];

    const { complianceRules } = executionData;
    const { disabledRules } = complianceRules;

    const disabledRulesWithKeys = createUniqueKeys(disabledRules);

    return createList(disabledRulesWithKeys, 'Regras desabilitadas');
  }, [executionData, createUniqueKeys, createList]);

  const rulesNotValidatedList = useMemo(() => {
    if (!executionData) return [];

    const { complianceRules } = executionData;
    const { rulesNotValidated } = complianceRules;

    const rulesNotValidatedWithKeys = createUniqueKeys(rulesNotValidated);

    return createList(rulesNotValidatedWithKeys, 'Regras que não foram validadas');
  }, [executionData, createUniqueKeys, createList]);

  const validatedRulesList = useMemo(() => {
    if (!executionData) return [];

    const { complianceRules } = executionData;
    const { validatedRules } = complianceRules;

    const validatedRulesWithKeys = createUniqueKeys(validatedRules) || [];

    const parsedValidatedRulesWithKeys = ocrRules
      ? validatedRulesWithKeys.filter((rule) => rule?.validation?.status === 'INVALID')
      : validatedRulesWithKeys;

    if (ocrRules) {
      setOcrRulesList(parsedValidatedRulesWithKeys);
    }

    const validatedRulesListTitle = ocrRules ? '' : 'Regras validadas';

    return createList(parsedValidatedRulesWithKeys, validatedRulesListTitle);
  }, [executionData, createUniqueKeys, createList, ocrRules]);

  const preValidationsList = useMemo(() => {
    if (!executionData) return [];

    const { complianceRules } = executionData;
    const { preValidationsValidated } = complianceRules;

    const validatedRulesWithKeys = createUniqueKeys(preValidationsValidated) || [];

    const validatedRulesListTitle = 'Regras default de pré-validação';

    return createListPreValidations(validatedRulesWithKeys, validatedRulesListTitle);
  }, [executionData, createUniqueKeys, createListPreValidations]);

  return (
    isVisible && (
      <div id="compliance-rules-component" className="flex-column">
        {ocrRules ? (
          <>
            <Title level={4} className="font-size-16">
              Regras inválidas
            </Title>

            <Card className="gx-h-100">
              {ocrRulesList?.length > 0 ? (
                <div className="table">{validatedRulesList}</div>
              ) : (
                <EmptyMessage description="Nenhuma regra inválida disponível." />
              )}
            </Card>
          </>
        ) : (
          <div id="compliance-rules-component-table">
            <PageTitle title="Regras de compliance" />
            <Card className="gx-h-100">
              <div className="table">
                {disabledRulesList}
                {rulesNotValidatedList}
                {validatedRulesList}
                {preValidationsList}
              </div>
            </Card>
            {/* {!ocrRules && (
              <Waypoint
                topOffset="25%"
                onEnter={() =>
                  setWhichComponentIsActive('compliance-rules-component-table')
                }
              />
            )} */}
          </div>
        )}
      </div>
    )
  );
};

ComplianceRules.propTypes = {
  ocrRules: PropTypes.bool,
  // setWhichComponentIsActive: PropTypes.func.isRequired,
};

ComplianceRules.defaultProps = {
  ocrRules: false,
};

export default ComplianceRules;
