import React, { useState, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { message, DatePicker, Row, Col, Form, Select, Tooltip } from 'antd';
import { getMonth, getYear } from 'date-fns';
import moment from 'moment';
import _ from 'lodash';

import {
  Search,
  Calendar,
  ArrowRight1,
  Download,
  ArrowDown,
} from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';

import currencyFormatter from 'utils/currencyFormatter';
import { toFormatDate, toMaskedCnpj } from 'utils/formatters';

import { useTable } from 'components/List';
import Button from 'components/Button';
import Card from 'components/Card';

const { Option } = Select;
const { numberToFormattedString } = currencyFormatter;

const InvoicesTable = () => {
  const [searchCustomer, setSearchCustomer] = useState(null);
  const [columns, setColumns] = useState([
    {
      key: 'client',
      title: 'Cliente',
      dataIndex: 'client',
      render: (__, record) => {
        const invoices = record?.invoices || {};
        const invoiceKey = Object.keys(invoices)[0];

        const invoiceDetails = invoices[invoiceKey] || {};
        const invoiceDetailsKey = Object.keys(invoiceDetails)[0];

        const invoiceDetailsClient = invoiceDetails[invoiceDetailsKey] || {};
        const invoiceDetailsClientKey = Object.keys(invoiceDetailsClient)[0];

        return invoiceDetailsClient[invoiceDetailsClientKey]?.name || '-';
      },
    },
    {
      key: 'total',
      title: 'Faturamento Total',
      dataIndex: 'total',
      render: (record) => numberToFormattedString(record),
    },
  ]);

  const {
    tableContent,
    exportData,
    updateParams,
    loading: loadingData,
  } = useTable({
    getParams: {
      url: '/dashboard/kpi',
      config: {
        params: {
          report: 'faturamentoTotal',
        },
      },
    },
    columns,
    showSizeChanger: true,
    getExportData: true,
  });

  const {
    get: getCustomers,
    data: customers,
    loading: loadingCustomers,
    clearData: clearCustomers,
  } = useFetch();

  const onCustomerSearch = useCallback(
    _.debounce(async (searchTerm) => {
      if (searchTerm.trim().length < 2) {
        setSearchCustomer(null);
        clearCustomers();
        return;
      }

      setSearchCustomer(searchTerm);

      await getCustomers({
        url: '/clients',
        config: {
          params: {
            _limit: 20,
            _offset: 0,
            _status: 'active',
            _search: searchTerm,
            _sort: 'fantasyName',
            _order: 1,
          },
        },
      });
    }, 500),
    [getCustomers]
  );

  const handleDateRangeChange = useCallback(
    (values) => {
      try {
        const params = {
          report: 'faturamentoTotal',
          _tenantId: values.tenant,
        };

        if (values?.dates) {
          const newColumns = [
            {
              key: 'client',
              title: 'Cliente',
              dataIndex: 'client',
              render: (__, record) => {
                const invoices = record?.invoices || {};
                const invoiceKey = Object.keys(invoices)[0];

                const invoiceDetails = invoices[invoiceKey] || {};
                const invoiceDetailsKey = Object.keys(invoiceDetails)[0];

                const invoiceDetailsClient = invoiceDetails[invoiceDetailsKey] || {};
                const invoiceDetailsClientKey = Object.keys(invoiceDetailsClient)[0];

                return invoiceDetailsClient[invoiceDetailsClientKey]?.name || '-';
              },
            },
          ];

          const startMonth = +getMonth(moment(values.dates[0]).toDate()) + 1;
          const startYear = +getYear(moment(values.dates[0]).toDate());
          const endMonth = +getMonth(moment(values.dates[1]).toDate()) + 1;
          const endYear = +getYear(moment(values.dates[1]).toDate());

          let currentMonth = startMonth;
          let currentYear = startYear;
          let continueLoop = true;

          while (continueLoop) {
            const dateString = `${currentMonth}/${currentYear}`;
            newColumns.push({
              key: dateString,
              title: `${currentMonth?.toString()?.padStart(2, '0')}/${currentYear}`,
              render: (__, record) => {
                const monthInvoices = record?.invoices[dateString]
                  ? Object.values(record?.invoices[dateString])
                  : [];

                let monthTotal = 0;

                monthInvoices.forEach((i) => {
                  const currentMonthInvoices = Object.values(i) || [];

                  currentMonthInvoices.forEach((data) => {
                    monthTotal += data.total_cents / 100 || 0;
                  });
                });

                const parsedValue = numberToFormattedString(monthTotal);
                return <p>{parsedValue}</p>;
              },
            });

            if (`${currentMonth}/${currentYear}` === `${endMonth}/${endYear}`) {
              continueLoop = false;
            }

            currentMonth += 1;

            if (currentMonth > 12) {
              currentMonth = 1;
              currentYear += 1;
            }
          }

          newColumns.push({
            key: 'total',
            title: 'Faturamento Total',
            render: (__, record) => numberToFormattedString(record?.total),
          });

          setColumns(newColumns);

          params._StartMonthReference = startMonth;
          params._StartYearReference = startYear;
          params._EndMonthReference = endMonth;
          params._EndYearReference = endYear;
        } else {
          setColumns(columns);
        }

        updateParams({
          url: '/dashboard/kpi',
          config: {
            params: { ...params },
          },
        });
      } catch (error) {
        message.error('Falha ao atualizar os dados.');
      }
    },
    [setColumns, updateParams, columns]
  );

  return (
    <>
      <div className="flex align-center mrg-top-10 mrg-btm-25">
        <Form
          layout="vertical"
          className="flex-1"
          autoComplete="new-data"
          onFinish={handleDateRangeChange}
        >
          <Row className="flex fd-row">
            <Col span={8}>
              <Form.Item name="dates" className="no-mrg-btm">
                <DatePicker.RangePicker
                  picker="month"
                  className="gx-w-100"
                  format="MM/YYYY"
                  suffixIcon={<Calendar width={20.5} height={20.5} />}
                  separator={<ArrowRight1 />}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tenant" className="no-mrg-btm">
                <Select
                  showSearch
                  allowClear
                  dropdownClassName="multiselect-filters"
                  loading={loadingCustomers}
                  placeholder="Busque por nome do cliente"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  suffixIcon={<ArrowDown />}
                  onSearch={onCustomerSearch}
                  notFoundContent={
                    !loadingCustomers &&
                    !customers?.docs?.length && (
                      <span>
                        {searchCustomer
                          ? `Nenhum resultado encontrado para "${searchCustomer}"`
                          : 'Busque clientes por nome'}
                      </span>
                    )
                  }
                >
                  {customers?.docs?.map((d) => (
                    <Option
                      key={d._id}
                      label={d.fantasyName || d.companyName}
                      value={d.tenantId}
                    >
                      <div className="multiselect-option">
                        <span className="option-title">
                          {d.fantasyName || d.companyName}
                        </span>
                        <span className="option-subtitle">{toMaskedCnpj(d.cnpj)}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={8}>
            <Form.Item name="clientName">
              <Input placeholder="Busque por nome do cliente" allowClear={false} />
            </Form.Item>
          </Col> */}
            <Button
              htmlType="submit"
              className="btn-custom btn-custom-primary btn-search"
              loading={loadingCustomers || loadingData}
            >
              <Search width={20} height={20} />
            </Button>
          </Row>
        </Form>

        <Tooltip title="Exportar dados (CSV)" overlayClassName="font-size-12">
          <Button
            type="default"
            shape="circle"
            disabled={loadingData}
            className="export-list-btn flex center"
          >
            {exportData.length > 0 ? (
              <CSVLink
                data={exportData}
                filename={`[CAF]_faturamento_${toFormatDate(
                  new Date(),
                  "dd'-'MM'-'yyyy_HH'h'mm'min'"
                )}.csv`}
              >
                <Download width={20} height={20} />
              </CSVLink>
            ) : (
              <Download width={20} height={20} />
            )}
          </Button>
        </Tooltip>
      </div>
      <Card>
        <div className="invoices-table">{tableContent}</div>
      </Card>
    </>
  );
};

export default InvoicesTable;
