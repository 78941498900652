import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Row, Col, Drawer, Form, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';

import { useTimeline } from 'hooks/timeline';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import { Activity, DollarSign, MessageCircle } from '@combateafraude/icons/general';

const { Option } = Select;

const AnnotationCreate = () => {
  const { tenantId } = useParams();
  const { reload } = useTimeline();
  const { loading, post } = useFetch();
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const openDrawer = useCallback(() => {
    setVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (payload) => {
      const submitPayload = {
        ...payload,
        tenantId,
        star: false,
      };

      await post({
        url: 'clients-timeline',
        payload: submitPayload,
      });

      reload();
    },
    [post, reload, tenantId]
  );

  useEffect(() => {
    if (loading) return;

    form.resetFields();
    closeDrawer();
  }, [loading, closeDrawer, form]);

  const annotationCreateDrawer = useMemo(
    () => (
      <Drawer
        title="Nova anotação"
        placement="bottom"
        closable
        visible={visible}
        onClose={closeDrawer}
        getContainer={false}
        mask={false}
        height={385}
      >
        <div className="main-content annotation-create">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="pdd-top-15"
            autoComplete="off"
          >
            <Form.Item name="type" rules={[{ required: true }]}>
              <Select
                style={{ fontWeight: 500 }}
                className="text-dark"
                placeholder="Categoria"
              >
                <Option value="activity">
                  <Activity width={16} height={16} /> Atividades
                </Option>
                <Option value="observation">
                  <MessageCircle width={16} height={16} /> Observações
                </Option>
                <Option value="invoices">
                  <DollarSign width={16} height={16} /> Financeiro
                </Option>
              </Select>
            </Form.Item>

            <Form.Item name="title" rules={[{ required: true }]}>
              <Input allowClear placeholder="Título" />
            </Form.Item>

            <Form.Item name="description">
              <Input.TextArea rows={4} allowClear placeholder="Mensagem" />
            </Form.Item>

            <Row className="flex space-between">
              <Col span={8}>
                <Button
                  type="default"
                  className="btn-cancel"
                  disabled={loading}
                  onClick={closeDrawer}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  loading={loading}
                  className="btn-custom btn-custom-primary"
                  htmlType="submit"
                >
                  Enviar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer>
    ),
    [closeDrawer, visible, handleSubmit, loading, form]
  );

  return {
    openDrawer,
    closeDrawer,
    annotationCreateDrawer,
  };
};

export default AnnotationCreate;
