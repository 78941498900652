import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Tooltip, Tag } from 'antd';

import Card from 'components/Card';
import { Edit, Eye } from '@combateafraude/icons/general';
import { EyeInvisibleFilled } from '@ant-design/icons';
import useIproovEdit from '../../IproovEdit';

import './CardIproov.less';

const { Title, Text } = Typography;

const CardIproov = ({ data = {} }) => {
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(true);
  const [isApiSecretVisible, setIsApiSecretVisible] = useState(true);

  const truncateText = (text, maxLength) => {
    if (text) {
      if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
      }
      return text;
    }
    return '-';
  };

  const { openModal, IproovEditModal } = useIproovEdit({});

  const renderVisibilityToggle = (isVisible, toggleVisibility) =>
    isVisible ? (
      <Eye
        color="#004AF7"
        width={20}
        height={20}
        className="mrg-left-10"
        onClick={toggleVisibility}
      />
    ) : (
      <EyeInvisibleFilled
        onClick={toggleVisibility}
        style={{ color: '#004AF7', width: 20, height: 20, marginLeft: 10 }}
      />
    );

  const tokenTestFromSecretAndKey = useMemo(() => {
    const secret = process.env.REACT_APP_IPROOV_SECRET ?? '';
    const key = process.env.REACT_APP_IPROOV_API_KEY ?? '';

    return data?.secret === secret && data?.api_key === key;
  }, [data]);

  return (
    <Card id="card-iproov-component">
      {IproovEditModal}
      <Row className="no-pdd no-mrg header-card">
        <Col span={9} className="cells-left">
          <Title level={4} className="no-mrg mrg-btm-5">
            Nome
          </Title>
          <Text>{data?.name ?? '-'}</Text>
        </Col>
        <Col span={9} className="cells">
          <Title level={4} className="no-mrg mrg-btm-5">
            Descrição
          </Title>
          <Tooltip title={data?.description} mouseEnterDelay={0.5} placement="bottomLeft">
            <Text>{truncateText(data?.description, 55)}</Text>
          </Tooltip>
        </Col>
        <Col span={5} className="cells">
          <Title level={4} className="no-mrg mrg-btm-5">
            SP Name
          </Title>
          <Tooltip title={data?.spName} mouseEnterDelay={0.5} placement="bottomLeft">
            <Text>{truncateText(data?.spName, 30)}</Text>
          </Tooltip>
        </Col>
      </Row>

      <Row className="no-pdd no-mrg">
        <Col span={9} className="cells-left">
          <Title level={4} className="no-mrg mrg-btm-5">
            API Key
          </Title>
          <Col>
            <Text>
              {isApiSecretVisible
                ? '****************************************'
                : data?.api_key}
              {renderVisibilityToggle(isApiSecretVisible, () =>
                setIsApiSecretVisible(!isApiSecretVisible)
              )}
            </Text>
          </Col>
        </Col>
        <Col span={9} className="cells">
          <Title level={4} className="no-mrg mrg-btm-5">
            API Secret
          </Title>
          <Col>
            <Text>
              {isApiKeyVisible
                ? '****************************************'
                : data?.secret}
              {renderVisibilityToggle(isApiKeyVisible, () =>
                setIsApiKeyVisible(!isApiKeyVisible)
              )}
            </Text>
          </Col>
        </Col>
        <Col span={3} className="cells">
          <Title level={4} className="no-mrg mrg-btm-5">
            Link
          </Title>
          {data?.link ? (
            <a href={data?.link} target="_blank" rel="noreferrer">
              {data?.link && 'Ver mais'}
            </a>
          ) : (
            '-'
          )}
        </Col>
      </Row>

      <Row className="no-pdd no-mrg footer-card">
        <Col span={23} className="mrg-top-20">
          <Tag
            bordered={false}
            className={
              data?.tokenType === 'test' || tokenTestFromSecretAndKey
                ? 'test'
                : 'production'
            }
          >
            {data?.tokenType === 'test' || tokenTestFromSecretAndKey
              ? 'Token de teste'
              : 'Token de produção'}
          </Tag>
        </Col>
        <Col span={1} className="mrg-top-20">
          <Edit width={20} height={20} color="#004AF7" onClick={() => openModal(data)} />
        </Col>
      </Row>
    </Card>
  );
};

CardIproov.propTypes = {
  data: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    name: PropTypes.string,
    spName: PropTypes.string,
    link: PropTypes.string,
    api_key: PropTypes.string,
    secret: PropTypes.string,
    description: PropTypes.string,
    tokenType: PropTypes.string,
  }),
};

CardIproov.defaultProps = {
  data: undefined,
};

export default CardIproov;
