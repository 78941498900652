// Função para verificar se todos os valores de um objeto são válidos (não vazios, não nulos, não indefinidos)
export const areValuesValid = (values, requiredCount) => {
  const valuesValid = Object.values(values).filter(
    (v) => v !== undefined && v !== null && v !== ''
  );
  return valuesValid.length !== requiredCount;
};

// Função para configurar campos de formulário para um token de teste
export const setTestTokenFields = (form, testCredentials) => {
  form.setFieldsValue({
    apiSecret: testCredentials.secret,
    apiKey: testCredentials.key,
    spName: 'CAF_DEV',
    link: 'https://portal.iproov.com/service-providers/1372',
  });
};

// Função para limpar campos de formulário para um token de produção
export const clearTokenFields = (form) => {
  form.setFieldsValue({ apiSecret: '', apiKey: '', link: '', spName: '' });
};
