const TAGS = {
  APROVADO: {
    className: 'approved',
    title: 'Aprovado',
    icon: 'CorrectStroke',
  },
  PENDENTE: {
    className: 'pending',
    title: 'Pendente de análise',
    icon: 'IcPauseSmall',
  },
  'PENDENTE OCR': {
    className: 'pending-ocr',
    title: 'Pendente de correção',
    icon: 'EditPenStroke',
  },
  PROCESSANDO: {
    className: 'processing',
    title: 'Processando',
    icon: 'IcProcessingSmall',
  },
  REPROVADO: {
    className: 'reproved',
    title: 'Reprovado',
    icon: 'ErroStroke',
  },
  FRAUDE: {
    className: 'fraud',
    title: 'Reprovado por fraude',
    icon: 'FraudManRejected',
  },
  APPROVED: {
    className: 'approved',
    title: 'Aprovado',
    icon: 'CorrectStroke',
  },
  PENDING: {
    className: 'pending',
    title: 'Pendente de análise',
    icon: 'IcPauseSmall',
  },
  'PENDING OCR': {
    className: 'pending-ocr',
    title: 'Pendente de correção',
    icon: 'EditPenStroke',
  },
  PENDING_OCR: {
    className: 'pending-ocr',
    title: 'Pendente de correção',
    icon: 'EditPenStroke',
  },
  PROCESSING: {
    className: 'processing',
    title: 'Processando',
    icon: 'IcProcessingSmall',
  },
  REPROVE: {
    className: 'reproved',
    title: 'Reprovado',
    icon: 'ErroStroke',
  },
  REPROVED: {
    className: 'reproved',
    title: 'Reprovado',
    icon: 'ErroStroke',
  },
  DOCUMENTSCOPY: {
    className: 'pending',
    title: 'Documentoscopia',
    icon: 'IcPauseSmall',
  },
  COMBATE_A_FRAUDE: {
    className: 'pending',
    title: 'Combate à fraude',
    icon: 'IcPauseSmall',
  },
};

export default TAGS;
