import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, Modal, message } from 'antd';

import { useFetch } from 'services/hooks';
import { Settings, Trash } from '@combateafraude/icons/general';

const ListDropdown = ({ tenantId, _id, refreshList }) => {
  const { delete: deleteAPI } = useFetch();

  const cancelInvoice = useCallback(() => {
    Modal.confirm({
      title: 'Você realmente deseja deletar esse relatório?',
      okText: 'Sim, excluir',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await deleteAPI({
            url: `/clients/${tenantId}/reports/${_id}`,
          });
          message.success('Relatório excluído com sucesso! Recarregando a lista.');
          refreshList();
        } catch (err) {
          message.error(`Houve um erro ao excluir este relatório.`);
        }
      },
    });
  }, [deleteAPI, _id, tenantId, refreshList]);

  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`/customers/${tenantId}/reports/registration/${_id}`}>
          <Settings width={20} height={20} />
          Editar
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1" onClick={cancelInvoice} className="gx-text-danger">
        <Trash width={20} height={20} />
        Excluir relatório
      </Menu.Item>
    </Menu>
  );
};

ListDropdown.propTypes = {
  tenantId: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  refreshList: PropTypes.func.isRequired,
};

export default ListDropdown;
