import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { Input, Row, Col } from 'antd';

import './styles.less';

const MarkdownEditor = ({ name, form, disabled, onTextAreaChange }) => {
  const [markdown, setMarkdown] = useState(form.getFieldValue(name) || '');

  const handleTextAreaChange = useCallback(
    (event) => {
      setMarkdown(event.target.value);
      form.setFieldsValue({ [name]: event.target.value });
    },
    [form, name]
  );

  return (
    <Row id="markdown-editor-component">
      <Col span={12} className="markdown-input">
        <Input.TextArea
          disabled={disabled}
          size="large"
          value={markdown}
          onChange={(e) => {
            onTextAreaChange(e);
            handleTextAreaChange(e);
          }}
        />
      </Col>
      <Col span={12} className="markdown-preview">
        <ReactMarkdown
          skipHtml
          rehypePlugins={[rehypeSanitize]}
          remarkPlugins={[remarkGfm]}
          className="result"
        >
          {markdown}
        </ReactMarkdown>
      </Col>
    </Row>
  );
};

MarkdownEditor.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  onTextAreaChange: PropTypes.func.isRequired,
};

MarkdownEditor.defaultProps = {
  disabled: undefined,
};

export default MarkdownEditor;
