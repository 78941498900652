import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, message, Alert } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';
import InputMask from 'components/Form/InputMask';
import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const Register = ({ data, refreshListRef }) => {
  const { customer } = useCustomer();
  const { put, post, loading, error: errorCreate } = useFetch();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [identification, setIdentification] = useState('');

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setErrorMessage(undefined);
    setIdentification('');
    form.resetFields();
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
    if (data) {
      setIdentification(
        data.identification?.length === 11
          ? toMaskedCpf(data.identification)
          : toMaskedCnpj(data.identification)
      );
      form.setFieldsValue({
        name: data.name,
        identification: data.identification,
      });
    }
  }, [data, form]);

  const createRegister = useCallback(
    async (values) => {
      try {
        let payload = { ...values };
        if (!customer || !customer?.tenantId) {
          throw new Error('Sem dados necessários');
        }

        payload = {
          ...payload,
        };

        if (data?._id) {
          await put({
            url: `/clients/${customer?.tenantId}/deny-list/${data._id}`,
            payload,
          });
          message.success('Registro editado com sucesso.');
        } else {
          await post({
            url: `/clients/${customer?.tenantId}/deny-list`,
            payload,
          });

          message.success('Registro realizado com sucesso.');
        }

        refreshListRef.current();

        closeModal();
      } catch (error) {
        message.error('Erro ao fazer registro.');
      }
    },
    [customer, data, refreshListRef, closeModal, put, post]
  );

  useEffect(() => {
    if (!errorCreate || errorCreate === true) return;

    const error = errorCreate.message?.split(' - ');
    const errorFormatted =
      error?.length > 1
        ? { ...errorCreate, error: JSON.parse(error[1]) }
        : { ...errorCreate };

    setErrorMessage(errorFormatted?.error?.message);
  }, [errorCreate]);

  const RegisterModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <PageTitle
          title="Novo Registro"
          subtitle="Preencha os campos abaixo para incluir um novo registro na lista."
        />
        <Form
          form={form}
          layout="vertical"
          className="mrg-top-10"
          onFinish={createRegister}
        >
          <Form.Item
            name="identification"
            label="Identificação (CPF/CNPJ)"
            rules={[{ required: true }]}
          >
            <InputMask
              mask={identification.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'}
              maskChar=""
              value={identification}
              onChange={(e) => setIdentification(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label={
              <span>
                Nome -<span className="gx-font-italic font-size-11"> Opcional</span>
              </span>
            }
          >
            <Input className="text-dark" allowClear />
          </Form.Item>

          <Form.Item
            name="reason"
            label={
              <span>
                Motivo da Inclusão -
                <span className="gx-font-italic font-size-11"> Opcional</span>
              </span>
            }
          >
            <Input className="text-dark" allowClear />
          </Form.Item>

          {errorMessage && (
            <Alert
              type="error"
              showIcon
              closable
              message="Houve um problema ao criar o registro."
              description={errorMessage}
            />
          )}

          <div className="flex fd-row space-between mrg-top-35">
            <Button type="default" onClick={closeModal} className="btn-cancel">
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              htmlType="submit"
              loading={loading}
            >
              {loading ? 'Carregando...' : 'Confirmar'}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }, [
    form,
    modalVisible,
    closeModal,
    createRegister,
    identification,
    errorMessage,
    loading,
  ]);

  return {
    openModal,
    closeModal,
    RegisterModal,
  };
};

export default Register;
