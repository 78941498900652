/* eslint-disable react/prop-types */

import React, { useCallback, useMemo, createElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Typography,
  Divider,
  Dropdown,
  Tooltip,
  Form,
  message,
  Tag,
} from 'antd';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import { format, parseISO } from 'date-fns';

import { useFetch } from 'services/hooks';

import Card from 'components/Card';
import SearchInput from 'components/Form/SearchInput';

import { MoreVertical } from '@combateafraude/icons/general';
import ListDropdown from './ListDropdown';
import { TAGS_ENGLISH as TAGS } from '../utils/tags';
import './CardReportStyles.less';

const { Title, Text, Paragraph, Link } = Typography;

const CardReport = ({ data, refreshList }) => {
  const { tenantId } = useParams();

  const { put, loading } = useFetch();

  const Icons = Object.assign(iconsGeneral || {}, iconsUsers || {}, iconsFace || {});

  const renderTag = useCallback(
    ({ validation, key, description }) => {
      return (
        <Tag color={TAGS[validation[key]]?.color}>
          <Text className="mrg-right-5">{description}</Text>
          {TAGS[validation[key]]?.title(validation)}
          {Object.keys(Icons || {}).includes(TAGS[validation[key]]?.icon) &&
            createElement(Icons[TAGS[validation[key]]?.icon])}
        </Tag>
      );
    },
    [Icons]
  );

  const renderValidation = useCallback(
    ({ validation }) => {
      return (
        <Tooltip key={validation.rule} title={validation.description}>
          <div className="validation-tag">
            {validation.title}
            {validation.actionWhenInvalid &&
              renderTag({
                validation,
                key: 'actionWhenInvalid',
                description: 'Quando inválida: ',
              })}
            {validation.actionWhenPending &&
              renderTag({
                validation,
                key: 'actionWhenPending',
                description: 'Quando pendente: ',
              })}
          </div>
        </Tooltip>
      );
    },
    [renderTag]
  );

  const handleWebhookSubmit = useCallback(
    async (payload) => {
      try {
        await put({
          url: `/clients/${tenantId}/reports/${data._id}`,
          payload,
        });
        message.success('Webhook alterado com sucesso.');
      } catch (err) {
        message.error('Erro ao atualizar Webhook.');
      }
    },
    [tenantId, data._id, put]
  );

  const lastUpdate = useMemo(() => {
    if (!data.updatedAt) return '';

    const date = parseISO(data.updatedAt);
    return format(date, "dd/MM/yyyy 'às' HH:mm");
  }, [data.updatedAt]);

  const reportIcon = useMemo(() => {
    if (data.inputType === 'cpf') return 'UserActive';
    if (data.inputType === 'cnpj') return 'Company';
    if (data.name.toUpperCase().includes('COMBAT')) return 'Admin';

    return 'Onboarding';
  }, [data.inputType, data.name]);

  return (
    <Card id="card-report-component">
      <Row className="no-pdd no-mrg flex space-between">
        <Col className="flex start-center header">
          <div className="icon">
            {Object.keys(Icons || {}).includes(reportIcon) &&
              createElement(Icons[reportIcon])}
          </div>

          <div>
            <Title level={4} className="no-mrg inline-block title">
              {data.name || 'Relatório sem nome'}
            </Title>{' '}
            • Report ID: {data._id}
            <Paragraph className="paragraph">
              {data.updatedAt &&
                data.updatedAt !== data.createdAt &&
                `Última atualização em ${lastUpdate}`}
            </Paragraph>
          </div>
        </Col>
        <Col className="flex end-center">
          <Form initialValues={data} onFinish={handleWebhookSubmit}>
            <Form.Item
              name="webhook"
              label="Webhook"
              labelCol={{ span: 0 }}
              rules={[{ type: 'url' }]}
            >
              <SearchInput
                disabled={loading}
                icon="Anchor"
                placeholder="Webhook"
                name="webhook"
                className="no-mrg mrg-right-25"
                style={{ width: 250 }}
              />
            </Form.Item>
          </Form>
          <Link
            href="https://docs.combateafraude.com/docs/conhecendo-produto/visao-geral/"
            target="_blank"
            className="no-mrg-vertical mrg-horizon-5"
          >
            O que é isso?
          </Link>
          <Dropdown
            overlay={
              <ListDropdown
                tenantId={tenantId}
                _id={data._id}
                refreshList={refreshList}
              />
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <div className="btn-more-icon">
              <MoreVertical width={24} height={24} />
            </div>
          </Dropdown>
        </Col>
      </Row>

      <Divider />

      <Title level={4}>Consultas personalizadas:</Title>

      {data.customData && data.customData.length > 0 ? (
        data.customData.map((d) => (
          <Tooltip key={d.source} title={d.source}>
            <div className="validation-tag">{d.title}</div>
          </Tooltip>
        ))
      ) : (
        <Text>Nenhuma consulta personalizada definida para o relatório.</Text>
      )}

      <Divider />

      <Title level={4}>Regras de Validação:</Title>

      {data.validations && data.validations.length > 0 ? (
        data.validations.map(
          (validation) => validation.enabled && renderValidation({ validation })
        )
      ) : (
        <Text>Nenhuma regra de validação definida para o relatório.</Text>
      )}

      {/* <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div> */}
    </Card>
  );
};

export default CardReport;
