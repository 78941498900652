import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Input, Tooltip } from 'antd';
import InputMask from 'components/Form/InputMask';
import { Duplicate } from '@combateafraude/icons/general';
import OcrFormItem from './OcrFormItem';

const TypeCRLV = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="name"
          label="Nome completo"
          errorText={invalidParams.name}
          span={24}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issueState"
          label="UF emissora"
          errorText={invalidParams.issueState}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem name="via" label="Via" errorText={invalidParams.via} span={8}>
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="type"
          label="Espécie/Tipo"
          errorText={invalidParams.type}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="crlvNumber"
          label="CRLV"
          errorText={invalidParams.crlvNumber}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="renavamCode"
          label="Renavam"
          errorText={invalidParams.renavamCode}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="rntrc"
          label="R.N.T.R.C."
          errorText={invalidParams.rntrc}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem name="cpf" label="CPF" errorText={invalidParams.cpf} span={8}>
          <Input
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate className="copy-input" onClick={() => copyHandler('cpf')} />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem name="cnpj" label="CNPJ" errorText={invalidParams.cnpj} span={8}>
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="assignmentYear"
          label="Exercício"
          errorText={invalidParams.assignmentYear}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="issueDate"
          label="Data de emissão"
          errorText={invalidParams.issueDate}
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="previousPlateAndState"
          label="Placa anterior / UF"
          errorText={invalidParams.previousPlateAndState}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="fuel"
          label="Combustível"
          errorText={invalidParams.fuel}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="brandAndModel"
          label="Marca/Modelo"
          errorText={invalidParams.brandAndModel}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="chassis"
          label="Nº chassi"
          errorText={invalidParams.chassis}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="capacityPowerCilynders"
          label="Cap/Pot/Cil"
          errorText={invalidParams.capacityPowerCilynders}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem name="plate" label="Placa" errorText={invalidParams.plate} span={8}>
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="manufactureYear"
          label="Ano de fabricação"
          errorText={invalidParams.manufactureYear}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="category"
          label="Categoria"
          errorText={invalidParams.category}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="predominantColor"
          label="Cor"
          errorText={invalidParams.predominantColor}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="modelYear"
          label="Ano de modelo"
          errorText={invalidParams.modelYear}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypeCRLV.propTypes = {
  invalidParams: PropTypes.shape,
  onCopy: PropTypes.func.isRequired,
};

TypeCRLV.defaultProps = {
  invalidParams: {},
};

export default TypeCRLV;
