import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, Modal, message } from 'antd';
import { useParams } from 'react-router-dom';

import { useFetch } from 'services/hooks';

import { Edit, Trash } from '@combateafraude/icons/general';
import denyRegisterEditModal from '../../../components/DenyListModal/RegisterModal';

const DenyListDropdown = ({ data, refreshListRef }) => {
  const { tenantId } = useParams();
  const { delete: deleteAPI } = useFetch();

  const { openModal, RegisterModal } = denyRegisterEditModal({
    data,
    refreshListRef,
  });

  const deleteRegister = useCallback(() => {
    Modal.confirm({
      title: 'Você realmente deseja excluir o registro da deny list?',
      okText: 'Sim, excluir',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await deleteAPI({
            url: `/clients/${tenantId}/deny-list/${data._id}`,
          });

          message.success('Registro excluido com sucesso!');
          refreshListRef.current();
        } catch (err) {
          message.error('Não foi possível excluir o registro.');
        }
      },
    });
  }, [data, deleteAPI, refreshListRef, tenantId]);

  return (
    <>
      {RegisterModal}
      <Menu>
        <Menu.Item key="1" onClick={openModal}>
          <Edit width={20} height={20} />
          Editar registro
        </Menu.Item>
        <Menu.Item key="2" onClick={deleteRegister} className="gx-text-danger">
          <Trash width={20} height={20} />
          Excluir registro
        </Menu.Item>
      </Menu>
    </>
  );
};

DenyListDropdown.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    hasContract: PropTypes.bool,
    contract: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default DenyListDropdown;
