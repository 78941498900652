import React, { useCallback, createElement, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Dropdown,
  Menu,
  Row,
  Col,
  Typography,
  Divider,
  Tooltip,
  Popconfirm,
  message,
} from 'antd';
import { parseISO, format } from 'date-fns';

import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import { useFetch } from 'services/hooks';
import Card from 'components/Card';
import { HiddenParagraph } from 'components/Text';

import { findMobileProduct } from 'pages/private/Customers/pages/Mobile/utils/mobileProducts';

import './CardMobile.less';
import { Trash, MoreVertical, Settings } from '@combateafraude/icons/general';

import useMobileCreate from '../../MobileCreate';

const { Title, Text } = Typography;

const CardMobile = ({ data, refreshList }) => {
  const refreshListRef = useRef(() => {});
  const { tenantId } = useParams();
  const { delete: deleteAPI, loading: loadingDelete } = useFetch();

  const Icons = Object.assign(iconsGeneral || {}, iconsUsers || {}, iconsFace || {});
  const { openModal, MobileCreateModal } = useMobileCreate({
    initialData: { ...data },
    refreshListRef,
  });

  const deleteToken = useCallback(async () => {
    if (!tenantId) {
      message.error('Houve um problema ao exluir a chave.');
      return;
    }

    try {
      await deleteAPI({
        url: `/clients/${tenantId}/mobile-tokens/${data.clientId}`,
      });

      refreshList();

      message.success('Chave excluída com sucesso.');
    } catch (err) {
      message.error('Houve um problema ao exluir a chave.');
    }
  }, [tenantId, data, deleteAPI, refreshList]);

  const menu = (
    <Menu className="pdd-vertical-5">
      {data.products?.includes('PassiveFaceLiveness') && (
        <Menu.Item key="settings" onClick={openModal}>
          <Settings width={20} height={20} />
          Configurações
        </Menu.Item>
      )}
      <Menu.Item key="delete" loading={loadingDelete}>
        <Popconfirm
          title="Você deseja mesmo excluir essa chave?"
          onConfirm={deleteToken}
          okText="Sim"
          cancelText="Não"
        >
          <span style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Trash style={{ marginRight: 8 }} width={20} height={20} />
            Excluir
          </span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    data && (
      <Card id="card-mobile-component">
        {MobileCreateModal}
        <Row className="no-pdd no-mrg">
          <Col span={6} className="cells">
            <Title level={4} className="no-mrg">
              Client ID
            </Title>
            <HiddenParagraph text={data.clientId} copyable />
          </Col>
          <Col span={12} className="cells border-left">
            <Title level={4} className="no-mrg">
              Client Secret
            </Title>
            <HiddenParagraph text={data.clientSecret} hidden copyable />
          </Col>
          <Col span={4} className="cells border-left">
            <Title level={4} className="no-mrg">
              Criado em
            </Title>
            <Text>
              {format(parseISO(data.createdAt || new Date()), "dd/MM/yyyy 'às' HH:mm")}
            </Text>
          </Col>
          <Col span={2} className="flex-jc-end custom-col-padding-right">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <div className="btn-more-icon">
                <MoreVertical width={24} height={24} />
              </div>
            </Dropdown>
          </Col>
        </Row>

        <Divider />

        {data.products &&
          data.products.map((p) => {
            const product = findMobileProduct(p);

            return (
              <Tooltip key={product.name} title={product.description}>
                <div className="validation-tag">
                  {Object.keys(Icons || {}).includes(product.icon) &&
                    createElement(Icons[product.icon])}
                  {product.title}
                </div>
              </Tooltip>
            );
          })}
      </Card>
    )
  );
};

CardMobile.propTypes = {
  data: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    createdAt: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.string),
    liveness: PropTypes.shape({
      integrations: PropTypes.arrayOf(PropTypes.string),
      supplier: PropTypes.shape({
        supplierType: PropTypes.string,
        product: PropTypes.string,
      }),
    }),
  }),
  refreshList: PropTypes.func.isRequired,
};

CardMobile.defaultProps = {
  data: undefined,
};

export default CardMobile;
