const creationDateDates = (creationDate) => {
  let startDate;
  let endDate;

  switch (creationDate) {
    case 'last24Hours':
      startDate = new Date().getTime() - 24 * 60 * 60 * 1000;
      endDate = new Date().getTime();
      break;
    case 'last7Days':
      startDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
      endDate = new Date().getTime();
      break;
    case 'last14Days':
      startDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 14;
      endDate = new Date().getTime();
      break;
    case 'last30Days':
      startDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
      endDate = new Date().getTime();
      break;
    default:
      if (creationDate.substr(0, 6) === 'custom') {
        [startDate, endDate] = creationDate.substr(7).split('-');
      }
      break;
  }

  return { startDate, endDate };
};

export default creationDateDates;
