import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Modal, Form, InputNumber, message } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useParams } from 'react-router-dom';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const useChangeReferenceDateModal = ({ data, refreshListRef }) => {
  const [form] = Form.useForm();
  const { loading, patch } = useFetch();
  const { tenantId } = useParams();

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => {
    setModalVisible(true);

    form.setFieldsValue({
      monthReference: data.monthReference,
      yearReference: data.yearReference,
    });
  }, [data.monthReference, data.yearReference, form]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    // se não fizer isso, aparece o modal sendo reinicializado ao fechar
    setTimeout(() => {
      form.resetFields();
    }, 250);
  }, [form]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await patch({
          url: `/invoices/${data._id}`,
          payload: {
            tenantId,
            ...values,
          },
        });
        refreshListRef.current();
        message.success('Data de referência alterada com sucesso.');
        closeModal();
      } catch (err) {
        message.error('Houve um problema ao realizar a alteração.');
      }
    },
    [closeModal, data, patch, refreshListRef, tenantId]
  );

  const changeReferenceDateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={500}
      >
        <Row>
          <Col span={24}>
            <PageTitle
              title="Alterar data de referência"
              subtitle="Informe o mês e ano de referência para essa cobrança."
              align="center"
            />
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          className="mrg-top-10"
        >
          <Row className="flex center space-between">
            <Col>
              <Form.Item
                label="Mês de referência"
                name="monthReference"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} max={12} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Ano de referência"
                name="yearReference"
                rules={[{ required: true }]}
              >
                <InputNumber min={2017} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mrg-top-10">
            <Col span={24} className="flex space-between">
              <Button className="btn-cancel" onClick={closeModal} disabled={loading}>
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                htmlType="submit"
                loading={loading}
              >
                Alterar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    ),
    [modalVisible, closeModal, form, handleSubmit, loading]
  );

  return { openModal, changeReferenceDateModal };
};

export default useChangeReferenceDateModal;
