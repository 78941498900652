import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Button } from 'antd';

import { toFormatDate } from 'utils/formatters';

import useTimelineDataModal from './TimelineDataModal';

import './TimelineContent.less';

const DOUBLE_CHECK_VALUES = {
  double_check: 'Double-check',
  official_data_reprove: 'Dados oficiais',
  sanctions_reprove: 'Sanções no nome',
  liveness: 'Liveness',
  valid_documents: 'Documento validado',
  shared_faceset: 'Banco de suspeitos',
  custom_data_pj: 'Custom data - PJ',
  custom_data_pf: 'Custom data - PF',
};

const TimelineContent = ({
  title,
  description,
  createdAt,
  updatedAt,
  user,
  data,
  type,
  blank,
}) => {
  const blankClass = useMemo(() => (blank ? 'blank' : ''), [blank]);

  const subtitle = useMemo(() => {
    if (DOUBLE_CHECK_VALUES[type]) {
      return DOUBLE_CHECK_VALUES[type];
    }

    if (type.includes('custom_data_pj')) {
      return DOUBLE_CHECK_VALUES.custom_data_pj;
    }

    if (type.includes('custom_data_pf')) {
      return DOUBLE_CHECK_VALUES.custom_data_pf;
    }

    return undefined;
  }, [type]);

  const { openModal, TimelineDataModal } = useTimelineDataModal({
    title,
    user,
    data,
    subtitle,
  });

  return (
    <>
      {TimelineDataModal}
      <div id="timeline-content-component" className={`${blankClass}`}>
        {blank ? (
          <Divider />
        ) : (
          <>
            <h2>{title}</h2>
            {subtitle && <h5 className="gx-font-italic">{subtitle}</h5>}

            {description && <p>{description}</p>}
            {data && (
              <span className="flex open-modal">
                <Button type="link" onClick={openModal}>
                  Ver dados da edição
                </Button>
              </span>
            )}

            <small>
              Editado no dia {toFormatDate(updatedAt || createdAt)} por {user.name}
            </small>
          </>
        )}
      </div>
    </>
  );
};

TimelineContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({}),
  blank: PropTypes.bool,
};

TimelineContent.defaultProps = {
  title: '',
  type: '',
  description: undefined,
  createdAt: new Date().toISOString(),
  updatedAt: undefined,
  user: undefined,
  blank: false,
  data: undefined,
};

export default TimelineContent;
