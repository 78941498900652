import React, { useCallback } from 'react';
import { Row, Col, message } from 'antd';

import { useExecution } from 'hooks/execution';
import { useParams } from 'react-router-dom';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';
import { useAuth } from 'hooks/auth';

import { useFetch } from 'services/hooks';

const SentImages = () => {
  const { executionData, reloadData } = useExecution();
  const { loading, post } = useFetch();
  const { tenantId, reportId, executionId } = useParams();

  const { images } = executionData?.executions || {};

  const { user } = useAuth();

  const handleSubmit = useCallback(
    async (event) => {
      try {
        await post({
          url: `executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/FACEMATCH`,
          payload: {
            action: 'true',
            approvedFacematch: event,
            _id: user?._id,
            email: user?.username,
          },
        });

        message.success('Ação realizada com sucesso.');
        reloadData(true);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [post, tenantId, reportId, executionId, user, reloadData]
  );

  return (
    <div id="sended-images-component">
      <PageTitle title="Avalie o Facematch (Foto do documento e selfie são a mesma pessoa)" />
      {executionData?.executions?.reviews?.FACEMATCH?.reviewAt && (
        <h3>
          <font color="green">
            <b>Facematch já avaliado</b>
          </font>
        </h3>
      )}
      <Card>
        <Row align="middle" justify="center">
          <Col span={8}>
            <ZoomedImage src={images?.back} label="Verso do documento" />
          </Col>
          <Col span={8}>
            <ZoomedImage src={images?.front} label="Frente do documento" />
          </Col>
          <Col span={8}>
            <ZoomedImage src={images?.selfie} label="Selfie" />
          </Col>
        </Row>
        <Row align="middle" justify="center" className="mrg-top-20">
          <Button
            htmlType="submit"
            className="btn-custom btn-custom-primary"
            onClick={() => {
              if (window.confirm('Você deseja aprovar?')) {
                handleSubmit(true);
              }
            }}
            loading={loading}
          >
            Aprovar
          </Button>
          <Button
            className="btn-custom btn-custom-danger"
            htmlType="submit"
            onClick={() => {
              if (window.confirm('Você deseja reprovar?')) {
                handleSubmit(false);
              }
            }}
            disabled={loading}
          >
            Reprovar
          </Button>
        </Row>
      </Card>
    </div>
  );
};

// SentImages.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default SentImages;
