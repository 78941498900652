import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Input, Tooltip } from 'antd';
import InputMask from 'components/Form/InputMask';
import { Duplicate } from '@combateafraude/icons/general';
import OcrFormItem from './OcrFormItem';

const TypeRNE = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem name="cpf" label="CPF" errorText={invalidParams.cpf} span={6}>
          <Input
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate className="copy-input" onClick={() => copyHandler('cpf')} />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="birthDate"
          label="Data de nascimento"
          errorText={invalidParams.birthDate}
          span={6}
        >
          <InputMask
            mask="99/99/9999"
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate
                  className="copy-input"
                  onClick={() => copyHandler('birthDate')}
                />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="name"
          label="Nome completo"
          errorText={invalidParams.name}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="motherName"
          label="Nome da mãe"
          errorText={invalidParams.motherName}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="fatherName"
          label="Nome do pai"
          errorText={invalidParams.fatherName}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="rne"
          label="RNE"
          isImportant
          errorText={invalidParams.rne}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issueDate"
          label="Data de emissão"
          isImportant
          errorText={invalidParams.issueDate}
          span={6}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issuingAuthority"
          label="Órgão expedidor"
          isImportant
          errorText={invalidParams.issuingAuthority}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="validity"
          label="Data de validade"
          isImportant
          errorText={invalidParams.validity}
          span={6}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="nativeCountry"
          label="Naturalidade"
          errorText={invalidParams.nativeCountry}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="nationality"
          label="Nacionalidade"
          errorText={invalidParams.nationality}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypeRNE.propTypes = {
  invalidParams: PropTypes.shape,
  onCopy: PropTypes.func.isRequired,
};

TypeRNE.defaultProps = {
  invalidParams: {},
};

export default TypeRNE;
