import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './styles.less';

const HiddenParagraph = ({ text, hidden, copyable }) => {
  const [isHidden, setIsHidden] = useState(hidden);

  const toggleIsHidden = useCallback(() => {
    setIsHidden((state) => !state);
  }, []);

  const onCopy = useCallback(() => {
    message.info('Copiado!');
  }, []);

  return (
    <div id="hidden-paragraph-component">
      {copyable && (
        <CopyToClipboard text={text} onCopy={onCopy}>
          <Button className="no-mrg no-pdd" type="link">
            Copiar
          </Button>
        </CopyToClipboard>
      )}

      {hidden && (
        <Button className="no-mrg no-pdd" type="link" onClick={toggleIsHidden}>
          {isHidden ? 'Mostrar' : 'Esconder'}
        </Button>
      )}

      {isHidden ? '****************' : text}
    </div>
  );
};

HiddenParagraph.propTypes = {
  text: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  copyable: PropTypes.bool,
};

HiddenParagraph.defaultProps = {
  hidden: false,
  copyable: false,
};

export default HiddenParagraph;
