import React, { useCallback, useState } from 'react';
import { Row, Input, Tooltip } from 'antd';
import InputMask from 'components/Form/InputMask';
import { Duplicate } from '@combateafraude/icons/general';
import PropTypes from 'prop-types';
import OcrFormItem from './OcrFormItem';

const TypePassport = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem name="name" label="Nome" errorText={invalidParams.name} span={14}>
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="birthDate"
          label="Data de nascimento"
          errorText={invalidParams.birthDate}
          isImportant
          span={10}
        >
          <InputMask
            mask="99/99/9999"
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate
                  className="copy-input"
                  onClick={() => copyHandler('birthDate')}
                />
              </Tooltip>
            }
          />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem name="mrz" label="MRZ" errorText={invalidParams.mrz} span={24}>
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="motherName"
          label="Nome da mãe"
          errorText={invalidParams.motherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="frontNumber"
          label="Número da Frente"
          errorText={invalidParams.frontNumber}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="fatherName"
          label="Nome do pai"
          errorText={invalidParams.fatherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="expirationDate"
          label="Data de expiração"
          errorText={invalidParams.expirationDate}
          isImportant
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="issueDate"
          label="Data de emissão"
          errorText={invalidParams.issueDate}
          isImportant
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issuingAuthority"
          label="Autoridade emissora"
          errorText={invalidParams.issuingAuthority}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issuingCountry"
          label="País de emisão"
          errorText={invalidParams.issuingCountry}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="number"
          label="Número"
          errorText={invalidParams.number}
          isImportant
          span={4}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="nationality"
          label="Nacionalidade"
          errorText={invalidParams.nationality}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="naturality"
          label="Naturalidade"
          errorText={invalidParams.naturality}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="gender"
          label="Gênero"
          errorText={invalidParams.gender}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypePassport.propTypes = {
  invalidParams: PropTypes.shape,
  onCopy: PropTypes.func.isRequired,
};

TypePassport.defaultProps = {
  invalidParams: {},
};

export default TypePassport;
