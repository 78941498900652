import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';

import { useExecutionDetailsDoubleCheckFaceAuthenticator } from 'hooks/executionDetailsDoubleCheckFaceAuthenticator';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';
import { useFetch } from 'services/hooks';

const SentImages = () => {
  const { _id } = useParams();
  const { loading, post } = useFetch();
  const { executionData, reloadData } = useExecutionDetailsDoubleCheckFaceAuthenticator();

  const handleSubmit = useCallback(
    async (event) => {
      try {
        await post({
          url: `executions-master-v2/faceauthenticator/${_id}?doubleCheckFaceAuthenticator=true`,
          payload: {
            review: event,
            peopleId: executionData?.doc?.peopleId,
          },
        });

        message.success('Ação realizada com sucesso.');
        reloadData(true);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [post, _id, executionData, reloadData]
  );

  const { doc } = executionData || {};

  return (
    <div id="sended-images-component">
      <PageTitle title="Imagens enviadas" />
      {executionData?.doc?.review?.verified && (
        <h3>
          <font color="green">
            <b>Imagens já avaliadas </b>
          </font>
        </h3>
      )}

      <Card>
        <Row align="middle" justify="center">
          <Col span={8}>
            <ZoomedImage src={doc?.imageDataUrl} label="Selfie" />
          </Col>
          <Col span={8}>
            <ZoomedImage src={doc?.imageFaceUrl} label="Original" />
          </Col>
        </Row>
        <Row align="middle" justify="center" className="mrg-top-20">
          <Button
            htmlType="submit"
            className="btn-custom btn-custom-primary"
            onClick={() => {
              if (window.confirm('Você deseja aprovar?')) {
                handleSubmit('approved');
              }
            }}
            loading={loading}
          >
            Aprovar
          </Button>
          <Button
            className="btn-custom btn-custom-danger"
            htmlType="submit"
            onClick={() => {
              if (window.confirm('Você deseja reprovar por spoofing?')) {
                handleSubmit('disapproved');
              }
            }}
            disabled={loading}
          >
            Reprovar
          </Button>
        </Row>
      </Card>
    </div>
  );
};

export default SentImages;
