import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

const UserHasPermissions = ({ children, permissions }) => {
  const { hasPermissions } = useAuth();

  const userHasPermissionsToSee = useMemo(() => {
    return hasPermissions(permissions);
  }, [hasPermissions, permissions]);

  return userHasPermissionsToSee ? <>{children}</> : <></>;
};

export default UserHasPermissions;

UserHasPermissions.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

UserHasPermissions.defaultProps = {
  children: null,
  permissions: [],
};
