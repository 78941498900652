/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import classNames from 'classnames';

import {
  PenEdit,
  Edit,
  CircleTrue,
  CircleFalse,
  ProcessingLine,
  Lawier,
  News,
  Law,
  Onboarding,
  Company,
  CheckD,
  Match,
} from '@combateafraude/icons/general';

import { Face } from '@combateafraude/icons/face';
import { Person } from '@combateafraude/icons/users';

import './TimelineIcon.less';

const typeIcon = {
  approve: <CircleTrue />,
  reprove: <CircleFalse />,
  retry: <ProcessingLine />,
  edit_ocr: <Edit />,
  manual_ocr: <PenEdit />,
  liveness: <Face />,
  shared_faceset: <Lawier />,
  official_data_reprove: <News />,
  sanctions_reprove: <Law />,
  rg_issue_less_than_25: <Onboarding />,
  custom_data_shared_faceset_available: <Lawier />,
  custom_data_pf: <Person />,
  custom_data_pj: <Company />,
  double_check: <CheckD />,
  valid_documents: <Match />,
};

const TimelineIcon = ({ date, type }) => {
  let typeIncludesCustomPjData = false;
  let typeIncludesCustomPfData = false;

  if (type) {
    typeIncludesCustomPjData = type.includes('custom_data_pj');
    typeIncludesCustomPfData = type.includes('custom_data_pf');
  }

  const formattedDay = useMemo(() => {
    if (!date) return undefined;

    const newDate = date.replace('T', ' ').replace('Z', '');

    const formattedDate = format(parseISO(newDate), 'dd', {
      locale: ptBR,
    });

    return formattedDate;
  }, [date]);

  const formattedMonth = useMemo(() => {
    if (!date) return undefined;

    const newDate = date.replace('T', ' ').replace('Z', '');

    const formattedDate = format(parseISO(newDate), 'MMM', {
      locale: ptBR,
    });
    return formattedDate;
  }, [date]);

  const dateClass = useMemo(() => (date ? 'date' : ''), [date]);

  return (
    <div
      id="timeline-icon-component"
      className={classNames(dateClass, {
        'reprove-reason': type === 'reprove',
      })}
    >
      {date ? (
        <>
          <h3>{formattedDay}</h3>
          <h6>{formattedMonth}</h6>
        </>
      ) : typeIncludesCustomPjData ? (
        typeIcon.custom_data_pj
      ) : typeIncludesCustomPfData ? (
        typeIcon.custom_data_pf
      ) : (
        typeIcon[type]
      )}
    </div>
  );
};

TimelineIcon.propTypes = {
  date: PropTypes.string,
  type: PropTypes.string,
};

TimelineIcon.defaultProps = {
  date: undefined,
  type: undefined,
};

export default TimelineIcon;
