import React from 'react';
import { Tabs } from 'antd';

import { Admin, DollarSign } from '@combateafraude/icons/general';

import { useQueryParams, StringParam } from 'use-query-params';
import Wrapper from '../wrapper';
import TabComponent from './components/TabComponent';
import './styles.less';
import InvoicesTable from './components/InvoicesTable';

const { TabPane } = Tabs;

const Dashboard = () => {
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  return (
    <Wrapper>
      <div id="dashboard-component">
        <Tabs
          defaultActiveKey={query.tab || 'support'}
          activeKey={query.tab}
          onChange={(tab) => setQuery({ tab })}
        >
          <TabPane
            tab={
              <div className="flex center">
                <Admin width={26} height={26} />
                <span className="mrg-left-5">Suporte</span>
              </div>
            }
            key="support"
          >
            <TabComponent dashboardNumber={1} title="suporte" />
          </TabPane>
          <TabPane
            tab={
              <div className="flex center">
                <DollarSign width={26} height={26} />
                <span className="mrg-left-5">Comercial</span>
              </div>
            }
            key="commercial"
          >
            <TabComponent dashboardNumber={2} title="comercial" />
          </TabPane>
          <TabPane
            tab={
              <div className="flex center">
                <DollarSign width={26} height={26} />
                <span className="mrg-left-5">Faturamento</span>
              </div>
            }
            key="revenues"
          >
            <InvoicesTable title="faturamento" />
          </TabPane>
          {/* <TabPane
            tab={
              <>
                <DocumentsFile /> Monitor Cliente
              </>
            }
            key="3"
          >
            <TabComponent dashboardNumber={35} title="monitorCliente" />
          </TabPane> */}
        </Tabs>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
