import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Divider, Typography, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { useAccountProducts } from 'hooks/accountProducts';

import { productsInfos } from '../utils/productsInfos';
import { addFixedProductsKeysIfNeeded, ALL_PRODUCTS_KEYS } from '../utils';

import ProductCard from './ProductCard';
import './styles.less';

const { Title } = Typography;

const ProductsList = () => {
  const [activeProducts, setActiveProducts] = useState([]);
  const [inactiveProducts, setInactiveProducts] = useState([]);

  const { tenantId } = useParams();
  const { getAccountProducts, accountsProducts, loading } = useAccountProducts();

  const getProductsInfos = useCallback(
    (productKey) => ({
      ...accountsProducts?.[productKey],
      ...productsInfos[productKey?.toUpperCase()],
    }),
    [accountsProducts]
  );

  const hasProducts = useMemo(
    () => !loading && Object.keys(accountsProducts || {})?.length,
    [accountsProducts, loading]
  );

  const disableProductActivation = useCallback(() => {
    setActiveProducts([]);
    setInactiveProducts(
      ALL_PRODUCTS_KEYS.map((key) => ({
        ...getProductsInfos(key),
        active: false,
        alwaysActive: false,
      }))
    );
  }, [getProductsInfos]);

  useMemo(() => {
    if (!hasProducts) {
      return disableProductActivation();
    }

    const activeProductsList = [];
    const inactiveProductsList = [];

    const productsKeys = Object.keys(accountsProducts || {});

    addFixedProductsKeysIfNeeded(productsKeys);

    const parsedProducts = productsKeys?.map((product) => {
      const parsedProduct = getProductsInfos(product);

      if (parsedProduct?.alwaysActive || parsedProduct?.active) {
        return activeProductsList.push(parsedProduct);
      }

      return inactiveProductsList.push(parsedProduct);
    });

    setActiveProducts(activeProductsList);
    setInactiveProducts(inactiveProductsList);

    return parsedProducts;
  }, [accountsProducts, disableProductActivation, getProductsInfos, hasProducts]);

  useMemo(() => {
    try {
      getAccountProducts({ tenantId });
    } catch (error) {
      //
    }
  }, [getAccountProducts, tenantId]);

  return loading ? (
    <Row align="middle" justify="center" className="mrg-top-15">
      <Col span={24}>
        <Spin />
      </Col>
    </Row>
  ) : (
    <Row align="middle" className="mrg-top-15">
      <Col span={24}>
        <Divider orientation="left">
          <Title level={3} className="title">
            Produtos ativos
          </Title>
        </Divider>
      </Col>

      <Col span={24}>
        <ProductCard hasProducts={hasProducts} product={getProductsInfos('caf')} />
        {activeProducts
          ?.sort((p) => (p?.key === 'management' ? -1 : 1))
          ?.map((product) => (
            <ProductCard hasProducts={hasProducts} product={product} />
          ))}
      </Col>

      <Col span={24} className="mrg-top-15">
        <Divider orientation="left">
          <Title level={3} className="title">
            Produtos inativos
          </Title>
        </Divider>
      </Col>
      <Col span={24}>
        {inactiveProducts?.map((product) => (
          <ProductCard hasProducts={hasProducts} product={product} />
        ))}
      </Col>
    </Row>
  );
};

export default ProductsList;
