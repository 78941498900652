import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, Timeline, Divider, Form } from 'antd';

import { useFetch } from 'services/hooks/';

import EmptyMessage from 'components/EmptyMessage';
import Button from 'components/Button';
import FAB from 'components/Button/FloatingActionButton';
import SearchInput from 'components/Form/SearchInput';

import { RotateCw } from '@combateafraude/icons/general';
import TimelineContent from './components/TimelineContent';
import TimelineIcon from './components/TimelineIcon';

import './styles.less';

const INITIAL_LIMIT = 3;
const INITIAL_OFFSET = 0;

const ExecutionTimeline = ({ isDrawerVisible, closeDrawer }) => {
  const { source } = useParams();

  const {
    data: annotationListResponse,
    loading: loadingAnnotationList,
    get: getAnnotationList,
    clearData,
  } = useFetch();

  const [annotationList, setAnnotationList] = useState([]);

  const [pagination, setPagination] = useState({
    _limit: INITIAL_LIMIT,
    _offset: INITIAL_OFFSET,
    hasMore: true,
  });

  const [filter, setFilter] = useState({
    _search: '',
  });

  const fetch = useCallback(
    ({ forceReload = false }) => {
      if (!pagination.hasMore && !forceReload) return;

      getAnnotationList({
        url: `/services-timeline/${source}`,
        config: {
          params: {
            _dated: true,
            _limit: pagination._limit,
            _offset: forceReload ? 0 : pagination._offset,
            _search: filter._search,
          },
        },
        clearData: forceReload,
      });
    },
    [getAnnotationList, source, pagination, filter]
  );

  const reload = useCallback(() => {
    setAnnotationList([]);

    setPagination({
      _limit: INITIAL_LIMIT,
      _offset: INITIAL_OFFSET,
      totalItems: 0,
      hasMore: true,
    });

    setTimeout(() => {
      fetch({ forceReload: true });
    }, 500);
  }, [fetch]);

  const handleReloadData = useCallback(() => {
    reload();
  }, [reload]);

  const clearListData = useCallback(() => {
    setAnnotationList([]);
    clearData();
    setPagination({
      _limit: INITIAL_LIMIT,
      _offset: INITIAL_OFFSET,
      totalItems: 0,
      hasMore: true,
    });
  }, [clearData]);

  const closeDrawerAndClearAnnotationList = useCallback(() => {
    if (isDrawerVisible) {
      closeDrawer();
      clearListData();
    }
  }, [clearListData, closeDrawer, isDrawerVisible]);

  useEffect(() => {
    if (!isDrawerVisible) return;

    fetch({});
    // clearListData();
  }, [isDrawerVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormChange = useCallback(
    (value) => {
      setFilter(value);
    },
    [setFilter]
  );

  useEffect(() => {
    reload();
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!annotationListResponse) return;

    if (!annotationListResponse?.docs?.length) {
      setPagination({
        _limit: INITIAL_LIMIT,
        _offset: INITIAL_OFFSET,
        totalItems: 0,
        hasMore: false,
      });

      return;
    }

    setPagination((oldState) => ({
      ...oldState,
      _offset: oldState._offset + oldState._limit,
      totalItems: annotationListResponse?.totalItems,
      hasMore: true,
    }));

    setAnnotationList((oldState) => [
      ...oldState,
      ...(annotationListResponse?.docs || []),
    ]);
  }, [annotationListResponse]);

  const createTimeline = useCallback((list) => {
    return list.map((annotationDate) => (
      <React.Fragment key={annotationDate._id}>
        <Timeline.Item dot={<TimelineIcon date={annotationDate._id} />}>
          <TimelineContent blank />
        </Timeline.Item>

        {annotationDate.docs.map((annotationItem) => (
          <Timeline.Item
            key={annotationItem._id}
            dot={<TimelineIcon type="edit_datasource" />}
          >
            <TimelineContent {...annotationItem} />
          </Timeline.Item>
        ))}
      </React.Fragment>
    ));
  }, []);

  const annotations = useMemo(() => {
    if (!annotationList?.length && !loadingAnnotationList) {
      return (
        <>
          <Divider orientation="left">Todas as alterações</Divider>

          {!pagination.hasMore && (
            <EmptyMessage show type="empty" description="Não há alterações encontradas" />
          )}
        </>
      );
    }

    return (
      <>
        <Divider orientation="left">Todas as alterações</Divider>

        <Timeline className="every-annotation">
          {createTimeline(annotationList)}
          <footer className="flex center">
            {pagination.hasMore ? (
              <Button
                className="mrg-vertical-15"
                onClick={() => fetch({})}
                htmlType="button"
                type="primary"
                loading={loadingAnnotationList}
                disabled={loadingAnnotationList}
              >
                {loadingAnnotationList ? 'Carregando...' : 'Carregar mais'}
              </Button>
            ) : (
              'Não há mais dados para carregamento.'
            )}
          </footer>
        </Timeline>
      </>
    );
  }, [annotationList, createTimeline, fetch, loadingAnnotationList, pagination]);

  return (
    <Drawer
      key="timeline"
      visible={isDrawerVisible}
      title="Linha do tempo"
      onClose={closeDrawerAndClearAnnotationList}
      width={false}
      id="timeline-component"
    >
      <header className="search-box">
        <Form onFinish={handleFormChange}>
          <SearchInput disabled={loadingAnnotationList} />
        </Form>
      </header>

      <div className="main-content">
        {annotations}
        <FAB onClick={handleReloadData} placement="left-bottom">
          <RotateCw width={20} height={20} />
        </FAB>
      </div>
    </Drawer>
  );
};

ExecutionTimeline.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default ExecutionTimeline;
