import React, { useCallback } from 'react';
import { Collapse, Radio, Form } from 'antd';
import {
  useQueryParams,
  StringParam,
  encodeObject,
  decodeObject,
} from 'use-query-params';

import { ArrowDown, ArrowRight } from '@combateafraude/icons/general';

import SearchInput from 'components/Form/SearchInput';

import _ from 'lodash';

import Button from 'components/Button';

const { Panel } = Collapse;

export const UnderscoreObjectParam = {
  encode: (obj) => encodeObject(obj, '->', '_'),
  decode: (objStr) => decodeObject(objStr, '->', '_'),
};

export const HashObjectParam = {
  encode: (obj) => encodeObject(obj, '->', '#'),
  decode: (objStr) => decodeObject(objStr, '->', '#'),
};

const SourceFilter = () => {
  const [query, setQuery] = useQueryParams({
    available: StringParam,
    search: StringParam,
  });

  const setFilter = useCallback(
    (filterName, event) => {
      const queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      setQuery(queryParams);
    },
    [setQuery]
  );

  const resetFilters = useCallback(() => {
    setQuery({
      available: '',
      search: undefined,
    });
  }, [setQuery]);

  return (
    <div id="custom-filter-component">
      <Form
        layout="inline"
        className="source-select"
        onValuesChange={(values) => setFilter('search', values.search)}
        initialValues={{ search: query?.search }}
      >
        <SearchInput
          name="search"
          placeholder="Busque por fonte"
          style={{ width: 300, marginLeft: 0 }}
        />
      </Form>

      <Collapse
        defaultActiveKey={['source', 'available']}
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <ArrowDown width={24} height={24} />
          ) : (
            <ArrowRight width={24} height={24} />
          )
        }
      >
        <Panel header="Por status" key="available">
          <Radio.Group
            defaultValue=""
            value={query?.available || ''}
            onChange={(e) => setFilter('available', e.target.value)}
          >
            <Radio value="1">Disponível</Radio>
            <Radio value="0">Indisponível</Radio>
            <Radio value="">Todos</Radio>
          </Radio.Group>
        </Panel>
      </Collapse>
      <div className="btn-filters mrg-right-15">
        <Button
          type="default"
          block
          onClick={resetFilters}
          className="btn-custom-secondary"
        >
          Limpar filtros
        </Button>
      </div>
    </div>
  );
};

export default SourceFilter;
