import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Modal, Typography, Form, Select, message } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import EmptyMessage from 'components/EmptyMessage';

const { Option } = Select;
const { Title } = Typography;

const imageTypes = {
  front: 'Frente',
  back: 'Verso',
  selfie: 'Selfie',
  selectedBack: 'Verso (imgem tratada)',
  selectedFront: 'Frente (imgem tratada)',
};

const ReprocessOfficialDataModal = () => {
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();
  const { loading: loadingReprocess, post: postReprocess } = useFetch();
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await postReprocess({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/OFFICIAL_DATA_REPROVE`,
          payload: {
            action: 'RETRY',
            _id: user?._id,
            email: user?.email,
            ...values,
          },
        });
        message.success('Ação realizada com sucesso.');
        reloadExecutionData(false);
        closeModal();
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [
      postReprocess,
      tenantId,
      reportId,
      executionId,
      reloadExecutionData,
      closeModal,
      user,
    ]
  );

  const thumbnailImage = useMemo(() => {
    if (!selectedImage) {
      return <EmptyMessage type="image" description="Selecione uma imagem" withCard />;
    }

    return (
      <Row justify="center">
        <img
          src={executionData?.executions?.images[selectedImage]}
          alt="Foto para reprocessar a execução."
        />
      </Row>
    );
  }, [executionData, selectedImage]);

  const reprocessModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={500}
      >
        <>
          <Row>
            <Col span={24}>
              <Title className="text-center modal-title pdd-horizon-20">
                Selecione a foto para reprocessar a execução.
              </Title>
            </Col>
          </Row>

          {thumbnailImage}

          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
            className="mrg-top-20"
          >
            <Form.Item name="key" rules={[{ required: true }]}>
              <Select
                className="text-dark"
                placeholder="Selecione..."
                onChange={setSelectedImage}
              >
                {Object.keys(executionData?.executions?.images || {}).map((key) => (
                  <Option value={key}>{imageTypes[key] || key}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Row className="mrg-top-40">
            <Col span={24} className="text-center">
              <Button onClick={closeModal} disabled={loadingReprocess}>
                Cancelar
              </Button>
              <Button type="primary" onClick={form.submit} loading={loadingReprocess}>
                Processar
              </Button>
            </Col>
          </Row>
        </>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      handleSubmit,
      executionData,
      loadingReprocess,
      thumbnailImage,
    ]
  );

  return { openModal, reprocessModal };
};

export default ReprocessOfficialDataModal;
