import React, { useEffect, useState, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import { Button } from 'antd';

import { useFetch } from 'services/hooks';

import loginImg from 'assets/images/login.png';
import logo from 'assets/images/logo-login.svg';
import logoGoogle from 'assets/images/logo-google.svg';
import Loading from '../../../routes/components/Loading';

import './styles.less';

const Login = () => {
  const { post } = useFetch();
  const [loading, setLoading] = useState(true);
  const [signInEnded, setSignInEnded] = useState(false);
  const location = useLocation();
  const { saveCredentials } = useAuth();
  const authenticated = useAuth().isAuthenticated();

  useEffect(() => {
    setTimeout(() => {
      if (!authenticated) setLoading(false);
    }, 3000);

    const signIn = async (code, redirectUri) => {
      const response = await post({
        url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/auth/signin/?redirect_uri=${redirectUri}`,
        payload: {
          code,
        },
      });

      const { idToken, refreshToken } = response;

      saveCredentials(idToken, refreshToken);
      setSignInEnded(true);
    };

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      signIn(code, `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_WEB}/login`);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  useEffect(() => {
    if (signInEnded) {
      setLoading(false);
    }
  }, [signInEnded]);

  const login = useCallback(() => {
    const redirectUri = encodeURIComponent(
      `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_WEB}/login`
    );
    window.location.href = `${process.env.REACT_APP_BASE_URL_COGNITO}${redirectUri}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <div id="login-component">
      {authenticated && <Redirect to="/home" />}
      <div className="login">
        <img src={logo} alt="Logo Wecheck" />

        <Button onClick={login} loading={loading} className="loginGoogle">
          <img src={logoGoogle} alt="Logo Wecheck" />
          Continue with Google
        </Button>
      </div>
      <div className="login-img">
        <img src={loginImg} alt="Logo Google" />
      </div>
    </div>
  ) : (
    <div id="page-loading">
      <Loading size="large" />
    </div>
  );
};

export default Login;
