import React, { useMemo, useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Row, Col, Checkbox, Typography, Form, Divider } from 'antd';

import Button from 'components/Button';
import { Checkmark } from '@combateafraude/icons/general';

const { Text, Title, Paragraph } = Typography;

const useValidationsPopover = ({ formRef, templates, validations, customData }) => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [template, setTemplate] = useState();

  const validationsNotYetApplied = useCallback(
    (temp) => {
      const formValidations = formRef.getFieldValue('validations') || [];
      const selectedValidations = formValidations.map((v) => v.rule);

      return (
        temp?.validations?.filter(
          (rule) => !selectedValidations.includes(rule.rule) && rule
        ) || []
      );
    },
    [formRef]
  );

  const customDataNotYetApplied = useCallback(
    (temp) => {
      const formCustomData = formRef.getFieldValue('customData') || [];
      const selectedCustomData = formCustomData.map((r) => r);

      return (
        temp?.customData?.filter((rule) => !selectedCustomData.includes(rule) && rule) ||
        []
      );
    },
    [formRef]
  );

  const openRelatedRulesPopover = useCallback(
    (templateId) => {
      if (!templates || !templateId) return;

      const { docs } = templates.templates;
      const foundTemplate = docs.find((d) => d._id === templateId);

      const templateValidations = validationsNotYetApplied(foundTemplate);
      const templateCustomData = customDataNotYetApplied(foundTemplate);

      const state =
        foundTemplate && (!!templateValidations?.length || !!templateCustomData?.length);

      setIsVisible(state);
      setTemplate(foundTemplate);
    },
    [customDataNotYetApplied, templates, validationsNotYetApplied]
  );

  const closeRelatedRulesPopover = useCallback(() => {
    setIsVisible(false);
    form.resetFields();
  }, [form]);

  const applyRelatedRules = useCallback(() => {
    const formCustomData = form.getFieldValue('customData') || [];
    const formRefCustomData = formRef.getFieldValue('customData') || [];

    const { validations: templateValidations } = template || [];

    const formValidationsData = formRef.getFieldValue('validations') || [];
    const selectedValidations = formValidationsData.map((v) => v.rule);

    const newValidations = templateValidations.filter(
      (rule) =>
        !selectedValidations.includes(rule.rule) && {
          ...rule,
          enabled: true,
        }
    );

    formRef.setFieldsValue({
      customData: [...formCustomData, ...formRefCustomData],
      validations: [...formValidationsData, ...newValidations],
    });

    closeRelatedRulesPopover();
  }, [form, formRef, closeRelatedRulesPopover, template]);

  const validationsFormatted = useMemo(() => {
    if (!template?.validations) return <></>;

    const templateValidations = validationsNotYetApplied(template);

    const templateValidationsFormatted = templateValidations?.map(
      (rule) => validations?.validations?.docs.find((v) => v.rule === rule.rule)?.title
    );

    return (
      templateValidationsFormatted?.length > 0 && (
        <>
          <Divider className="no-mrg">Validações</Divider>

          <Paragraph className="custom-card-paragraph mrg-btm-0">
            {templateValidationsFormatted.join(', ')}.
          </Paragraph>
        </>
      )
    );
  }, [template, validations, validationsNotYetApplied]);

  const customDataFormatted = useMemo(() => {
    if (!template?.customData) return <></>;

    const templateCustomData = customDataNotYetApplied(template);

    const templateCustomDataFormatted = templateCustomData?.map((tcd) => {
      const cd = customData?.customData?.docs?.find((data) => data.source === tcd);
      return {
        title: cd?.title,
        source: cd?.source,
      };
    });

    return (
      templateCustomDataFormatted?.length > 0 && (
        <>
          <Divider className="no-mrg">Consultas</Divider>

          <Form form={form} layout="vertical">
            <Row>
              <Form.Item name="customData">
                <Checkbox.Group className="checkbox-group">
                  {templateCustomDataFormatted.map((rule) => (
                    <Col span={24} className="mrg-top-5">
                      <Checkbox value={rule.source}>{rule.title || rule.source}</Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Row>
          </Form>
        </>
      )
    );
  }, [template, customDataNotYetApplied, form, customData]);

  const relatedRulesPopoverContent = useMemo(() => {
    return (
      <OutsideClickHandler onOutsideClick={closeRelatedRulesPopover}>
        <div className="checkmark">
          <Checkmark />
        </div>

        <Title level={4}>Sugestões de ação</Title>
        <Paragraph className="custom-card-paragraph">
          Olá, detectamos que você marcou o template base{' '}
          <Text type="primary">{template?.name}</Text>. Com base nessa ação recomendamos
          adicionar as seguintes validações e consultas:
        </Paragraph>

        {validationsFormatted}

        {customDataFormatted}

        <Button
          htmlType="button"
          className="btn-custom btn-custom-primary"
          onClick={applyRelatedRules}
        >
          Aplicar sugestões
        </Button>

        <Button type="text" onClick={closeRelatedRulesPopover}>
          Não, obrigado
        </Button>
      </OutsideClickHandler>
    );
  }, [
    closeRelatedRulesPopover,
    template,
    validationsFormatted,
    customDataFormatted,
    applyRelatedRules,
  ]);

  return {
    openRelatedRulesPopover,
    closeRelatedRulesPopover,
    isVisible,
    relatedRulesPopoverContent,
  };
};

export default useValidationsPopover;
