import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useExecution } from 'hooks/execution';
import { ArrowDown } from '@combateafraude/icons/general';
import {
  CicleWarn,
  CicleError,
  CicleApproved,
  IcProcessingSmall,
  FraudManRejected,
  ErroStroke,
  CorrectStroke,
  EditPenStroke,
  IcPauseSmall,
} from '@combateafraude/icons/others';

const statusMap = {
  pending: 'pendente',
  pendente: 'pendente',

  approved: 'aprovado',
  aprovado: 'aprovado',

  reprove: 'reprovado',
  reproved: 'reprovado',
  reprovado: 'reprovado',

  processing: 'processando',
  processando: 'processando',

  pendingocr: 'pendente-ocr',
  pendenteocr: 'pendente-ocr',
};

const translateStatusToValue = (status, isFraud) => {
  const value = status?.toLowerCase().replace(/[ _-]+/gm, '');

  if (!value) return 'erro';

  if (isFraud && value === 'reprovado') return 'fraude';

  return statusMap[value];
};

const StatusChangeSelector = ({
  openApprove,
  openReject,
  openPending,
  isWorkflowBuilder,
  isFraud,
}) => {
  const { executionData } = useExecution();
  const { Option } = Select;

  const statusChangeHandler = useCallback(
    (value) => {
      if (value === 'aprovado') openApprove(isFraud);
      if (value === 'reprovado') openReject();
      if (value === 'pendente') openPending();
    },
    [openApprove, openReject, openPending, isFraud]
  );

  const status = executionData?.executions?.status || null;
  const fraud = executionData?.executions?.fraud || null;

  const value = translateStatusToValue(status, fraud);

  return (
    <Select
      className={`select-status ${value}`}
      disabled={isWorkflowBuilder || value === 'erro'}
      suffixIcon={isWorkflowBuilder || value === 'erro' ? '' : <ArrowDown id="arrow" />}
      onChange={statusChangeHandler}
      value={value}
    >
      <Option
        value="erro"
        className="status-option erro"
        style={{
          display: 'none',
        }}
      >
        <ErroStroke />
        Erro
      </Option>
      <Option
        value="aprovado"
        className="status-option aprovado"
        style={{ display: value === 'aprovado' ? 'none' : '' }}
      >
        {value === 'aprovado' ? <CorrectStroke /> : <CicleApproved />}
        Aprovado
      </Option>
      <Option
        value="pendente"
        className="status-option pendente"
        style={{
          display: ['reprovado', 'aprovado', 'pendente'].includes(value) ? 'none' : '',
        }}
      >
        {value === 'pendente' ? <IcPauseSmall /> : <CicleWarn />}
        Pendente de análise
      </Option>
      <Option
        value="reprovado"
        className="status-option reprovado"
        style={{ display: value === 'reprovado' ? 'none' : '' }}
      >
        {value === 'reprovado' ? <ErroStroke /> : <CicleError />}
        Reprovado
      </Option>
      <Option value="pendente-ocr" style={{ display: 'none' }}>
        <EditPenStroke />
        Pendente de correção
      </Option>

      <Option value="fraude" style={{ display: 'none' }}>
        <FraudManRejected />
        Reprovado por fraude
      </Option>
      <Option value="processando" style={{ display: 'none' }}>
        <IcProcessingSmall />
        Processando
      </Option>
    </Select>
  );
};

StatusChangeSelector.propTypes = {
  openApprove: PropTypes.func.isRequired,
  openReject: PropTypes.func.isRequired,
  openPending: PropTypes.func.isRequired,
  isWorkflowBuilder: PropTypes.bool,
  isFraud: PropTypes.bool,
};

StatusChangeSelector.defaultProps = {
  isWorkflowBuilder: false,
  isFraud: false,
};

export default StatusChangeSelector;
