import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Modal, Form, Input, message } from 'antd';
import TooltipInfo from 'components/TooltipInfo';

import { useFetch } from 'services/hooks';

import { Close } from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';
import Button from 'components/Button';
import FileUpload from 'components/Files/FileUpload';
import PageTitle from 'components/PageTitle';
import SuccessStepModal from 'components/SuccessStepModal';

const ImportCSV = ({ refreshList }) => {
  const { customer } = useCustomer();
  const [form] = Form.useForm();
  const { post, put, delete: _delete, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [fileUrl, setFileUrl] = useState();
  const [step, setStep] = useState('');
  const [modalAction, setModalAction] = useState('Incluir');

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
      setPage(0);
      setStep('');
      setModalAction('Incluir');
      setFileUrl(undefined);
    }, 500);
  }, [form]);

  const openModal = useCallback((action) => {
    setModalAction(action);
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    try {
      if (modalAction === 'Incluir') {
        await post({
          url: `/clients/${customer?.tenantId}/deny-list/`,
          payload: {
            import: true,
            file: fileUrl,
            webhookUrl: 'webhookUrl',
            columnIdentification: form.getFieldValue('columnIdentification'),
            columnName: form.getFieldValue('columnName'),
            columnReason: form.getFieldValue('columnReason'),
          },
        });
      } else if (modalAction === 'Substituir') {
        await put({
          url: `/clients/${customer.tenantId}/deny-list/`,
          payload: {
            import: true,
            file: fileUrl,
            webhookUrl: 'webhookUrl',
            columnIdentification: form.getFieldValue('columnIdentification'),
            columnName: form.getFieldValue('columnName'),
            columnReason: form.getFieldValue('columnReason'),
          },
        });
      } else if (modalAction === 'Remover') {
        await _delete({
          url: `/clients/${customer.tenantId}/deny-list/`,
          config: {
            data: {
              import: true,
              file: fileUrl,
              webhookUrl: 'webhookUrl',
              columnIdentification: form.getFieldValue('columnIdentification'),
            },
          },
        });
      }
      setStep('success');
      if (refreshList?.current) refreshList.current();
    } catch (error) {
      message.error('Erro ao importar CSV.');
    }
  }, [_delete, customer, fileUrl, form, modalAction, post, put, refreshList]);

  const formContent = useMemo(() => {
    if (page === 0) {
      return (
        <Row>
          <Col span={24}>
            <PageTitle
              title={`${modalAction} registros via CSV`}
              subtitle="Envie o arquivo e informe o nome/título das colunas da sua planilha"
            />
          </Col>

          <Col span={24} className="mrg-vertical-20">
            <FileUpload
              title="Arquivo"
              file={fileUrl}
              onChangeFile={setFileUrl}
              supportedFiles={['.csv', '.CSV']}
            />
            <Form
              form={form}
              layout="vertical"
              // onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label={
                  <>
                    Titulo da coluna de identificação (CPF/CNPJ)
                    <TooltipInfo
                      color="#2f80ed"
                      placement="top"
                      title="Os campos são sensíveis a letras maiúsculas"
                      size={12}
                    />
                  </>
                }
                name="columnIdentification"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {modalAction !== 'Remover' && (
                <Form.Item
                  label={
                    <>
                      <span>Título da coluna de nome</span>
                      <i
                        className="font-size-10 gx-font-italic"
                        style={{ marginLeft: 2 }}
                      >
                        -Opcional
                      </i>
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title="Os campos são sensíveis a letras maiúsculas"
                        size={12}
                      />
                    </>
                  }
                  name="columnName"
                >
                  <Input />
                </Form.Item>
              )}
              {modalAction !== 'Remover' && (
                <Form.Item
                  label={
                    <>
                      <span>Título da coluna de motivo da inclusão</span>
                      <i
                        className="font-size-10 gx-font-italic"
                        style={{ marginLeft: 2 }}
                      >
                        -Opcional
                      </i>
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title="Os campos são sensíveis a letras maiúsculas"
                        size={12}
                      />
                    </>
                  }
                  name="columnReason"
                >
                  <Input />
                </Form.Item>
              )}
            </Form>
          </Col>

          <Col span={24} className="flex end">
            <Button
              className="btn-custom btn-custom-primary"
              htmlType="button"
              onClick={handleSubmit}
              disabled={!fileUrl || loading}
            >
              Importar arquivo
            </Button>
          </Col>
        </Row>
      );
    }

    return <></>;
  }, [page, modalAction, fileUrl, form, handleSubmit, loading]);

  const ImportCSVModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={650}
      >
        {step !== 'success' ? (
          formContent
        ) : (
          <SuccessStepModal
            pageTitle="Planilha enviada com sucesso"
            content="O processo de leitura e processamento dos registros da planilha foi iniciado. Aguarde alguns minutos para a finalização."
          >
            <Button className="btn-custom btn-custom-primary" onClick={closeModal}>
              Ok, fechar
            </Button>
          </SuccessStepModal>
        )}
      </Modal>
    ),
    [modalVisible, closeModal, step, formContent]
  );

  return { openModal, closeModal, ImportCSVModal };
};

export default ImportCSV;
