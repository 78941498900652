import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Upload,
  Typography,
  Progress,
  Divider,
  Spin,
  Button,
  Dropdown,
  Menu,
} from 'antd';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

import uploadImg from 'assets/images/upload.svg';
import docIcon from 'assets/images/ib_doc.svg';
import excelIcon from 'assets/images/ib_excel.svg';
import pdfIcon from 'assets/images/ib_pdf.svg';
import pngIcon from 'assets/images/ib_png.svg';
import zipIcon from 'assets/images/ib_zip.svg';
import { MoreVertical, StarOutline, Trash } from '@combateafraude/icons/general';

const { Text } = Typography;
const { Dragger } = Upload;

const icon = {
  doc: docIcon,
  docx: docIcon,
  xls: excelIcon,
  xlsx: excelIcon,
  pdf: pdfIcon,
  png: pngIcon,
  zip: zipIcon,
  rar: zipIcon,
  '7z': zipIcon,
};

const Document = ({ data, fetch }) => {
  const { tenantId } = useParams();
  const { get, patch, delete: deleteAPI, loading } = useFetch();
  const { loadCustomer } = useCustomer();

  const handleDeleteDocument = useCallback(
    async (_id) => {
      try {
        await deleteAPI({
          url: `/clients/${tenantId}/upload/${_id}`,
        });

        fetch();
      } catch (error) {
        // console.log(error);
      }
    },
    [deleteAPI, tenantId, fetch]
  );

  const handleStarDocument = useCallback(
    async (_id, contract = false) => {
      try {
        await patch({
          url: `/clients/${tenantId}/upload/${_id}`,
          payload: {
            contract,
          },
        });

        loadCustomer({ tenantId });

        fetch();
      } catch (error) {
        // console.log(error);
      }
    },
    [patch, fetch, loadCustomer, tenantId]
  );

  const handleDownloadDocument = useCallback(
    async (_id) => {
      const { fileName } = await get({
        url: `clients/${tenantId}/upload/${_id}`,
      });

      window.open(fileName, '_blank');
    },
    [tenantId, get]
  );

  const renderDropdownItems = useCallback(
    (_id, contract) => (
      <Menu>
        <Menu.Item key="0" onClick={() => handleStarDocument(_id, !contract)}>
          <StarOutline />
          {contract ? 'Desmarcar como contrato' : 'Marcar como contrato'}
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => handleDeleteDocument(_id)}
          className="gx-text-danger"
        >
          <Trash />
          Excluir
        </Menu.Item>
      </Menu>
    ),
    [handleDeleteDocument, handleStarDocument]
  );

  const extension = useMemo(() => {
    const splittedByDots = data.fileName.split('.');

    return splittedByDots.pop();
  }, [data.fileName]);

  return (
    <Row
      key={data._id}
      className={`documents-list-item no-mrg-horizon ${data.contract ? 'contract' : ''}`}
    >
      <Col className="documents-list-item-content">
        <aside>
          {loading ? <Spin /> : <img src={icon[extension]} alt="Ícone" height="36px" />}
          <Button type="link" onClick={() => handleDownloadDocument(data._id)}>
            {data.fileName}
          </Button>
        </aside>
        <Dropdown
          overlay={() => renderDropdownItems(data._id, data.contract)}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      </Col>
    </Row>
  );
};

Document.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    contract: PropTypes.bool.isRequired,
  }).isRequired,
  fetch: PropTypes.func.isRequired,
};

const DocumentsUploadCard = () => {
  const { tenantId } = useParams();
  const { post } = useFetch();
  const { get: getDocuments, loading: loadingDocuments } = useFetch();
  const { customer } = useCustomer();

  const [progress, setProgress] = useState([]);
  const [documents, setDocuments] = useState([]);

  const fetch = useCallback(async () => {
    const { docs } = await getDocuments({
      url: `/clients/${tenantId}/upload`,
    });

    setDocuments(docs || []);
  }, [getDocuments, tenantId]);

  const updateProgress = useCallback(({ name, percent, error }) => {
    setProgress((state) => {
      const elementPosition = state.map((p) => p.name).indexOf(name);

      if (elementPosition === -1) return [...state, { name, percent, error }];

      const newArray = [...state];

      if (percent === 0 && !newArray[elementPosition].error)
        return newArray.filter((obj) => obj.name !== name);

      newArray[elementPosition] = {
        ...newArray[elementPosition],
        percent: !newArray[elementPosition].error ? percent : 100,
        error: newArray[elementPosition].error || error,
      };
      return [...newArray];
    });
  }, []);

  const fileToArrayBuffer = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const customRequest = useCallback(
    async ({ onSuccess, onError, file, onProgress }) => {
      const { name } = file;

      const config = {
        // headers: { 'content-type': 'multipart/form-data' },
        headers: { 'Content-Type': file.type },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          updateProgress({ name, percent });

          if (percent === 100) {
            setTimeout(() => updateProgress({ name, percent: 0 }), 5000);
          }

          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      // const data = new FormData();
      // data.append('file', file);
      const data = await fileToArrayBuffer(file);

      try {
        // pega urls para mandar pro bucket temporário
        const { uploadUrl, getUrl } = await post({
          url: '/upload',
        });

        // envia o arquivo para o bucket temporário
        await cleanApi.put(uploadUrl, data, config);

        // envia a referência da aws pra nossa base
        await post({
          url: `/clients/${tenantId}/upload`,
          payload: {
            fileName: name,
            file: getUrl,
          },
        });

        fetch();

        onSuccess('Ok');
      } catch (err) {
        updateProgress({ name, percent: 100, error: true });

        onError({ err });
      }
    },
    [updateProgress, post, tenantId, fetch, fileToArrayBuffer]
  );

  const progressList = useMemo(
    () =>
      progress.length > 0 &&
      progress.map((p) => (
        <React.Fragment key={p.name}>
          <Text>{p.name}</Text>
          <Progress status={p.error && 'exception'} key={p.name} percent={p.percent} />
        </React.Fragment>
      )),
    [progress]
  );

  const documentsList = useMemo(() => {
    if (!documents.length) return <></>;

    const contracts = documents.filter((d) => d.contract);
    const normalDocuments = documents.filter((d) => !d.contract);

    const newDocuments = [...contracts, ...normalDocuments];

    return newDocuments.map((d) => <Document key={d._id} data={d} fetch={fetch} />);
  }, [documents, fetch]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!customer || !firstLoad.current) return;

    firstLoad.current = false;
    fetch();
  }, [customer, fetch]);

  return (
    <Card id="files-upload-card-component">
      <PageTitle title="Documentos" />
      <Dragger
        className="dragger"
        name="contracts"
        multiple
        customRequest={customRequest}
        showUploadList={false}
      >
        <Row>
          <Col span={24}>
            <img src={uploadImg} alt="Imagem de upload" />
          </Col>
          <Col className="mrg-top-20" span={24}>
            <Text>
              Arraste os items para cá ou clique aqui para adicionar documentos.
            </Text>
          </Col>
        </Row>
      </Dragger>
      {progressList}
      {documents && (
        <>
          <Divider />
          {loadingDocuments && !documents.length ? (
            <Spin className="flex center no-mrg-right" />
          ) : (
            documentsList
          )}
        </>
      )}
    </Card>
  );
};

export default DocumentsUploadCard;
