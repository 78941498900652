import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Modal, Form, Select, message } from 'antd';

import { useFetch } from 'services/hooks';
import { Close } from '@combateafraude/icons/general';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const { Option } = Select;

// import { Container } from './styles';

const ChangeStatusModal = ({ data, refreshListRef }) => {
  const [form] = Form.useForm();
  const { loading, put } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => {
    setModalVisible(true);
    form.setFieldsValue(data);
  }, [form, data]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    // se não fizer isso, aparece o modal sendo reinicializado ao fechar
    setTimeout(() => {
      form.resetFields();
    }, 250);
  }, [form]);

  const handleSubmit = useCallback(
    async (payload) => {
      try {
        await put({
          url: `/clients/${data.tenantId}`,
          payload,
        });
        refreshListRef.current();
        message.success('Status alterado com sucesso.');
        closeModal();
      } catch (err) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [closeModal, data.tenantId, put, refreshListRef]
  );

  const changeStatusModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={500}
      >
        <Row>
          <Col span={24}>
            <PageTitle
              title="Alterar status do cliente"
              subtitle="Selecione o status que você deseja para esse cliente."
              align="center"
            />
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          className="mrg-top-10"
        >
          <Form.Item name="status" rules={[{ required: true }]}>
            <Select className="text-dark" placeholder="Selecione um status">
              <Option value="lead">Lead</Option>
              <Option value="active">Ativo</Option>
              <Option value="poc">Teste</Option>
              <Option value="integration">Integração</Option>
              <Option value="internal-customer">Cliente Interno</Option>
              <Option value="inactive">Inativo</Option>
            </Select>
          </Form.Item>
          <Row className="mrg-top-40">
            <Col span={24} className="text-center">
              <Button onClick={closeModal} disabled={loading}>
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                htmlType="submit"
                loading={loading}
              >
                Alterar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    ),
    [modalVisible, closeModal, form, handleSubmit, loading]
  );

  return { openModal, changeStatusModal };
};

export default ChangeStatusModal;
