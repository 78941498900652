import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Menu, Modal, message } from 'antd';
import { Calendar, CircleFalse, Search, Trash } from '@combateafraude/icons/general';

import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import useChangeReferenceDateModal from './useChangeReferenceDateModal';

const InvoiceDropdown = ({ invoice, refreshListRef }) => {
  const { tenantId } = useParams();
  const { put, delete: deleteAPI } = useFetch();
  const { hasPermissions } = useAuth();

  const { openModal, changeReferenceDateModal } = useChangeReferenceDateModal({
    data: invoice,
    refreshListRef,
  });

  const cancelInvoice = useCallback(
    (id) => {
      Modal.confirm({
        title: 'Você realmente deseja cancelar a fatura?',
        okText: 'Sim, cancelar',
        okType: 'danger',
        cancelText: 'Não',
        onOk: async () => {
          try {
            await put({
              url: 'invoices',
              payload: { id, tenantId },
            });

            message.success('Cobrança cancelada com sucesso!');
            refreshListRef.current();
          } catch (err) {
            message.error('Não foi possível cancelar a cobrança.');
          }
        },
      });
    },
    [put, refreshListRef, tenantId]
  );

  const deleteInvoice = useCallback(
    (id) => {
      Modal.confirm({
        title: 'Você realmente deseja excluir a fatura?',
        okText: 'Sim, excluir',
        okType: 'danger',
        cancelText: 'Não',
        onOk: async () => {
          try {
            await deleteAPI({
              url: `/invoices/${id}`,
              config: {
                params: {
                  tenantId,
                },
              },
            });

            message.success('Cobrança excluida com sucesso!');
            refreshListRef.current();
          } catch (err) {
            message.error('Não foi possível excluir a cobrança.');
          }
        },
      });
    },
    [deleteAPI, refreshListRef, tenantId]
  );

  return (
    <>
      {changeReferenceDateModal}
      <Menu>
        <Menu.Item key="1">
          {/* eslint-disable-next-line */}
          <a href={invoice?.secure_url} rel="noopener noreferrer" target="_blank">
            <Search width={16} height={16} />
            Visualizar
          </a>
        </Menu.Item>
        <Menu.Item key="2" disabled={!hasPermissions(['finances'])} onClick={openModal}>
          <Calendar width={16} height={16} />
          Alterar data de referência
        </Menu.Item>
        <Menu.Item
          key="3"
          disabled={
            !['PENDING', 'EXPIRED'].includes(invoice?.status?.toUpperCase()) ||
            !hasPermissions(['finances'])
          }
          onClick={() => cancelInvoice(invoice.id)}
        >
          <CircleFalse width={16} height={16} />
          Cancelar cobrança
        </Menu.Item>
        <Menu.Item
          key="4"
          disabled={
            ['PAID', 'PENDING'].includes(invoice?.status?.toUpperCase()) ||
            !hasPermissions(['finances'])
          }
          onClick={() => deleteInvoice(invoice._id)}
          className="gx-text-danger"
        >
          <Trash width={16} height={16} />
          Excluir cobrança
        </Menu.Item>
      </Menu>
    </>
  );
};

InvoiceDropdown.propTypes = {
  invoice: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    secure_url: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoiceDropdown;
