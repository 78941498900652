import React, { useMemo, useCallback } from 'react';
import { Dropdown, Divider, Avatar } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';

import ProfileDefault from 'assets/images/profile-default.png';

import './styles.less';
import { ArrowDown, Settings } from '@combateafraude/icons/general';

const UserInfo = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();

  const logout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut, history]);

  const userProfile = useMemo(
    () => (
      <>
        <div className="user-profile">
          <div>
            <Avatar src={ProfileDefault} size={48} />
          </div>
          <section>
            <h3 className="max-lines-1">{user?.name || user?.docs?.name}</h3>
            <span className="max-lines-1">{user?.username || user?.docs?.username}</span>
          </section>
        </div>
        <Divider className="no-mrg-btm" />
      </>
    ),
    [user]
  );

  const menu = useMemo(
    () => (
      <div>
        {user && <>{userProfile}</>}

        <div className="gx-flex-column">
          <div className="user-menu">
            <NavLink to="/datasources" className="nav-link-menu">
              <Settings width={20} height={20} style={{ marginRight: 7 }} />
              Editar Fontes de Dados
            </NavLink>
            <Button className="btn-logout btn-custom btn-custom-primary" onClick={logout}>
              Sair
            </Button>
          </div>
          <Divider className="no-mrg" />
          <section className="privacy-policies-terms-of-use">
            <div>
              <a
                href="https://www.caf.io/politicas/politicas-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de privacidade
              </a>
              <a
                href="https://www.caf.io/politicas/termos-e-condicoes-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                Termos e condições de uso
              </a>
            </div>
          </section>
        </div>
      </div>
    ),
    [user, userProfile, logout]
  );

  return (
    <div id="user-info-component">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="user-dropdown"
      >
        <div className="user-dropdown-trigger">
          <Avatar src={ProfileDefault} size={28} />
          {user.name}
          <ArrowDown height={24} width={24} />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
