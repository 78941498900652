import React, {
  useCallback,
  useState,
  createElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Modal, Form, Checkbox, message } from 'antd';
import { Close } from '@combateafraude/icons/general';

import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import { useTimeline } from 'hooks/timeline';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

import mobileProducts from 'pages/private/Customers/pages/Mobile/utils/mobileProducts';
import AntiFraudSettings from '../AntiFraudSettings';

import './styles.less';

const MobileCreate = ({
  refreshListRef,
  initialData = {
    liveness: { supplier: { name: 'Iproov', product: 'LA' }, integrations: [] },
  },
}) => {
  const { tenantId } = useParams();
  const { closeModalMandatory } = useTimeline();
  const [form] = Form.useForm();
  const livenessSettingsRef = useRef();

  const { post: postMobile, loading: loadingCreate } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [generateKeyButtonDisabled, setGenerateKeyButtonDisabled] = useState(true);
  const [steps, setSteps] = useState(
    !initialData.clientId ? 'permissions' : 'livenessSettings'
  );
  const [data, setData] = useState(initialData);

  const Icons = Object.assign(iconsGeneral || {}, iconsUsers || {}, iconsFace || {});

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setGenerateKeyButtonDisabled(true);
    form.resetFields();
    setSteps(!data.clientId ? 'permissions' : 'livenessSettings');
  }, [closeModalMandatory, refreshListRef]); // eslint-disable-line

  useEffect(() => {
    if (!modalVisible && livenessSettingsRef.current) {
      livenessSettingsRef.current.resetValues();
    }
  }, [modalVisible]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const renderCheckboxesList = useCallback(
    () =>
      mobileProducts.map(
        (mp) =>
          mp.enabled && (
            <Col span={12}>
              <Checkbox value={mp.name}>
                <div className="checkbox-custom-label">
                  <div className="icon">
                    {Object.keys(Icons || {}).includes(mp.icon) &&
                      createElement(Icons[mp.icon])}
                  </div>
                  <div className="details">
                    <strong>{mp.title}</strong>
                    <p>{mp.description}</p>
                  </div>
                </div>
              </Checkbox>
            </Col>
          )
      ),
    [Icons]
  );

  const createToken = useCallback(
    async (payload) => {
      if (!tenantId || !form.getFieldValue('products')) {
        message.error('Houve um problema ao gerar nova chave.');
        return;
      }

      try {
        await postMobile({
          url: `/clients/${tenantId}/mobile-tokens`,
          payload,
        });

        refreshListRef.current();
        closeModal();

        message.success('Nova chave gerada com sucesso.');
      } catch (err) {
        message.error('Houve um problema ao gerar nova chave.');
      }
    },
    [closeModal, tenantId, form, postMobile, refreshListRef]
  );

  const handleOnValuesChange = useCallback(
    (changedValue) => {
      if (changedValue.products.includes('PassiveFaceLiveness')) {
        setSteps('nextLivenessSettings');
      } else {
        setSteps('permissions');
      }
      setGenerateKeyButtonDisabled(
        changedValue?.products?.length === 0 ||
          changedValue?.products?.length === undefined
      );
    },
    [data, steps]
  );

  const handleNextLivenessSettings = useCallback(() => {
    const updatedData = { ...data, products: form.getFieldValue('products') };
    setData(updatedData);
    setSteps('livenessSettings');
  }, [steps]);

  const MobileCreateModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        {steps === 'livenessSettings' ? (
          <AntiFraudSettings
            data={data}
            closeModal={closeModal}
            refreshListRef={refreshListRef}
            ref={livenessSettingsRef}
          />
        ) : (
          <div id="mobile-modal-component" className="no-mrg no-pdd">
            <PageTitle
              title="Nova chave"
              subtitle="Selecione os produtos que você deseja utilizar em seu app mobile."
            />

            <Form
              form={form}
              layout="vertical"
              className="mrg-top-30"
              onFinish={createToken}
              onValuesChange={handleOnValuesChange}
            >
              <Form.Item name="products" className="no-mrg">
                <Checkbox.Group className="checkbox-group">
                  <Row gutter={[16, 16]}>{renderCheckboxesList()}</Row>
                </Checkbox.Group>
              </Form.Item>

              <div className="gx-flex-row gx-justify-content-between mrg-top-10">
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  Cancelar
                </Button>
                {steps === 'permissions' || steps === 'livenessSettings' ? (
                  <Button
                    className="btn-custom btn-custom-primary"
                    htmlType="submit"
                    disabled={loadingCreate || generateKeyButtonDisabled}
                    loading={loadingCreate}
                  >
                    {loadingCreate ? 'Gerando chave...' : 'Gerar nova chave'}
                  </Button>
                ) : (
                  <Button
                    className="btn-custom btn-custom-primary"
                    onClick={handleNextLivenessSettings}
                  >
                    Continuar
                  </Button>
                )}
              </div>
            </Form>
          </div>
        )}
      </Modal>
    ),
    [
      steps,
      modalVisible,
      closeModal,
      form,
      renderCheckboxesList,
      createToken,
      loadingCreate,
      handleOnValuesChange,
      generateKeyButtonDisabled,
      handleNextLivenessSettings,
    ]
  );
  return { openModal, closeModal, MobileCreateModal };
};

export default MobileCreate;
