import React, { useMemo, useState, useCallback } from 'react';
import { Modal, Form, Row, Col, Card, Typography, Switch, Spin } from 'antd';
import { Close } from '@combateafraude/icons/general';

import ReactJson from 'react-json-view';
import Loader from 'components/Loader';

import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';

const { Text } = Typography;

const Tracking = () => {
  const [loading, setLoading] = useState(false);
  const { get } = useFetch();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [isFormatted, setIsFormatted] = useState(false);
  const [tracking, setTracking] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(
    async (trackingId) => {
      setModalVisible(true);
      setLoading(true);
      const res = await get({
        url: `/analytics/tracking/${trackingId}`,
      });
      setTracking(res);
      setLoading(false);
    },
    [get]
  );

  const cardContent = useMemo(() => {
    return isFormatted ? (
      <ReactJson
        name={false}
        src={tracking || {}}
        iconStyle="triangle"
        indentWidth={2}
        collapsed={1}
        theme="shapeshifter:inverted"
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
      />
    ) : (
      <pre className="execution-json">{JSON.stringify(tracking, null, 2)}</pre>
    );
  }, [isFormatted, tracking]);

  const TrackingModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        {loading ? (
          <div className="loading">
            <Spin
              align="center"
              indicator={<Loader size="25px" color="#bdbdbd" />}
              // tip="Carregando..."
            />
          </div>
        ) : (
          <>
            <Row align="middle">
              <Col span={16}>
                <PageTitle title="Detalhes do tracking" />
              </Col>
              <Col span={8} className="formatted-switch-col">
                <Switch checked={isFormatted} onChange={setIsFormatted} />
                <Text strong className="no-mrg mrg-left-10">
                  Formatação
                </Text>
              </Col>
            </Row>

            <Card>{cardContent}</Card>
          </>
        )}
      </Modal>
    );
  }, [form, modalVisible, closeModal, isFormatted, cardContent, loading]);

  return {
    openModal,
    closeModal,
    TrackingModal,
  };
};

export default Tracking;
