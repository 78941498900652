import React, { useCallback, useState } from 'react';

import Wrapper from 'pages/private/wrapper';

import _ from 'lodash';
import ExecutionList from './ExecutionList';
import ExecutionFilter from './ExecutionFilter';
import { creationDateDates } from './ExecutionFilter/utils';
import './styles.less';

const defaultFilters = {
  creationDate: 'last24Hours',
  customCreatedAt: 'custom',
  startDate: undefined,
  endDate: undefined,
  status: [{ key: 'PROCESSANDO;PROCESSING', value: 'PROCESSANDO;PROCESSING' }],
  customers: undefined,
  notCustomers: undefined,
  search: undefined,
  documentoscopy: undefined,
  doubleCheckLiveness: undefined,
  datasources: undefined,
  lifetime: 'threeMin',
  _order: undefined,
  _sort: undefined,
};

const Executions = () => {
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [errorFilter, setErrorFilter] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const applyFiltersHandler = useCallback((filterData) => {
    let params = defaultFilters;

    if (filterData.creationDate === 'custom' && filterData.customCreatedAt === 'custom') {
      setErrorFilter('Selecione uma Data');
    } else if (filterData.status.length === 0) {
      setErrorFilter('Selecione um Status');
    } else {
      setErrorFilter(false);

      const threeMinutes = 3 * 60 * 1000;

      if (Array.isArray(filterData.search) && filterData.search.length > 0) {
        params = { ...params, _search: filterData.search.join(';') };
      }
      if (Array.isArray(filterData.customers) && filterData.customers.length > 0) {
        params = {
          ...params,
          customers: filterData.customers.map((val) => val.key).join(';'),
        };
      }
      if (Array.isArray(filterData.notCustomers) && filterData.notCustomers.length > 0) {
        params = {
          ...params,
          notCustomers: filterData.notCustomers.map((val) => val.key).join(';'),
        };
      }
      if (Array.isArray(filterData.datasources) && filterData.datasources.length > 0) {
        params = {
          ...params,
          datasources: filterData.datasources.map((val) => val.key).join(';'),
        };
      }
      if (Array.isArray(filterData.lifetime) && filterData.lifetime.length === 0) {
        params = {
          ...params,
          lifetime: [],
        };
      }
      if (Array.isArray(filterData.service) && filterData.service.length > 0) {
        params = {
          ...params,
          service: filterData.service.map((val) => val.key).join(';'),
        };
      }
      if (Array.isArray(filterData.status) && filterData.status.length > 0) {
        params = { ...params, status: filterData.status.map((val) => val.key).join(';') };
      }
      if (
        Array.isArray(filterData.documentoscopy) &&
        filterData.documentoscopy.length > 0
      ) {
        params = {
          ...params,
          documentoscopy: filterData.documentoscopy.map((val) => val.key).join(';'),
        };
      }
      if (Array.isArray(filterData.doubleCheckLiveness)) {
        params = {
          ...params,
          doubleCheckLiveness: filterData.doubleCheckLiveness
            .map((val) => val.key)
            .join(';'),
        };
      }

      if (
        filterData.customCreatedAt !== 'custom' &&
        Array.isArray(filterData.customCreatedAt) &&
        filterData.creationDate === 'custom'
      ) {
        const { startDate, endDate } = filterData.customCreatedAt[0];

        params = {
          ...params,
          creationDate: `custom:${startDate}-${endDate}`,
          startDate,
          endDate,
        };
      } else {
        const date = creationDateDates(filterData.creationDate);
        const { startDate } = date;
        let { endDate } = date;

        if (filterData.lifetime === 'threeMin' || filterData.lifetime[0] === 'threeMin') {
          endDate -= threeMinutes;
        }

        params = {
          ...params,
          creationDate:
            filterData.creationDate && !_.isEmpty(filterData.creationDate)
              ? filterData.creationDate
              : undefined,
          startDate,
          endDate,
        };
      }
      setFilters(params);
    }
  }, []);

  return (
    <Wrapper id="executions-component">
      <div className="gx-flex-row">
        <div id="filter" className="hide-scroll">
          <ExecutionFilter
            isLoadingList={isLoadingList}
            onFiltersApply={applyFiltersHandler}
          />
        </div>

        <div id="list">
          <ExecutionList
            setIsLoadingList={setIsLoadingList}
            onSortChange={setFilters}
            errorFilter={errorFilter}
            filters={filters}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Executions;
