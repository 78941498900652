import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Modal } from 'antd';
import Button from 'components/Button';
import { NavLink } from 'react-router-dom';

import { Checkmark, Close, Edit } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';

const ListDropdown = ({ data, refreshListRef }) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalInactiveVisible, setIsModalInactiveVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalActiveVisible, setIsModalActiveVisible] = useState(false);
  const { put: putHub } = useFetch();

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalInactiveVisible(false);
    setIsModalActiveVisible(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConfirmPutHub = async (status) => {
    const newPayload = {
      ...data?.hub,
      tenantsId: data?.tenants,
      active: status,
    };
    setLoading(true);
    await putHub({
      url: `/hubs-auth/${data?.hub?.id}`,
      payload: newPayload,
    });
    refreshListRef.current();
    setLoading(false);
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalInactiveVisible(false);
    setIsModalActiveVisible(false);
  };

  const handleInactivate = () => {
    setIsModalInactiveVisible(true);
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    setIsModalDeleteVisible(true);
    setIsModalVisible(false);
  };

  const modal = useMemo(() => {
    if (isModalVisible) {
      return (
        <Modal visible={isModalVisible} onCancel={handleCancel} footer={null} centered>
          <div className="flex-column center">
            <h1 className="mrg-vertical-20 text-bold">Deseja excluir esse HUB?</h1>
            <span>Você pode optar em desativar ele nesse momento.</span>
          </div>
          <div className="flex space-between mrg-top-50">
            <Button
              type="cancel"
              onClick={handleInactivate}
              className="gx-text-danger"
              disabled={!data?.hub?.active || loading}
            >
              Inativar
            </Button>
            <div>
              <Button onClick={handleCancel} disabled={loading}>
                Cancelar
              </Button>
              <Button type="danger" onClick={handleConfirm} disabled loading={loading}>
                Excluir
              </Button>
            </div>
          </div>
        </Modal>
      );
    }
    if (isModalActiveVisible) {
      return (
        <Modal
          visible={isModalActiveVisible}
          onCancel={handleCancel}
          footer={null}
          centered
        >
          <div className="flex-column center">
            <h1 className="mrg-vertical-20 text-bold">Deseja Ativar esse HUB?</h1>
            <span>Está ação irá ativar o HUB de contas.</span>
          </div>
          <div className="flex space-between mrg-top-50">
            <div />
            <div>
              <Button onClick={handleCancel} disabled={loading}>
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                onClick={() => {
                  handleConfirmPutHub(true);
                }}
                loading={loading}
              >
                Ativar
              </Button>
            </div>
          </div>
        </Modal>
      );
    }
    if (isModalInactiveVisible) {
      return (
        <Modal
          visible={isModalInactiveVisible}
          onCancel={handleCancel}
          footer={null}
          centered
        >
          <div className="flex-column center">
            <h1 className="mrg-vertical-20 text-bold">Deseja inativar esse HUB?</h1>
            <span className="text-center" style={{ width: '60%' }}>
              Você poderá torná-lo ativo editando ele na listagem de HUBs
            </span>
          </div>
          <div className="flex space-between mrg-top-50">
            <div />
            <div>
              <Button onClick={handleCancel} disabled={loading}>
                Cancelar
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  handleConfirmPutHub(false);
                }}
                loading={loading}
              >
                Inativar
              </Button>
            </div>
          </div>
        </Modal>
      );
    }
    if (isModalDeleteVisible) {
      return (
        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          footer={null}
          bodyStyle={{
            backgroundColor: '#E21B45',
            borderRadius: 8,
          }}
          centered
        >
          <div className="flex-column center mrg-auto gx-text-white">
            <h1 className="mrg-vertical-20 gx-text-white">ATENÇÃO!</h1>
            <span className="text-center" style={{ width: '80%' }}>
              Você está prestes a excluir esse HUB definitivamente, deseja mesmo
              prosseguir?
            </span>
          </div>
          <div className="flex center mrg-top-50">
            <Button onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button
              type="secondary"
              className="gx-text-danger"
              onClick={handleConfirm}
              loading={loading}
            >
              Sim, excluir
            </Button>
          </div>
        </Modal>
      );
    }
    return true;
  }, [
    data,
    handleConfirmPutHub,
    isModalVisible,
    isModalInactiveVisible,
    isModalDeleteVisible,
    isModalActiveVisible,
    loading,
  ]);

  return (
    <>
      <Menu>
        <Menu.Item key="2">
          <NavLink to={`/hub-accounts/${data?.hub?.id}`}>
            <Edit width={24} height={24} style={{ marginRight: 4 }} />
            Editar
          </NavLink>
        </Menu.Item>
        {!data?.hub?.active ? (
          <Menu.Item
            key="1"
            className="gx-text-green"
            onClick={() => {
              setIsModalActiveVisible(true);
            }}
          >
            <Checkmark width={24} height={24} style={{ marginRight: 4 }} />
            Ativar
          </Menu.Item>
        ) : (
          <Menu.Item
            key="3"
            className="gx-text-danger"
            onClick={() => {
              setIsModalInactiveVisible(true);
            }}
          >
            <Close width={20} height={20} style={{ marginRight: 4 }} />
            Inativar
          </Menu.Item>
        )}
      </Menu>
      {modal}
    </>
  );
};

ListDropdown.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }),
    tenants: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default ListDropdown;
