import React from 'react';
import { useParams } from 'react-router-dom';

import { useCustomer } from 'hooks/customer';
import Wrapper from '../../wrapper';

const UserList = () => {
  const { tenantId } = useParams();
  const { customer } = useCustomer();

  return (
    <Wrapper>
      {customer && (
        <>
          <iframe
            title="Templates"
            src={`${process.env.REACT_APP_ONBOARDING_BUILDER_URL}onboarding?tenantId=${tenantId}&noMargin&newTab`}
            allow="clipboard-write"
            width="100%"
            height="630"
            style={{ border: 'none' }}
          />
        </>
      )}
    </Wrapper>
  );
};

export default UserList;
