import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Tooltip,
  Popover,
  Form,
  Button as AntButton,
} from 'antd';

import Button from 'components/Button';

import currency from 'utils/currencyFormatter';
import { Checkmark } from '@combateafraude/icons/general';

const { Text, Title, Paragraph } = Typography;

const CustomDataCardRow = ({ customData, formRef }) => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const openRelatedRulesCard = useCallback(
    (e) => {
      let state = e.target.checked && customData?.relatedRules?.length > 0;

      if (state) {
        const formValidationsData = formRef.getFieldValue('validations') || [];
        const selectedValidations = formValidationsData.map((vd) => vd.rule);

        const validations = customData.relatedRules
          .map((rule) => rule.rule)
          .filter((rule) => !selectedValidations.includes(rule));

        if (validations.length === 0) {
          state = false;
        } else {
          form.setFieldsValue({
            validations,
          });
        }
      } else {
        form.resetFields();
      }

      setIsVisible(state);

      return e;
    },
    [customData, form, formRef]
  );

  const closeRelatedRulesCard = useCallback(() => {
    setIsVisible(false);
    form.resetFields();
  }, [form]);

  const applyRelatedRules = useCallback(
    (values) => {
      const formValidationsData = formRef.getFieldValue('validations') || [];
      const newData =
        values.validations.map((rule) => ({
          rule,
          enabled: true,
        })) || [];

      formRef.setFieldsValue({
        validations: [...formValidationsData, ...newData],
      });

      closeRelatedRulesCard();
    },
    [formRef, closeRelatedRulesCard]
  );

  const relatedRulesCard = useMemo(() => {
    return (
      <OutsideClickHandler onOutsideClick={closeRelatedRulesCard}>
        <div className="checkmark ">
          <Checkmark />
        </div>
        <Title level={4}>Sugestões de ação</Title>
        <Paragraph className="custom-card-paragraph">
          Olá, detectamos que você assinalou{' '}
          <Text type="primary">{customData.title}</Text>, com base nessa ação recomendamos
          adicionar as seguintes consultas:
        </Paragraph>

        <Form form={form} layout="vertical" onFinish={applyRelatedRules}>
          <Row>
            <Form.Item name="validations">
              <Checkbox.Group className="checkbox-group">
                {customData.relatedRules?.map((rule) => {
                  const formValidationsData = formRef.getFieldValue('validations') || [];
                  const selectedValidations = formValidationsData.map((vd) => vd.rule);

                  return (
                    !selectedValidations.includes(rule.rule) && (
                      <Col span={24} className="mrg-top-5">
                        <Checkbox value={rule.rule}>{rule.title || rule.rule}</Checkbox>
                      </Col>
                    )
                  );
                })}
              </Checkbox.Group>
            </Form.Item>
          </Row>
        </Form>

        <Button
          htmlType="button"
          className="btn-custom btn-custom-primary"
          onClick={form.submit}
        >
          Aplicar sugestões
        </Button>

        <AntButton type="text" onClick={closeRelatedRulesCard}>
          Não, obrigado
        </AntButton>
      </OutsideClickHandler>
    );
  }, [customData, form, closeRelatedRulesCard, applyRelatedRules, formRef, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row key={customData.source} className="custom-data-row">
      <Col flex={1}>
        <Popover
          overlayClassName="custom-card-popover"
          content={relatedRulesCard}
          visible={isVisible}
        >
          <Checkbox value={customData.source} onClick={openRelatedRulesCard}>
            <Tooltip title={customData.source}>
              <Text>{customData.title}</Text>
            </Tooltip>
          </Checkbox>
        </Popover>
      </Col>
      <Col>
        <Text>
          + <Text strong>{currency.numberToFormattedString(customData.price)}</Text> /
          consulta
        </Text>
      </Col>
    </Row>
  );
};

CustomDataCardRow.propTypes = {
  customData: PropTypes.shape({
    source: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    relatedRules: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default CustomDataCardRow;
