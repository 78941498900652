const mobileProducts = [
  {
    enabled: true,
    name: 'DocumentDetector',
    title: 'Captura de documento',
    description: 'Realiza a captura de um documento',
    icon: 'Onboarding',
  },
  {
    enabled: true,
    name: 'FaceRegistration',
    title: 'Registro facial',
    description: 'Vincula a selfie do usuário ao seu cadastro',
    icon: 'Admin',
  },
  {
    enabled: true,
    name: 'FaceAuthenticator',
    title: 'Autenticação facial',
    description: 'Compara a selfie do usuário com a registrada em nossos servidores',
    icon: 'Face',
  },
  {
    enabled: true,
    name: 'PassiveFaceLiveness',
    title: 'Prova de vida passiva',
    description: 'Utiliza IA para provar se uma pessoa está viva',
    icon: 'Criminal',
  },
  {
    enabled: false,
    name: 'ActiveFaceLiveness',
    title: 'Prova de vida ativa',
    description: 'Utiliza de movimentos faciais para provar se uma pessoa está viva',
    icon: 'Criminal',
  },
  {
    enabled: false,
    name: 'DeviceAuthenticator',
    title: 'Autenticação do dispositivo',
    description:
      'Informa dados importantes sobre o dispositivo de seu cliente para mapear fraudadores',
    icon: 'Admin',
  },
  {
    enabled: false,
    name: 'AddressCheck',
    title: 'Comprovação de endereço',
    description: 'Utiliza a localização do dispositivo para validar o endereço informado',
    icon: 'Map',
  },
  {
    enabled: false,
    name: 'Security',
    title: 'Segurança',
    description: ' ',
    icon: 'Admin',
  },
  {
    enabled: false,
    name: 'DocumentOcr',
    title: 'OCR de documento',
    description: ' ',
    icon: 'Match',
  },
];

export const findMobileProduct = (name) => {
  const filtered = mobileProducts.filter((mp) => mp.name === name);

  return filtered.length ? filtered[0] : {};
};

export default mobileProducts;
