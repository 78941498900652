import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Modal, Typography, Result, Tooltip } from 'antd';
import { useFetch } from 'services/hooks';
import { useExecution } from 'hooks/execution';

import {
  CheckD,
  Close,
  Link as IconLink,
  News,
  DoubleArrow,
} from '@combateafraude/icons/general';
// import { Person } from '@combateafraude/icons/users';

import SquaredButton from 'components/Button/SquaredButton';

const { Title } = Typography;

const ReprocessModal = (status = null) => {
  const { tenantId, reportId, executionId } = useParams();
  const { loading: loadingCPF, error: errorCPF, get: getCPF } = useFetch();
  const { loading: loadingCAF, error: errorCAF, get: getCAF } = useFetch();
  const { loading: loadingRF, error: errorRF, get: getRF } = useFetch();
  const {
    loading: loadingATT_BACKOFFICE,
    error: errorATT_BACKOFFICE,
    get: getATT_BACKOFFICE,
  } = useFetch();
  const { loading: loadingCUSTOM, error: errorCUSTOM, get: getCUSTOM } = useFetch();
  const { loading: loadingWEBHOOK, error: errorWEBHOOK, get: getWEBHOOK } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [data, setData] = useState({});

  const { reloadData, executionData } = useExecution();

  const openModal = useCallback((props) => {
    setModalVisible(true);
    setData(props);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    // se não fizer isso, aparece o modal sendo reinicializado ao fechar
    setTimeout(() => {
      setRequestFinished(false);
    }, 250);
  }, []);

  // garante que o botão ATT_RF só apareça se houver alguma fonte que possa ser reprocessada
  const forceRetrySources = ['pjData', 'pfData', 'cpf'];
  const [someSourceToRetry, setSomeSourceToRetry] = useState(false);

  useEffect(() => {
    if (!executionData) return;

    setSomeSourceToRetry(
      Object.keys(executionData?.executions?.sections || {}).some((source) =>
        forceRetrySources.includes(source)
      ) || false
    );
  }, [executionData]);

  const handleReprocess = useCallback(
    async (type) => {
      const url = `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/retry`;

      if (type === 'CAF') {
        await getCAF({
          url,
          config: {
            params: {
              _executionName: '_combate_a_fraude',
            },
          },
        });
      } else if (type === 'CPF') {
        await getCPF({
          url,
          config: {
            params: {
              _executionName: '_retry_receita_cpf',
              _birthDate: data.birthDate,
            },
          },
        });
        setTimeout(() => reloadData(false), 1000);
      } else if (type === 'CUSTOM_DATA') {
        await getCUSTOM({
          url,
          config: {
            params: {
              _executionName: '_custom_data',
            },
          },
        });
      } else if (type === 'ATT_BACKOFFICE') {
        await getATT_BACKOFFICE({
          url,
          config: {
            params: {
              _service: 'backoffice',
            },
          },
        });
      } else if (type === 'WEBHOOK') {
        await getWEBHOOK({
          url,
          config: {
            params: {
              _executionName: '_call_webhook',
              _status: status,
              _type: 'status_updated',
            },
          },
        });
        setTimeout(() => reloadData(false), 1000);
      } else if (type === 'ATT_RF') {
        await getRF({
          url,
          config: {
            params: {
              _executionName: '_force_retry_rf',
            },
          },
        });
        setTimeout(() => reloadData(false), 1000);
      }

      setRequestFinished(true);
    },
    [
      tenantId,
      reportId,
      executionId,
      getCAF,
      getCPF,
      data,
      reloadData,
      getCUSTOM,
      getATT_BACKOFFICE,
      getWEBHOOK,
      getRF,
      status,
    ]
  );

  const disabled = useMemo(() => {
    return (
      loadingCAF ||
      loadingCUSTOM ||
      loadingWEBHOOK ||
      loadingATT_BACKOFFICE ||
      loadingCPF ||
      loadingRF
    );
  }, [
    loadingCAF,
    loadingCUSTOM,
    loadingWEBHOOK,
    loadingATT_BACKOFFICE,
    loadingCPF,
    loadingRF,
  ]);

  const reprocessModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={800}
      >
        {requestFinished ? (
          <Result
            status={
              !errorCPF &&
              !errorCAF &&
              !errorCUSTOM &&
              !errorWEBHOOK &&
              !errorATT_BACKOFFICE &&
              !errorRF
                ? 'success'
                : 'error'
            }
            title={
              !errorCPF &&
              !errorCAF &&
              !errorCUSTOM &&
              !errorWEBHOOK &&
              !errorATT_BACKOFFICE &&
              !errorRF
                ? 'Reprocessamento agendado com sucesso.'
                : 'Houve um problema ao agendar o reprocessamento.'
            }
          />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <Title className="text-center modal-title pdd-horizon-20">
                  Selecione a forma que deseja reprocessar a execução:
                </Title>
              </Col>
            </Row>
            <Row className="flex center pdd-vertical-20">
              <Col span={6} className="flex end-center">
                <Tooltip title="Reprocessa fontes relacionadas a Receita Federal (cpf, pfData, pjData)">
                  <SquaredButton
                    disabled={disabled || !someSourceToRetry}
                    loading={loadingRF}
                    onClick={() => handleReprocess('ATT_RF')}
                  >
                    <div>
                      <DoubleArrow width={40} height={40} />
                    </div>
                    Atualizar CPF ou CNPJ na Receita Federal
                  </SquaredButton>
                </Tooltip>
              </Col>
              {/* <Col span={6} className="flex end-center">
                <SquaredButton
                  disabled={disabled}
                  loading={loadingCPF}
                  onClick={() => handleReprocess('CPF')}
                >
                  <div>
                    <Person width={40} height={40} />
                  </div>
                  Apenas CPF
                </SquaredButton>
              </Col> */}
              <Col span={6} className="flex end-center">
                <Tooltip title="Reprocessa serviços que finalizaram com erro">
                  <SquaredButton
                    disabled={disabled}
                    loading={loadingCUSTOM}
                    onClick={() => handleReprocess('CUSTOM_DATA')}
                  >
                    <div>
                      <News width={40} height={40} />
                    </div>
                    Custom Data
                  </SquaredButton>
                </Tooltip>
              </Col>
              {/* <Col span={6} className="flex start-center">
                <SquaredButton
                  disabled={disabled}
                  loading={loadingCAF}
                  onClick={() => handleReprocess('CAF')}
                >
                  <Combateafraude />
                  Combate à Fraude
                </SquaredButton>
              </Col> */}
            </Row>
            <Row className="flex center pdd-btm-20">
              <Col span={6} className="flex end-center">
                <Tooltip title="Reenvia o Webhook da execução">
                  <SquaredButton
                    disabled={disabled}
                    loading={loadingWEBHOOK}
                    onClick={() => handleReprocess('WEBHOOK')}
                  >
                    <div>
                      <IconLink width={40} height={40} />
                    </div>
                    Webhook
                  </SquaredButton>
                </Tooltip>
              </Col>
              <Col span={6} className="flex end-center">
                <Tooltip title="Atualiza a execução nas filas de Backoffice">
                  <SquaredButton
                    disabled={disabled}
                    loading={loadingATT_BACKOFFICE}
                    onClick={() => handleReprocess('ATT_BACKOFFICE')}
                  >
                    <div>
                      <CheckD width={40} height={40} />
                    </div>
                    Atualizar execução no Backoffice
                  </SquaredButton>
                </Tooltip>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      requestFinished,
      errorCPF,
      errorCAF,
      errorCUSTOM,
      errorWEBHOOK,
      errorATT_BACKOFFICE,
      loadingCAF,
      loadingCUSTOM,
      loadingWEBHOOK,
      loadingATT_BACKOFFICE,
      loadingCPF,
      loadingRF,
      errorRF,
      handleReprocess,
    ]
  );

  return { openModal, reprocessModal };
};

export default ReprocessModal;
