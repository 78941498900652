import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import {
  Checkmark,
  Close,
  Trash,
  Upload as UploadIcon,
} from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';
import { getExtensionFile } from 'utils/getExtensionFile';
import Loader from 'components/Loader';

import './styles.less';

const { Dragger } = Upload;

const FileUpload = ({ title, file, onChangeFile, supportedFiles, isInvalid }) => {
  const { post } = useFetch();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const handleRemoveCurrentFile = useCallback(() => {
    onChangeFile(undefined);
  }, [onChangeFile]);

  const fileToArrayBuffer = useCallback(async (newFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(newFile);
    });
  }, []);

  const customRequest = useCallback(
    async (params) => {
      setErrorMessage(undefined);
      setLoading(true);
      setSelectedFile(params?.file);

      const { file: newFile, onSuccess, onError } = params;

      // verifica se o arquivo tem os formatos corretos
      if (!supportedFiles.includes(`.${getExtensionFile(newFile.name.toLowerCase())}`)) {
        let msg = 'Formato de arquivo não suportado.';

        if (supportedFiles?.length) {
          msg = `Selecione entre ${supportedFiles?.join(', ')}`;
        }

        setErrorMessage(msg);
        setLoading(false);

        return;
      }

      try {
        // Transforma em um buffer
        const data = await fileToArrayBuffer(newFile);

        // pega urls para mandar pro bucket temporário
        const { uploadUrl, getUrl } = await post({
          url: `/upload`,
        });

        // envia o arquivo para o bucket temporário
        await cleanApi.put(uploadUrl, data);

        onChangeFile(getUrl);

        onSuccess('Ok');
      } catch (err) {
        onError({ err });
        setErrorMessage('Falha ao armazenar o arquivo. Tente novamente.');
      } finally {
        setLoading(false);
      }
    },
    [fileToArrayBuffer, post, onChangeFile, supportedFiles]
  );

  const renderIcon = useMemo(() => {
    if (errorMessage) return <Close width={24} height={24} className="gx-text-danger" />;
    if (loading) return <Loader padding="3px" size="20px" strokeWidth={4} />;
    if (file) return <Checkmark width={24} height={24} className="gx-text-primary" />;

    return <UploadIcon width={24} height={24} />;
  }, [errorMessage, loading, file]);

  return (
    <>
      <Dragger
        accept={supportedFiles?.join(',')}
        showUploadList={false}
        disabled={file}
        customRequest={customRequest}
        listType="picture"
        className={`manual-upload-documents-dragger ${
          isInvalid && !loading ? 'invalid' : ''
        } `}
      >
        <div>
          <div className="icons">{renderIcon}</div>
          <strong>{title}</strong>
        </div>

        {!file && !loading ? (
          <span>
            Arraste aqui ou
            <strong> selecione</strong> (somente: {supportedFiles?.join(', ')};)
          </span>
        ) : (
          <div className="manual-upload-documents-file-info">
            {loading && <span className="max-lines-1">Carregando arquivo...</span>}

            {!loading && (
              <>
                <span className="max-lines-1">
                  {selectedFile?.name || 'Arquivo pronto'}
                </span>
                <button
                  type="button"
                  className="reset-btn"
                  onClick={handleRemoveCurrentFile}
                >
                  <Trash width={16} height={16} className="gx-text-danger" />
                </button>
              </>
            )}
          </div>
        )}
      </Dragger>

      {errorMessage && (
        <span className="manual-upload-documents-unsupported-file">{errorMessage}</span>
      )}
    </>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFile: PropTypes.func.isRequired,
  supportedFiles: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes?.bool,
};

FileUpload.defaultProps = {
  supportedFiles: [],
  isInvalid: false,
};

export default FileUpload;
