import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';

const HorizontalNav = () => {
  return (
    <Menu className="menu-container" mode="horizontal" selectedKeys={['dashboard']}>
      <Menu.Item key="dashboard">
        <NavLink to="/dashboard" className="nav-link" activeClassName="active">
          Visão geral
        </NavLink>
      </Menu.Item>
      <Menu.Item key="executions">
        <NavLink to="/executions" className="nav-link" activeClassName="active">
          Consultas
        </NavLink>
      </Menu.Item>
      <Menu.Item key="all-executions">
        <NavLink to="/all-executions" className="nav-link" activeClassName="active">
          Consultas Históricas
        </NavLink>
      </Menu.Item>
      <Menu.Item key="customers">
        <NavLink
          to="/customers?_order=1&_sort=fantasyName"
          className="nav-link"
          activeClassName="active"
        >
          Clientes
        </NavLink>
      </Menu.Item>
      <Menu.Item key="hub-accounts">
        <NavLink to="/hub-accounts" className="nav-link" activeClassName="active">
          HUB de Contas
        </NavLink>
      </Menu.Item>
      <Menu.Item key="onboarding-ext">
        <NavLink to="/onboarding-ext" className="nav-link" activeClassName="active">
          Banco de Faces
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="analytics">
        <NavLink to="/analytics/tracking" className="nav-link" activeClassName="active">
          Analytics
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="sources">
        <NavLink to="/sources/availability" className="nav-link" activeClassName="active">
          Fontes
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default HorizontalNav;
