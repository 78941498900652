import identitySVG from 'assets/images/identity.svg';
import managementSVG from 'assets/images/management.svg';
import onboardingSVG from 'assets/images/onboarding.svg';
import trustSVG from 'assets/images/trust.svg';

export const productsInfos = {
  MANAGEMENT: {
    title: 'Management',
    description: 'Gerencie seus produtos, regras de acesso e metodos de pagamento.',
    alwaysActive: true,
    key: 'management',
    icon: managementSVG,
  },
  TRUST: {
    title: 'Trust',
    description:
      'Verificação recorrente de antecedentes criminais, financeiros e outras fontes de dados',
    key: 'trust',
    icon: trustSVG,
  },
  IDENTITY: {
    title: 'Identity',
    description:
      'Gerenciamento autentificação de usuários que passaram por um fluxo de Onboarding.',
    key: 'identity',
    icon: identitySVG,
  },
  SDK: {
    title: 'SDK',
    description: 'Receba a documentação de seu cliente de forma simples e automática.',
    key: 'sdk',
    comingSoon: true,
    icon: onboardingSVG,
  },
  CAF: {
    title: 'Combate à fraude',
    description: 'Acesso ao painel da Combate à Fraude',
    key: 'caf',
    icon: onboardingSVG,
    alwaysActive: true,
  },
};
