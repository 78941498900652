import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import Cookies from 'js-cookie';
import { message } from 'antd';

import { Redirect } from 'react-router-dom';

import api from 'services/api';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const ID_TOKEN = isLocalhost
  ? `CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`
  : `__Secure-CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`;

const REFRESH_TOKEN = isLocalhost
  ? `CAF-Bifrost-${process.env.REACT_APP_ENV}_refresh_token`
  : `__Secure-CAF-Bifrost-${process.env.REACT_APP_ENV}_refresh_token`;

const COOKIE_OPTIONS = isLocalhost
  ? {
      expires: 30,
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
      expires: 30,
    };

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setLoggedUser] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const idToken = Cookies.get(ID_TOKEN);

    api.defaults.headers.Authorization = idToken;
  }, []);

  const saveCredentials = useCallback((idToken, refreshToken) => {
    Cookies.set(ID_TOKEN, idToken, COOKIE_OPTIONS);
    Cookies.set(REFRESH_TOKEN, refreshToken, COOKIE_OPTIONS);

    api.defaults.headers.Authorization = idToken;
  }, []);

  const isAuthenticated = useCallback(() => !!Cookies.get(ID_TOKEN), []);

  const signOut = useCallback(() => {
    Cookies.remove(ID_TOKEN, COOKIE_OPTIONS);
    Cookies.remove(REFRESH_TOKEN, COOKIE_OPTIONS);

    setLoggedUser();
  }, []);

  const getLoggedUser = useCallback(async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/users/me`
      );
      setLoggedUser(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        const userData = error?.response?.data?.docs;
        if (!userData) {
          message.error('Sessão expirada. Faça login novamente');
        }
      } else if (error?.response?.status === 404) {
        message.error(
          'Erro: Usuário não encontrado. Se persistir, procure o suporte do sistema.'
        );
      } else {
        message.error('Aconteceu um erro. Se persistir, procure o suporte do sistema.');
      }
    }

    setLoadingAuth(false);
  }, []);

  const hasPermissions = useCallback(
    (permissions) => {
      if (!user) return false;

      const { policyGroup = [] } = user;

      if (!permissions?.length > 0) {
        return false;
      }

      const authorized = permissions?.some((p) =>
        policyGroup?.products?.backoffice?.policies?.find(
          (polices) => polices?.name === p
        )
      );

      if (!authorized || !user.active) {
        return <Redirect to="/unauthorized" />;
      }

      return true;
    },
    [user]
  );

  const refreshUserToken = useCallback(async () => {
    const token = Cookies.get(REFRESH_TOKEN);

    if (token) {
      return api
        .post(`${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/auth/refresh`, {
          refreshToken: token,
        })
        .then((response) => {
          const { idToken, refreshToken } = response?.data || {};

          saveCredentials(idToken, refreshToken);

          return response.data;
        });
    }

    signOut();
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCredentials]);

  return (
    <AuthContext.Provider
      value={{
        hasPermissions,
        isAuthenticated,
        saveCredentials,
        getLoggedUser,
        signOut,
        user,
        loadingAuth,
        refreshUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
