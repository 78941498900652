import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, message, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import ReactJson from 'react-json-view';

import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';

const { Title } = Typography;

const Parameters = ({ ocrParameters }) => {
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData } = useExecution();
  const { loading, put } = useFetch();
  const [disabled, setDisabled] = useState(true);
  const [newParameters, setNewParameters] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!executionData) return;

    const { parameters } = executionData.executions || {};

    form.setFieldsValue({
      parameters,
    });
  }, [form, executionData]);

  const onEdit = useCallback(
    async (values) => {
      values.updated_src[values.name] = values.updated_src[values.name].toString(); // fix: avoid type change
      values.updated_src[values.name] = values.updated_src[values.name].replaceAll(
        '\n',
        ' '
      );
      setNewParameters(values.updated_src);
      if (disabled && values.existing_value !== values.new_value) {
        setDisabled(false);
      }
    },
    [setDisabled, disabled, setNewParameters]
  );

  const updateParameters = useCallback(async () => {
    const { parameters } = executionData?.executions || {};

    await put({
      url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}`,
      payload: {
        oldData: parameters,
        newData: { ...newParameters },
        execution: {
          parameters: { ...newParameters },
        },
      },
    });
    reloadData(false);
    message.success('Ação realizada com sucesso.');
    setDisabled(true);
  }, [put, newParameters, tenantId, reportId, executionId, reloadData, executionData]);

  return (
    <div id="parameters-component" className="flex-column flex-1 gx-h-100">
      {ocrParameters ? (
        <Title level={4} className="font-size-16">
          Parâmetros
        </Title>
      ) : (
        <PageTitle title="Parâmetros" subtitle="Parâmetros enviados na execução." />
      )}

      <Card className="gx-h-100 flex-column flex-1">
        {!_.isEmpty(executionData?.executions?.parameters) ? (
          <Row align="middle" justify="center">
            <Col span={24}>
              <ReactJson
                name={false}
                src={executionData?.executions?.parameters}
                iconStyle="triangle"
                indentWidth={2}
                collapsed={1}
                theme="shapeshifter:inverted"
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                onEdit={onEdit}
              />
              {/* <Form form={form} layout="vertical" autoComplete="off">
                {Object.keys(executionData?.executions?.parameters).map((p) => (
                  <Row align="middle" className="fd-row">
                    <Col span={24}>
                      <Form.Item name={['parameters', p]} label={p}>
                        <Input className="custom-input" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Form> */}
            </Col>
            <Button
              className="mrg-top-20 btn-custom btn-custom-primary"
              onClick={updateParameters}
              disabled={disabled}
              loading={loading}
            >
              Salvar
            </Button>
          </Row>
        ) : (
          <div className="flex-column center flex-1 gx-h-100">
            <EmptyMessage description="Nenhum parâmetro enviado." />
          </div>
        )}
      </Card>
    </div>
  );
};

Parameters.propTypes = {
  ocrParameters: PropTypes.bool,
};

Parameters.defaultProps = {
  ocrParameters: false,
};

export default Parameters;
