import React, { useMemo, useCallback, useState } from 'react';
import { Row, Col, Typography, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { useFetch } from 'services/hooks';
import { useExecution } from 'hooks/execution';
import { Close, WrongCircle, CheckCircle2 } from '@combateafraude/icons/general';
import Button from 'components/Button';

const { Title, Text } = Typography;

const SetPendingExecutionModal = () => {
  const { loading, post, error } = useFetch();
  const { tenantId, reportId, executionId } = useParams();

  const { reloadData: reloadExecutionData } = useExecution();
  const [modalVisible, setModalVisible] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);

  const openModal = useCallback(() => {
    setRequestFinished(false);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await post({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/update`,
        payload: {},
      });
    } catch (e) {
      // sillencer
    }
    setRequestFinished(true);
    reloadExecutionData(false);
  }, [post, tenantId, reportId, executionId, reloadExecutionData]);

  let resultContent = (
    <div className="text-center">
      <CheckCircle2 className="modal-icon success" />
      <Title className="text-center modal-title">Execução pendenciada com sucesso!</Title>
    </div>
  );

  if (error) {
    resultContent = (
      <div className="text-center">
        <WrongCircle className="modal-icon error" />
        <Title className="text-center modal-title">
          Houve um problema ao tentar pendenciar a execução.
        </Title>
      </div>
    );
  }
  const pendingExecutionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={
          <Close
            style={{ display: 'flex-center', verticalAlign: 'middle' }}
            width={24}
            height={24}
          />
        }
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={600}
      >
        {requestFinished ? (
          resultContent
        ) : (
          <>
            <Title className="text-left modal-title">Pendenciar execução</Title>
            <Text>Você realmente deseja pendenciar essa execução?</Text>
            <Row className="mrg-top-40">
              <Col span={24} className="text-right">
                <Button
                  type="link"
                  className="cancel-button"
                  onClick={closeModal}
                  disabled={loading}
                >
                  Cancelar
                </Button>
                <Button
                  className="btn-custom btn-custom-warning"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Pendenciar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    ),
    [closeModal, handleSubmit, modalVisible, requestFinished, loading, resultContent]
  );

  return { openModal, pendingExecutionModal };
};

export default SetPendingExecutionModal;
