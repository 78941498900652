import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, Typography, Switch, Modal } from 'antd';
import Button from 'components/Button';
import { useCustomer } from 'hooks/customer';
import TooltipInfo from 'components/TooltipInfo';
import LabelWithInformation from '../../LabelWithInformation';

const { Text, Title } = Typography;

const EditAdditionalInfo = ({ isEditing, form, loading }) => {
  const { customer } = useCustomer();
  const [testClientModalVisible, setTestClientModalVisible] = useState(false);
  const [hasPendingOcrDocumentscopy, setPendingOcrDocumentscopy] = useState(
    customer?.pendingOcrDocumentscopy
  );

  const renderPlaceholder = useCallback(
    (placeholder) => (!isEditing ? '' : placeholder),
    [isEditing]
  );

  const toggleHasPendingOcrDocumentscopy = useCallback(
    (value) => {
      setPendingOcrDocumentscopy(value);

      if (value) {
        form.setFieldsValue({
          resolvePendingAnalysisInternally: true,
        });
      }
    },
    [form]
  );

  const changeQaClientModal = useMemo(
    () => (
      <Modal
        id="enable-test-client"
        centered
        visible={testClientModalVisible}
        destroyOnClose
        width={500}
        closable={false}
        footer={false}
      >
        <div align="middle">
          <Title className="title">Ativar flag para teste de QA?</Title>
          <Text>
            Ativando essa flag, as execuções não cairão para o time de documentoscopia
          </Text>
        </div>
        <div align="right" className="actions">
          <Button
            type="link"
            className="cancel-btn"
            onClick={() => {
              form.setFieldsValue({
                wecheckTestEvaluation: false,
              });
              setTestClientModalVisible(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            className="btn-custom btn-custom-primary"
            onClick={() => setTestClientModalVisible(false)}
          >
            Ativar
          </Button>
        </div>
      </Modal>
    ),
    [testClientModalVisible, form]
  );

  return (
    <>
      {changeQaClientModal}
      <div className="mrg-top-25 mrg-btm-15">
        <Text className="text-bold text-dark font-size-20">Informações adicionais</Text>
      </div>

      <Row className="fd-row">
        <Col span={24}>
          <Form.Item name="additionalInfo" label="Observações">
            <Input.TextArea
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite as observações')}
              className={isEditing ? 'editing-highlight' : ''}
              rows={3}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="resolvePendingAnalysisInternally"
            valuePropName="checked"
            label={
              <LabelWithInformation
                label="Resolver Pendente Análise internamente?"
                title="Atenção"
                textInformation={
                  <>
                    Clientes marcados com esta opção terão os seus documentos <br />
                    em Pendente Análise resolvidos pela equipe do Suporte
                  </>
                }
              />
            }
          >
            <Switch
              checkedChildren="Sim"
              unCheckedChildren="Não"
              disabled={!isEditing || hasPendingOcrDocumentscopy || loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="pendingOcrDocumentscopy"
            valuePropName="checked"
            label={
              <LabelWithInformation
                title="Atenção"
                label="Resolver Pendente OCR na Documentoscopia?"
                textInformation={
                  <>
                    Clientes marcados com esta opção terão os seus documentos <br />
                    em Pendente OCR resolvidos pela Documentoscopia e Suporte
                  </>
                }
              />
            }
          >
            <Switch
              disabled={!isEditing || loading}
              checkedChildren="Sim"
              unCheckedChildren="Não"
              onChange={toggleHasPendingOcrDocumentscopy}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="acceptDocumentSpoofing"
            valuePropName="checked"
            label={
              <LabelWithInformation
                title="Atenção"
                label="Impede reprovação por Spoofing de Documentos?"
                textInformation={
                  <>
                    Quando esta opção estiver marcada, na documentoscopia os operadores
                    <br />
                    não poderão selecionar o motivo de reprovação por spoofing de
                    documentos
                  </>
                }
              />
            }
          >
            <Switch
              disabled={!isEditing || loading}
              checkedChildren="Sim"
              unCheckedChildren="Não"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['wecheckTestEvaluation']}
            valuePropName="checked"
            label={
              <>
                Cliente de teste QA
                <TooltipInfo
                  title="Execuções desse tipo não vão para o time de documentoscopia"
                  size={14}
                />
              </>
            }
          >
            <Switch
              onChange={(e) => {
                if (e) setTestClientModalVisible(true);
              }}
              disabled={!isEditing || loading}
              checkedChildren="Sim"
              unCheckedChildren="Não"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

EditAdditionalInfo.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  form: PropTypes.shape.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditAdditionalInfo;
