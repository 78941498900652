import React, { useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, message, Input, Checkbox } from 'antd';
import { Close } from '@combateafraude/icons/general';

import { useTimeline } from 'hooks/timeline';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

import './styles.less';
import { areValuesValid, clearTokenFields, setTestTokenFields } from '../utils/utils';

const IproovCreate = ({ refreshListRef }) => {
  const { tenantId } = useParams();
  const { closeModalMandatory } = useTimeline();
  const [form] = Form.useForm();

  const { post: createIproovToken, loading: loadingCreate } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [generateKeyButtonDisabled, setGenerateKeyButtonDisabled] = useState(true);
  const [isTokenTest, setIsTokenTest] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setGenerateKeyButtonDisabled(true);
    form.resetFields();
  }, [closeModalMandatory, refreshListRef]); // eslint-disable-line

  const openModal = useCallback(() => {
    setModalVisible(true);
    setIsTokenTest(true);
  }, []);

  const createToken = useCallback(
    async (payload) => {
      const newPayload = {
        ...payload,
        tokenType: payload.tokenType[0],
      };

      try {
        await createIproovToken({
          url: `/clients/${tenantId}/iproov-tokens`,
          payload: newPayload,
        });

        refreshListRef.current();
        closeModal();

        message.success('Nova chave gerada com sucesso.');
      } catch (err) {
        message.error('Houve um problema ao gerar nova chave.');
      }
    },
    [tenantId, closeModal, createIproovToken, refreshListRef]
  );

  const handleOnValuesChange = useCallback(() => {
    const values = form.getFieldsValue();
    delete values.description;
    delete values.tokenType;

    setGenerateKeyButtonDisabled(areValuesValid(values, 5));
  }, [form]);

  const testCredentials = useMemo(() => {
    const secret = process.env.REACT_APP_IPROOV_SECRET ?? '';
    const key = process.env.REACT_APP_IPROOV_API_KEY ?? '';

    return { secret, key };
  }, []);

  const handleCheckboxChange = useCallback(
    (checkedValues) => {
      if (checkedValues.length > 1) {
        const lastChecked = checkedValues[checkedValues.length - 1];
        form.setFieldsValue({ tokenType: [lastChecked] });
        if (lastChecked === 'test') {
          setIsTokenTest(true);
          setTestTokenFields(form, testCredentials);
        } else {
          setIsTokenTest(false);
          clearTokenFields(form);
          handleOnValuesChange();
        }
      } else {
        setIsTokenTest(true);
        form.setFieldsValue({
          tokenType: ['test'],
        });
        setTestTokenFields(form, testCredentials);
      }
    },
    [form, testCredentials, handleOnValuesChange]
  );

  const IproovCreateModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <div id="iproov-modal-component" className="no-mrg no-pdd">
          <PageTitle title="Novo token iProov" />

          <Form
            form={form}
            layout="vertical"
            className="mrg-top-5"
            onFinish={createToken}
            onValuesChange={handleOnValuesChange}
            initialValues={{
              tokenType: ['test'],
              apiSecret: testCredentials.secret,
              apiKey: testCredentials.key,
              spName: 'CAF_DEV',
              link: 'https://portal.iproov.com/service-providers/1372',
            }}
          >
            <Form.Item name="tokenType">
              <Checkbox.Group onChange={handleCheckboxChange} className="flex">
                <Checkbox value="test">Token de teste</Checkbox>
                <Checkbox value="production">Token de produção</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="name" label="Nome*" className="mrg-top-5">
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item name="link" label="Link*" className="mrg-top-15">
              <Input maxLength={150} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item name="spName" label="SP Name*" className="mrg-top-15">
              <Input maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item name="apiSecret" label="API Secret*" className="mrg-top-15">
              <Input.Password maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item name="apiKey" label="API Key*" className="mrg-top-15">
              <Input.Password maxLength={100} disabled={isTokenTest} />
            </Form.Item>
            <Form.Item name="description" label="Descrição" className="mrg-top-15">
              <Input maxLength={200} />
            </Form.Item>

            <div className="btn-container flex-end">
              <Button onClick={closeModal} className="btn">
                Cancelar
              </Button>
              <Button
                className="btn-custom btn-custom-primary"
                htmlType="submit"
                disabled={loadingCreate || generateKeyButtonDisabled}
                loading={loadingCreate}
              >
                {loadingCreate ? 'Salvando...' : 'Salvar'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      isTokenTest,
      form,
      createToken,
      loadingCreate,
      handleOnValuesChange,
      generateKeyButtonDisabled,
      handleCheckboxChange,
      testCredentials,
    ]
  );

  return { openModal, closeModal, IproovCreateModal };
};

export default IproovCreate;
