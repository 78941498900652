import React, { useCallback, useState } from 'react';
import { Row, Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Duplicate } from '@combateafraude/icons/general';
import InputMask from 'components/Form/InputMask';
import OcrFormItem from './OcrFormItem';

const TypeCTPS = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="pisPasep"
          label="PIS/Pasep"
          errorText={invalidParams.pisPasep}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="documentNumber"
          label="Número do documento"
          errorText={invalidParams.documentNumber}
          isImportant
          span={10}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="documentSeries"
          label="Série do documento"
          errorText={invalidParams.documentSeries}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="federativeUnit"
          label="Unidade federativa"
          errorText={invalidParams.federativeUnit}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem name="name" label="Nome" errorText={invalidParams.name} span={16}>
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="birthDate"
          label="Data de nascimento"
          errorText={invalidParams.birthDate}
          isImportant
          span={8}
        >
          <InputMask
            mask="99/99/9999"
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate
                  className="copy-input"
                  onClick={() => copyHandler('birthDate')}
                />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="motherName"
          label="Nome da mãe"
          errorText={invalidParams.motherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="fatherName"
          label="Nome do pai"
          errorText={invalidParams.fatherName}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="maritalStatus"
          label="Estado civil"
          errorText={invalidParams.maritalStatus}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="cpf"
          label="CPF"
          errorText={invalidParams.cpf}
          isImportant
          span={8}
        >
          <Input
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate className="copy-input" onClick={() => copyHandler('cpf')} />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="issueDate"
          label="Data de emissão"
          errorText={invalidParams.issueDate}
          isImportant
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="issuePlace"
          label="Local de emissão"
          errorText={invalidParams.issuePlace}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="birthPlace"
          label="Local de nascimento"
          errorText={invalidParams.birthPlace}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="referenceDocument"
          label="Documento de referência"
          errorText={invalidParams.referenceDocument}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypeCTPS.propTypes = {
  invalidParams: PropTypes.shape,
  onCopy: PropTypes.func.isRequired,
};

TypeCTPS.defaultProps = {
  invalidParams: {},
};

export default TypeCTPS;
