import React, { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Form, Dropdown } from 'antd';
import _ from 'lodash';

import { useCustomer } from 'hooks/customer';

import { useTable } from 'components/List';
import SearchInput from 'components/Form/SearchInput';
import { HiddenParagraph } from 'components/Text';

import { MoreVertical, Reorder } from '@combateafraude/icons/general';
import Wrapper from '../../wrapper';
import ListDropdown from '../components/ListDropdown';

const { Title, Text } = Typography;

const UserList = () => {
  const { tenantId } = useParams();
  const refreshListRef = useRef(() => {});
  const { customer } = useCustomer();

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex center row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'name',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
    },
    {
      key: 'email',
      title: (
        <div className="flex center row">
          <span className="column-title">E-mail principal</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'email',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'accessToken',
      title: 'Código de acesso',
      dataIndex: 'accessToken',
      render: (record) => <HiddenParagraph copyable hidden text={record} />,
    },
    // {
    //   key: 'cnpj',
    //   title: 'CNPJ',
    //   dataIndex: 'cnpj',
    //   sorter: {
    //     compare: () => 0,
    //     multiple: 3,
    //   },
    // },
    // {
    //   key: 'tenantId',
    //   title: 'ID',
    //   dataIndex: 'tenantId',
    //   sorter: {
    //     compare: () => 0,
    //     multiple: 4,
    //   },
    // },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown data={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { tableContent, setFilterData, refreshList } = useTable({
    columns,
    getParams: {
      url: '/clients',
      config: {
        params: {
          _users: true,
          _tenantId: tenantId,
        },
      },
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      {customer && (
        <>
          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} className="page-title">
              <Title className="title">Usuários do sistema</Title>
              <Text className="subtitle">Gerencie os usuários da base.</Text>
            </Col>
            <Col>
              <Form layout="inline" onValuesChange={handleFormChange}>
                <SearchInput
                  placeholder="Pesquise por nome ou e-mail..."
                  style={{ width: 300 }}
                />
              </Form>
            </Col>
          </Row>
          {tableContent}
        </>
      )}
    </Wrapper>
  );
};

export default UserList;
