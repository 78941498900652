import React, { useCallback, useState } from 'react';
import { Row, Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import InputMask from 'components/Form/InputMask';
import { Duplicate } from '@combateafraude/icons/general';
import OcrFormItem from './OcrFormItem';

const TypeCNH = ({ invalidParams, onCopy }) => {
  const [copyText, setCopyText] = useState('Copiar');

  const copyHandler = useCallback(
    (fieldName) => {
      onCopy(fieldName);
      setCopyText('Copiado');

      setTimeout(() => setCopyText('Copiar'), 1000);
    },
    [onCopy]
  );

  return (
    <>
      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="name"
          label="Nome completo"
          errorText={invalidParams.name}
          span={16}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem name="cpf" label="CPF" errorText={invalidParams.cpf} span={8}>
          <Input
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate className="copy-input" onClick={() => copyHandler('cpf')} />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="motherName"
          label="Nome da mãe"
          errorText={invalidParams.motherName}
          span={12}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem name="fatherName" label="Nome do pai" span={12}>
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="expirationDate"
          label="Validade"
          errorText={invalidParams.expirationDate}
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="registrationNumber"
          label="Nº do registro"
          errorText={invalidParams.registrationNumber}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="firstDriverLicenseDate"
          label="Primeira habilitação"
          errorText={invalidParams.firstDriverLicenseDate}
          span={8}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="securityNumber"
          label="Nº de segurança"
          errorText={invalidParams.securityNumber}
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="cnhIssueDate"
          label="Data de emissão"
          errorText={invalidParams.cnhIssueDate}
          isImportant
          span={6}
        >
          <InputMask mask="99/99/9999" className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="cnhPlaceOfIssue"
          label="Local de emissão"
          errorText={invalidParams.cnhPlaceOfIssue}
          isImportant
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="cnhIssueState"
          label="UF de emissão"
          errorText={invalidParams.cnhIssueState}
          isImportant
          span={6}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="birthDate"
          label="Data de nascimento"
          errorText={invalidParams.birthDate}
          span={8}
        >
          <InputMask
            mask="99/99/9999"
            className="custom-input"
            suffix={
              <Tooltip title={copyText}>
                <Duplicate
                  className="copy-input"
                  onClick={() => copyHandler('birthDate')}
                />
              </Tooltip>
            }
          />
        </OcrFormItem>

        <OcrFormItem
          name="mirrorNumber"
          label="Nº do espelho"
          errorText={invalidParams.mirrorNumber}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="renachNumber"
          label="Nº do renach"
          errorText={invalidParams.renachNumber}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="notes"
          label="Observações"
          errorText={invalidParams.notes}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
        <OcrFormItem
          name="rg"
          label="RG"
          isImportant
          errorText={invalidParams.rg}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>

        <OcrFormItem
          name="rgIssuingAuthority"
          label="Órgão emissor do RG"
          isImportant
          errorText={invalidParams.rgIssuingAuthority}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>

      <Row align="middle" className="fd-row">
        <OcrFormItem
          name="category"
          label="Categoria da CNH"
          errorText={invalidParams.category}
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
        <OcrFormItem
          name="rgIssueState"
          label="UF de emissão do RG"
          errorText={invalidParams.rgIssueState}
          isImportant
          span={8}
        >
          <Input className="custom-input" />
        </OcrFormItem>
      </Row>
    </>
  );
};

TypeCNH.propTypes = {
  invalidParams: PropTypes.shape.isRequired,
  onCopy: PropTypes.func.isRequired,
};
export default TypeCNH;
