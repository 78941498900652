import React, { useState } from 'react';

import Wrapper from 'pages/private/wrapper';

import ExecutionList from './ExecutionList';
import ExecutionFilter from './ExecutionFilter';

import './styles.less';

const Executions = () => {
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [errorFilter, setErrorFilter] = useState(false);

  return (
    <Wrapper id="executions-component">
      <div className="gx-flex-row">
        <div id="filter" className="hide-scroll">
          <ExecutionFilter
            isLoadingList={isLoadingList}
            setErrorFilter={setErrorFilter}
          />
        </div>

        <div id="list">
          <ExecutionList setIsLoadingList={setIsLoadingList} errorFilter={errorFilter} />
        </div>
      </div>
    </Wrapper>
  );
};

export default Executions;
