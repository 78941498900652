import React, { useCallback, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Dropdown, Form, Row, Select, Tag, Typography } from 'antd';
import Button from 'components/Button';

import _ from 'lodash';
import { useQueryParams, StringParam } from 'use-query-params';
import { toFormatDate } from 'utils/formatters';

import SearchInput from 'components/Form/SearchInput';
import { useTable } from 'components/List';
import {
  ArrowDown,
  Checkmark,
  Close,
  MoreVertical,
  Plus,
  Reorder,
} from '@combateafraude/icons/general';
import useCreateHubModal from '../Components/CreateHubModal';

import Wrapper from '../wrapper';

import './styles.less';
import ListDropdown from '../Components/ListDropdown';

const { Title, Text } = Typography;
const { Option } = Select;

const HubAccountsList = () => {
  const refreshListRef = useRef(() => {});

  const [query] = useQueryParams({
    _search: StringParam,
  });

  const columns = [
    {
      key: 'hubName',
      title: (
        <div className="flex row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'hubName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.hub?.hubName?.localeCompare(b?.hub?.hubName),
      render: (__, record) => (
        <NavLink to={`/hub-accounts/${record?.hub?.id}`}>
          <span className="flex align-center text-bold">{record?.hub?.hubName}</span>
        </NavLink>
      ),
    },
    {
      key: 'accountsCount',
      title: (
        <div className="flex row">
          <span className="column-title">Tenants vinculados</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'accountsCount',
      sorter: (a, b) => a?.accountsCount - b?.accountsCount,
      render: (__, record) => <span>{record.accountsCount}</span>,
    },
    {
      key: 'createdAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Data de criação</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'createdAt',
      sorter: (a, b) => {
        const dateA = new Date(a?.hub?.createdAt);
        const dateB = new Date(b?.hub?.createdAt);
        return dateA - dateB;
      },
      render: (__, record) => {
        return <span>{toFormatDate(record?.hub?.createdAt)}</span>;
      },
    },
    {
      key: 'status',
      title: (
        <div className="flex row">
          <span className="column-title">Status</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'status',
      sorter: (a, b) => {
        const statusA = a?.hub?.active ? 1 : 0;
        const statusB = b?.hub?.active ? 1 : 0;
        return statusA - statusB;
      },
      render: (__, record) => (
        <Tag color={record?.hub?.active ? 'green' : 'red'}>
          {record?.hub?.active ? (
            <Checkmark className="mrg-right-5" />
          ) : (
            <Close className="mrg-right-5" />
          )}
          {record?.hub?.active ? 'Ativo' : 'Inativo'}
        </Tag>
      ),
    },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown data={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomLeft"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { openHubModal, CreateHubModal } = useCreateHubModal();

  const { tableContent, setFilterData, refreshList, loading } = useTable({
    getParams: {
      url: '/hubs-auth',
      config: { params: {} },
    },
    queryParams: {
      _search: StringParam,
      _status: StringParam,
    },
    columns,
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        _search: values._search ? values._search.trim() : undefined,
        _active: values._status ? values._status.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    const handleHubCreated = () => {
      refreshList();
    };
    document.addEventListener('hubCreated', handleHubCreated);
    return () => {
      document.removeEventListener('hubCreated', handleHubCreated);
    };
  }, [refreshList]);

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper id="hub-accounts-list-wrapper-component">
      {CreateHubModal}
      <Row id="customer-list-component">
        <Col span={24}>
          <Row>
            <Col className="page-title">
              <Title className="title">HUB de Contas</Title>
              <Text className="subtitle">
                Gerencie as permissões e acessos de usuários às contas de acordo com seu
                perfil
              </Text>
            </Col>
          </Row>
          <Row className="pdd-horizon-15">
            <Col className="mrg-btm-50" flex={1}>
              <Form
                layout="inline"
                onValuesChange={handleFormChange}
                initialValues={{ _search: query?._search, _status: query?._status }}
              >
                <SearchInput
                  className="search-hub"
                  prefixEnabled={false}
                  suffixEnabled
                  placeholder="Pesquise por nome..."
                  style={{ width: 400 }}
                />
                <Form.Item name="_status" labelAlign="right" className="no-mrg-btm">
                  <Select
                    disabled={loading}
                    defaultValue=""
                    style={{ width: 155, fontWeight: 500 }}
                    className="text-dark"
                    suffixIcon={<ArrowDown />}
                  >
                    <Option value="">Mostrar todos</Option>
                    <Option value="true">Ativo</Option>
                    <Option value="false">Inativo</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col className="mrg-left-20">
              <Button
                className="btn-custom btn-custom-primary"
                onClick={openHubModal}
                disabled={loading}
              >
                <Plus width={20} height={20} className="mrg-right-5" />
                Adicionar novo HUB de contas
              </Button>
            </Col>
          </Row>
          {tableContent}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default HubAccountsList;
