import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Select } from 'antd';
import { ArrowDown } from '@combateafraude/icons/general';

import './styles.less';

const { Text } = Typography;

const months = [
  { value: 1, label: 'Janeiro' },
  { value: 2, label: 'Fevereiro' },
  { value: 3, label: 'Março' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Maio' },
  { value: 6, label: 'Junho' },
  { value: 7, label: 'Julho' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Setembro' },
  { value: 10, label: 'Outubro' },
  { value: 11, label: 'Novembro' },
  { value: 12, label: 'Dezembro' },
];

const MonthPicker = ({ value, onChange, ...rest }) => {
  return (
    <>
      <Text className="picker-text">Mês</Text>
      <Select
        value={value}
        className="month-picker"
        onChange={onChange}
        options={months}
        suffixIcon={<ArrowDown />}
        {...rest}
      />
    </>
  );
};

MonthPicker.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default MonthPicker;
