import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Login from 'pages/public/Login';

import CustomerList from 'pages/private/Customers/CustomerList';
import CustomerUpdate from 'pages/private/Customers/pages/Registration/RegistrationUpdate';
import CustomerUsers from 'pages/private/Customers/pages/Users/UserList';
import CustomerInvoices from 'pages/private/Customers/pages/Invoices/InvoiceList';
import CustomerReports from 'pages/private/Customers/pages/Reports/ReportList';
import CustomerProducts from 'pages/private/Customers/pages/Products';
import CustomerReportsCreate from 'pages/private/Customers/pages/Reports/ReportCreate';
import CustomerMobile from 'pages/private/Customers/pages/Mobile/MobileList';
import CustomerIproov from 'pages/private/Customers/pages/Iproov/IproovList';
import CustomerLinks from 'pages/private/Customers/pages/Links/LinksList';
import CustomersTemplates from 'pages/private/Customers/pages/Templates/TemplatesList';
import CustomersDenyList from 'pages/private/Customers/pages/DenyList';
import CustomerCustomPriceProducts from 'pages/private/Customers/pages/CustomPriceProducts';
// import CustomersWorkflows from 'pages/private/Customers/pages/Workflows/WorkflowsList';

import SharedFacesetList from 'pages/private/SharedFaceset/SharedFacesetList';
import TrackingList from 'pages/private/Analytics/pages/Tracking/TrackingList';
import EventsList from 'pages/private/Analytics/pages/Events/EventsList';

import Dashboard from 'pages/private/Dashboard';

import AllExecutions from 'pages/private/AllExecutions';

import Sources from 'pages/private/Sources';

import DatasourcesList from 'pages/private/Datasources/DatasourcesList';
import DatasourceEdit from 'pages/private/Datasources/DatasourceEdit';

import HubAccountsList from 'pages/private/HubAccounts/HubAccountsList';
import HubAccountsEdit from 'pages/private/HubAccounts/HubAccountsEdit';

import Unauthorized from 'pages/private/Unauthorized';
import ExecutionDetails from 'pages/private/Executions/ExecutionDetails';
import ExecutionDetailsDoubleCheckFaceAuthenticator from 'pages/private/Executions/ExecutionDetailsDoubleCheckFaceAuthenticator';
import ExecutionDetailsDoubleCheckFacematch from 'pages/private/Executions/ExecutionDetailsDoubleCheckFacematch';
import Executions from 'pages/private/Executions';

import Route from './Route';

const Routes = () => (
  <Switch>
    {/* Public routes */}
    <Route path="/login" exact component={Login} />

    {/* Private routes */}

    <Route
      path="/analytics/tracking"
      exact
      component={TrackingList}
      isPrivate
      permissions={['executions']}
    />
    <Route
      path="/analytics/events"
      exact
      component={EventsList}
      isPrivate
      permissions={['executions']}
    />
    <Route
      path="/onboarding-ext"
      exact
      component={SharedFacesetList}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/onboarding-ext/:documentId"
      exact
      component={SharedFacesetList}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers"
      exact
      component={CustomerList}
      isPrivate
      permissions={['clients']}
    />

    <Route
      path="/customers/:tenantId/registration"
      component={CustomerUpdate}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/users"
      component={CustomerUsers}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/invoices"
      component={CustomerInvoices}
      exact
      isPrivate
      permissions={['finances']}
    />
    <Route
      path="/customers/:tenantId/custom-price"
      component={CustomerCustomPriceProducts}
      isPrivate
      permissions={['finances']}
    />
    <Route
      path="/customers/:tenantId/reports"
      exact
      component={CustomerReports}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/reports/registration/:reportId?"
      component={CustomerReportsCreate}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/products"
      exact
      component={CustomerProducts}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/mobile"
      exact
      component={CustomerMobile}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/tokens-iproov"
      exact
      component={CustomerIproov}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/links"
      exact
      component={CustomerLinks}
      isPrivate
      permissions={['clients']}
    />
    <Route
      path="/customers/:tenantId/templates"
      exact
      component={CustomersTemplates}
      isPrivate
      permissions={['clients']}
    />

    {/* <Route
      path="/customers/:tenantId/workflows"
      exact
      component={CustomersWorkflows}
      isPrivate
      permissions={['backoffice_clientWorkflows']}
    /> */}

    <Route
      path="/customers/:tenantId/denylist"
      exact
      component={CustomersDenyList}
      isPrivate
      permissions={['clients']}
    />

    <Route path="/dashboard" exact component={Dashboard} isPrivate permissions={['bi']} />

    <Route
      path="/executions/details/user/:tenantId/report/:reportId/execution/:executionId"
      component={ExecutionDetails}
      exact
      isPrivate
      permissions={['executions']}
    />

    <Route
      path="/executions/details/id/:_id/double-check-face-authenticator"
      component={ExecutionDetailsDoubleCheckFaceAuthenticator}
      isPrivate
      permissions={['executions']}
    />

    <Route
      path="/executions/details/user/:tenantId/report/:reportId/execution/:executionId/double-check-facematch"
      exact
      component={ExecutionDetailsDoubleCheckFacematch}
      isPrivate
      permissions={['executions']}
    />

    <Route
      path="/sources/availability"
      exact
      component={Sources}
      isPrivate
      permissions={['executions']}
    />

    <Route
      path="/all-executions"
      exact
      component={AllExecutions}
      isPrivate
      permissions={['executions']}
    />
    <Route
      path="/executions"
      exact
      component={Executions}
      isPrivate
      permissions={['executions']}
    />

    <Route
      path="/datasources/:source"
      component={DatasourceEdit}
      exact
      isPrivate
      permissions={['datasources']}
    />

    <Route
      path="/datasources"
      component={DatasourcesList}
      exact
      isPrivate
      permissions={['datasources']}
    />

    <Route
      path="/hub-accounts"
      exact
      component={HubAccountsList}
      isPrivate
      permissions={['hub_accounts']}
    />

    <Route
      path="/hub-accounts/:id"
      exact
      component={HubAccountsEdit}
      isPrivate
      permissions={['hub_accounts']}
    />

    <Route path="/unauthorized" exact component={Unauthorized} isPrivate />

    <Redirect from="/customers/:customerId" to="/customers/:customerId/users" />
    <Redirect to="/dashboard" />
  </Switch>
);

export default Routes;
