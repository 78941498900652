/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Spin, Row, Col, Button, Alert } from 'antd';
import { useParams } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';

import Wrapper from 'pages/private/wrapper';

import { Edit } from '@combateafraude/icons/general';
import InformationBanner from './components/InformationBanner';

import ProcessedInfos from './components/ProcessedInfos';
import SentImages from './components/SentImages';

import './styles.less';

const Details = () => {
  const [operators, setOperators] = useState();
  const operatorsRef = useRef([]);

  const { tenantId, reportId, executionId } = useParams();
  const { executionData, loadingExecution, loadData, error } = useExecution();
  const { user } = useAuth();

  useEffect(() => {
    operatorsRef.current = operators;
  }, [operators]);

  useEffect(() => {
    try {
      const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);

      socket.addEventListener('open', () => {
        socket.send(
          JSON.stringify({
            action: 'subscribeAndPublish',
            topic: `backoffice-execution-${executionId}`,
            infoData: { executionId, name: user.name, userId: user.username },
          })
        );
      });

      socket.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);

        let newOperators = [];
        if (message.type === 'disconnect') {
          const disconnectedClient = message.content;

          newOperators = operatorsRef.current.filter(
            (operator) => operator.socketId !== disconnectedClient.id
          );
          setOperators(() => [...newOperators]);
        } else if (message.type !== 'error') {
          const dataByExecutionId = message.content.filter(
            (socketClient) =>
              socketClient.infoData?.executionId === executionId &&
              socketClient.topics.find((t) => t === `backoffice-execution-${executionId}`)
          );

          newOperators = dataByExecutionId
            .map((socketClient) => ({
              socketId: socketClient.id,
              executionId: socketClient.infoData.executionId,
              name: socketClient.infoData.name,
              userId: socketClient.infoData.userId,
              createdAt: socketClient.createdAt,
            }))
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

          setOperators(() => [...newOperators]);
        }
      });

      return () => {
        socket.close();
      };
    } catch {
      // SILENCER
      return () => {};
    }
  }, []); // eslint-disable-line

  const alertErrorExecutionLoad = useMemo(() => {
    return (
      <div id="alert-error-execution-load">
        <Alert
          message="Erro"
          showIcon
          description={`O seguinte erro ocorreu com essa execução: ${error?.message}`}
          type="error"
          action={
            <Button
              size="small"
              danger
              onClick={() => {
                window.location.reload();
              }}
            >
              Recarregar a página
            </Button>
          }
        />
      </div>
    );
  }, [error]);

  useEffect(() => {
    loadData({ tenantId, reportId, executionId });
  }, [loadData, tenantId, reportId, executionId]);

  const bannerData = useMemo(() => {
    if (!operators || operators.length === 0) return { hideBanner: true };

    const mainOperator = operators[0];

    if (mainOperator.userId === user.username) return { hideBanner: true };

    return {
      operator: mainOperator,
      hideBanner: false,
    };
  }, [operators]); // eslint-disable-line

  const formattedErrorStatusCode = error?.statusCode.toString();

  const hasExecutionError = ['500', '503', '401', '400'].includes(
    formattedErrorStatusCode
  );

  return (
    <>
      <InformationBanner
        icon={<Edit width={16} height={16} />}
        bannerData={bannerData}
        bannerType="socket-operator"
      />

      <Wrapper id="execution-wrapper-component">
        {loadingExecution && !executionData ? (
          <Spin className="flex center" />
        ) : (
          <>
            {hasExecutionError ? (
              alertErrorExecutionLoad
            ) : (
              <Row>
                <Col span={22}>
                  <SentImages />
                  <ProcessedInfos />
                </Col>
              </Row>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Details;
