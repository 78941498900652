import React from 'react';
import NumberFormat from 'react-number-format';

const MoneyInput = ({ ...rest }) => {
  const formatCurrencyByEnd = (value) => {
    if (!Number(value) && Number(value) !== 0) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: rest.currency || 'BRL',
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount}`;
  };

  return (
    <NumberFormat
      {...rest}
      allowNegative={false}
      displayType="input"
      format={formatCurrencyByEnd}
    />
  );
};

export default MoneyInput;
