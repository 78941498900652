const convertForTimestamp = (values) => {
  const startDate = values[0].utcOffset(0).set({ second: 0, millisecond: 0 }).valueOf();
  const endDate = values[1].utcOffset(0).set({ second: 0, millisecond: 0 }).valueOf();

  return {
    startDate,
    endDate,
  };
};

export default convertForTimestamp;
