import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

const MultipleInvoicesContext = createContext({});

const stateReducer = (state, action) => {
  if (action.type === 'RESET') return {};

  const currentState = state;

  if (action.type === 'ADD_CLIENT') {
    const { tenantId, fantasyName, cnpj } = action?.payload;
    return {
      ...state,
      [tenantId]: {
        fantasyName,
        cnpj,
        date: undefined,
        checked: true,
        status: 'GENERATING',
        link: '',
      },
    };
  }

  if (action.type === 'REMOVE_CLIENT') {
    delete currentState[action.payload];

    return { ...currentState };
  }

  if (action.type === 'ADD_MULTIPLE_CLIENTS') {
    const clients = action.payload;

    const newList = clients.reduce((clientsObject, client) => {
      return {
        ...clientsObject,
        [client.tenantId]: {
          fantasyName: client?.fantasyName,
          cnpj: client?.cnpj,
          date: undefined,
          checked: true,
          status: 'GENERATING',
          link: '',
        },
      };
    }, state);
    return { ...newList };
  }

  if (action.type === 'LIST_REFRESHED') {
    const clients = action.payload;

    let result = {};

    clients?.forEach((client) => {
      if (currentState[client?.tenantId])
        result = { ...result, [client?.tenantId]: currentState[client?.tenantId] };
    });

    return { ...result };
  }

  if (action.type === 'CHECK_CLIENT') {
    const { tenantId, checked } = action.payload;

    currentState[tenantId].checked = checked;

    return { ...currentState };
  }

  if (action.type === 'CHANGE_CLIENT_DATE') {
    const { tenantId, date } = action.payload;

    currentState[tenantId].date = date;

    return { ...currentState };
  }

  if (action.type === 'REMOVE_UNCHECKED') {
    const tenants = Object.keys(currentState);

    tenants.forEach((tenant) => {
      if (!currentState[tenant].checked) delete currentState[tenant];
    });

    return { ...currentState };
  }

  if (action.type === 'UPDATE_CLIENT_STATUS') {
    const { tenantId, status, link } = action.payload;

    if (Object.keys(currentState).length > 0) {
      currentState[tenantId].status = status;
      currentState[tenantId].link = link;
    }

    return { ...currentState };
  }

  return state;
};

const currentDate = new Date();

const MultipleInvoicesProvider = ({ children }) => {
  const [invoiceClients, invoiceClientsDispatcher] = useReducer(stateReducer, {});

  const [month, setMonth] = useState(
    currentDate.getMonth() === 0 ? 12 : currentDate.getMonth() + 1
  );
  const [year, setYear] = useState(currentDate.getFullYear());

  const resetInvoiceList = () => invoiceClientsDispatcher({ type: 'RESET' });

  const refreshInvoiceList = useCallback(
    (clients) => invoiceClientsDispatcher({ type: 'LIST_REFRESHED', payload: clients }),
    []
  );

  const updateInvoiceList = useCallback((client, checked) => {
    if (!client) return;

    if (!checked) {
      if (Array.isArray(client)) {
        resetInvoiceList();
        return;
      }
      invoiceClientsDispatcher({ type: 'REMOVE_CLIENT', payload: client.tenantId });
      return;
    }

    if (Array.isArray(client)) {
      invoiceClientsDispatcher({
        type: 'ADD_MULTIPLE_CLIENTS',
        payload: client,
      });
      return;
    }

    invoiceClientsDispatcher({ type: 'ADD_CLIENT', payload: client });
  }, []);

  const checkClient = useCallback(
    (tenantId, checked) =>
      invoiceClientsDispatcher({
        type: 'CHECK_CLIENT',
        payload: { tenantId, checked },
      }),
    []
  );

  const changeDate = useCallback(
    (tenantId, date) =>
      invoiceClientsDispatcher({
        type: 'CHANGE_CLIENT_DATE',
        payload: { tenantId, date },
      }),
    []
  );

  const removeUnchecked = useCallback(
    () =>
      invoiceClientsDispatcher({
        type: 'REMOVE_UNCHECKED',
      }),
    []
  );

  const updateStatus = useCallback(
    (tenantId, status, link) =>
      invoiceClientsDispatcher({
        type: 'UPDATE_CLIENT_STATUS',
        payload: { tenantId, status, link },
      }),
    []
  );

  const invoiceClientsTenants = useMemo(
    () => Object.keys(invoiceClients),
    [invoiceClients]
  );

  const hasClients = useMemo(
    () => invoiceClientsTenants.length > 0,
    [invoiceClientsTenants]
  );

  return (
    <MultipleInvoicesContext.Provider
      value={{
        invoiceClients,
        invoiceClientsTenants,
        hasClients,
        month,
        year,
        setMonth,
        setYear,
        updateInvoiceList,
        resetInvoiceList,
        refreshInvoiceList,
        checkClient,
        changeDate,
        removeUnchecked,
        updateStatus,
      }}
    >
      {children}
    </MultipleInvoicesContext.Provider>
  );
};

function useMultipleInvoices() {
  const context = useContext(MultipleInvoicesContext);

  if (!context) {
    throw new Error('useMultipleInvoices must be used within an MultipleInvoicesContext');
  }

  return context;
}

export { MultipleInvoicesProvider, useMultipleInvoices };
