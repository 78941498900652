import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Radio, Carousel, message, Tooltip } from 'antd';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import { toFormatPercentage, toFormatTwoDigitsNumber } from 'utils/formatters';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';
import Tag from 'components/Tag';
import { Eye } from '@combateafraude/icons/general';

const FacesetConfirmation = ({
  review,
  isPrivate = false,
  // setWhichComponentIsActive
}) => {
  const [form] = Form.useForm();
  const carouselRef = useRef();
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData } = useExecution();
  const { user } = useAuth();
  const { loading, post } = useFetch();

  const [sentImages, setSentImages] = useState([]);
  const [faces, setFaces] = useState([]);
  const [reviewMatch, setReviewMatch] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  useEffect(() => {
    if (!executionData) return;

    setSentImages(executionData.executions?.images);

    const sections = executionData.executions?.sections;
    const faceset = isPrivate ? sections?.privateFaceset : sections?.sharedFaceset;

    setFaces(faceset?.data?.faceMatches || []);
    setReviewMatch(() => {
      return !!faceset?.data?.suspect;
    });
  }, [executionData, isPrivate]);

  const onChangeReviewFace = useCallback(() => {
    setTimeout(() => {
      if (!isLastSlide) {
        carouselRef.current.next();
      }
    }, 500);
  }, [isLastSlide]);

  const displayedFaces = useMemo(() => {
    return (
      faces?.length > 0 &&
      faces?.map((face, index) => {
        return (
          <div key={face?.faceset?.image} className="text-center mrg-btm-20">
            <span className="text-dark">
              <span className="text-bold" />
            </span>
            <ZoomedImage src={face?.faceset?.image} label="Suspeito" />
            <Form.Item
              name={`${face?.face?.faceId}_index_${index}`}
              rules={[{ required: true }]}
              className="mrg-top-15 mrg-btm-10"
            >
              <Radio.Group onChange={onChangeReviewFace}>
                <Radio value="true" className="faceset-suspect-radio">
                  São a mesma pessoa?
                </Radio>
                <Radio value="false" className="faceset-suspect-radio">
                  Não são a mesma pessoa?
                </Radio>
              </Radio.Group>
            </Form.Item>
            <span className="text-dark">
              {/* <span>{face?.faceset?.description}</span> */}
              <span>
                Similaridade: {toFormatPercentage(face?.similarity, 'Indisponível', 1)}
              </span>
              {face?.faceset?.tenantId && (
                <div className="mrg-top-10">
                  <Link
                    to={`/executions/details/user/${face?.faceset?.tenantId}/report/${face?.faceset?.reportId}/execution/${face?.faceset?.executionId}`}
                  >
                    Execução
                  </Link>
                </div>
              )}
            </span>
          </div>
        );
      })
    );
  }, [faces, onChangeReviewFace]);

  const onSubmitReviewFace = useCallback(
    async (values) => {
      try {
        // const newDocuments = documents?.map((doc) => {
        //   delete doc?.url;
        //   return {
        //     ...doc,
        //     verified: status,
        //   };
        // });
        // await post({
        //   url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/VALID_DOCUMENTS`,
        //   payload: {
        //     action: 'true',
        //     documents: newDocuments,
        //     _id: user?._id,
        //     email: user?.email,
        //   },
        // });
        // message.success(`Os documentos foram ${status ? 'confirmados' : 'rejeitados'}.`);
        // reloadData(false);

        const reportedFaces = [];

        Object.keys(values).map((key) => {
          const parsedKey = key.split('_index_')[0];
          reportedFaces.push({
            faceId: parsedKey,
            verified: values[key] === 'true',
          });
          return false;
        });

        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/${
            isPrivate ? 'PRIVATE_FACESET' : 'SHARED_FACESET'
          }`,
          payload: {
            action: 'true',
            reportedFaces,
            _id: user?._id,
            email: user?.email,
          },
        });

        message.success('Documento reportado com sucesso!');
        reloadData(true);
      } catch (err) {
        message.error('Falha reportar o documentos.');
      }
    },
    [post, reloadData, tenantId, reportId, executionId, user, isPrivate]
  );

  const onFailedSubmitReviewFace = useCallback(({ values }) => {
    const failedReportedFaces = [];

    Object.keys(values).map((key) => {
      const indexKey = key.split('_index_')[1];
      if (values[key] === undefined) {
        failedReportedFaces.push(indexKey);
      }
      return false;
    });

    const toNavigate = failedReportedFaces.sort((a, b) => a - b)[0] || '0';

    carouselRef.current.goTo(toNavigate);
  }, []);

  const onChangeCarousel = useCallback(
    (_, currentSlide) => {
      if (currentSlide + 1 === faces?.length) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    },
    [faces]
  );

  return (
    <div id={`${isPrivate ? 'private' : 'shared'}-faceset-confirmation-component`}>
      <div className="flex between-center gx-w-100">
        <PageTitle
          title={`${review ? 'Confirmação de suspeito' : 'Ação requerida: confirmação de suspeito'} (banco ${isPrivate ? 'privado' : 'compartilhado'})`} // prettier-ignore
          subtitle={`${review ? 'Reavalie' : 'Avalie'} as imagens abaixo de acordo com o banco ${isPrivate ? 'privado' : 'compartilhado'} de suspeitos.`} // prettier-ignore
        />
        {review && (
          <Tag
            outlined
            className={`${reviewMatch ? 'ant-tag-error' : 'ant-tag-success'} font-size-14`} // prettier-ignore
          >
            {`Última avaliação: suspeita ${reviewMatch ? 'confirmada' : 'negada'}`}
            {faces?.length > 0 && (
              <Tooltip
                title={faces?.map((face, index) => (
                  <span className="display-block mrg-vertical-5">
                    Foto {toFormatTwoDigitsNumber(index + 1)}:{' '}
                    {face?.verified ? 'suspeita confirmada' : 'suspeita negada'}
                  </span>
                ))}
              >
                <Eye width={16} height={16} />
              </Tooltip>
            )}
          </Tag>
        )}
      </div>
      <Form
        form={form}
        onFinish={onSubmitReviewFace}
        onFinishFailed={onFailedSubmitReviewFace}
        layout="horizontal"
        labelAlign="left"
        autoComplete="off"
      >
        <Card>
          <Row align="start" justify="center" className="mrg-btm-35">
            <Col span={8}>
              <ZoomedImage src={sentImages?.front} label="Frente do documento" />
            </Col>
            <Col span={8}>
              <ZoomedImage src={sentImages?.selfie} label="Selfie" />
            </Col>
            {faces?.length > 0 && (
              <Col span={8}>
                <Carousel ref={carouselRef} beforeChange={onChangeCarousel}>
                  {displayedFaces}
                </Carousel>
              </Col>
            )}
          </Row>
          {(isLastSlide || faces?.length === 1) && (
            <Row className="flex center">
              <Col className="center space-between">
                <Button
                  type="danger"
                  htmlType="submit"
                  loading={loading}
                  className="mrg-btm-10"
                >
                  Reportar
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </Form>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('shared-faceset-confirmation-component')}
      /> */}
    </div>
  );
};

FacesetConfirmation.propTypes = {
  review: PropTypes.bool,
  isPrivate: PropTypes.bool,
  // setWhichComponentIsActive: PropTypes.func.isRequired,
};

FacesetConfirmation.defaultProps = {
  review: false,
  isPrivate: false,
};

export default FacesetConfirmation;
