import { message } from 'antd';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useFetch } from 'services/hooks';

const AccountProductsContext = createContext({});

const AccountProductsProvider = ({ children }) => {
  const [accountsProducts, setAccountProducts] = useState({});
  const [loading, setLoading] = useState(false);
  const { clearData, loading: loadingCustomer, get, patch } = useFetch();

  const getAccountProducts = useCallback(
    async ({ tenantId }) => {
      setLoading(true);
      setAccountProducts({});
      try {
        const response = await get({
          url: `/clients/${tenantId}/products`,
        });
        setAccountProducts(response?.docs);
      } catch (error) {
        clearData();
        // message.error('Ocorreu um erro ao carregar a lista de produtos');
      }
      setLoading(false);
    },
    [clearData, get]
  );

  const changeProductActivation = useCallback(
    async ({ tenantId, productKey, active }) => {
      try {
        await patch({
          url: `/clients/${tenantId}/products/${productKey}`,
          payload: { active },
        });
      } catch (error) {
        clearData();
        message.error('Ocorreu um erro ao realizar a ação');
      }

      await getAccountProducts({ tenantId });
    },
    [clearData, getAccountProducts, patch]
  );

  return (
    <AccountProductsContext.Provider
      value={{
        loadingCustomer,
        accountsProducts,
        getAccountProducts,
        changeProductActivation,
        clearData,
        loading,
      }}
    >
      {children}
    </AccountProductsContext.Provider>
  );
};

function useAccountProducts() {
  const context = useContext(AccountProductsContext);

  if (!context) {
    throw new Error('useAccountProducts must be used within an AccountProductsContext');
  }

  return context;
}

export { AccountProductsProvider, useAccountProducts };
