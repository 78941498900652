import React from 'react';
import IconSuccess from 'assets/images/icon-success.svg';
import { Typography } from 'antd';
import './styles.less';

const { Title } = Typography;

const SuccessStepModal = ({ pageTitle, content, children }) => {
  return (
    <div id="content-container-success-step">
      <img src={IconSuccess} alt="" className="mrg-btm-30" />
      <Title level={2}>{pageTitle}</Title>
      <div className="copy-link-container flex center">{content}</div>
      {children}
    </div>
  );
};

export default SuccessStepModal;
