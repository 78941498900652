import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Form, Input, Select } from 'antd';
import nifValidator from 'utils/nifValidator';
import { CNPJisValid } from 'utils/cnpjValidator';
import InputMask from 'components/Form/InputMask';

const { Text } = Typography;

const EditInvoice = ({ isEditing, setCurrencyUsed, loading }) => {
  const renderPlaceholder = useCallback(
    (placeholder) => (!isEditing ? '' : placeholder),
    [isEditing]
  );

  return (
    <>
      <div className="mrg-top-25 mrg-btm-15">
        <Text className="text-bold text-dark font-size-20">Cobrança</Text>
      </div>

      <Row className="fd-row">
        <Col span={24}>
          <Form.Item name="companyName" label="Razão social">
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite a razão social')}
              className={isEditing ? 'editing-highlight' : ''}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="fd-row">
        <Col span={8}>
          <Form.Item
            name="cnpj"
            label="CNPJ"
            rules={[
              {
                validator: CNPJisValid,
              },
            ]}
          >
            <InputMask
              mask="99.999.999/9999-99"
              allowClear
              placeholder={renderPlaceholder('Digite o CNPJ')}
              className={isEditing ? 'editing-highlight' : ''}
              disabled={!isEditing || loading}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="nif" label="NIF" rules={[{ validator: nifValidator }]}>
            <InputMask
              mask="999999999"
              className={isEditing ? 'text-dark editing-highlight' : 'text-dark'}
              placeholder={renderPlaceholder('Digite o NIF da empresa')}
              allowClear
              disabled={!isEditing || loading}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="phone" label="Telefone de contato">
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite o telefone')}
              className={isEditing ? 'editing-highlight' : ''}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="emailInvoice"
            label="E-mail de cobrança"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite o e-mail de cobrança')}
              className={isEditing ? 'editing-highlight' : ''}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="codeNibo" label="Código Nibo (Cliente)">
            <Input
              disabled={!isEditing || loading}
              allowClear
              placeholder={renderPlaceholder('Digite o código Nibo')}
              className={isEditing ? 'editing-highlight' : ''}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="fd-row">
        <Col span={12}>
          <Form.Item
            name="currency"
            label="Moeda de cobrança"
            rules={[{ required: true }]}
          >
            <Select
              disabled={!isEditing || loading}
              placeholder="Selecione"
              className={isEditing ? 'editing-highlight' : ''}
              onChange={(value) => setCurrencyUsed(value)}
              options={[
                {
                  value: 'BRL',
                  label: 'Real (BRL)',
                },
                {
                  value: 'USD',
                  label: 'Dólar (USD)',
                },
                {
                  value: 'EUR',
                  label: 'Euro (EUR)',
                },
                {
                  value: 'GBP',
                  label: 'Libra (GBP)',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

EditInvoice.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setCurrencyUsed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditInvoice;
