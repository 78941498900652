import React from 'react';
import { Alert } from 'antd';

const PendingFacematch = () => {
  return (
    <Alert
      message="Facematch pendente"
      description="Houve algum problema no processamento da consulta. Favor reprocessar."
      type="error"
    />
  );
};

export default PendingFacematch;
