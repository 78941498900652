import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMultipleInvoices } from 'hooks/useMultipleInvoices';
import { useFetch } from 'services/hooks';
// import axios from 'axios';
import PropTypes from 'prop-types';

import { Modal, Table, Tooltip, Typography } from 'antd';
import { LoadingOutlined as LoadingIcon } from '@ant-design/icons';
import Button from 'components/Button';

import {
  Close,
  Download2 as DownloadIcon,
  CircleFalse as FailedIcon,
} from '@combateafraude/icons/general';

import './styles.less';

const { Text, Title } = Typography;

const GenerateInvoicesModal = ({ modalVisible, onCloseModal }) => {
  const {
    month,
    year,
    invoiceClientsTenants,
    updateStatus,
    invoiceClients,
    resetInvoiceList,
  } = useMultipleInvoices();

  const { post: postInvoice } = useFetch();

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [closeModalVisible, setCloseModalVisible] = useState(false);

  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [generating, setGenerating] = useState(true);
  const [amountGenerated, setAmountGenerated] = useState(0);

  const closeModalHandler = useCallback(() => {
    setCancelModalVisible(false);
    setCloseModalVisible(false);
    setGenerating(false);
    resetInvoiceList();
    onCloseModal();
  }, [onCloseModal, resetInvoiceList]);

  const tableData = useMemo(() => {
    const data = invoiceClientsTenants.map((tenantId) => {
      return {
        fantasyName: invoiceClients[tenantId].fantasyName,
        date: invoiceClients[tenantId].date,
        cnpj: invoiceClients[tenantId].cnpj,
        status: invoiceClients[tenantId].status,
        link: invoiceClients[tenantId].link,
      };
    });

    return data;
  }, [invoiceClientsTenants, invoiceClients]);

  const getNextToGenerate = useCallback(() => {
    const tenantId = invoiceClientsTenants.find(
      (tenant) => invoiceClients[tenant]?.status === 'GENERATING'
    );

    if (!tenantId) return false;

    return { ...invoiceClients[tenantId], tenantId };
  }, [invoiceClientsTenants, invoiceClients]);

  const columns = [
    {
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      className: 'fantasy-col',
    },
    {
      dataIndex: 'cnpj',
      key: 'cnpj',
    },
    {
      dataIndex: 'icon',
      key: 'icon',
      className: 'status-col',
      render: (__, client) => {
        if (client?.status === 'SUCCESS')
          return (
            <Tooltip title="Abrir cobrança">
              <a href={client?.link} rel="noopener noreferrer" target="_blank">
                <DownloadIcon className="download-icon" />
              </a>
            </Tooltip>
          );

        if (client?.status === 'FAILED' || !generating)
          return (
            <Tooltip
              title={
                client.status === 'FAILED'
                  ? 'Erro ao gerar cobrança'
                  : 'Geração cancelada'
              }
            >
              <FailedIcon className="error-generating" />
            </Tooltip>
          );

        return <LoadingIcon className="generating" />;
      },
    },
  ];

  const cancelHandler = useCallback(() => {
    setGenerating(false);
    setCancelModalVisible(false);
  }, []);

  const generateInvoice = useCallback(async () => {
    if (!generating) return;

    setLoadingInvoice(true);

    const client = getNextToGenerate();

    if (!client) {
      setGenerating(false);
      return;
    }

    let status = 'SUCCESS';
    let link = '';

    try {
      const previewData = await postInvoice({
        url: '/invoices',
        payload: {
          tenantId: client?.tenantId,
          month,
          year,
          dueDate: client?.date,
          preview: true,
        },
      });

      const { items } = previewData;

      const usage = items?.map((item) => {
        return {
          reportId: item._id,
          templateId: item.template,
          description: item.name,
          quantity: item.totalExecutions,
          price: item.price,
          total: item.totalPrice,
          ignoreBilling: false,
        };
      });

      const invoice = await postInvoice({
        url: '/invoices',
        payload: {
          tenantId: client?.tenantId,
          month,
          year,
          dueDate: client?.date,
          preview: false,
          items: usage,
        },
      });

      link = `${invoice.body.secure_url}.pdf`;
      setAmountGenerated((state) => state + 1);
    } catch {
      status = 'FAILED';
    } finally {
      updateStatus(client?.tenantId, status, link);
      setLoadingInvoice(false);
    }
  }, [getNextToGenerate, month, year, generating, updateStatus, postInvoice]);

  useEffect(() => {
    if (loadingInvoice) return;

    generateInvoice();
  }, [loadingInvoice, invoiceClients, generateInvoice]);

  const openAllInvoices = useCallback(
    () =>
      invoiceClientsTenants.forEach((tenant) => {
        if (invoiceClients[tenant].link.trim() !== '')
          window.open(invoiceClients[tenant].link, '_blank');
      }),
    [invoiceClients, invoiceClientsTenants]
  );

  const closeModal = useMemo(
    () => (
      <Modal
        visible={cancelModalVisible || closeModalVisible}
        className="invoices-modal-component"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={() =>
          cancelModalVisible ? setCancelModalVisible(false) : setCloseModalVisible(false)
        }
        footer={null}
        destroyOnClose
        width={600}
      >
        <div>
          <Title className="title">
            {`Tem certeza que deseja ${cancelModalVisible ? 'cancelar' : 'sair'}?`}
          </Title>
          <Text className="subtitle">
            As cobranças já geradas não podem ser canceladas e estarão disponíveis na
            página de cobrança de cada cliente, enquanto aquelas que estão na fila não
            serão geradas.
          </Text>
        </div>

        <div className="actions">
          <Button
            onClick={() =>
              cancelModalVisible
                ? setCancelModalVisible(false)
                : setCloseModalVisible(false)
            }
            type="link"
          >
            Não
          </Button>
          <Button
            type="danger"
            className="btn-custom"
            onClick={cancelModalVisible ? cancelHandler : closeModalHandler}
          >
            {`Sim, quero ${cancelModalVisible ? 'cancelar' : 'sair'}`}
          </Button>
        </div>
      </Modal>
    ),
    [cancelModalVisible, closeModalVisible, closeModalHandler, cancelHandler]
  );

  const disabledOpenInvoices = useMemo(
    () =>
      invoiceClientsTenants.every((tenant) => invoiceClients[tenant].link.trim() === ''),
    [invoiceClientsTenants, invoiceClients]
  );
  return (
    <Modal
      visible={modalVisible}
      className="invoices-modal-component"
      closeIcon={<Close width={18} height={18} />}
      centered
      onCancel={() => setCloseModalVisible(true)}
      footer={null}
      destroyOnClose
      width={1000}
    >
      {closeModal}

      <div>
        <div className="header">
          <Title className="title">Geração de cobrança</Title>
          <Text className="subtitle">
            Quando todas as cobranças forem geradas você poderá fazer o download das
            faturas.
          </Text>
        </div>

        <div>
          <Table
            className="invoices-table hide-header"
            dataSource={tableData}
            pagination={false}
            columns={columns}
            rowKey="tenantId"
          />
        </div>
      </div>

      <div className="actions space-between">
        <Text>
          <b>{amountGenerated}</b> de <b>{tableData.length}</b> cobranças geradas.
        </Text>
        <span>
          <Button type="link" onClick={() => setCancelModalVisible(true)}>
            Cancelar
          </Button>

          {generating ? (
            <Button loading={generating} className="btn-custom btn-custom-primary">
              Gerando cobranças
            </Button>
          ) : (
            <Button
              onClick={openAllInvoices}
              disabled={disabledOpenInvoices}
              className="btn-custom btn-custom-primary"
            >
              {!disabledOpenInvoices && (
                <>
                  <DownloadIcon className="download" />
                  Abrir faturas
                </>
              )}
              {disabledOpenInvoices && 'Gerar cobrança'}
            </Button>
          )}
        </span>
      </div>
    </Modal>
  );
};

GenerateInvoicesModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default GenerateInvoicesModal;
