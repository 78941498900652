const toCurrency = (value, prefix, numOfDigits = 3) => {
  if (!value && value !== 0) return '';

  const formatted = parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: numOfDigits,
  });

  return prefix ? `${prefix} ${formatted}` : formatted;
};

const toNumber = (value) => {
  if (!value && value !== 0) return null;

  let formatted = `${value}`.replace(/R\$|[,|.]+/g, '').replace(/([0-9]{3})$/g, '.$1');
  formatted = parseFloat(formatted);

  if (`${value}`.length === 1) {
    formatted /= 100;
  }

  return formatted;
};

const toNumberTwoDigits = (value) => {
  if (!value && value !== 0) return null;

  let formatted = `${value}`
    .replace(/[R,U,S]*\$|£|€|[,|.]+/g, '')
    .replace(/([0-9]{2})$/g, '.$1');
  formatted = parseFloat(formatted);

  if (`${value}`.length === 1) {
    formatted /= 100;
  }

  return formatted;
};

const numberToFormattedString = (value) => {
  if (!value) return 'R$ 0,00';
  return `R$ ${value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const toCurrencyTwoDigits = (value, prefix) => {
  if (!value && value !== 0) return '';

  const formatted = parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });

  return prefix ? `${prefix} ${formatted}` : formatted;
};

const getCurrencySign = (currency) => {
  if (currency === 'USD') return 'US$';
  if (currency === 'GBP') return '£';
  if (currency === 'EUR') return '€';

  return 'R$';
};

export default {
  toCurrency,
  toNumber,
  toNumberTwoDigits,
  numberToFormattedString,
  toCurrencyTwoDigits,
  getCurrencySign,
};
