const TAGS = {
  APROVADO: {
    color: 'success',
    title: () => 'APROVADO',
    icon: 'CircleTrue',
  },
  PENDENTE: {
    color: 'purple',
    title: () => 'DEPENDE DE ANÁLISE MANUAL',
    icon: 'CircleHold',
  },
  'PENDENTE OCR': {
    color: 'warning',
    title: () => 'DEPENDE DE CORREÇÃO MANUAL',
    icon: 'CircleHold',
  },
  PROCESSANDO: {
    color: 'processing',
    title: () => 'PROCESSANDO',
    icon: 'Processing',
  },
  REPROVADO: {
    color: 'error',
    title: (doc) => (doc.fraud ? 'REPROVADO FRAUDE' : 'REPROVADO'),
    icon: 'CircleFalse',
  },
};

export const TAGS_ENGLISH = {
  APPROVED: {
    color: 'success',
    title: () => 'APROVADO',
    icon: 'CircleTrue',
  },
  PENDING: {
    color: 'warning',
    title: () => 'DEPENDE DE ANÁLISE MANUAL',
    icon: 'CircleHold',
  },
  'PENDING OCR': {
    color: 'warning',
    title: () => 'DEPENDE DE CORREÇÃO MANUAL',
    icon: 'CircleHold',
  },
  PENDING_OCR: {
    color: 'warning',
    title: () => 'DEPENDE DE CORREÇÃO MANUAL',
    icon: 'CircleHold',
  },
  PROCESSING: {
    color: 'processing',
    title: () => 'PROCESSANDO',
    icon: 'Processing',
  },
  REPROVE: {
    color: 'error',
    title: (doc) => (doc.fraud ? 'REPROVADO FRAUDE' : 'REPROVADO'),
    icon: 'CircleFalse',
  },
  REPROVED: {
    color: 'error',
    title: () => 'REPROVADO',
    icon: 'CircleFalse',
  },
  DOCUMENTSCOPY: {
    color: 'processing',
    title: () => 'DOCUMENTOSCOPIA',
    icon: 'CircleHold',
  },
  COMBATE_A_FRAUDE: {
    color: 'processing',
    title: () => 'COMBATE À FRAUDE',
    icon: 'CircleHold',
  },
};

export default TAGS;
