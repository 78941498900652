import React, { useCallback, useMemo, createElement } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Divider } from 'antd';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';
import * as iconsSecurity from '@combateafraude/icons/security';

import Card from 'components/Card';

import CustomDataCardRow from './CustomDataCardRow';
import customDataCategories from '../utils/customDataCategories.json';

const { Title } = Typography;

const CustomDataCard = ({ customData, formRef }) => {
  const getDocsDeprecated = useCallback(
    (type) => {
      const customDataValues = formRef.getFieldValue('customData');
      let docs = customData?.docs?.[type] || [];
      if (customData?.category?.title === customDataCategories.deprecated.title) {
        const newDocs = docs.filter((doc) => customDataValues?.indexOf(doc.source) > -1);
        docs = newDocs;
      }

      return docs;
    },
    [customData, formRef]
  );
  const docsPf = useMemo(() => getDocsDeprecated('pfDocs'), [getDocsDeprecated]);
  const docsPj = useMemo(() => getDocsDeprecated('pjDocs'), [getDocsDeprecated]);

  const Icons = Object.assign(
    iconsGeneral || {},
    iconsUsers || {},
    iconsFace || {},
    iconsSecurity || {}
  );

  const renderType = (docs, title) => {
    return (
      docs?.length > 0 && (
        <>
          <Divider orientation="left">{title}</Divider>
          {docs.map((cd) => (
            <CustomDataCardRow customData={cd} formRef={formRef} />
          ))}
        </>
      )
    );
  };

  return (
    (docsPf?.length > 0 || docsPj.length > 0) && (
      <Card>
        <Row>
          <Col className="card-header">
            <div>
              {Object.keys(Icons || {}).includes(customData?.category?.icon) &&
                createElement(Icons[customData.category.icon])}
            </div>

            <Title level={3}>{customData.category.title}</Title>
          </Col>
        </Row>
        {renderType(docsPf, 'Pessoa Física')}
        {renderType(docsPj, 'Pessoa Jurídica')}
      </Card>
    )
  );
};

CustomDataCard.propTypes = {
  customData: PropTypes.shape({
    category: PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
    }),
    docs: PropTypes.shape({
      pfDocs: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          title: PropTypes.string,
          price: PropTypes.number,
        })
      ),
      pjDocs: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          title: PropTypes.string,
          price: PropTypes.number,
        })
      ),
    }),
  }).isRequired,
};

export default CustomDataCard;
