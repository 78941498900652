import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import Button from 'components/Button';
import { useComponentList } from 'components/List';
import PageTitle from 'components/PageTitle';

// import CardAccessToken from './components/CardAccessToken';
import CardReport from './components/CardReport';
import CardUserConfigs from './components/CardUserConfigs';

import Wrapper from '../../wrapper';

import './styles.less';

const Reports = () => {
  const { tenantId } = useParams();

  const { componentList } = useComponentList({
    type: 'list',
    component: CardReport,
    getParams: {
      url: `/clients/${tenantId}/reports`,
      config: {
        params: {},
      },
    },
  });

  return (
    <Wrapper>
      <Row id="card-user-configs-component" align="middle">
        <Col span={24}>
          <CardUserConfigs />
        </Col>
      </Row>

      <Row align="middle" className="no-mrg">
        <Col span={16} className="page-title">
          <PageTitle
            title="Consultas utilizadas no app"
            subtitle="Selecione o tipo de consulta que você quer utilizar em seu app mobile."
          />
        </Col>
        <Col span={8} className="flex end">
          <NavLink to={`/customers/${tenantId}/reports/registration`}>
            <Button className="btn-custom-secondary">Nova consulta</Button>
          </NavLink>
        </Col>
      </Row>

      {componentList}
    </Wrapper>
  );
};

export default Reports;
