import { format as formatDateFns } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const toFormatDate = (date, _format, _default) => {
  try {
    if (!date) return _default || '-';
    return formatDateFns(new Date(date), _format || "dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR,
    });
  } catch (error) {
    return _default || '-';
  }
};

export const toFormatPercentage = (percentage, _default, _toFixed) => {
  if (!percentage || Number.isNaN(parseFloat(percentage))) return _default || '-';
  return `${parseFloat(percentage).toFixed(_toFixed || 2)}%`;
};

export const toMaskedCnpj = (value) => {
  if (!value) return '';
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export const toMaskedCpf = (value) => {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const toFormatTwoDigitsNumber = (value) => {
  if (!value) return '';
  const stringValue = value.toString();
  return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};

export const secondsToDay = (seconds) => {
  const parsedSeconds = parseInt(seconds, 10);
  const formatted = parsedSeconds / 86400;

  return formatted;
};
