import React, { useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { Row, Col, message } from 'antd';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';
import SquaredButton from 'components/Button/SquaredButton';
import { Checkmark, Close } from '@combateafraude/icons/general';

import useRejectExecutionModal from 'components/Header/components/ExecutionSubheader/components/RejectExecutionModal';

const LivenessPending = () => {
  const { tenantId, reportId, executionId } = useParams();
  const { post } = useFetch();
  const { executionData, reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();
  const [loadings, setLoadings] = useState({});

  const { openModal: openRejectExecutionModal, rejectExecutionModal } =
    useRejectExecutionModal(null, 18);

  const handleSubmit = useCallback(
    async (action) => {
      try {
        setLoadings((state) => ({ ...state, [action]: true }));
        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/LIVENESS`,
          payload: {
            action,
            _id: user?.id,
            email: user?.email,
          },
        });
        message.success('Ação realizada com sucesso.');
        reloadExecutionData(false);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      } finally {
        setLoadings((state) => ({ ...state, [action]: false }));
      }
    },
    [executionId, post, reloadExecutionData, reportId, tenantId, user]
  );

  return (
    <div id="liveness-component">
      {rejectExecutionModal}
      <PageTitle
        title="Ação requerida: dupla checagem de selfie (liveness)"
        subtitle="Avalie a vivacidade da pessoa na foto e selecione a alternativa que mais se enquadra."
      />
      <Card>
        <Row align="middle" justify="center">
          <Col className="document-col" span={11}>
            <ZoomedImage
              src={executionData?.executions?.images?.selfie}
              label="Selfie"
              height={300}
            />
          </Col>
          <Col span={13}>
            <div className="flex-column center">
              <div>
                <div className="flex-row">
                  <div className="mrg-btm-20 mrg-horizon-10">
                    <SquaredButton
                      disabled={loadings.REPROVE}
                      loading={loadings.APPROVE}
                      onClick={() => handleSubmit('APPROVE')}
                    >
                      <div>
                        <Checkmark width={40} height={40} />
                      </div>
                      Aprovar selfie
                    </SquaredButton>
                  </div>

                  <div className="mrg-btm-20 mrg-horizon-10">
                    <SquaredButton
                      disabled={loadings.APPROVE}
                      loading={loadings.REPROVE}
                      onClick={openRejectExecutionModal}
                      className="danger mrg-horizon-10"
                    >
                      <div>
                        <Close width={40} height={40} />
                      </div>
                      Reprovar documento
                    </SquaredButton>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('liveness-component')}
      /> */}
    </div>
  );
};

// Liveness.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default LivenessPending;
