import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import countries from 'i18n-iso-countries';
import ptLocale from 'i18n-iso-countries/langs/pt.json';

import { Checkmark, CircleFalse, ArrowDown } from '@combateafraude/icons/general';

const { Option } = Select;

countries.registerLocale(ptLocale);
const countriesObj = countries.getNames('pt', { select: 'official' });
const countriesArr = Object.entries(countriesObj).map(([key, value]) => ({
  label: value,
  value: key,
}));

const SelectCountry = ({ isMultiple, disabled, suffixIcon, ...rest }) => {
  return (
    <Select
      {...rest}
      mode={isMultiple ? 'multiple' : undefined}
      optionLabelProp="label"
      showSearch
      listHeight={320}
      dropdownAlign={{ offset: [0, 2] }}
      removeIcon={<CircleFalse />}
      menuItemSelectedIcon={<Checkmark />}
      suffixIcon={suffixIcon}
      disabled={disabled}
      optionFilterProp="label"
      filterOption={(input, option) => {
        return (
          option.label
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .indexOf(
              input
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ) >= 0
        );
      }}
    >
      {countriesArr
        ?.map((country) => (
          <Option key={country.value} value={country.value} label={country.label}>
            {country.label}
          </Option>
        ))
        .sort((a, b) => a.props.label.localeCompare(b.props.label))}
    </Select>
  );
};

SelectCountry.propTypes = {
  isMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  suffixIcon: PropTypes.node,
};

SelectCountry.defaultProps = {
  isMultiple: false,
  disabled: false,
  suffixIcon: <ArrowDown />,
};

export default SelectCountry;
