import React, { useMemo, useCallback } from 'react';
import { Row, Col, Spin, Typography, Breadcrumb, Modal, message, Tooltip } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link as LinkIcon, Trash } from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';
import { useCustomerConfigs } from 'hooks/customerConfigs';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';

import './styles.less';

const { Title } = Typography;

const CustomerSubheader = ({ ...rest }) => {
  const { tenantId } = useParams();
  const { customer, customerData, loadingCustomer, loadCustomer } = useCustomer();
  const { deleteCustomerExecutionLimit } = useCustomerConfigs();
  const { put } = useFetch();

  const activateCustomerModal = useCallback(() => {
    Modal.confirm({
      title: 'Você realmente deseja ativar esse cliente?',
      content:
        !customerData?.contract &&
        'Este cliente ainda não possui contrato assinado. Você tem certeza que quer colocar ele em produção?',
      okText: 'Sim, ativar',
      okType: 'primary',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await put({
            url: `/clients/${tenantId}`,
            payload: {
              verified: true,
              status: 'active',
            },
          });

          await deleteCustomerExecutionLimit({ tenantId });

          loadCustomer({ tenantId });

          message.success('Cliente ativado com sucesso!');
        } catch (err) {
          message.error(`Houve um erro ao ativar esse cliente.`);
        }
      },
    });
  }, [customerData, put, tenantId, deleteCustomerExecutionLimit, loadCustomer]);

  const deactivateCustomerModal = useCallback(() => {
    Modal.confirm({
      title: 'Você realmente deseja desativar esse cliente?',
      okText: 'Sim, desativar',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await put({
            url: `/clients/${tenantId}`,
            payload: {
              verified: false,
            },
          });

          loadCustomer({ tenantId });

          message.success('Cliente desativado com sucesso!');
        } catch (err) {
          message.error(`Houve um erro ao desativar esse cliente.`);
        }
      },
    });
  }, [put, loadCustomer, tenantId]);

  const onCopyTenantID = useCallback(() => {
    message.info('Copiado!');
  }, []);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/customers?_order=1&_sort=fantasyName" className="gx-link">
            Clientes
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detalhamento</Breadcrumb.Item>
      </Breadcrumb>
    ),
    []
  );

  return (
    (customer || loadingCustomer) && (
      <div id="customer-subheader-component" {...rest}>
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <Row align="middle" className="no-mrg">
              <Col flex="auto">
                {standardBreadcrumbs}
                <Title className="title">
                  {!customer ? (
                    <Spin />
                  ) : (
                    <>
                      <p className="no-mrg">
                        {customer?.fantasyName || customer?.companyName || 'Cliente'}
                      </p>
                      <strong className="display-block mrg-right-10 mrg-top-5 font-size-15">
                        ID: {tenantId}
                        <Tooltip title="Clique para copiar">
                          <CopyToClipboard text={tenantId} onCopy={onCopyTenantID}>
                            <button type="button">
                              <LinkIcon width={20} height={20} />
                            </button>
                          </CopyToClipboard>
                        </Tooltip>
                      </strong>
                    </>
                  )}
                </Title>
              </Col>
              {customer &&
                (!customer?.verified ? (
                  <Col>
                    <Button
                      className="btn-custom btn-custom-primary"
                      disabled={loadingCustomer}
                      onClick={activateCustomerModal}
                    >
                      Ir para produção
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      className="gx-text-danger delete-button"
                      type="link"
                      disabled={loadingCustomer}
                      onClick={deactivateCustomerModal}
                    >
                      <Trash className="trash" />
                      Remover de produção
                    </Button>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomerSubheader;
