import React, { useMemo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';
import {
  Close,
  CheckCircle2,
  WrongCircle,
  ArrowDown,
} from '@combateafraude/icons/general';
import Button from 'components/Button';
import rejectReasonData from 'utils/data/rejectReasonData.json';
import rejectionsFraudData from 'utils/data/rejectionsFraudData.json';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Select,
  Checkbox,
  List,
  Tag,
  Tooltip,
  Alert,
} from 'antd';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const RejectExecutionModal = (type = null, idReason = null, hasTscopy = false) => {
  const [form] = Form.useForm();
  const { loading, post, error } = useFetch();
  const { tenantId, reportId, executionId } = useParams();
  const { reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [selectFraud, setSelectFraud] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectFraudValues, setSelectFraudValues] = useState([]);

  const openModal = useCallback(() => {
    setRequestFinished(false);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectFraudValues([]);
    setSelectFraud(false);
    setConfirmationModal(false);
    form.resetFields();
  }, [form]);

  const handleFraudSubmit = useCallback(async () => {
    const reasons = selectFraudValues?.map((reason) => reason.replace(/"/g, ''));

    try {
      await post({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/reprove`,
        payload: {
          reasonDocumentscopy: reasons,
          fraud: true,
          _id: user?._id,
          email: user?.email || user?.username,
        },
      });
    } catch (e) {
      // sillencer
    }
    setConfirmationModal(false);
    setRequestFinished(true);
    reloadExecutionData(false);
  }, [
    post,
    tenantId,
    reportId,
    executionId,
    selectFraudValues,
    user,
    reloadExecutionData,
  ]);

  const handleSubmit = useCallback(
    async (values) => {
      const reason = JSON.parse(values.reason);

      if (type === 'OFFICIAL_DATA_REPROVE') {
        try {
          await post({
            url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/OFFICIAL_DATA_REPROVE`,
            payload: {
              action: 'REPROVE',
              reason: values.reason,
              _id: user?._id,
              email: user?.email,
            },
          });
        } catch (e) {
          // sillencer
        }
      } else {
        try {
          await post({
            url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/reprove`,
            payload: {
              reason: reason.description,
              statusReasonCode: reason.statusReasonCode,
            },
          });
        } catch (e) {
          // sillencer
        }
      }

      setRequestFinished(true);
      reloadExecutionData(false);
    },
    [type, post, tenantId, reportId, executionId, reloadExecutionData, user]
  );

  const optionsReason = useMemo(() => {
    let reason = null;
    let optionsReasonContent = null;

    if (idReason) reason = rejectReasonData.find((item) => item?.id === idReason);

    if (reason) {
      form.setFieldsValue({
        reason: reason?.description,
      });

      optionsReasonContent = (
        <Select
          className="text-dark"
          suffixIcon={<ArrowDown />}
          placeholder="Selecione o motivo..."
        >
          <Option key={reason?.statusReasonCode} value={JSON.stringify(reason)}>
            {reason?.description}
          </Option>
        </Select>
      );

      return { optionsReasonContent, reason };
    }

    optionsReasonContent = (
      <Select
        className="text-dark"
        suffixIcon={<ArrowDown />}
        placeholder="Selecione o motivo..."
      >
        {rejectReasonData.map((item) => (
          <Option key={item?.statusReasonCode} value={JSON.stringify(item)}>
            {item?.description}
          </Option>
        ))}
      </Select>
    );
    return { optionsReasonContent, reason };
  }, [form, idReason]);

  const fraudReason = useMemo(() => {
    let optionsReasonContent = null;
    optionsReasonContent = (
      <Select
        className="text-dark"
        suffixIcon={<ArrowDown />}
        placeholder="Selecione um ou mais motivos para fraude..."
        mode="multiple"
        allowClear
        maxTagCount={0}
        value={selectFraudValues}
        onChange={(value) => {
          setSelectFraudValues(value);
        }}
      >
        {rejectionsFraudData.map((item) => (
          <Option key={item?.key} value={JSON.stringify(item.description)}>
            <Tooltip title={item?.description}>
              <span className="gx-text-truncate">{item?.description}</span>
            </Tooltip>
          </Option>
        ))}
      </Select>
    );
    return { optionsReasonContent };
  }, [selectFraudValues]);

  let resultContent = (
    <div className="text-center">
      <CheckCircle2 className="modal-icon success" />
      <Title className="modal-title">Execução reprovada com sucesso!</Title>
    </div>
  );

  if (error) {
    resultContent = (
      <div className="text-center">
        <WrongCircle className="modal-icon error" />
        <Title className="modal-title">
          Houve um problema ao tentar reprovar a execução.
        </Title>
      </div>
    );
  }

  const getButtonText = useMemo(() => {
    if (selectFraud && !confirmationModal) {
      return 'Reprovar fraude';
    }
    if (confirmationModal) {
      return 'Sim, reprovar';
    }
    return 'Reprovar';
  }, [confirmationModal, selectFraud]);

  const rejectExecutionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={
          <Close
            style={{ display: 'flex-center', verticalAlign: 'middle' }}
            width={24}
            height={24}
          />
        }
        bodyStyle={{
          backgroundColor: confirmationModal ? '#E21B45' : '',
          borderRadius: '8px',
        }}
        centered
        onCancel={closeModal}
        footer={null}
        className="reprocess-modal-component"
        width={600}
      >
        {requestFinished ? (
          resultContent
        ) : (
          <>
            {!confirmationModal && (
              <Row>
                <Col span={24}>
                  <Title className="text-left modal-title">Reprovar execução</Title>
                </Col>
                <Col span={24}>
                  <Paragraph className="text-left modal-subtitle font-size-15">
                    Você deseja reprovar este documento? Por favor, informe um motivo.
                  </Paragraph>
                </Col>
              </Row>
            )}
            {!selectFraud && (
              <>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  autoComplete="off"
                  className="mrg-top-20"
                >
                  <Form.Item name="reason" rules={[{ required: true }]}>
                    {optionsReason?.optionsReasonContent}
                  </Form.Item>
                </Form>
              </>
            )}
            {!confirmationModal && (
              <Checkbox
                checked={selectFraud}
                onChange={(value) => {
                  setSelectFraud(value.target.checked);
                }}
              >
                Essa execução é uma fraude
              </Checkbox>
            )}
            {selectFraud && (
              <>
                {confirmationModal ? (
                  <>
                    <Row className="text-center">
                      <Row className="text-center">
                        <Col span={24}>
                          <Title className="modal-title text-center gx-text-white">
                            ATENÇÃO
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph
                            className="no-mrg-btm text-center modal-subtitle font-size-15 mrg-top-10 gx-text-white"
                            style={{ width: '350px', margin: 'auto' }}
                          >
                            Você está prestes a{' '}
                            <span className="text-bold">
                              reprovar uma execução por fraude
                            </span>
                            , deseja mesmo prosseguir?
                          </Paragraph>
                        </Col>
                      </Row>
                    </Row>
                  </>
                ) : (
                  <>
                    <>
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        autoComplete="off"
                        className="mrg-top-20"
                      >
                        <Form.Item name="fraudReason" rules={[{ required: true }]}>
                          {fraudReason?.optionsReasonContent}
                        </Form.Item>
                      </Form>
                      <div
                        style={{
                          maxHeight: '124px',
                          height: '124px',
                          overflowY: 'auto',
                          border: '1px solid #d9d9d9',
                          padding: '10px 0px 10px 0px',
                          borderRadius: '8px',
                        }}
                      >
                        <List
                          size="small"
                          split={false}
                          dataSource={selectFraudValues}
                          renderItem={(_item, index) => {
                            const item = JSON.parse(_item);
                            return (
                              <List.Item style={{ height: '30px' }}>
                                <Tooltip title={item}>
                                  <Tag color="#E21B45">
                                    <span
                                      className="gx-text-truncate"
                                      style={{
                                        maxWidth: '440px',
                                      }}
                                    >
                                      {item}
                                    </span>
                                    <CloseCircleFilled
                                      width={18}
                                      height={18}
                                      className="mrg-left-10"
                                      onClick={() => {
                                        const updatedValues = selectFraudValues.filter(
                                          (_, idx) => idx !== index
                                        );
                                        setSelectFraudValues(updatedValues);
                                        form.setFieldsValue({
                                          fraudReason: updatedValues,
                                        });
                                      }}
                                    />
                                  </Tag>
                                </Tooltip>
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                      {!hasTscopy && (
                        <Alert
                          className="mrg-top-30"
                          message="Este template não utiliza o serviço de documentoscopia. Você deseja reprovar como fraude da documentoscopia mesmo assim?"
                          type="error"
                        />
                      )}
                    </>
                  </>
                )}
              </>
            )}
            <Row className="mrg-top-40">
              <Col span={24} className={confirmationModal ? 'text-center' : 'text-right'}>
                <Button
                  className={
                    confirmationModal
                      ? 'cancel-button-red-modal gx-text-white'
                      : 'cancel-button'
                  }
                  type="link"
                  onClick={closeModal}
                  disabled={loading}
                >
                  Cancelar
                </Button>
                <Button
                  className={
                    confirmationModal ? 'gx-text-danger' : 'btn-custom btn-custom-danger'
                  }
                  onClick={() => {
                    if (confirmationModal && selectFraud) {
                      handleFraudSubmit();
                    } else if (selectFraud) {
                      setConfirmationModal(true);
                    } else form.submit();
                  }}
                  loading={loading}
                  disabled={selectFraud && !selectFraudValues.length}
                >
                  {getButtonText}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    ),
    [
      hasTscopy,
      getButtonText,
      confirmationModal,
      fraudReason,
      handleFraudSubmit,
      selectFraud,
      selectFraudValues,
      modalVisible,
      closeModal,
      requestFinished,
      resultContent,
      form,
      handleSubmit,
      optionsReason,
      loading,
    ]
  );

  return { openModal, rejectExecutionModal };
};

export default RejectExecutionModal;
