import React, { useCallback, useMemo, useState } from 'react';

import { useMultipleInvoices } from 'hooks/useMultipleInvoices';

import EmptyListModal from './components/EmptyListModal';
import AdjustClientsModal from './components/AdjustClientsModal';
import GenerateInvoicesModal from './components/GenerateInvoicesModal';

const InvoicesModal = () => {
  const { hasClients } = useMultipleInvoices();

  const [modalVisible, setModalVisible] = useState(false);
  const [generate, setGenerate] = useState(false);

  const openModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => {
    setModalVisible(false);
    setGenerate(false);
  }, []);

  const generateHandler = useCallback(() => setGenerate(true), []);

  const CreateInvoicesModal = useMemo(() => {
    return (
      <>
        {!hasClients && !generate && (
          <EmptyListModal onCloseModal={closeModal} modalVisible={modalVisible} />
        )}

        {hasClients && !generate && (
          <AdjustClientsModal
            onCloseModal={closeModal}
            onGenerate={generateHandler}
            modalVisible={modalVisible}
          />
        )}

        {hasClients && generate && (
          <GenerateInvoicesModal onCloseModal={closeModal} modalVisible={modalVisible} />
        )}
      </>
    );
  }, [modalVisible, hasClients, generate, closeModal, generateHandler]);

  return { CreateInvoicesModal, openModal };
};

export default InvoicesModal;
