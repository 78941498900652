import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import UserInfo from 'components/UserInfo';

import logo from 'assets/images/logo.svg';

import HorizontalNav from './HorizontalNav';

import './styles.less';

const { Header } = Layout;

const InsideHeader = ({ subheader }) => {
  return (
    <div
      id="header-component"
      className="gx-header-horizontal gx-inside-header-horizontal"
    >
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img alt="logo caf" className="logo-caf" src={logo} height="32px" />
            </Link>

            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav />
            </div>
            <ul className="gx-header-notifications gx-ml-auto">
              {/* <li className="gx-notify">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={<AppNotification />}
                  trigger="click"
                >
                  <span className="gx-pointer gx-d-block">
                    <i className="icon icon-notification gx-text-white" />
                  </span>
                </Popover>
              </li> */}

              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </ul>
          </div>
        </div>
      </Header>

      {/* {subheaderType === 'customers' && (
        <CustomerSubheader className={subheaderClassName} data={data} />
      )}
      {subheaderType === 'execution' && (
        <ExecutionSubheader className={subheaderClassName} data={data} />
      )} */}
      {subheader}
    </div>
  );
};

InsideHeader.propTypes = {
  subheader: PropTypes.node,
};

InsideHeader.defaultProps = {
  subheader: <></>,
};

export default InsideHeader;
