import React, { useMemo, useState, useCallback } from 'react';
import { Modal } from 'antd';
import { Close } from '@combateafraude/icons/general';

import ReactJson from 'react-json-view';
import _ from 'lodash';

import PageTitle from 'components/PageTitle';

const TimelineData = ({ title, data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const differenceInData = useMemo(() => {
    const changes = (object, base) => {
      return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    };

    if (!data?.oldData || !data?.newData) return { data };

    const differenceOldData = changes(data?.oldData, data?.newData);
    const differenceNewData = changes(data?.newData, data?.oldData);

    const difference = {
      differenceOldData,
      differenceNewData,
    };

    return { data, difference };
  }, [data]);

  const TimelineDataModal = useMemo(() => {
    return (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <PageTitle title={title} subtitle="Detalhes dos dados alterados." />

        <ReactJson
          name={false}
          src={differenceInData}
          iconStyle="triangle"
          indentWidth={2}
          collapsed={2}
          theme="shapeshifter:inverted"
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
        />
      </Modal>
    );
  }, [modalVisible, closeModal, title, differenceInData]);

  return {
    openModal,
    closeModal,
    TimelineDataModal,
  };
};

export default TimelineData;
