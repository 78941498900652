import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { message, Modal, Form } from 'antd';

import { useFetch } from 'services/hooks';
import { Close } from '@combateafraude/icons/general';

import Button from 'components/Button';
import HubForm from './components/HubForm';

import './styles.less';

const HubCreate = () => {
  const { get, post, loading, clearData } = useFetch();
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [hubs, setHubs] = useState([]);

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
    clearData({ shouldClearError: true });
    const hubClosedEvent = new Event('closedModal');
    document.dispatchEvent(hubClosedEvent);
  }, [clearData, form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const createHub = useCallback(async () => {
    await form.validateFields();
    const hubsName = hubs?.map((data) => data?.hub?.hubName?.toLowerCase());

    const payload = form.getFieldsValue();
    payload.hubName = payload.hubName.trim();

    try {
      if (hubsName?.includes(payload?.hubName?.toLowerCase())) {
        throw new Error('O nome do HUB já existe.');
      }

      await post({
        url: '/hubs-auth',
        payload,
      });

      message.success('HUB criado com sucesso.');

      const hubCreatedEvent = new Event('hubCreated');
      document.dispatchEvent(hubCreatedEvent);

      return setTimeout(() => {
        closeModal();
      }, 500);
    } catch (err) {
      if (err) {
        message.error(err.message);
      } else {
        message.error('Erro ao criar HUB.');
      }
      return () => {};
    }
  }, [closeModal, form, post, hubs]);

  const createNewHub = useCallback(() => {
    const data = form.getFieldsValue();
    Modal.confirm({
      centered: true,
      icon: null,
      width: 750,
      closable: true,
      closeIcon: <Close width={18} height={18} />,
      content: (
        <div className="pdd-horizon-30">
          <div className="mrg-top-30 mrg-btm-30">
            <h1 className="text-bold">Criar novo Hub de Contas?</h1>
          </div>
          <div>
            <h3 className="text-bold">
              Deseja criar um novo hub com os seguintes dados:
            </h3>
          </div>
          <div className="mrg-top-20 mrg-btm-20">
            <span className="text-dark text-bold">Nome:</span>
            <p className="text-dark">{data?.hubName}</p>
          </div>
          <div className="mrg-top-20 mrg-btm-20">
            <span className="text-dark text-bold">Tenants vinculados:</span>
            {data?.tenantsId?.map((tenantId) => {
              const a = tenants?.find((tenant) => tenantId === tenant?.tenantId);
              return (
                <div className="text-dark">
                  <span>• {a?.fantasyName} - </span>
                  <span>{a?.tenantId}</span>
                </div>
              );
            })}
          </div>
        </div>
      ),
      okText: 'Criar HUB',
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelType: 'btn-cancel',
      onOk: async () => {
        createHub();
      },
    });
  }, [createHub, form, tenants]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const _tenants = await get({
      url: '/clients',
      config: {
        params: {
          _offset: 0,
          _sort: 'fantasyName',
          _order: 1,
          _returnDocuments: false,
        },
      },
    });

    const _hubs = await get({
      url: '/hubs-auth',
      config: {},
    });

    setHubs(_hubs?.docs);
    setTenants(_tenants?.docs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreateHubModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={750}
        height={10}
      >
        <HubForm formRef={form} hubs={hubs} />
        <div className="flex fd-row space-between mrg-top-35">
          <Button type="link" onClick={closeModal} className="btn-cancel">
            Cancelar
          </Button>
          <Button
            className="btn-custom btn-custom-primary"
            htmlType="button"
            onClick={createNewHub}
            loading={loading}
          >
            Prosseguir
          </Button>
        </div>
      </Modal>
    );
  }, [form, modalVisible, closeModal, createNewHub, loading, hubs]);

  return {
    openHubModal: openModal,
    closeModal,
    CreateHubModal,
  };
};

export default HubCreate;
