import React, { useEffect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';

import Wrapper from 'pages/private/wrapper';

import { useCustomer } from 'hooks/customer';
import { useTimeline } from 'hooks/timeline';

import CustomerSubheader from 'components/Header/components/CustomerSubheader';
import UserHasPermissions from 'components/UserHasPermissions';

import {
  Admin,
  DollarSign,
  IcXClose,
  Image,
  Onboarding,
  Plus,
  Settings,
  Smartphone,
  // ToolsWench,
} from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import { AutenticaçãoFacial } from '@combateafraude/icons/face';

import Timeline from './components/Timeline';

import './styles.less';

const CustomerWrapper = ({ subheader, children }) => {
  const history = useHistory();
  const { tenantId } = useParams();
  const { customer, loadCustomer } = useCustomer();
  const { openModal } = useTimeline();

  useEffect(() => {
    if (!loadCustomer) return;
    if (customer?.tenantId === tenantId) return;

    loadCustomer({ tenantId, clearData: true });
  }, [tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customer?.deletedAt) {
      message.error(`Cliente excluido, não pode ser alterado`);
      history.push(`/customers/`);
    }
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper
      id="customer-wrapper-component"
      subheader={subheader || <CustomerSubheader />}
    >
      {!subheader && (
        <div className="tabs">
          <NavLink to={`/customers/${tenantId}/registration`} activeClassName="active">
            <Settings width={20} height={20} style={{ marginRight: 4 }} />
            Cadastro
          </NavLink>
          <NavLink to={`/customers/${tenantId}/users`} activeClassName="active">
            <Person width={20} height={20} style={{ marginRight: 4 }} />
            Usuários
          </NavLink>
          <UserHasPermissions permissions={['finances', 'financesRead']}>
            <NavLink to={`/customers/${tenantId}/invoices`} activeClassName="active">
              <DollarSign width={20} height={20} style={{ marginRight: 4 }} />
              Cobranças
            </NavLink>
          </UserHasPermissions>
          <NavLink to={`/customers/${tenantId}/reports`} activeClassName="active">
            <Admin width={20} height={20} style={{ marginRight: 4 }} />
            Relatórios
          </NavLink>
          <NavLink to={`/customers/${tenantId}/products`} activeClassName="active">
            <Onboarding width={20} height={20} style={{ marginRight: 4 }} />
            Produtos
          </NavLink>
          <NavLink to={`/customers/${tenantId}/mobile`} activeClassName="active">
            <Smartphone width={20} height={20} style={{ marginRight: 4 }} />
            Mobile
          </NavLink>
          <NavLink to={`/customers/${tenantId}/tokens-iproov`} activeClassName="active">
            <AutenticaçãoFacial width={20} height={20} style={{ marginRight: 4 }} />
            Tokens iProov
          </NavLink>
          <NavLink to={`/customers/${tenantId}/templates`} activeClassName="active">
            <Image width={20} height={20} style={{ marginRight: 4 }} />
            Templates
          </NavLink>
          <NavLink to={`/customers/${tenantId}/denylist`} activeClassName="active">
            <IcXClose width={20} height={20} style={{ marginRight: 4 }} />
            Deny List
          </NavLink>
          <NavLink to={`/customers/${tenantId}/custom-price`} activeClassName="active">
            <Settings width={20} height={20} style={{ marginRight: 4 }} />
            Produtos e serviços
          </NavLink>
          {/* <NavLink to={`/customers/${tenantId}/workflows`} activeClassName="active">
            <ToolsWench width={20} height={20} style={{ marginRight: 4 }} />
            Workflows
          </NavLink> */}
        </div>
      )}

      {children}

      <Timeline />
      <div className="timeline-button-wrapper">
        <Button disabled={!customer} onClick={openModal} className="timeline-button">
          Linha do tempo <Plus width={20} height={20} />
        </Button>
      </div>
    </Wrapper>
  );
};

CustomerWrapper.propTypes = {
  subheader: PropTypes.node,
};

CustomerWrapper.defaultProps = {
  subheader: undefined,
};

export default CustomerWrapper;
