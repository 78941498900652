import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

import Button from 'components/Button';

const OpenAllButton = ({ data }) => {
  const { user } = useAuth();

  const url_location = window.location.href;
  const isFacematch = url_location.includes('doubleCheckFacematch');

  const handleOpenAll = useCallback(() => {
    const docs = data?.docs || [];

    const elems = [...document.getElementsByClassName('editing-data')];
    const ids = elems
      .map((obj) => {
        const parsedObj = JSON.parse(obj.innerHTML);

        if (parsedObj.userId && parsedObj.userId !== user._id) return undefined;

        return parsedObj.executionId;
      })
      .filter((obj) => obj);

    docs.forEach((d) => {
      if (isFacematch) {
        window.open(
          `/executions/details/user/${d.tenantId}/report/${d.reportId}/execution/${d.executionId}/double-check-facematch`,
          '_blank'
        );
      }

      if (!ids.includes(d.executionId)) {
        if (d._id) {
          window.open(
            `/executions/details/id/${d._id}/double-check-face-authenticator`,
            '_blank'
          );
        }
        return;
      }

      window.open(
        `/executions/details/user/${d.tenantId}/report/${d.report}/execution/${d.executionId}`,
        '_blank'
      );
    });
  }, [data, isFacematch, user]);

  return (
    <Button className="btn-custom-secondary" onClick={handleOpenAll} disabled={!data}>
      Abrir todos
    </Button>
  );
};

OpenAllButton.propTypes = {
  data: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default OpenAllButton;
