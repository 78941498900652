import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Input,
  Result,
  message,
  Checkbox,
  Tabs,
} from 'antd';
import { useParams } from 'react-router-dom';
import ZoomedImage from 'components/Image/ZoomedImage';
import { useFetch } from 'services/hooks';
import { useExecution } from 'hooks/execution';

import { Close } from '@combateafraude/icons/general';

import Button from 'components/Button';

const { TabPane } = Tabs;

// const { Option } = Select;
const { Title, Paragraph } = Typography;

const SharedFacesetExecutionModal = () => {
  const [form] = Form.useForm();

  const { loading, post, error } = useFetch();
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData: reloadExecutionData } = useExecution();

  const [sentImages, setSentImages] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  useEffect(() => {
    if (!executionData || !modalVisible) return;

    setSentImages(executionData.executions?.images);

    if (!form) return;

    const docFrauds =
      executionData.executions?.sections?.documentscopy?.evaluation?.filter(
        (evaluation) => evaluation.fraud
      ) || [];

    const messages = docFrauds?.map((f) => (f.message ? f.message : ''));

    form.setFieldsValue({
      description: messages?.join('. ').replaceAll('..', '.') || '',
    });
  }, [executionData, form, modalVisible]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    // se não fizer isso, aparece o modal sendo reinicializado ao fechar
    setTimeout(() => {
      setRequestFinished(false);
      form.resetFields();
    }, 250);
  }, [form]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const payload = {
          description: values.description,
          tenantId,
          reportId,
          executionId,
          images: [],
          cpf:
            executionData?.executions?.data?.cpf ||
            executionData?.executions?.parameters?.cpf ||
            executionData?.executions?.sections?.ocr?.cpf ||
            executionData?.executions?.sections?.ocrRedundancy?.cpf ||
            '',
        };

        if (values.optionalImages.includes('front')) {
          payload.images.push(executionData.executions.images.front);
        }

        if (values.optionalImages.includes('back')) {
          payload.images.push(executionData.executions.images.back);
        }

        if (values.optionalImages.includes('selfie')) {
          payload.images.push(executionData.executions.images.selfie);
        }

        await post({
          url: `/shared-faceset`,
          payload,
        });
        message.success('Reporte de suspeito executado com sucesso.');
        reloadExecutionData(false);
        closeModal();
      } catch (e) {
        message.error('Houve um problema ao realizar a ação.');
      }
    },
    [
      post,
      tenantId,
      reportId,
      executionId,
      reloadExecutionData,
      closeModal,
      executionData,
    ]
  );

  const sharedFacesetExecutionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        centered
        onCancel={closeModal}
        footer={null}
        className="shared-faceset-modal-component"
        width={1200}
      >
        {requestFinished ? (
          <Result
            status={error ? 'error' : 'success'}
            title={
              error
                ? 'Houve um problema ao tentar reportar suspeito.'
                : 'Suspeito cadastrado com sucesso.'
            }
          />
        ) : (
          <div id="sharedFacesetExecutionModalContainer">
            <Tabs className="tabs-zoomed-images">
              <TabPane key="1" tab="Frente">
                <ZoomedImage src={sentImages.front} alt="Imagem da frente do documento" />
              </TabPane>
              <TabPane key="2" tab="Verso">
                <ZoomedImage src={sentImages.back} alt="Imagem do verso do documento" />
              </TabPane>
              <TabPane key="3" tab="Selfie">
                <ZoomedImage src={sentImages.selfie} alt="Imagem de selfie" />
              </TabPane>
            </Tabs>
            <div>
              <Row>
                <Col span={24}>
                  <Title className="text-center modal-title pdd-horizon-20">
                    Reportar como suspeito
                  </Title>
                </Col>
                <Col span={24}>
                  <Paragraph className="text-center modal-subtitle">
                    Você tem certeza que deseja adicionar uma selfie na base de suspeitos?
                    É necessário ser uma selfie, caso não tenha, informe um documento que
                    contenha uma foto. Por favor, descreva o motivo dessa ação.
                  </Paragraph>
                </Col>
              </Row>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                autoComplete="off"
                className="mrg-top-20"
              >
                <Form.Item name="description" rules={[{ required: true }]}>
                  <Input.TextArea rows={4} allowClear placeholder="Descrição" />
                </Form.Item>
                <Form.Item name="optionalImages" rules={[{ required: true }]}>
                  <Checkbox.Group>
                    <Row className="mrg-btm-10 mrg-left-5">
                      <Checkbox value="front">Frente</Checkbox>
                    </Row>
                    <Row className="mrg-btm-10 mrg-left-5">
                      <Checkbox value="back">Verso</Checkbox>
                    </Row>
                    <Row className="mrg-btm-10 mrg-left-5">
                      <Checkbox value="selfie">Selfie</Checkbox>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Form>
              <Row className="mrg-top-40">
                <Col span={24} className="text-center">
                  <Button onClick={closeModal} disabled={loading}>
                    Cancelar
                  </Button>
                  <Button type="danger" onClick={form.submit} loading={loading}>
                    Sim, reportar
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Modal>
    ),
    [
      modalVisible,
      form,
      handleSubmit,
      closeModal,
      requestFinished,
      loading,
      error,
      sentImages,
    ]
  );

  return { openModal, sharedFacesetExecutionModal };
};

export default SharedFacesetExecutionModal;
