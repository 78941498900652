import React, { useRef, useEffect, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Row,
  Col,
  Select,
  Tag,
  Form,
  Dropdown,
  Tooltip,
  Typography,
  Checkbox,
} from 'antd';
import _ from 'lodash';
import { format } from 'date-fns';
import { useQueryParams, StringParam } from 'use-query-params';
import { useMultipleInvoices } from 'hooks/useMultipleInvoices';
import Button from 'components/Button';
import { useTable } from 'components/List';
import SearchInput from 'components/Form/SearchInput';
import {
  ArrowDown,
  CircleTrue,
  File,
  MoreVertical,
  Reorder,
  ToolsWench,
  DollarSign,
} from '@combateafraude/icons/general';

import { toMaskedCnpj } from 'utils/formatters';
import useInvoicesModal from '../components/CreateInvoicesModal';
import useCreateManagementUserModal from '../components/CreateManagementUserModal';
import statusList from './utils/statusList.json';
import Wrapper from '../../wrapper';
import ListDropdown from '../components/ListDropdown';

import './styles.less';

const { Option } = Select;
const { Title, Text } = Typography;

const Customers = () => {
  const refreshListRef = useRef(() => {});
  const [query] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
    _search: StringParam,
    _status: StringParam,
  });

  const { CreateInvoicesModal, openModal: openInvoicesModal } = useInvoicesModal();

  const {
    updateInvoiceList,
    invoiceClients,
    hasClients: hasMultipleInvoicesClients,
    refreshInvoiceList,
  } = useMultipleInvoices();

  const [selectAll, setSelectAll] = useState(false);

  const checkHandler = useCallback(
    (client, checked) => {
      updateInvoiceList(client, checked);
    },
    [updateInvoiceList]
  );

  const checkAllHandler = useCallback(
    (clients, checked) => {
      updateInvoiceList(clients, checked);
      setSelectAll(checked);
    },
    [updateInvoiceList]
  );

  const columns = [
    {
      key: 'fantasyName',
      title: (
        <div className="flex center row">
          <span className="column-title">Nome Fantasia</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'fantasyName',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (__, record) => {
        return (
          <div style={{ display: 'flex' }}>
            <span className="checkbox">
              <Checkbox
                checked={invoiceClients[record?.tenantId]}
                onChange={(e) => checkHandler(record, e?.target?.checked)}
              />
            </span>
            <span>
              <NavLink to={`/customers/${record?.tenantId}/registration`}>
                {record?.fantasyName}
                {record?.verified && (
                  <Tooltip title="Em produção">
                    <ToolsWench width={20} height={20} style={{ marginLeft: 4 }} />
                  </Tooltip>
                )}
                {record?.hasContract && (
                  <Tooltip title="Possui contrato">
                    <File width={20} height={20} />
                  </Tooltip>
                )}
              </NavLink>
            </span>
          </div>
        );
      },
    },
    {
      key: 'status',
      title: (
        <div className="flex center row">
          <span className="column-title">Status</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      sorter: {
        compare: () => 0,
        multiple: 5,
      },
      render: (record = undefined) => (
        <Tag color={statusList[record?.status || 'lead'].color}>
          {statusList[record?.status || 'lead'].name}
        </Tag>
      ),
    },
    {
      key: 'companyName',
      title: (
        <div className="flex center row">
          <span className="column-title">Razão Social</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'companyName',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'cnpj',
      title: (
        <div className="flex center row">
          <span className="column-title">CNPJ</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'cnpj',
      sorter: {
        compare: () => 0,
        multiple: 3,
      },
      render: (record) => toMaskedCnpj(record),
    },
    {
      key: 'tenantId',
      title: (
        <div className="flex center row">
          <span className="column-title">ID</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'tenantId',
      sorter: {
        compare: () => 0,
        multiple: 4,
      },
    },
    {
      key: 'wecheckExclusiveQueue',
      title: (
        <div className="flex center row">
          <span className="column-title">Fila exclusiva no WeCheck</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'wecheckExclusiveQueue',
      sorter: {
        compare: () => 0,
        multiple: 7,
      },
      render: (__, record) => {
        return (
          <>
            {record?.wecheckExclusiveQueue && (
              <CircleTrue
                width={20}
                height={20}
                className="exclusive-queue-icon gx-text-primary"
              />
            )}
          </>
        );
      },
    },
    {
      key: 'createdAt',
      title: (
        <div className="flex center row">
          <span className="column-title">Data de criação</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'createdAt',
      sorter: {
        compare: () => 0,
        multiple: 6,
      },
      render: (text) => {
        try {
          return format(new Date(text), "dd/MM/yyyy 'às' HH:mm");
        } catch (error) {
          return '';
        }
      },
    },
    {
      render: (record) => (
        <Dropdown
          overlay={<ListDropdown data={record} refreshListRef={refreshListRef} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical width={24} height={24} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const { tableContent, responseData, refreshList, setFilterData, loading } = useTable({
    getParams: {
      url: '/clients',
      config: { params: {} },
    },
    queryParams: {
      _search: StringParam,
      _status: StringParam,
    },
    columns,
    rowKey: 'tenantId',
    lockPageChange: hasMultipleInvoicesClients,
  });

  const { openV2Modal, CreateManagementUserModal } = useCreateManagementUserModal();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
    refreshInvoiceList(responseData);
  }, [refreshList, refreshInvoiceList, responseData]);

  useEffect(() => {
    setSelectAll(Object.keys(invoiceClients)?.length === responseData?.length);
  }, [invoiceClients, responseData]);

  return (
    <Wrapper>
      {CreateManagementUserModal}
      {CreateInvoicesModal}
      <Row id="customer-list-component">
        <Col span={24}>
          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} span={12} className="page-title">
              <Title className="title">Clientes</Title>
              <Text className="subtitle">Visualização de perfis de clientes.</Text>
            </Col>
            <Col className="mrg-left-20" align="end" span={12}>
              <Button
                className="btn-custom-secondary new-invoice"
                onClick={openInvoicesModal}
              >
                <span>
                  <DollarSign width={20} height={20} />
                </span>
                Gerar cobrança
              </Button>
              <Button className="btn-custom btn-custom-primary" onClick={openV2Modal}>
                Novo cliente
              </Button>
            </Col>
          </Row>
          <Row align="middle" justify="center" className="mrg-left-15 mrg-btm-15">
            <Col span={24}>
              <Form
                layout="inline"
                onValuesChange={handleFormChange}
                initialValues={{ _search: query?._search, _status: query?._status || '' }}
              >
                <SearchInput
                  disabled={hasMultipleInvoicesClients}
                  className="search-client"
                  placeholder="Pesquise por nome fantasia ou razão social..."
                  prefixEnabled={false}
                  suffixEnabled
                  style={{ width: 400 }}
                />
                <Form.Item name="_status">
                  <Select
                    disabled={hasMultipleInvoicesClients}
                    style={{ width: 180, fontWeight: 500 }}
                    className="text-dark"
                    suffixIcon={<ArrowDown />}
                  >
                    <Option value="">Todos os clientes</Option>
                    <Option value="active">Clientes ativos</Option>
                    <Option value="lead">Leads</Option>
                    <Option value="integration">Integração</Option>
                    <Option value="internal-customer">Clientes Internos</Option>
                    <Option value="inactive">Clientes inativos</Option>
                    <Option value="poc">Teste</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row align="left" className="no-mrg mrg-btm-15">
            <Col span={5}>
              <Checkbox
                className="select-all"
                checked={selectAll && responseData?.length !== 0}
                disabled={responseData?.length === 0 || loading}
                onChange={(e) => checkAllHandler(responseData, e?.target?.checked)}
              >
                Selecionar todos
              </Checkbox>
            </Col>
          </Row>
          {tableContent}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Customers;
