import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider, Button } from 'antd';
import classNames from 'classnames';
import { StarOutline } from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import { toFormatDate } from 'utils/formatters';

import { useFetch } from 'services/hooks';

import useTimelineDataModal from './TimelineDataModal';

import './TimelineContent.less';

const TimelineContent = ({
  _id,
  title,
  description,
  createdAt,
  updatedAt,
  user,
  data,
  star,
  blank,
}) => {
  const { put } = useFetch();

  const [isStarred, setIsStarred] = useState(star);

  const changeStarStatus = useCallback(
    (status) => {
      if (!_id) return;

      setIsStarred(status);
      put({
        url: `/clients-timeline/${_id}`,
        payload: {
          star: status,
        },
      });
    },
    [_id, put]
  );

  const starredClass = useMemo(
    () => (isStarred ? 'starred' : 'not-starred'),
    [isStarred]
  );
  const blankClass = useMemo(() => (blank ? 'blank' : ''), [blank]);

  const dataStarredClass = useMemo(() => (isStarred ? 'data-starred' : ''), [isStarred]);

  const { openModal, TimelineDataModal } = useTimelineDataModal({
    title,
    user,
    data,
  });

  return (
    <>
      {TimelineDataModal}
      <div id="timeline-content-component" className={`${starredClass} ${blankClass}`}>
        {blank ? (
          <Divider />
        ) : (
          <>
            <div className={`star ${starredClass}`}>
              {isStarred ? (
                <button type="button" onClick={() => changeStarStatus(false)}>
                  <StarOutline fill="#333" />
                </button>
              ) : (
                <button type="button" onClick={() => changeStarStatus(true)}>
                  <StarOutline />
                </button>
              )}
            </div>

            <h2>{title}</h2>

            {description && <p>{description}</p>}
            {data && (
              <span className={`flex open-modal ${dataStarredClass}`}>
                <Button type="link" onClick={openModal}>
                  Ver dados modificados
                </Button>
              </span>
            )}

            {user && (
              <div className="mrg-top-5 flex start-center">
                <Person
                  className={classNames({
                    'gx-text-white': isStarred,
                    'gx-text-black': !isStarred,
                  })}
                />
                <small className="mrg-left-5">{user.name}</small>
              </div>
            )}

            <small>Editado no dia {toFormatDate(updatedAt || createdAt)}</small>
          </>
        )}
      </div>
    </>
  );
};

TimelineContent.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  star: PropTypes.bool,
  blank: PropTypes.bool,
  data: PropTypes.shape({}),
};

TimelineContent.defaultProps = {
  _id: undefined,
  title: '',
  description: undefined,
  createdAt: new Date().toISOString(),
  updatedAt: undefined,
  user: undefined,
  star: false,
  blank: false,
  data: undefined,
};

export default TimelineContent;
