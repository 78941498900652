import React from 'react';

import { Map, Onboarding, Smartphone } from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';

import { Person } from '@combateafraude/icons/users';
import SDKCard from './components/SDKCard';

const ActiveSDKsCard = () => {
  const { customerProducts } = useCustomer();

  const productsKeys = Object.keys(customerProducts || {});
  const products = productsKeys?.map((productKey) => customerProducts[productKey]);

  return (
    <div id="active-sdks-card">
      <h3>SDKS</h3>
      <SDKCard
        icon={<Onboarding />}
        title="Documento"
        sdksList={products?.filter((product) => product?.category === 'document')}
      />
      <SDKCard
        icon={<Person />}
        title="Face"
        sdksList={products?.filter((product) => product?.category === 'face')}
      />
      <SDKCard
        icon={<Map />}
        title="Rastreio"
        sdksList={products?.filter((product) => product?.category === 'tracking')}
      />
      <SDKCard
        icon={<Smartphone />}
        title="Dispositivo"
        sdksList={products?.filter((product) => product?.category === 'device')}
      />
    </div>
  );
};

export default ActiveSDKsCard;
