import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, Spin } from 'antd';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';

import {
  Admin,
  Attach,
  DollarSign,
  Settings,
  Smartphone,
  Trash,
  EMail,
} from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';

import useSendInviteModal from './SendInviteModal';
import useChangeStatusModal from './ChangeStatusModal';
import useUserDeleteModal from './UserDeleteModal';

const ListDropdown = ({ data, refreshListRef }) => {
  const { get: getContract, loading: loadingContract } = useFetch();

  const { hasPermissions } = useAuth();

  const { openModal: openSendInviteModal, sendInviteModal } = useSendInviteModal({
    data,
    refreshListRef,
  });
  const { openModal: openChangeStatusModal, changeStatusModal } = useChangeStatusModal({
    data,
    refreshListRef,
  });
  const { userDeleteModal } = useUserDeleteModal({
    data,
    type: 'Cliente',
    refreshListRef,
  });

  const handleDownloadContract = useCallback(async () => {
    const { fileName } = await getContract({
      url: `clients/${data.tenantId}/upload/${data.contract._id}`,
    });

    window.open(fileName, '_blank');
  }, [data, getContract]);

  return (
    <>
      {changeStatusModal}
      {sendInviteModal}
      <Menu>
        <Menu.Item key="-1">
          <NavLink to={`/customers/${data.tenantId}/registration`}>
            <Settings width={24} height={24} style={{ marginRight: 4 }} />
            Cadastro
          </NavLink>
        </Menu.Item>
        <Menu.Item key="0">
          <NavLink to={`/customers/${data.tenantId}/users`}>
            <Person width={20} height={20} style={{ marginRight: 4 }} />
            Usuários
          </NavLink>
        </Menu.Item>
        {hasPermissions(['finances', 'financesRead']) === true && (
          <Menu.Item key="1">
            <NavLink to={`/customers/${data.tenantId}/invoices`}>
              <DollarSign width={20} height={20} style={{ marginRight: 4 }} />
              Cobranças
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="3">
          <NavLink to={`/customers/${data.tenantId}/reports`}>
            <Admin width={20} height={20} style={{ marginRight: 4 }} />
            Relatórios
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to={`/customers/${data.tenantId}/mobile`}>
            <Smartphone width={20} height={20} style={{ marginRight: 4 }} />
            Mobile
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5" onClick={openSendInviteModal}>
          <EMail width={20} height={20} style={{ marginRight: 4 }} />
          Enviar convite
        </Menu.Item>
        <Menu.Divider />
        {data.hasContract && (
          <Menu.Item key="6" onClick={handleDownloadContract}>
            {loadingContract ? (
              <Spin size="small" className="center no-mrg mrg-right-10 no-pdd" />
            ) : (
              <Attach width={20} height={20} style={{ marginRight: 4 }} />
            )}
            Baixar contrato
          </Menu.Item>
        )}
        <Menu.Item key="7" onClick={openChangeStatusModal}>
          <Settings width={20} height={20} style={{ marginRight: 4 }} />
          Alterar status
        </Menu.Item>
        <Menu.Item key="8" onClick={userDeleteModal} className="gx-text-danger">
          <Trash width={20} height={20} style={{ marginRight: 4 }} />
          Excluir
        </Menu.Item>
      </Menu>
    </>
  );
};

ListDropdown.propTypes = {
  data: PropTypes.shape({
    tenantId: PropTypes.string.isRequired,
    hasContract: PropTypes.bool,
    contract: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default ListDropdown;
