import React, { useEffect, useCallback, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { Row, Col, Tabs, message, Spin } from 'antd';
import ReactJson from 'react-json-view';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import { toFormatDate } from 'utils/formatters';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader';

const { TabPane } = Tabs;

const CustomDataAvailable = () => {
  const { tenantId, reportId, executionId } = useParams();
  const { post } = useFetch();
  const { loading: loadingCustomData, get, data: customDataReponse } = useFetch();
  const [loadings, setLoadings] = useState({});
  const { executionData, reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();
  const [doubleCheckSections, setDoubleCheckSections] = useState([]);

  const handleSubmit = useCallback(
    async (reviewId, action) => {
      try {
        setLoadings((state) => ({ ...state, [action]: true }));
        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/${reviewId}`,
          payload: {
            action,
            _id: user?._id,
            email: user?.email,
          },
        });
        message.success('Ação realizada com sucesso.');
        reloadExecutionData(false);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      } finally {
        setLoadings((state) => ({ ...state, [action]: false }));
      }
    },
    [executionId, post, reloadExecutionData, reportId, tenantId, user]
  );

  useEffect(() => {
    get({ url: `/reports/custom-data?tenantId=${tenantId}` });
  }, [get, tenantId]);

  useEffect(() => {
    const { executions } = executionData || {};
    if (!executions) return;

    if (customDataReponse?.customData?.docs) {
      const customDataList = customDataReponse.customData.docs;
      const dcSections = Object.keys(executions?.reviews).reduce((acc, review) => {
        const sections = acc;
        if (review.includes('CUSTOM_DATA_') && review.includes('_AVAILABLE')) {
          const source = review.replace('CUSTOM_DATA_', '').replace('_AVAILABLE', '');
          const query = customDataList.find((c) => c.source === source.toLowerCase());
          if (query && !executions?.reviews[review]?.reviewAt) {
            sections.push({
              reviewId: review,
              infos: query,
              section: executions?.sections[query.sectionAttr],
            });
          }
        }
        return sections;
      }, []);
      setDoubleCheckSections(dcSections);
    }
  }, [customDataReponse, executionData, get]);

  /**
   * Caso não tenha nenhuma seção com statusCode diferente de 01
   * não mostra o card por inteiro.
   */
  const hasAvailableSection = useMemo(() => {
    if (!doubleCheckSections?.length) return false;

    const doubleCheckSectionsFiltered = doubleCheckSections.filter(
      (dcs) => dcs?.section?.statusCode !== '01'
    );

    return !!doubleCheckSectionsFiltered.length;
  }, [doubleCheckSections]);

  return (
    hasAvailableSection && (
      <div id="custom-data-available-component">
        <PageTitle
          title="Ação requerida: dupla checagem de consultas personalizadas"
          subtitle="Avalie os dados e escolha a opção que deseja realizar."
        />
        <Card>
          {loadingCustomData && (
            <div className="text-center mrg-vertical-15">
              <Spin
                indicator={<Loader color="#bdbdbd" />}
                tip="Carregando..."
                className="text-secondary"
              />
            </div>
          )}

          {!loadingCustomData && doubleCheckSections?.length && (
            <Row justify="center">
              <Col span={24}>
                <Tabs>
                  {doubleCheckSections.map(
                    (item) =>
                      item.section?.statusCode !== '01' && ( // só coloca caso statusCode for diferente de '01'
                        <TabPane key={item.infos.source} tab={item.infos.title}>
                          <Row justify="center">
                            <Col span={12}>
                              <div className="table-overflow json-viewer">
                                <ReactJson
                                  name={item.infos.sectionAttr}
                                  src={item.section || {}}
                                  iconStyle="triangle"
                                  indentWidth={2}
                                  collapsed={2}
                                  theme="shapeshifter:inverted"
                                  displayDataTypes={false}
                                  displayObjectSize={false}
                                  enableClipboard={false}
                                />
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="table-overflow">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Status</td>
                                      <td>
                                        {item?.section?.available
                                          ? 'Disponível'
                                          : 'Indisponível'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Status code</td>
                                      <td>{item?.section?.statusCode}</td>
                                    </tr>
                                    <tr>
                                      <td>Iniciou em</td>
                                      <td>
                                        {toFormatDate(
                                          item?.section?.query?.startAt,
                                          "dd/MM/yyyy 'às' HH:mm:ss"
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Terminou em</td>
                                      <td>
                                        {toFormatDate(
                                          item?.section?.query?.endAt,
                                          "dd/MM/yyyy 'às' HH:mm:ss"
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Mensagem</td>
                                      <td>{item?.section?.query?.error?.message}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="mrg-top-20">
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  loading={loadings.RETRY}
                                  disabled={loadings.UNAVAILABLE}
                                  onClick={() => handleSubmit(item?.reviewId, 'RETRY')}
                                >
                                  Reprocessar
                                </Button>
                                <Button
                                  type="danger"
                                  loading={loadings.UNAVAILABLE}
                                  disabled={loadings.RETRY}
                                  onClick={() =>
                                    handleSubmit(item?.reviewId, 'UNAVAILABLE')
                                  }
                                >
                                  Marcar indisponibilidade
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )
                  )}
                </Tabs>
              </Col>
            </Row>
          )}
        </Card>
        {/* <Waypoint
          topOffset="25%"
          onEnter={() => setWhichComponentIsActive('custom-data-available-component')}
        /> */}
      </div>
    )
  );
};

// CustomDataAvailable.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default CustomDataAvailable;
