/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Col, Divider, Form, message, Row, Select, Spin, Switch, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

import Button from 'components/Button';
import { useCustomerConfigs } from 'hooks/customerConfigs';

import { useFetch } from 'services/hooks';

import { FEATURE_FLAGS } from './featureFlags';

import SelectModel from './SelectModel';

const { Option } = Select;

function UpdateFeatureFlagsForm() {
  const {
    loadCustomerFeatureFlags,
    loadingCustomerFeatureFlags,
    loadingPutCustomerFeatureFlags,
    putCustomerFeatureFlags,
    customerFeatureFlags,
  } = useCustomerConfigs();

  const { tenantId } = useParams();
  const [profileTemplates, setProfileTemplates] = useState([]);
  const [flagsState, setFlagsState] = useState([]);
  const [modelStates, setModelStates] = useState({});
  const [optionsStates, setOptionsStates] = useState({});

  const { clearData, get } = useFetch();

  const getValidationFeatureFlags = useCallback(() => {
    const getFlagStatusModels = (flag) => {
      return flag?.state && (!flag?.templates || flag.templates.length === 0);
    };

    const getFlagStatusModelsOptions = (flag) => {
      return flag?.state && (!flag?.fields || flag.fields.length === 0);
    };

    const {
      isPriorityGovernmentBiometric,
      disableManualOcr,
      additionalFieldsCsvExtraction,
      alwaysDocumentscopy,
    } = flagsState ?? {};

    const isPriorityBiometricFlag = getFlagStatusModels(isPriorityGovernmentBiometric);
    const isManualOcrDisabledFlag = getFlagStatusModels(disableManualOcr);
    const alwaysDocumentscopyFlag = getFlagStatusModels(alwaysDocumentscopy);
    const isAdditionalFieldsFlag = getFlagStatusModelsOptions(
      additionalFieldsCsvExtraction
    );

    return (
      isPriorityBiometricFlag ||
      isManualOcrDisabledFlag ||
      alwaysDocumentscopyFlag ||
      isAdditionalFieldsFlag
    );
  }, [flagsState]);

  const getModels = useCallback(async () => {
    try {
      const response = await get({
        url: `/users/${tenantId}/profile-templates`,
      });
      setProfileTemplates(response?.docs);
    } catch (error) {
      clearData();
      message.error('Ocorreu um erro ao carregar a lista de produtos');
    }
  }, [clearData, get, tenantId]);

  useEffect(() => {
    getModels();
    loadCustomerFeatureFlags({ tenantId });
  }, [loadCustomerFeatureFlags, tenantId, getModels]);

  useMemo(() => {
    const flags = {};

    FEATURE_FLAGS.forEach((feature) => {
      flags[feature?.name] = customerFeatureFlags?.[feature?.name];

      // Inicialize o estado do modelo para cada flag
      setModelStates((prevModelStates) => ({
        ...prevModelStates,
        [feature?.name]: customerFeatureFlags?.[feature?.name]?.templates || [],
      }));

      // Inicialize o estado das opções para cada flag
      setOptionsStates((prevOptionsStates) => ({
        ...prevOptionsStates,
        [feature?.name]: customerFeatureFlags?.[feature?.name]?.fields || [],
      }));
    });

    setFlagsState(flags);
  }, [customerFeatureFlags]);

  const handleSubmit = useCallback(async () => {
    try {
      await putCustomerFeatureFlags({ tenantId, data: flagsState });
      message.success('Feature flags atualizadas com sucesso.');
    } catch (error) {
      message.error('Erro ao atualizar flags');
    }

    loadCustomerFeatureFlags({ tenantId });
  }, [flagsState, tenantId, putCustomerFeatureFlags, loadCustomerFeatureFlags]);

  const handleChange = useCallback(
    async ({ flag, checked }) => {
      const newState = { ...(flagsState || {}) };

      if (flag.enableAnother && checked) newState[flag.enableAnother] = true;
      if (!flag.hasDependency || !newState[flag.hasDependency])
        newState[flag.name] = checked;

      // Atualize o estado do modelo para essa flag
      setModelStates((prevModelStates) => ({
        ...prevModelStates,
        [flag.name]: checked ? flagsState[flag.name]?.templates || [] : [],
      }));

      setOptionsStates((prevOptionsStates) => ({
        ...prevOptionsStates,
        [flag.name]: checked ? flagsState[flag.name]?.fields || [] : [],
      }));

      setFlagsState(newState);
    },
    [flagsState]
  );

  const selectChangeOptions = useCallback(
    async ({ flag, checked, values }) => {
      const newState = { ...flagsState };

      newState[flag] = {
        state: checked,
        fields: checked ? values?.map((v) => v) : [],
      };

      setOptionsStates((prevOptionsStates) => ({
        ...prevOptionsStates,
        [flag]: checked ? values?.map((v) => v) : [],
      }));

      setFlagsState(newState);
    },
    [flagsState]
  );

  const selectChangeModels = useCallback(
    async ({ flag, checked, values }) => {
      const newState = { ...flagsState };

      newState[flag] = {
        state: checked,
        templates: checked ? values?.map((t) => t.value) : [],
      };

      setModelStates((prevModelStates) => ({
        ...prevModelStates,
        [flag]: checked ? values?.map((t) => t.value) : [],
      }));

      setFlagsState(newState);
    },
    [flagsState]
  );

  return (
    <Form onFinish={handleSubmit} layout="vertical" className="mrg-top-10">
      <Row align="middle">
        {loadingCustomerFeatureFlags ? (
          <Spin />
        ) : (
          <>
            {FEATURE_FLAGS.map((feature) => {
              if (!feature?.hasModel && !feature?.options) {
                return (
                  <Tooltip
                    title={feature?.tooltip}
                    mouseEnterDelay={2}
                    placement="topLeft"
                  >
                    <Col key={feature?.name} span={8}>
                      <Form.Item
                        key={feature?.name}
                        name={feature?.name}
                        label={<span style={{ height: 15 }}>{feature?.label}</span>}
                      >
                        <Switch
                          defaultChecked={!!customerFeatureFlags?.[feature?.name]}
                          checked={!!flagsState[feature.name]}
                          loading={loadingCustomerFeatureFlags}
                          key={feature?.name}
                          onChange={(checked) =>
                            handleChange({
                              flag: feature,
                              checked,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Tooltip>
                );
              }
              return null;
            })}
            <Divider>Features com opções</Divider>
            {FEATURE_FLAGS.map((feature) => {
              if (!feature?.hasModel && feature?.options) {
                return (
                  <Tooltip
                    title={feature?.tooltip}
                    mouseEnterDelay={2}
                    placement="topLeft"
                  >
                    <Col key={feature?.name} span={12}>
                      <Form.Item
                        key={feature?.name}
                        name={feature?.name}
                        span={10}
                        label={<span style={{ height: 15 }}>{feature?.label}</span>}
                        style={{ display: 'flex' }}
                      >
                        <Switch
                          defaultChecked={!!customerFeatureFlags?.[feature?.name]?.state}
                          loading={loadingCustomerFeatureFlags}
                          key={feature?.name}
                          onChange={(checked) =>
                            selectChangeOptions({ flag: feature.name, checked })
                          }
                        />
                        {flagsState[feature.name]?.state && feature?.options && (
                          <>
                            <Select
                              mode="multiple"
                              style={{ width: 400, marginLeft: 50 }}
                              values={optionsStates[feature.name] || []}
                              defaultValue={optionsStates[feature.name] || []}
                              onChange={(value) => {
                                selectChangeOptions({
                                  flag: feature.name,
                                  checked: true,
                                  values: value,
                                });
                              }}
                            >
                              {feature?.options.map((option) => (
                                <Option key={option} label={option} value={option}>
                                  <span className="option-title">{option}</span>
                                </Option>
                              ))}
                            </Select>
                            {optionsStates[feature.name]?.length === 0 ||
                              (optionsStates[feature.name] === undefined && (
                                <p className="gx-text-danger mrg-left-100">
                                  Selecione pelo menos uma opção
                                </p>
                              ))}
                          </>
                        )}
                      </Form.Item>
                    </Col>
                  </Tooltip>
                );
              }
              return null;
            })}
            <Divider>Features por modelos de consulta</Divider>
            {FEATURE_FLAGS.map((feature) => {
              if (feature?.hasModel) {
                return (
                  <Tooltip
                    title={feature?.tooltip}
                    mouseEnterDelay={2}
                    placement="topLeft"
                  >
                    <Col key={feature?.name} span={12}>
                      <Form.Item
                        key={feature?.name}
                        span={10}
                        name={feature?.name}
                        label={<span style={{ height: 15 }}>{feature?.label}</span>}
                      >
                        <Switch
                          defaultChecked={!!customerFeatureFlags?.[feature?.name]?.state}
                          loading={loadingCustomerFeatureFlags}
                          key={feature?.name}
                          onChange={(checked) =>
                            selectChangeModels({ flag: feature.name, checked })
                          }
                        />
                        {flagsState[feature.name]?.state && (
                          <SelectModel
                            profileTemplates={profileTemplates}
                            featureFlag={feature}
                            values={modelStates[feature.name] || []}
                            onChange={(value) => {
                              selectChangeModels({
                                flag: feature.name,
                                checked: true,
                                values: value,
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Tooltip>
                );
              }
              return null;
            })}
            <Col span={24}>
              <Row align="middle" flex justify="end" className="mrg-top-10 mrg-btm-10">
                <Col span={8}>
                  <Button
                    className="btn-custom btn-custom-primary"
                    onClick={handleSubmit}
                    loading={
                      loadingPutCustomerFeatureFlags || loadingCustomerFeatureFlags
                    }
                    disabled={getValidationFeatureFlags()}
                  >
                    Atualizar features
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
}

export default UpdateFeatureFlagsForm;
