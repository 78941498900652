import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { message, Modal, Form } from 'antd';

import { useFetch } from 'services/hooks';
import { Close } from '@combateafraude/icons/general';
import Button from 'components/Button';

import HubForm from './components/HubForm';

import './styles.less';

const HubEdit = () => {
  const { get, put, loading, clearData } = useFetch();
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [hubData, setHubData] = useState(false);
  const [hubs, setHubs] = useState([]);

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
    clearData({ shouldClearError: true });
    const CloseModalEvent = new Event('closedModal');
    document.dispatchEvent(CloseModalEvent);
  }, [clearData, form]);

  const openModal = useCallback(
    (allHubData) => {
      setHubData(allHubData);
      form.setFieldsValue({
        hubName: allHubData?.hubData?.hubName,
        tenantsId: allHubData?.docs?.map((tenant) => {
          return `${tenant?.companyName || '-'}tenantId:${tenant?.tenantId}`;
        }),
        active: allHubData?.hubData?.active.toString(),
        id: allHubData?.hubData?.id,
      });
      setModalVisible(true);
    },
    [form]
  );

  const createHub = useCallback(async () => {
    await form.validateFields();

    const payload = form.getFieldsValue();

    const tenantsId = payload?.tenantsId?.map((item) => {
      if (item?.includes('tenantId:')) {
        const infos = item?.split('tenantId:');
        return infos[1]?.trim();
      }
      return item;
    });

    const active = payload?.active === 'true';

    try {
      await put({
        url: `/hubs-auth/${hubData?.hubData?.id}`,
        payload: {
          ...payload,
          active,
          tenantsId,
          remove: false,
        },
      });

      message.success('HUB criado com sucesso.');

      const hubCreatedEvent = new Event('hubEdited');
      document.dispatchEvent(hubCreatedEvent);

      return setTimeout(() => {
        closeModal();
      }, 500);
    } catch (err) {
      message.error('Erro ao editar HUB.');
      return () => {};
    }
  }, [closeModal, form, put, hubData]);

  const createNewHub = useCallback(() => {
    Modal.confirm({
      centered: true,
      icon: null,
      width: 530,
      closable: true,
      closeIcon: <Close width={18} height={18} />,
      content: (
        <div className="text-center mrg-btm-30">
          <div className="mrg-top-30 mrg-btm-30">
            <h1 className="text-bold">Salvar alterações no HUB?</h1>
          </div>
          <div>
            <h5 className="text-">Confirme as alterações realizadas nesse HUB</h5>
          </div>
        </div>
      ),
      okText: 'Salvar',
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelType: 'btn-cancel',
      onOk: async () => {
        createHub();
      },
    });
  }, [createHub]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const _hubs = await get({
      url: '/hubs-auth',
      config: {},
    });

    setHubs(_hubs?.docs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EditHubModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={750}
        height={10}
      >
        <HubForm formRef={form} hubs={hubs} />
        <div className="flex fd-row space-between mrg-top-35">
          <Button type="link" onClick={closeModal} className="btn-cancel">
            Cancelar
          </Button>
          <Button
            className="btn-custom btn-custom-primary"
            htmlType="button"
            onClick={createNewHub}
            loading={loading}
          >
            Salvar
          </Button>
        </div>
      </Modal>
    );
  }, [form, modalVisible, closeModal, createNewHub, loading, hubs]);

  return {
    openHubModal: openModal,
    closeModal,
    EditHubModal,
  };
};

export default HubEdit;
