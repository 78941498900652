import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import './styles.less';

const { Title, Text } = Typography;

const PageTitle = ({ title, subtitle, align }) => {
  return (
    <div id="page-title-component" className={`page-title mrg-btm-15 align-${align}`}>
      <Title className="title">{title}</Title>
      {subtitle && <Text className="subtitle">{subtitle}</Text>}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  align: PropTypes.string,
};

PageTitle.defaultProps = {
  subtitle: undefined,
  align: undefined,
};

export default PageTitle;
