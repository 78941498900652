import React, { useEffect, useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { Row, Col, Tabs, message } from 'antd';
import { uuid } from 'uuidv4';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import moment from 'moment';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

const { TabPane } = Tabs;

const SanctionsReprove = ({
  children,
  // setWhichComponentIsActive
}) => {
  const { tenantId, reportId, executionId } = useParams();
  const { post } = useFetch();
  const [loadings, setLoadings] = useState({});
  const { executionData, reloadData: reloadExecutionData } = useExecution();
  const { user } = useAuth();
  const [sanctionList, setSanctionList] = useState([]);
  const [peopleName, setPeopleName] = useState('');

  const handleSubmit = useCallback(
    async (action) => {
      try {
        setLoadings((state) => ({ ...state, [action]: true }));
        await post({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/SANCTIONS_REPROVE`,
          payload: {
            action,
            _id: user?._id,
            email: user?.email,
          },
        });
        message.success('Ação realizada com sucesso.');
        reloadExecutionData(false);
      } catch (error) {
        message.error('Houve um problema ao realizar a ação.');
      } finally {
        setLoadings((state) => ({ ...state, [action]: false }));
      }
    },
    [executionId, post, reloadExecutionData, reportId, tenantId, user]
  );

  useEffect(() => {
    const { executions } = executionData || {};
    if (!executions) return;

    if (Array.isArray(executions?.sections?.pfKycCompliance?.data?.sanctionsHistory)) {
      let sanctions = executions.sections.pfKycCompliance.data.sanctionsHistory.reduce(
        (acc, s) => {
          const sanctionsAcc = acc;
          if (sanctionsAcc[s.source.toUpperCase() || 'INDEFINIDO']) {
            sanctionsAcc[s.source.toUpperCase() || 'INDEFINIDO'].push(s);
          } else {
            sanctionsAcc[s.source.toUpperCase() || 'INDEFINIDO'] = [s];
          }
          return sanctionsAcc;
        },
        {}
      );

      sanctions = Object.keys(sanctions).map((key) => ({
        source: key,
        sanctions: sanctions[key],
      }));

      const name =
        executions?.data?.name ||
        executions?.sections?.cpf?.name ||
        executions?.parameters?.name ||
        executions?.sections?.ocr?.name ||
        executions?.sections?.pfBasicData?.data?.name;

      setSanctionList(sanctions);
      setPeopleName(name);
    }
  }, [executionData, setSanctionList]);

  const renderSanctionType = (type) => {
    return (
      type &&
      type
        .toUpperCase()
        .replace('LAW ENFORCEMENT', 'AÇÃO JUDICIAL')
        .replace('MONEY LAUNDERING', 'LAVAGEM DE DINHEIRO')
    );
  };

  const renderEyeAndHair = (ref) => {
    return (
      ref &&
      ref
        .toUpperCase()
        .replace('DARK BROWN', 'CASTANHO ESCURO')
        .replace('LIGHT BROWN', 'CASTANHO CLARO')
        .replace('BROWN', 'CASTANHO')
        .replace('DARK', 'ESCURO')
        .replace('BLACK', 'PRETO')
    );
  };

  return (
    <div id="sanctions-reprove-component">
      <PageTitle
        title="Ação requerida: dupla checagem de sanções (KYC)"
        subtitle="Avalie os dados e selecione a alternativa que mais se enquadra."
      />
      <Card>
        <Row justify="center">
          <Col className="document-col" span={10}>
            {children}
            <div className="table-overflow mrg-top-10">
              <table>
                <tbody>
                  <tr>
                    <td>Nome na CAF</td>
                    <td>{peopleName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col span={14}>
            <Tabs className="tabs-sanctions">
              {sanctionList.map((item) => (
                <TabPane key={item.source} tab={item.source}>
                  {item.sanctions.map((s) => (
                    <div key={uuid()} className="table-overflow">
                      <table>
                        <tbody>
                          {s?.matchRate && (
                            <tr>
                              <td>Similaridade</td>
                              <td>{s?.matchRate}%</td>
                            </tr>
                          )}
                          {s?.type && (
                            <tr>
                              <td>Tipo</td>
                              <td>{renderSanctionType(s?.type)}</td>
                            </tr>
                          )}
                          {s?.sanctionName && (
                            <tr>
                              <td>Nome na sanção</td>
                              <td>{s?.sanctionName?.toUpperCase()}</td>
                            </tr>
                          )}
                          {s?.originalName && (
                            <tr>
                              <td>Nome original</td>
                              <td>{s?.originalName?.toUpperCase()}</td>
                            </tr>
                          )}
                          {s?.birthDate && (
                            <tr>
                              <td>Data de nascimento</td>
                              <td>
                                {moment(s?.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </td>
                            </tr>
                          )}
                          {s?.description && (
                            <tr>
                              <td>Descrição</td>
                              <td>{s?.description}</td>
                            </tr>
                          )}
                          {s?.nationalities && (
                            <tr>
                              <td>Nacionalidade</td>
                              <td>{s?.nationalities}</td>
                            </tr>
                          )}
                          {s?.eyes && (
                            <tr>
                              <td>Olhos</td>
                              <td>{renderEyeAndHair(s?.eyes)}</td>
                            </tr>
                          )}
                          {s?.hair && (
                            <tr>
                              <td>Cabelo</td>
                              <td>{renderEyeAndHair(s?.hair)}</td>
                            </tr>
                          )}
                          {s?.considerations && (
                            <tr>
                              <td>Considerações</td>
                              <td>{s?.considerations}</td>
                            </tr>
                          )}
                          {s?.distinguishingMarks && (
                            <tr>
                              <td>Características</td>
                              <td>{s?.distinguishingMarks}</td>
                            </tr>
                          )}
                          {s?.department && (
                            <tr>
                              <td>Departamento</td>
                              <td>{s?.department}</td>
                            </tr>
                          )}
                          {s?.image && (
                            <tr>
                              <td>Imagem</td>
                              <td>
                                <img src={s.image} alt="Imagem" width="200px" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </TabPane>
              ))}
            </Tabs>

            <div className="mrg-top-20">
              <Button
                htmlType="submit"
                className="btn-custom btn-custom-primary"
                loading={loadings.CONFIRM}
                disabled={loadings.NOT_CONFIRM}
                onClick={() => handleSubmit('CONFIRM')}
              >
                Confirmar (mesma pessoa)
              </Button>
              <Button
                className="btn-custom btn-custom-danger"
                loading={loadings.NOT_CONFIRM}
                disabled={loadings.CONFIRM}
                onClick={() => handleSubmit('NOT_CONFIRM')}
              >
                Rejeitar (não é a mesma pessoa)
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('sanctions-reprove-component')}
      /> */}
    </div>
  );
};

// SanctionsReprove.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default SanctionsReprove;
