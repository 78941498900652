import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import { Person } from '@combateafraude/icons/users';
import { Trash } from '@combateafraude/icons/general';
import useUserCreateModal from '../../../components/UserCreateModal';

import useUserDeleteModal from '../../../components/UserDeleteModal';

const UserDropdown = ({ data, refreshListRef }) => {
  const { userDeleteModal } = useUserDeleteModal({
    data,
    type: 'Usuário',
    refreshListRef,
  });

  const { openModal, UserCreateModal } = useUserCreateModal({
    refUserId: data._id,
    type: 'child',
    refreshListRef,
  });

  return (
    <>
      {UserCreateModal}
      <Menu>
        <Menu.Item key="2" onClick={openModal}>
          <Person width={20} height={20} />
          Novo sub-usuário
        </Menu.Item>
        <Menu.Item key="3" onClick={userDeleteModal} className="gx-text-danger">
          <Trash width={20} height={20} />
          Excluir usuário
        </Menu.Item>
      </Menu>
    </>
  );
};

UserDropdown.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    hasContract: PropTypes.bool,
    contract: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default UserDropdown;
