import React from 'react';
import { Row, Col } from 'antd';

import PageTitle from 'components/PageTitle';

import ProductsList from './components/ProductsList';
import Wrapper from '../wrapper';

import './styles.less';

const Products = () => {
  return (
    <Wrapper>
      <Row align="middle">
        <Col span={24}>
          <PageTitle
            title="Produtos"
            subtitle="Gerencie subscrições e metodos de pagamentos"
          />
        </Col>
      </Row>

      <Row id="card-user-configs-component" align="middle">
        <Col span={24}>
          <ProductsList />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Products;
