import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, addDays } from 'date-fns';
import {
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Input,
  InputNumber,
  Divider,
  message,
  Alert,
  Tooltip,
  Checkbox,
  Popconfirm,
} from 'antd';

import { Add, Close, Lock, Trash } from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';
import { useTimeline } from 'hooks/timeline';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import InputMask from 'components/Form/InputMask';

import currency from 'utils/currencyFormatter';
import generateHash from 'utils/generateHash';
import formattedDate from 'utils/formattedDate';
import CurrencyTag from 'components/CurrencyTag';

import './styles.less';

const { Text, Title } = Typography;

const keyValueIuguError = {
  'payer.address.zip_code': 'CEP do cliente',
  due_date: 'Data de vencimento',
  total: 'Total da fatura',
};

const InvoiceCreate = ({ refreshListRef }) => {
  const { tenantId } = useParams();
  const { customer } = useCustomer();
  const { closeModalMandatory } = useTimeline();

  const { post: postInvoicePreview, loading: loadingPreview } = useFetch();
  const { post: postInvoice, loading: loadingCreate, error: errorCreate } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [total, setTotal] = useState(0);

  const currencySign = currency.getCurrencySign(customer?.currency);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    closeModalMandatory();
    setInvoiceItems([]);
    form.resetFields();
  }, [closeModalMandatory]); // eslint-disable-line

  const previewInvoice = useCallback(async () => {
    await form.validateFields(['customer', 'month', 'year']);

    const values = form.getFieldsValue();

    const payload = {
      tenantId,
      month: values.month.toString(),
      year: values.year.toString(),
      dueDate: values.dueDate,
      preview: true,
    };
    const preview = await postInvoicePreview({ url: '/invoices', payload });

    if (preview.items) {
      let previewItems = preview.items;

      if (previewItems.length === 0) {
        previewItems = [
          {
            name: '',
            totalExecutions: '',
            price: '',
          },
        ];
      }

      const items = previewItems.map((item) => {
        const key = generateHash();
        const row = {
          key,
          reportId: { key: `reportId.${key}`, value: item._id },
          template: { key: `template.${key}`, value: item.template },
          description: { key: `description.${key}`, value: item.name },
          quantity: { key: `quantity.${key}`, value: item.totalExecutions },
          price: { key: `price.${key}`, value: item.price },
          ignoreBilling: { key: `ignoreBilling.${key}`, value: !!item.ignoreBilling },
        };
        form.setFieldsValue({ [row.description.key]: row.description.value });
        form.setFieldsValue({ [row.quantity.key]: row.quantity.value });
        form.setFieldsValue({ [row.price.key]: row.price.value });
        return row;
      });

      setInvoiceItems(items);
    }
  }, [form, tenantId, postInvoicePreview]);

  const openModal = useCallback(() => {
    setModalVisible(true);

    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    const newValues = {
      month: month || 12,
      year: month ? year : year - 1,
    };

    if (customer?.invoiceDaysToExpire) {
      const dueDate = addDays(date, customer?.invoiceDaysToExpire);
      newValues.dueDate = format(dueDate, 'dd/MM/yyyy');
    }
    if (customer?.hasInvoiceExpirationDay && customer?.invoiceExpirationDay) {
      const dueDate = `${formattedDate(customer?.invoiceExpirationDay)}${formattedDate(
        month + 1
      )}${year}`;
      newValues.dueDate = dueDate;
    }

    form.setFieldsValue(newValues);

    setTimeout(() => {
      previewInvoice();
    }, 300);
  }, [customer, form, previewInvoice]);

  const addItemRow = useCallback(() => {
    const key = generateHash();
    const row = {
      key,
      reportId: { key: `reportId.${key}`, value: '' },
      template: { key: `template.${key}`, value: '' },
      description: { key: `description.${key}`, value: '' },
      quantity: { key: `quantity.${key}`, value: '' },
      price: { key: `price.${key}`, value: '' },
      ignoreBilling: { key: `ignoreBilling.${key}`, value: false },
    };
    setInvoiceItems((state) => state.concat([row]));
  }, []);

  const ignoreBilling = useCallback(
    (index, checked) => {
      const items = [...invoiceItems];

      items[index].ignoreBilling.value = checked;
      form.setFieldsValue({
        [items[index].ignoreBilling.key]: items[index].ignoreBilling.value,
      });

      setInvoiceItems(items);
    },
    [invoiceItems, form]
  );

  const removeItemRow = useCallback(
    (index) => {
      const items = [...invoiceItems];
      if (items.length === 1) {
        items[0].description.value = '';
        items[0].quantity.value = '';
        items[0].price.value = '';
        form.setFieldsValue({ [items[0].description.key]: items[0].description.value });
        form.setFieldsValue({ [items[0].quantity.key]: items[0].quantity.value });
        form.setFieldsValue({ [items[0].price.key]: items[0].price.value });
      } else {
        items.splice(index, 1);
      }
      setInvoiceItems(items);
    },
    [invoiceItems, form]
  );

  const calculateTotal = useCallback(() => {
    const values = form ? form.getFieldsValue() : {};
    const amount = invoiceItems.reduce((acc, cur) => {
      if (values[cur.quantity.key] && values[cur.price.key]) {
        if (!values[cur.ignoreBilling.key])
          return acc + values[cur.quantity.key] * values[cur.price.key];
      }
      return acc;
    }, 0);
    setTotal(amount);
  }, [form, invoiceItems]);

  useEffect(() => {
    if (invoiceItems) calculateTotal();
  }, [calculateTotal, invoiceItems]);

  const createInvoice = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();
    // eslint-disable-next-line array-callback-return
    invoiceItems.map((item) => {
      const valor = values[item.price.key];
      if (valor.toString().split('.')[1]?.length > 2) {
        values[item.description.key] = `${values[item.description.key]} (${
          values[item.quantity.key]
        } * ${values[item.price.key]})`;
        // eslint-disable-next-line no-shadow
        const total = (values[item.quantity.key] * values[item.price.key]).toFixed(2);
        values[item.quantity.key] = 1;
        values[item.price.key] = total;
      }
    });

    const payload = {
      tenantId,
      month: values.month,
      year: values.year,
      dueDate: values.dueDate,
      preview: false,
      clientId: customer._id,
      items: invoiceItems.map((item) => ({
        reportId: item.reportId?.value || null,
        templateId: item.template?.value || null,
        description: values[item.description.key],
        quantity: values[item.quantity.key],
        price: values[item.price.key],
        total: (values[item.price?.key] || 0) * (values[item.quantity?.key] || 0) || 0,
        ignoreBilling: values[item.ignoreBilling?.key] || false,
      })),
    };

    try {
      await postInvoice({
        url: '/invoices',
        payload,
      });

      refreshListRef.current();
      closeModal();
      message.success('Cobrança gerada com sucesso!');
    } catch (error) {
      message.error('Houve um problema ao gerar a cobrança.');
    }
  }, [form, tenantId, customer, invoiceItems, postInvoice, refreshListRef, closeModal]);

  const errorAlert = useMemo(() => {
    if (!errorCreate || errorCreate === true) return <></>;

    const error = errorCreate.error?.split(' - ');
    const errorFormatted =
      error?.length > 1
        ? { ...errorCreate, error: JSON.parse(error[1]) }
        : { ...errorCreate };

    let errorMessage = '';
    let description = '';

    if (errorFormatted.message.includes('Nibo')) {
      errorMessage = 'Houve um problema ao gerar a cobrança no Nibo.';
      description = errorFormatted.error?.error_description;
    } else if (errorFormatted.message.includes('Iugu')) {
      const { errors } = errorFormatted.error || {};

      errorMessage = 'Houve um problema ao gerar a cobrança na Iugu.';
      description = (
        <ul>
          {Object.keys(errors || {}).map((key) => (
            <li>{`${keyValueIuguError[key] || key} ${errors[key].join(', ')}.`}</li>
          ))}
        </ul>
      );
    }

    return (
      <Alert
        type="error"
        showIcon
        closable
        message={errorMessage}
        description={description}
      />
    );
  }, [errorCreate]);

  const handleValueFormatting = useCallback(
    (value) => {
      let newValue = '';
      if (`${value}`?.includes('-')) {
        newValue = currency.toCurrency(
          `${value}`.replaceAll(`-`, ``) || 0.0,
          currencySign
        );
        return `${newValue}`.replace(currencySign, `${currencySign} -`);
      }

      return currency.toCurrency(value || 0.0, currencySign);
    },
    [currencySign]
  );

  const handleValueParsing = (value) => {
    let newValue = '';
    if (`${value}`?.includes('-')) {
      newValue = currency.toNumber(`${value}`.replaceAll(`-`, ``));

      return `-${newValue || 0.0}`;
    }

    return currency.toNumber(value || 0.0);
  };

  const InvoiceCreateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal offset-timeline"
        closable
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        width={820}
        footer={null}
        maskClosable={false}
        destroyOnClose
        zIndex={1001}
        mask={false}
      >
        <div id="invoice-create-component">
          <div className="modal-header">
            <Row align="middle">
              <Col>
                <Title className="modal-title">Nova cobrança</Title>
              </Col>
              <Col>
                <CurrencyTag currency={customer?.currency} />
              </Col>
            </Row>
            <Text className="modal-subtitle">
              Preencha os dados para efetuar uma nova cobrança.
            </Text>
          </div>

          <Form
            form={form}
            layout="vertical"
            initialValues={{ customer: customer?.companyName || customer?.fantasyName }}
          >
            <Row>
              <Col span={8}>
                <Form.Item
                  name="customer"
                  label="Cliente"
                  rules={[{ required: true }]}
                  className="no-error"
                >
                  <Input disabled className="text-dark" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="month"
                  label="Mês"
                  rules={[{ required: true }]}
                  className="no-error"
                >
                  <InputNumber min={1} max={12} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="year"
                  label="Ano"
                  rules={[{ required: true }]}
                  className="no-error"
                >
                  <InputNumber min={2017} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="dueDate"
                  label="Vencimento"
                  rules={[{ required: true, len: 10 }]}
                  className="no-error"
                >
                  <InputMask mask="99/99/9999" />
                </Form.Item>
              </Col>
              <Col span={5} className="form-inline-button">
                <Form.Item>
                  <Button
                    className="btn-custom btn-custom-primary"
                    onClick={previewInvoice}
                    loading={loadingPreview}
                  >
                    {!loadingPreview ? 'Calcular fatura' : 'Aguarde...'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            {!loadingPreview && invoiceItems.length > 0 && (
              <>
                <div className="gx-flex-row gx-justify-content-between mrg-top-30 mrg-btm-15">
                  <Text className="text-bold text-dark">Cobrança</Text>

                  <button
                    type="button"
                    className="btn-simple flex center"
                    onClick={addItemRow}
                  >
                    <Add style={{ marginRight: 4 }} />
                    <span className="text-dark font-size-12">Adicionar item</span>
                  </button>
                </div>

                {invoiceItems.map((item, index) => (
                  <Row key={item.key}>
                    <Col span={10}>
                      <Form.Item
                        name={item.description.key}
                        label={index === 0 && 'Descrição'}
                        rules={[{ required: true, whitespace: true }]}
                        className="no-error"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={item.quantity.key}
                        label={index === 0 && 'Quantidade'}
                        rules={[{ required: true }]}
                        className="no-error"
                      >
                        <InputNumber min={0} onChange={calculateTotal} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name={item.price.key}
                        label={index === 0 && 'Valor unitário'}
                        rules={[{ required: true }]}
                        className="no-error"
                      >
                        <InputNumber
                          formatter={handleValueFormatting}
                          parser={handleValueParsing}
                          // className="no-arrow"
                          onChange={calculateTotal}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        name={item.ignoreBilling.key}
                        label={index === 0 && 'Ignorar'}
                        className="no-error"
                      >
                        <Checkbox
                          onChange={(value) => ignoreBilling(index, value.target.checked)}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} className="form-inline-button">
                      <Form.Item>
                        {!!item.reportId?.value && !!item.template?.value ? (
                          <Tooltip title='Este item não pode ser excluido da fatura, para evitar a cobrança, favor marcar a opção "Ignorar"'>
                            <Button
                              shape="circle"
                              type="link"
                              block
                              icon={<Lock width={20} height={20} />}
                              className="no-border no-shadow btn-trash disabled flex center"
                            />
                          </Tooltip>
                        ) : (
                          <Button
                            shape="circle"
                            type="link"
                            block
                            icon={<Trash width={20} height={20} />}
                            className="no-border no-shadow btn-trash flex center"
                            onClick={() => removeItemRow(index)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Divider />
              </>
            )}

            {customer?.additionalInfo && (
              <>
                <Text className="text-dark">Observações:</Text>
                <Alert description={customer?.additionalInfo} className="mrg-top-10" />
                <Divider />
              </>
            )}

            <div
              className="gx-flex-row gx-justify-content-between mrg-top-30 mrg-btm-10"
              style={{ opacity: 0.8 }}
            >
              <Text className="text-bold text-dark font-size-18">Total da fatura:</Text>
              <Text className="text-bold text-dark font-size-18">
                {currency.toCurrencyTwoDigits(total, currencySign)}
              </Text>
            </div>

            {customer?.minimumInvoice && (
              <div className="font-size-16 mrg-btm-15">
                <Text className="display-block">Valor mínimo utilizável*</Text>
                <Text className="text-bold">
                  {currency.toCurrencyTwoDigits(
                    customer?.minimumInvoiceAmount,
                    currencySign
                  )}
                </Text>
              </div>
            )}

            {errorAlert}

            <div className="gx-flex-row gx-justify-content-between mrg-top-50">
              <Button type="default" onClick={closeModal} className="btn-cancel">
                Cancelar
              </Button>
              {customer?.minimumInvoice && total < customer?.minimumInvoiceAmount ? (
                <Popconfirm
                  title="Cobrança abaixo do mínimo utilizável, deseja continuar?"
                  onConfirm={createInvoice}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button
                    className="btn-custom btn-custom-primary"
                    disabled={
                      loadingPreview ||
                      loadingCreate ||
                      total <= 0 ||
                      customer?.notBillable ||
                      (customer?.currency && customer?.currency !== 'BRL')
                    }
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      customer?.notBillable
                        ? 'Cliente configurado como não faturável'
                        : customer?.currency && customer?.currency !== 'BRL'
                        ? 'Não é possível gerar cobrança com moeda internacional'
                        : ''
                    }
                    loading={loadingCreate}
                  >
                    {loadingCreate ? 'Gerando cobrança...' : 'Gerar nova cobrança'}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  className="btn-custom btn-custom-primary"
                  onClick={createInvoice}
                  title={
                    // eslint-disable-next-line no-nested-ternary
                    customer?.notBillable
                      ? 'Cliente configurado como não faturável'
                      : customer?.currency && customer?.currency !== 'BRL'
                      ? 'Não é possível gerar cobrança com moeda internacional'
                      : ''
                  }
                  disabled={
                    loadingPreview ||
                    loadingCreate ||
                    total <= 0 ||
                    customer?.notBillable ||
                    (customer?.currency && customer?.currency !== 'BRL')
                  }
                  loading={loadingCreate}
                >
                  {loadingCreate ? 'Gerando cobrança...' : 'Gerar nova cobrança'}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      customer,
      previewInvoice,
      loadingPreview,
      invoiceItems,
      addItemRow,
      total,
      errorAlert,
      createInvoice,
      loadingCreate,
      calculateTotal,
      ignoreBilling,
      removeItemRow,
      currencySign,
      handleValueFormatting,
    ]
  );

  return { openModal, closeModal, InvoiceCreateModal };
};

export default InvoiceCreate;
