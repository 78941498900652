import React, { useEffect, useRef } from 'react';

import { format } from 'date-fns';

import { useTable } from 'components/List';
import Button from 'components/Button';

import Wrapper from '../../wrapper';
import useTrackingModal from '../../../components/TrackingModal';

const TrackingList = () => {
  const refreshListRef = useRef(() => {});

  const { openModal, TrackingModal } = useTrackingModal();

  const columns = [
    {
      key: 'sourceProject',
      title: 'Projeto',
      dataIndex: 'sourceProject',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
    },
    {
      key: 'sourceIp',
      title: 'IP',
      dataIndex: 'sourceIp',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
    },
    {
      title: 'Marca',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (__, record) => {
        return <>{record?.otherInfo?.brand}</>;
      },
    },
    {
      title: 'Modelo',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (__, record) => {
        return <>{record?.otherInfo?.model}</>;
      },
    },
    {
      key: 'createdAt',
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (text) => {
        try {
          return format(new Date(text), "dd/MM/yyyy 'às' HH:mm");
        } catch (error) {
          return '';
        }
      },
    },
    {
      render: (__, record) => {
        return (
          <Button
            className="no-mrg btn-custom btn-custom-primary"
            onClick={() => {
              openModal(record.trackingId);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  const { tableContent, refreshList } = useTable({
    columns,
    getParams: {
      url: '/analytics/tracking',
      config: {
        params: {
          _users: true,
        },
      },
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      {TrackingModal}
      {tableContent}
    </Wrapper>
  );
};

export default TrackingList;
