import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Typography, Input } from 'antd';
import Button from 'components/Button';

import { useFetch } from 'services/hooks';
import { CheckCircle2 } from '@combateafraude/icons/general';

import './styles.less';

const { Title, Text } = Typography;

const SaveFilterModal = ({ isVisible, onClose, filters }) => {
  const [filterName, setFilterName] = useState('');
  const [saved, setSaved] = useState(false);

  const { post: saveFilterRequest, loading: savingFilter } = useFetch();

  const filterNameInputHandler = useCallback((event) => {
    setFilterName(event.target.value);
  }, []);

  const closeHandler = useCallback(() => {
    setFilterName('');
    setSaved(false);
    onClose();
  }, [onClose]);

  const saveFilterHandler = useCallback(async () => {
    await saveFilterRequest({
      url: '/query-filters',
      payload: {
        name: filterName,
        filters,
      },
    }).then((response) => {
      if (response?.statusCode === 200) {
        setSaved(true);
      }
    });
  }, [saveFilterRequest, filters, filterName]);

  return (
    <Modal
      visible={isVisible}
      wrapClassName="caf-modal confirm-action"
      destroyOnClose
      closable
      onCancel={closeHandler}
      footer={null}
      width={600}
    >
      {!saved && (
        <>
          <div align="left">
            <Title className="title">Nomeie e salve o filtro</Title>
            <div className="filter-input">
              <Text className="filter-label">Nome</Text>
              <Input
                type="text"
                autoFocus
                placeholder="Digite um nome para o filtro..."
                onChange={filterNameInputHandler}
                value={filterName}
              />
            </div>
          </div>
          <div align="right" className="actions">
            <Button onClick={closeHandler} type="link" className="link-button">
              Cancelar
            </Button>
            <Button
              onClick={saveFilterHandler}
              disabled={filterName.trim().length === 0}
              loading={savingFilter}
              className="btn-custom btn-custom-primary"
            >
              Salvar
            </Button>
          </div>
        </>
      )}
      {saved && (
        <div align="middle" justify="center">
          <CheckCircle2 className="tooltip" width={60} height={60} />
          <Title className="title">Filtro salvo!</Title>
        </div>
      )}
    </Modal>
  );
};

SaveFilterModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.shape.isRequired,
};

export default SaveFilterModal;
