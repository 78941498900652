import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, message, Select, Typography } from 'antd';

import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';

import uppercaseObject from 'utils/uppercaseObject';
import isValidJson from './utils/isValidJson';

import TypeRG from './TypeRG';
import TypeCNH from './TypeCNH';
import TypeRNE from './TypeRNE';
import TypeCRLV from './TypeCRLV';
import TypeOthers from './TypeOthers';
import TypePassport from './TypePassport';
import TypeCTPS from './TypeCTPS';
import TypeRNM from './TypeRNM';

const { Option } = Select;
const { Paragraph } = Typography;

const OCRDetails = ({ imageTabs, complianceRules, parameters }) => {
  const { tenantId, reportId, executionId } = useParams();
  const { loading, put } = useFetch();
  const { executionData, reloadData } = useExecution();

  const [form] = Form.useForm();

  const [customFormType, setCustomFormType] = useState(
    executionData?.executions?.type?.toUpperCase()
  );
  const [invalidParams, setInvalidParams] = useState({});

  const copyHandler = useCallback(
    (fieldName) => {
      const data = form.getFieldValue(fieldName);
      navigator.clipboard.writeText(data || '');
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        delete values.parameters;

        let formattedValues = values;
        let responseValues = {};

        const { cnhIssueDate, issueDate } = values;

        if (cnhIssueDate && !issueDate) {
          formattedValues = { ...formattedValues, issueDate: cnhIssueDate };
        }

        const validValues = JSON.stringify(formattedValues, (_, value) => {
          return value === 'UNDEFINED' || value === undefined ? '' : value;
        });

        if (isValidJson(validValues)) {
          responseValues = uppercaseObject(JSON.parse(validValues));
        } else {
          responseValues = uppercaseObject(formattedValues);
        }

        const { sections } = executionData.executions || {};
        const { ocr } = sections || {};

        await put({
          url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/manual-ocr`,
          payload: {
            oldData: ocr,
            newData: {
              ...ocr,
              ...responseValues,
            },
          },
        });

        message.success('Dados atualizados com sucesso.');
        reloadData(false);
      } catch (error) {
        message.error('Houve um problema ao atualizar os dados.');
      }
    },
    [put, tenantId, reportId, executionId, executionData, reloadData]
  );

  useEffect(() => {
    if (!executionData) return;

    const { sections } = executionData.executions || {};
    const { ocr } = sections || {};
    const { ocrInvalidFields } = executionData.complianceRules;
    setInvalidParams(ocrInvalidFields);

    form.setFieldsValue({ ...ocr });
  }, [form, executionData]);

  return (
    <div id="ocr-details-component" className="flex-column flex-1">
      <PageTitle
        title={
          executionData?.executions?.status === 'PENDENTE OCR'
            ? 'Ação requerida: confirme os dados do OCR abaixo'
            : 'Detalhamento do OCR'
        }
        subtitle="Avalie as imagens e confirme os dados lidos pelo OCR."
      />
      <Card className="gx-h-100">
        <Row align="start" justify="center">
          <Col span={12}>{imageTabs}</Col>
          <Col span={12}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
              className="uppercase-form"
            >
              {executionData && (
                <>
                  {(executionData?.executions?.type?.toUpperCase() === 'OUTROS' ||
                    executionData?.executions?.type === null) && (
                    <Row className="flex start-center no-mrg-horizon mrg-btm-25">
                      <Paragraph className="no-mrg-btm mrg-right-10 text-dark text-semibold">
                        Tipo de formulário:
                      </Paragraph>
                      <Select
                        bordered={false}
                        placeholder="Selecione"
                        onChange={setCustomFormType}
                        value={customFormType}
                        className="custom-select-input text-dark"
                      >
                        <Option value="RG">RG</Option>
                        <Option value="CNH">CNH</Option>
                        <Option value="RNE">RNE</Option>
                        <Option value="CRLV">CRLV</Option>
                        <Option value="RNM">RNM</Option>
                        <Option value="CTPS">CTPS</Option>
                        <Option value="PASSPORT">PASSPORT</Option>
                        <Option value="OUTROS">Outros</Option>
                      </Select>
                    </Row>
                  )}
                  {customFormType === 'RG' && (
                    <TypeRG onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'CNH' && (
                    <TypeCNH onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'RNE' && (
                    <TypeRNE onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'CRLV' && (
                    <TypeCRLV onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'RNM' && (
                    <TypeRNM onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'CTPS' && (
                    <TypeCTPS onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {customFormType === 'PASSPORT' && (
                    <TypePassport onCopy={copyHandler} invalidParams={invalidParams} />
                  )}
                  {(customFormType === 'OUTROS' ||
                    (customFormType !== 'RG' &&
                      customFormType !== 'CNH' &&
                      customFormType !== 'RNE' &&
                      customFormType !== 'CRLV' &&
                      customFormType !== 'RNM' &&
                      customFormType !== 'CTPS' &&
                      customFormType !== 'PASSPORT')) && (
                    <TypeOthers onCopy={copyHandler} invalidParams={invalidParams} />
                  )}

                  {!customFormType && (
                    <div className="custom-form-message flex center gx-w-100">
                      <Paragraph>Por favor, informe o tipo de formulário.</Paragraph>
                    </div>
                  )}
                </>
              )}

              {customFormType && (
                <Button
                  htmlType="submit"
                  loading={loading}
                  className="mrg-top-20 btn-custom btn-custom-primary"
                >
                  Confirmar
                </Button>
              )}
            </Form>
          </Col>
        </Row>
        <Row className="ocr-details-extra-components mrg-top-25">
          <Col span={16}>{complianceRules}</Col>
          <Col span={8}>{parameters}</Col>
        </Row>
      </Card>
    </div>
  );
};

OCRDetails.propTypes = {
  imageTabs: PropTypes.node.isRequired,
  complianceRules: PropTypes.node.isRequired,
  parameters: PropTypes.node.isRequired,
  // setWhichComponentIsActive: PropTypes.func.isRequired,
};

export default OCRDetails;
