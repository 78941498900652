/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from 'antd';
import _ from 'lodash';
import { useQueryParams, StringParam } from 'use-query-params';

import PageTitle from 'components/PageTitle';
import SearchInput from 'components/Form/SearchInput';
import { useDynamoTable } from 'components/List';

import currency from 'utils/currencyFormatter';
import { Reorder } from '@combateafraude/icons/general';
import Wrapper from '../wrapper';

import './styles.less';

const DatasourcesList = () => {
  const [query] = useQueryParams({
    _search: StringParam,
  });

  const columns = [
    {
      key: 'source',
      title: (
        <div className="flex center row">
          <span className="column-title">Fonte</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'source',
      sorter: (a, b) => a.source.localeCompare(b.source),
      defaultSortOrder: 'ascend',
      render: (__, record) => (
        <NavLink to={`/datasources/${record?.source}`}>
          <span className="flex align-center">{record?.source}</span>
        </NavLink>
      ),
    },
    {
      key: 'title',
      title: (
        <div className="flex center row">
          <span className="column-title">Título</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (__, record) => (
        <span className="flex align-center text-dark">{record?.title}</span>
      ),
    },
    {
      key: 'price',
      title: (
        <div className="flex center row">
          <span className="column-title">Preço</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (__, record) => (
        <span className="flex align-center text-dark">
          {currency.toCurrencyTwoDigits(record?.price, 'R$')}
        </span>
      ),
    },
    {
      key: 'status',
      title: (
        <div className="flex center row">
          <span className="column-title">Status</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'status',
      render: (__, record) => (
        <span className="flex align-center text-dark">{record?.status || '-'}</span>
      ),
    },
  ];

  const { tableContent, setFilterData } = useDynamoTable({
    columns,
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/services`,
      config: {},
    },
    queryParams: {
      _search: StringParam,
    },
    rowKey: '_id',
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper id="datasources-list-wrapper-component">
      <div className="flex gx-w-100 mrg-btm-25 space-between">
        <PageTitle
          title="Fontes de dados"
          subtitle="Visualize e edite fontes de dados."
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex"
            onValuesChange={handleFormChange}
            initialValues={{
              _search: query?._search,
            }}
          >
            <SearchInput placeholder="Busque pelo nome da fonte..." />
          </Form>
        </div>
      </div>
      <div className="gx-w-100">{tableContent}</div>
    </Wrapper>
  );
};

export default DatasourcesList;
