import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Form, message, Typography, Spin } from 'antd';
import { Settings, AlertTriangle, PenEdit } from '@combateafraude/icons/general';

import { useFetch } from 'services/hooks';
import { useCustomer } from 'hooks/customer';

import EmptyMessage from 'components/EmptyMessage';
import Button from 'components/Button';
import generateHash from 'utils/generateHash';

import Card from 'components/Card';

import CurrencyTag from 'components/CurrencyTag';
import currency from 'utils/currencyFormatter';
import Wrapper from '../wrapper';

import CustomDataCard from './components/CustomDataCard';
import customDataCategories from './utils/customDataCategories.json';
import useConfirmPriceModal from './components/ConfirmCustomPriceModal';

import './styles.less';

const { Title, Text } = Typography;
const validCategories = [
  'basic_info',
  'onboarding',
  'finance',
  'deprecated',
  'driver',
  'criminal_background',
  'work',
  'media',
  'contact',
  'restrictive_lists',
  'justice',
  'others',
];

const arraysAreEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays to ensure the elements are in the same order
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare each element in the sorted arrays
  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  // If all elements match, the arrays are equal
  return true;
};

const CustomPrice = () => {
  const { tenantId } = useParams();
  const { data: customData, get: getCustomData, loading } = useFetch();
  const { customer, loadCustomer } = useCustomer();
  const [isEditing, setIsEditing] = useState(false);
  const [items, setItems] = useState([]);
  const [payload, setPayload] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);

  const [form] = Form.useForm();

  const customDataRef = useRef();
  const { docs: customDataDocs } = customData || [];

  const currencySign = currency.getCurrencySign(customer?.currency);

  const toggleEdit = useCallback(() => {
    setIsEditing((state) => !state);
  }, []);

  const handleValueFormatting = useCallback(
    (value) => {
      let newValue = '';
      if (`${value}`?.includes('-')) {
        newValue = currency.toCurrencyTwoDigits(
          `${value}`.replaceAll(`-`, ``) || '',
          currencySign
        );
        return `${newValue}`.replace(currencySign, `${currencySign} -`);
      }

      return currency.toCurrencyTwoDigits(value || '', currencySign);
    },
    [currencySign]
  );

  const fetch = useCallback(async () => {
    try {
      await Promise.all([
        getCustomData({
          url: `/clients/${tenantId}/custom-price`,
        }),
        loadCustomer({ tenantId }),
      ]);
    } catch (error) {
      message.error('Houve um problema ao buscar alguns dados.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, getCustomData, loadCustomer]);

  const { openModal, CustomPriceModal } = useConfirmPriceModal({
    payload,
    tenantId,
    fetch,
    setIsEditing,
    setAvailableServices,
  });

  const firstLoad = useRef(true);

  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetch();
  }, [fetch]);

  useEffect(() => {
    setAvailableServices(customer?.availableServices || []);
  }, [customer]);

  const customDataFormatted = useMemo(() => {
    if (!customData) return [];

    const getDocs = (documents) => {
      const pfDocs = documents.filter(
        (doc) => !doc.source.value.startsWith('pj_') && doc.type !== 'others'
      );
      const pjDocs = documents.filter((doc) => doc.source.value.startsWith('pj_'));
      const others = documents.filter((doc) => doc.type === 'others');

      return {
        others,
        pfDocs,
        pjDocs,
      };
    };

    const docs = customDataDocs.map((item) => {
      const key = generateHash();

      if (item.source === 'authentic_document') {
        return '';
      }

      const row = {
        key,
        type: item.type ? item.type : 'others',
        category: validCategories.includes(item.category) ? item.category : 'others',
        status: item.status ? item.status : 'ACTIVE',
        productType: item.productType,
        title: {
          key: `title.${key}`,
          value: item.restrictedTo?.length > 0 ? `(Privado) ${item.title}` : item.title,
        },
        source: { key: `source.${key}`, value: item.source },
        price: { key: `price.${key}`, value: handleValueFormatting(item.price) },
        customPrice: {
          key: `customPrice.${key}`,
          value: item.customPrice
            ? handleValueFormatting(item.customPrice)
            : item.customPrice,
        },
        serviceIsAvailable: {
          key: `serviceIsAvailable.${key}`,
          value: availableServices?.find((service) => service === item.source),
        },
      };
      form.setFieldsValue({ [row.title.key]: row.title.value });
      form.setFieldsValue({ [row.source.key]: row.source.value });
      form.setFieldsValue({ [row.price.key]: row.price.value });
      form.setFieldsValue({ [row.customPrice.key]: row.customPrice.value });
      form.setFieldsValue({ [row.serviceIsAvailable.key]: row.serviceIsAvailable.value });
      return row;
    });

    setItems(docs.filter((doc) => validCategories.includes(doc.category)));

    const customDataDocsValidCategories = docs
      .filter((doc) => validCategories.includes(doc.category))
      .map((doc) => ({
        ...doc,
        category: doc.category,
      }));

    const categoryKeys = customDataDocsValidCategories.map((d) => d.category);
    const uniqueKeys = _.uniqBy(categoryKeys);

    const result = uniqueKeys.map((category) => {
      const documents = customDataDocsValidCategories.filter((cd) =>
        cd.category.includes(category)
      );

      return {
        category: customDataCategories[category],
        docs: getDocs(documents),
      };
    });

    customDataRef.current = result;

    return result;
  }, [customData, customDataDocs, form, availableServices, handleValueFormatting]);

  useMemo(async () => {
    if (payload?.docs?.length > 0) openModal();
  }, [payload, openModal]);

  const handleValues = useCallback(async () => {
    const values = form.getFieldsValue();
    const docs = [];
    const docsItems = items.slice();

    let newAvailables = [...availableServices];

    // eslint-disable-next-line array-callback-return
    docsItems.map((item) => {
      if (item.customPrice.value) {
        item.oldPrice = item.customPrice.value
          // eslint-disable-next-line no-irregular-whitespace
          .replaceAll(`${currencySign} `, '')
          .replaceAll(`${currencySign} `, '')
          .replaceAll(',', '.');
      } else {
        item.oldPrice = item.price.value
          // eslint-disable-next-line no-irregular-whitespace
          .replaceAll(`${currencySign} `, '')
          .replaceAll(`${currencySign} `, '')
          .replaceAll(',', '.');
      }

      if (values[item.customPrice.key] !== 0 && !values[item.customPrice.key]) {
        values[item.customPrice.key] = undefined;
      }
      if (values[item.customPrice.key] !== item.customPrice.value) {
        let newValue = values[item.customPrice.key];
        if (values[item.customPrice.key]) {
          newValue = values[item.customPrice.key]
            // eslint-disable-next-line no-irregular-whitespace
            .replaceAll(`${currencySign} `, '')
            .replaceAll(`${currencySign} `, '')
            .replaceAll(',', '.');
        }
        values[item.customPrice.key] = newValue;

        docs.push(item);
      }

      if (!!values[item.serviceIsAvailable.key] !== !!item.serviceIsAvailable.value) {
        let newValue = values[item.customPrice.key];
        if (values[item.customPrice.key]) {
          newValue = values[item.customPrice.key]
            // eslint-disable-next-line no-irregular-whitespace
            .replaceAll(`${currencySign} `, '')
            .replaceAll(`${currencySign} `, '')
            .replaceAll(',', '.');
        }
        values[item.customPrice.key] = newValue;

        if (values[item.serviceIsAvailable.key] === false) {
          newAvailables = newAvailables.filter((newAv) => newAv !== item.source.value);
        }

        if (!docs.includes(item)) {
          docs.push(item);
        }
      }
    });

    if (docs.length > 0) {
      const availablesToBeAdded = docs
        .filter((doc) => values[doc.serviceIsAvailable.key])
        .map((doc) => doc.source.value);

      let availableServicesData = null;
      if (
        !arraysAreEqual(
          Array.from(new Set([...newAvailables, ...availablesToBeAdded])),
          customer?.availableServices || []
        )
      ) {
        availableServicesData = [...newAvailables, ...availablesToBeAdded];
      }

      setPayload({
        docs: docs.map((doc) => ({
          customPrice:
            values[doc.customPrice.key] !== undefined
              ? values[doc.customPrice.key]
              : null,
          oldPrice: doc.oldPrice,
          source: doc.source.value,
          title: doc.title.value,
          serviceIsAvailable: values[doc.serviceIsAvailable.key],
        })),
        availableServices: availableServicesData,
      });
    }
  }, [form, items, availableServices, currencySign, customer]);

  const handleSelectAll = useCallback(
    (productType, value) => {
      items.forEach((item) => {
        if (item?.productType === productType && item?.status !== 'DEPRECATED') {
          form.setFieldsValue({
            [`serviceIsAvailable.${item.key}`]: value,
          });
        }
      });
    },
    [form, items]
  );

  return (
    <Wrapper>
      {CustomPriceModal}
      {!loading ? (
        <Form form={form}>
          <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
            <Col flex={1} className="page-title">
              <Row align="middle">
                <Title className="title">Gestão de Produtos e Serviços</Title>
                <CurrencyTag currency={customer?.currency} />
              </Row>
              <Text className="subtitle">
                Gerencie preços, produtos e serviços do cliente.
              </Text>
            </Col>
            <Col span={5} className="flex end-center">
              {!isEditing ? (
                <Button onClick={toggleEdit} className="mrg-right-5 flex start-center">
                  <PenEdit width={24} height={24} style={{ marginRight: 4 }} />
                  Editar
                </Button>
              ) : (
                <Button
                  htmlType="button"
                  onClick={handleValues}
                  className="btn-custom btn-custom-primary flex start-center"
                >
                  <Settings width={24} height={24} style={{ marginRight: 4 }} />
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <div id="price-products-component">
            <Form.Item name="customData">
              {customDataFormatted.find(
                (cdf) =>
                  cdf.docs.pfDocs.length !== 0 ||
                  cdf.docs.pjDocs.length !== 0 ||
                  cdf.docs.others.length !== 0
              ) ? (
                <Card className="product-type">
                  <Col className="card-header">
                    <Title level={2}>Fontes</Title>
                    {isEditing && (
                      <p>
                        <Button
                          className="btn-custom btn-custom-danger"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('source', false);
                          }}
                        >
                          Remover todos
                        </Button>
                        <Button
                          className="btn-custom btn-custom-primary"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('source', true);
                          }}
                        >
                          Adicionar todos
                        </Button>
                      </p>
                    )}
                  </Col>
                  <Row>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [0, 1, 3, 4, 5, 6, 11].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              productType="source"
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [2, 7, 8, 9, 10].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              productType="source"
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                  </Row>
                </Card>
              ) : (
                <EmptyMessage description="Nenhum resultado encontrado." withCard />
              )}
              {customDataFormatted.find(
                (cdf) =>
                  cdf.docs.pfDocs.length !== 0 ||
                  cdf.docs.pjDocs.length !== 0 ||
                  cdf.docs.others.length !== 0
              ) ? (
                <Card className="product-type">
                  <Col className="card-header">
                    <Title level={2}>Serviços</Title>
                    {isEditing && (
                      <p>
                        <Button
                          className="btn-custom btn-custom-danger"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('service', false);
                          }}
                        >
                          Remover todos
                        </Button>
                        <Button
                          className="btn-custom btn-custom-primary"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('service', true);
                          }}
                        >
                          Adicionar todos
                        </Button>
                      </p>
                    )}
                  </Col>
                  <Row>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [0, 1, 3, 4, 5, 6, 11].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              productType="service"
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [2, 7, 8, 9, 10].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              productType="service"
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                  </Row>
                </Card>
              ) : (
                <EmptyMessage description="Nenhum resultado encontrado." withCard />
              )}
              {customDataFormatted.find(
                (cdf) =>
                  cdf.docs.pfDocs.length !== 0 ||
                  cdf.docs.pjDocs.length !== 0 ||
                  cdf.docs.others.length !== 0
              ) ? (
                <Card className="product-type">
                  <Col className="card-header">
                    <Title level={2}>Templates</Title>
                    {isEditing && (
                      <p>
                        <Button
                          className="btn-custom btn-custom-danger"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('template', false);
                          }}
                        >
                          Remover todos
                        </Button>
                        <Button
                          className="btn-custom btn-custom-primary"
                          disabled={!isEditing}
                          onClick={() => {
                            handleSelectAll('template', true);
                          }}
                        >
                          Adicionar todos
                        </Button>
                      </p>
                    )}
                  </Col>
                  <Row>
                    <Col span={12}>
                      {customDataFormatted.map((cdf) => (
                        <CustomDataCard
                          key={cdf.category.icon}
                          customData={cdf}
                          formRef={form}
                          isEditing={isEditing}
                          productType="template"
                          currency={customer?.currency}
                        />
                      ))}
                    </Col>
                  </Row>
                </Card>
              ) : (
                <EmptyMessage description="Nenhum resultado encontrado." withCard />
              )}
              {customDataFormatted.find(
                (cdf) =>
                  cdf.docs.pfDocs.length !== 0 ||
                  cdf.docs.pjDocs.length !== 0 ||
                  cdf.docs.others.length !== 0
              ) ? (
                <Card className="product-type">
                  <Col className="card-header-deprecated">
                    <AlertTriangle
                      width={36}
                      height={36}
                      className="gx-text-danger"
                      style={{ marginRight: 10 }}
                    />
                    <Title level={2}>Produtos e Serviços Descontinuados</Title>
                  </Col>
                  <Row>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [0, 1, 3, 4, 5, 6, 11].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                    <Col span={12}>
                      {customDataFormatted.map(
                        (cdf, index) =>
                          [2, 7, 8, 9, 10].includes(index) && (
                            <CustomDataCard
                              key={cdf.category.icon}
                              customData={cdf}
                              formRef={form}
                              isEditing={isEditing}
                              status="DEPRECATED"
                              currency={customer?.currency}
                            />
                          )
                      )}
                    </Col>
                  </Row>
                </Card>
              ) : (
                <EmptyMessage description="Nenhum resultado encontrado." withCard />
              )}
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Spin />
      )}
    </Wrapper>
  );
};

export default CustomPrice;
