import React, { useMemo, memo, useCallback, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
  message,
} from 'antd';
import {
  Search,
  Criminal,
  RotateCw,
  AlertTriangle,
  ArrowDown,
  ProcessingLine,
  Duplicate,
} from '@combateafraude/icons/general';

import { Link, useParams } from 'react-router-dom';

import { useExecution } from 'hooks/execution';

import Button from 'components/Button';
import Tag from 'components/Tag';

import { toFormatDate } from 'utils/formatters';
import { useFetch } from 'services/hooks/index';
import StatusChangeSelector from './components/StatusChangeSelector';
import useReprocessModal from './components/ReprocessModal';
import useRejectExecutionModal from './components/RejectExecutionModal';
import useApproveExecutionModal from './components/ApproveExecutionModal';
import useSharedFacesetExecutionModal from './components/SharedFacesetExecutionModal';
import useSetPendingExecutionModal from './components/SetExecutionToPendingModal';
import './styles.less';
import MenuItemDropDown from './components/MenuItemDropdown';
import OpenAwsExecution from './components/OpenAwsExecution';

const { Title, Text } = Typography;

const ExecutionSubheader = ({ ...rest }) => {
  const { tenantId, executionId } = useParams();
  const { executionData, loadingExecution } = useExecution();
  const { get: getCustomers, data: customers } = useFetch();
  const { post: postWecheck } = useFetch();
  const { openModal: openReprocessModal, reprocessModal } = useReprocessModal(
    executionData?.executions?.status
  );
  const { openModal: openRejectExecutionModal, rejectExecutionModal } =
    useRejectExecutionModal(
      null,
      null,
      executionData?.executions?.sections?.documentscopy ||
        executionData?.executions?.template?.services?.includes('authentic_document')
    );
  const { openModal: openApproveExecutionModal, approveExecutionModal } =
    useApproveExecutionModal();
  const { openModal: openSetPendingExecutionModal, pendingExecutionModal } =
    useSetPendingExecutionModal();

  const { openModal: openSharedFacesetExecutionModal, sharedFacesetExecutionModal } =
    useSharedFacesetExecutionModal();

  const [copy, setCopy] = useState('Copiar ID');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getCustomers({
      url: '/clients',
      config: {
        params: {
          _offset: 0,
          _sort: 'fantasyName',
          _order: 1,
          _returnDocuments: false,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const client = useMemo(() => {
    return customers?.docs?.find((customer) => customer?.tenantId === tenantId);
  }, [customers, tenantId]);

  const copyIdExecution = useCallback(() => {
    const { _id } = executionData.executions || {};
    const inputCopy = document.createElement('input');
    inputCopy.value = _id;
    document.body.appendChild(inputCopy);
    inputCopy.select();

    document.execCommand('copy');
    setCopy('Copiado');

    setTimeout(() => {
      setCopy('Copiar ID');
    }, 1000);
  }, [executionData]);

  const reprocessExecutionInTscopyStateOfAWS = useCallback(async () => {
    try {
      const url = `/executions-caf/users/${tenantId}/reports/${executionData?.executions?.report?._id}/executions/${executionId}/retry`;

      await postWecheck({
        url,
        payload: { ...executionData.executions },
      });
      message.success('Documentoscopia atualizada com sucesso.');
    } catch (err) {
      message.error('Não foi possível atualizar a documentoscopia.');
    }
  }, [executionData, executionId, tenantId, postWecheck]);

  const confirmReprocessingInTscopyStateOfAWS = useCallback(() => {
    Modal.confirm({
      title: 'Você tem certeza que deseja atualizar este documento na documentoscopia?',
      okText: 'Sim, atualizar',
      okType: 'danger',
      cancelText: 'Não, cancelar',
      onOk: reprocessExecutionInTscopyStateOfAWS,
    });
  }, [reprocessExecutionInTscopyStateOfAWS]);

  const goToExecutionOnTheTscopyStateOfAWS = useCallback(() => {
    const ambient = process.env.REACT_APP_ENV;

    const url = `https://console.aws.amazon.com/states/home?region=us-east-1#/statemachines/view/arn:aws:states:us-east-1:202255071295:stateMachine:${ambient}_wecheck`;

    window.open(url, '_blank');
  }, []);

  const goToExecutionInWecheckOrTscopy = useCallback(() => {
    let ambient = process.env.REACT_APP_ENV;

    if (process.env.REACT_APP_ENV === 'prod') {
      ambient = '';
    } else {
      ambient = `${process.env.REACT_APP_ENV}.`;
    }

    const { recordId = null, type = null } =
      executionData?.executions?.sections?.documentscopy?.review?.queue;

    let url;
    if (recordId) {
      url = `https://${ambient}wecheck.combateafraude.com/${type}/records/details/record/${recordId}`;
    } else {
      url = `https://${ambient}tscopy.combateafraude.com/admin/executions/${executionId}`;
    }

    window.open(url, '_blank');
  }, [executionId, executionData]);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={goToExecutionOnTheTscopyStateOfAWS}>
          <MenuItemDropDown
            text="Abrir a lista de execuções na AWS"
            icon={<Search width={20} height={20} />}
          />
        </Menu.Item>
        <Menu.Item key="2" onClick={goToExecutionInWecheckOrTscopy}>
          <MenuItemDropDown
            text="Abrir a execução na Documentoscopia"
            icon={<Criminal width={20} height={20} />}
          />
        </Menu.Item>

        <Menu.Item key="3" onClick={confirmReprocessingInTscopyStateOfAWS}>
          <MenuItemDropDown
            text="Atualizar documentoscopia no Wecheck"
            icon={<RotateCw width={20} height={20} />}
          />
        </Menu.Item>
      </Menu>
    ),
    [
      goToExecutionInWecheckOrTscopy,
      goToExecutionOnTheTscopyStateOfAWS,
      confirmReprocessingInTscopyStateOfAWS,
    ]
  );

  const renderDocumentscopy = useCallback(() => {
    const { documentscopy } = executionData?.executions?.sections || {};

    if (
      documentscopy?.review?.step === 'standard' &&
      documentscopy?.review?.status !== 'evaluated'
    ) {
      return (
        <Tooltip title="Documento está aguardando avaliação na fila Standard">
          <span className="gx-text-info"> Standard</span>
        </Tooltip>
      );
    }

    if (
      documentscopy?.review?.step === 'advanced' &&
      documentscopy?.review?.status !== 'evaluated'
    ) {
      return (
        <Tooltip title="Documento está aguardando avaliação na fila Advanced">
          <span className="gx-text-info"> Advanced</span>
        </Tooltip>
      );
    }

    if (
      !documentscopy?.review?.evaluated &&
      documentscopy?.review?.queue?.solutionLevel
    ) {
      const { solutionLevel = null } = documentscopy?.review?.queue;

      const solutionLevelWithFirstLetterCapitalized =
        solutionLevel && solutionLevel[0].toUpperCase() + solutionLevel.slice(1);

      return (
        <Tooltip
          title={`Documento está aguardando avaliação na fila ${solutionLevelWithFirstLetterCapitalized}`}
        >
          <span className="gx-text-info">{solutionLevelWithFirstLetterCapitalized}</span>
        </Tooltip>
      );
    }

    if (documentscopy?.review?.status === 'evaluated') {
      const { step } = documentscopy?.review;

      return (
        <span className="gx-text-success mrg-left-5">
          <Tooltip
            placement="bottomRight"
            mouseEnterDelay={0.3}
            title={
              step === 'standard'
                ? 'Documento foi avaliado na fila Standard'
                : step === 'advanced' && 'Documento foi avaliado na fila Advanced'
            }
          >
            Avaliado na
            <smal className="font-size-14">
              {step === 'standard' ? ' Standard' : step === 'advanced' && ' Advanced'}
            </smal>
          </Tooltip>
        </span>
      );
    }

    if (documentscopy?.review?.evaluated === true) {
      const { solutionLevel = null } = documentscopy?.review?.queue;

      const solutionLevelWithFirstLetterCapitalized =
        solutionLevel && solutionLevel[0].toUpperCase() + solutionLevel.slice(1);

      return (
        <span className="gx-text-success mrg-left-5">
          <Tooltip
            placement="bottomRight"
            mouseEnterDelay={0.3}
            title={
              solutionLevel
                ? `Documento foi avaliado na fila ${solutionLevelWithFirstLetterCapitalized}`
                : 'Documento foi avaliado'
            }
          >
            Avaliado
            {solutionLevel && ` na ${solutionLevelWithFirstLetterCapitalized}`}
          </Tooltip>
        </span>
      );
    }

    if (documentscopy?.data?.status && documentscopy?.data?.status === 'APPROVED') {
      return <span className="gx-text-success mrg-left-5">Aprovada</span>;
    }

    if (documentscopy?.data?.status && documentscopy?.data?.status === 'REPROVED') {
      return <span className="gx-text-danger mrg-left-5">Reprovada</span>;
    }

    if (
      !documentscopy?.review?._id &&
      executionData?.executions?.documentscopyRequestDate
    ) {
      return (
        <>
          <span className="gx-text-danger mrg-left-5">Não encontrada</span>
          <Tooltip placement="bottomRight" title="A execução pode estar quebrada na AWS">
            <AlertTriangle className="gx-text-warning" />
          </Tooltip>
        </>
      );
    }

    return 'Não';
  }, [executionData]);

  const renderSharedFaceset = useCallback(() => {
    if (executionData?.executions?.fraud) {
      return (
        <Button
          type="danger"
          onClick={openSharedFacesetExecutionModal}
          className="no-mrg-btm report-suspect"
        >
          Reportar suspeito
        </Button>
      );
    }
    return '';
  }, [executionData, openSharedFacesetExecutionModal]);

  const breadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Button
            onClick={() => {
              window.history.back();
            }}
            className="gx-link btn-menu"
          >
            Consulta
          </Button>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detalhamento</Breadcrumb.Item>
      </Breadcrumb>
    ),
    []
  );

  const detectedType = executionData?.executions?.detectedType?.execution;
  const type = executionData?.executions?.type;

  let docType = detectedType || type || 'OUTROS';
  docType = docType.toUpperCase();

  let docTypeTagClass = 'outros';

  if (docType.includes('NEW_')) {
    let replace = 'NOVO ';
    if (docType.includes('CNH')) replace = 'NOVA ';
    docType = docType.replace('NEW_', replace);
  }

  if (docType.includes('RG') || docType.includes('CIN')) {
    docTypeTagClass = 'rg';
  } else if (docType.includes('CRLV')) {
    docTypeTagClass = 'crlv';
  } else if (docType.includes('CNH')) {
    docTypeTagClass = 'cnh';
  } else if (docType !== 'OUTROS') {
    docTypeTagClass = docType.toLowerCase();
  }

  return (
    <div id="execution-subheader-component" {...rest}>
      <div className="gx-header-horizontal-top">
        <div className="gx-container">
          {rejectExecutionModal}
          {approveExecutionModal}
          {pendingExecutionModal}
          {reprocessModal}
          {sharedFacesetExecutionModal}
          <Row className="no-mrg main-row">
            <Col className="execution-title-column">
              {breadcrumbs}
              <div className="flex start-center mrg-btm-5">
                <Title className="title">Execução</Title>
                {!loadingExecution && (
                  <>
                    <Tag className="report-tag">
                      {executionData?.executions?.report?.name ? (
                        <Link
                          to={`/customers/${tenantId}/reports/registration/${executionData?.executions?.report?._id}`}
                          target="_blank"
                        >
                          {executionData?.executions?.report?.name || '-'}
                        </Link>
                      ) : (
                        <span
                          className="without-reportId"
                          title={
                            executionData?.executions?.template?.name ||
                            executionData?.executions?.routine?.name ||
                            'Execução sem relatório pré configurado'
                          }
                        >
                          {executionData?.executions?.template?.name ||
                            executionData?.executions?.routine?.name ||
                            'Execução sem relatório pré configurado'}
                        </span>
                      )}
                    </Tag>
                    <Tag className={`tag-${docTypeTagClass}`}>
                      <span title={docType.toUpperCase()}>{docType.toUpperCase()}</span>
                    </Tag>
                  </>
                )}
              </div>
              {!loadingExecution && (
                <>
                  <Text
                    className="subtitle"
                    title={`Consulta em ${toFormatDate(
                      executionData?.executions?.createdAt
                    )} por ${executionData?.executions?.createdBy?.email || '-'}`}
                  >
                    Consulta em {toFormatDate(executionData?.executions?.createdAt)} por{' '}
                    {executionData?.executions?.createdBy?.email || '-'}
                  </Text>
                  {client && (
                    <Link to={`/customers/${tenantId}/registration`} target="_blank">
                      <div className="mrg-top-15 client">
                        <span className="text-bold">
                          Cliente:{' '}
                          <span className="text-normal">
                            {client.fantasyName || client.companyName}
                          </span>
                        </span>
                      </div>
                    </Link>
                  )}
                </>
              )}
            </Col>
            <Col flex="auto" className="status-column">
              {!loadingExecution && (
                <>
                  <Row>
                    <Col span={24}>
                      {renderSharedFaceset()}
                      {!executionData?.executions?.workflowId && (
                        <Button
                          onClick={openReprocessModal}
                          className="no-mrg-btm btn-custom-secondary reprocess"
                        >
                          <ProcessingLine className="icon" />
                          Reprocessar
                        </Button>
                      )}
                      <StatusChangeSelector
                        openApprove={openApproveExecutionModal}
                        openReject={openRejectExecutionModal}
                        openPending={openSetPendingExecutionModal}
                        isWorkflowBuilder={!!executionData?.executions?.workflowId}
                        isFraud={executionData?.executions?.fraud}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} className="flex end-center extra-info-col">
                      {!executionData?.executions?.workflowId && (
                        <>
                          <OpenAwsExecution />
                          <Button
                            onClick={copyIdExecution}
                            className="no-mrg-btm copy-id-button"
                            type="link"
                          >
                            <Duplicate className="icon" />
                            {copy}
                          </Button>
                        </>
                      )}
                      <div className="extra-info tscopy">
                        {executionData?.executions?.documentscopyRequestDate && (
                          <Dropdown overlay={menu} trigger={['click']}>
                            <ArrowDown width={20} height={20} />
                          </Dropdown>
                        )}
                        Documentoscopia: {renderDocumentscopy()}
                      </div>
                      <div className="extra-info status">
                        {`Retry CPF: ${
                          executionData?.executions?.sections?.cpf?.retries ??
                          'Indefinido'
                        }`}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default memo(ExecutionSubheader);
