/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Typography, Alert, Button, Select } from 'antd';
import _ from 'lodash';

import { useComponentList } from 'components/List';

import { ArrowDown } from '@combateafraude/icons/general';
import CardExecution from './components/CardExecution';
import OpenAllButton from './components/OpenAllButton';

import './styles.less';

const { Text, Title } = Typography;
const { Option } = Select;

const ExecutionList = ({ setIsLoadingList, errorFilter, filters, onSortChange }) => {
  const [socket, setSocket] = useState(null);

  const { componentList, data, updateParams, loading, error } = useComponentList({
    component: CardExecution,
    rowKey: '_id',
    allowEmptyListWorkaround: true,
    showQuickJumper: true,
    method: 'POST',
    socket,
  });

  useEffect(() => {
    try {
      if (socket !== null) socket.close();

      if (data?.docs.length > 0) {
        const newSocket = new WebSocket(process.env.REACT_APP_SOCKET_URL);

        newSocket.addEventListener('open', () => {
          const executionsIds = data?.docs?.map(
            (execution) => execution.executionId || execution._id
          );

          if (executionsIds) {
            const topics = executionsIds.map((id) => `backoffice-execution-${id}`);

            newSocket.send(
              JSON.stringify({
                action: 'subscribeToTopic',
                topics,
              })
            );
          }
        });

        setSocket(newSocket);
      }

      return () => {
        if (socket !== null) socket.close();
      };
    } catch {
      // SILENCER
      return () => {};
    }
  }, [data?.docs]); // eslint-disable-line

  useEffect(() => {
    setIsLoadingList(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const alertErrorExecutionLoad = useMemo(() => {
    const errorMessage =
      error?.message || errorFilter || 'Faça um novo filtro e tente novamente';
    return (
      <div id="alert-error-execution-load">
        <Alert
          message="Erro"
          showIcon
          description={`Erro ao tentar consultar os dados: ${errorMessage}`}
          type="error"
          action={
            <>
              <Button
                size="small"
                danger
                onClick={() => {
                  window.location.reload();
                }}
              >
                Recarregar a página
              </Button>
              <Button
                size="small"
                onClick={() => {
                  window.history.back();
                }}
              >
                Voltar para a lista de execuções
              </Button>
            </>
          }
        />
      </div>
    );
  }, [error, errorFilter]);

  const formattedErrorStatusCode = error?.statusCode
    ? error?.statusCode.toString()
    : error && 'error';

  const hasExecutionError = ['500', '503', '401', '400', 'error'].includes(
    formattedErrorStatusCode
  );

  const filterSnapshot = useRef(filters);
  const [showCards, setShowCards] = useState(false);

  const fetch = useCallback(() => {
    const params = {
      ...filters,
      _startCreateDate: filters.startDate,
      _endCreateDate: filters.endDate,
      tenantId: filters.customers,
      notTenantId: filters.notCustomers,
      doubleCheckLiveness: filters.doubleCheckLiveness || undefined,
      endDate: undefined,
      startDate: undefined,
      creationDate: undefined,
      customCreatedAt: undefined,
      customers: undefined,
      notCustomers: undefined,
      lifetime: undefined,
    };

    if (!_.isEmpty(params)) {
      setShowCards(true);
      updateParams({
        url: '/executions-master-v2',
        config: {
          params,
        },
      });
    }
  }, [updateParams, setShowCards, filters]);

  useEffect(() => {
    // Não recarregar quando filtro de 'Intervalo personalizado' for selecionado (creationDate === 'custom')
    const changeCreationDate =
      filterSnapshot.current?.creationDate !== filters.creationDate &&
      filters.creationDate === 'custom';

    if (_.isEqual(filterSnapshot.current, filters)) return;
    filterSnapshot.current = filters;

    if (!changeCreationDate) {
      fetch();
    }
  }, [fetch, filters]);

  const sortChangeHandler = useCallback(
    (value) => {
      if (value === '1') {
        onSortChange({ ...filters, _order: value, _sort: 'createdAt' });
        return;
      }
      onSortChange({ ...filters, _order: undefined, _sort: undefined });
    },
    [onSortChange, filters]
  );
  return (
    <div id="execution-list-component">
      <Row align="middle" justify="center" className="no-mrg">
        <Col flex={1} className="page-title">
          <Title className="title">Consultas</Title>
          <Text className="subtitle">
            {showCards
              ? 'Exibindo resultado com base nos filtros aplicados'
              : 'É necessário atualizar os filtros e consultar para exibir os resultados'}
          </Text>
        </Col>
        {hasExecutionError || errorFilter ? (
          alertErrorExecutionLoad
        ) : (
          <>
            <Col>
              <OpenAllButton data={data} />
              <Select
                bordered={false}
                style={{ minWidth: 100 }}
                placeholder="Mais recente"
                onChange={sortChangeHandler}
                value={filters?._order}
                className="text-dark"
                suffixIcon={<ArrowDown />}
              >
                <Option value="">Mais recente</Option>
                <Option value="1">Mais antigo</Option>
              </Select>
            </Col>
          </>
        )}
      </Row>
      {showCards && !hasExecutionError && !errorFilter ? componentList : ''}
    </div>
  );
};

export default ExecutionList;
