import React from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { Row, Col } from 'antd';

import { useExecution } from 'hooks/execution';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';

const SentImages = () => {
  const { executionData } = useExecution();

  const { images } = executionData?.executions || {};

  return (
    <div id="sended-images-component">
      <PageTitle title="Imagens enviadas" />
      <Card>
        <Row align="middle" justify="center">
          <Col span={8}>
            <ZoomedImage src={images?.front} label="Frente do documento" />
          </Col>
          <Col span={8}>
            <ZoomedImage src={images?.back} label="Verso do documento" />
          </Col>
          <Col span={8}>
            <ZoomedImage src={images?.selfie} label="Selfie" />
          </Col>
        </Row>
      </Card>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('sended-images-component')}
      /> */}
    </div>
  );
};

// SentImages.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default SentImages;
