import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import { useCustomer } from 'hooks/customer';
import { useExecution } from 'hooks/execution';
import { useScroll } from 'hooks/scroll';

import Header from 'components/Header';

const { Content } = Layout;

const Wrapper = ({ subheader, children, ...rest }) => {
  const matchCustomers = useRouteMatch('/customers/:customerId/*');
  const matchExecution = useRouteMatch(
    '/executions/details/user/:tenantId/report/:reportId/execution/:executionId'
  );
  const { clearCustomer } = useCustomer();
  const { clearExecution } = useExecution();
  const { handleScroll } = useScroll();

  useEffect(() => {
    if (matchCustomers) return;

    clearCustomer();
  }, [matchCustomers, clearCustomer]);

  useEffect(() => {
    if (matchExecution) return;

    clearExecution();
  }, [matchExecution, clearExecution]);

  return (
    <Layout className="gx-app-layout">
      <Layout>
        <Header subheader={subheader} />
        <Content
          id="main-content"
          className="gx-layout-content gx-container-wrap"
          onScroll={handleScroll}
        >
          <div className="gx-main-content-wrapper" {...rest}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

Wrapper.propTypes = {
  subheader: PropTypes.node,
};

Wrapper.defaultProps = {
  subheader: undefined,
};

export default Wrapper;
