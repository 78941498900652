const uppercaseObject = (obj) => {
  const formattedValues = {};
  Object.keys(obj).forEach((key) => {
    formattedValues[key] = String(obj[key]).toUpperCase();
  });

  return formattedValues;
};

export default uppercaseObject;
