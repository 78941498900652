import React from 'react';
// import PropTypes from 'prop-types';
// import { Waypoint } from 'react-waypoint';
import { Row, Col } from 'antd';
import ReactJson from 'react-json-view';

import { useExecution } from 'hooks/execution';
// import { useAuth } from 'hooks/useAuth';
import { useFetch } from 'services/hooks';

import SquaredButton from 'components/Button/SquaredButton';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import { Image } from '@combateafraude/icons/general';

// import useRejectExecutionModal from 'components/Header/components/ExecutionSubheader/components/RejectExecutionModal';

import useReprocessOfficialDataModal from './ReprocessOfficialDataModal';

const OfficialDataReprove = () => {
  // const { tenantId, reportId, executionId } = useParams();
  // const { executionData, reloadData: reloadExecutionData } = useExecution();
  // const { user } = useAuth()
  const { executionData } = useExecution();
  // const { loading: loadingDocumentoscopy, post: postDocumentoscopy } = useFetch();
  const { loading: loadingDocumentoscopy } = useFetch();
  // const {
  //   openModal: openRejectExecutionModal,
  //   rejectExecutionModal,
  // } = useRejectExecutionModal('OFFICIAL_DATA_REPROVE');
  const { openModal: openReprocessModal, reprocessModal } =
    useReprocessOfficialDataModal();

  // const handleSendDocumentoscopy = useCallback(async () => {
  //   try {
  //     await postDocumentoscopy({
  //       url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/OFFICIAL_DATA_REPROVE`,
  //       payload: {
  //         action: 'DOCUMENTSCOPY',
  //         _id: user?._id,
  //         email: user?.email,
  //       },
  //     });
  //     message.success('Ação realizada com sucesso.');
  //     reloadExecutionData(false);
  //   } catch (error) {
  //     message.error('Houve um problema ao realizar a ação.');
  //   }
  // }, [reloadExecutionData, postDocumentoscopy, tenantId, reportId, executionId, user]);

  return (
    <div id="official-data-reprove-component">
      {/* {rejectExecutionModal} */}
      {reprocessModal}
      <PageTitle
        title="Ação requerida: dupla checagem de dados oficiais"
        subtitle="Avalie as imagens e selecione a alternativa que mais se enquadra."
      />
      <Card>
        <Row align="middle" justify="center">
          <Col span={15}>
            <div className="table-overflow json-viewer">
              <ReactJson
                name="officialData"
                src={executionData?.executions?.sections?.officialData || {}}
                iconStyle="triangle"
                indentWidth={2}
                collapsed={4}
                theme="shapeshifter:inverted"
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
              />
            </div>
            {/*
            {children}
            <div className="table-overflow mrg-top-10">
              <table>
                <tbody>
                  <tr>
                    <td>Similaridade</td>
                    <td>
                      {(
                        executionData?.executions?.sections?.officialData?.confidence *
                        100
                      ).toFixed(2)}
                      % - {executionData?.executions?.sections?.officialData?.probability}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </Col>
          <Col span={9}>
            <div className="flex-column center">
              <div>
                <div className="flex-row">
                  <div className="mrg-btm-20 mrg-horizon-10">
                    <SquaredButton
                      disabled={loadingDocumentoscopy}
                      onClick={openReprocessModal}
                    >
                      <div>
                        <Image width={40} height={40} />
                      </div>
                      Enviar nova foto {'\n'} e processar
                    </SquaredButton>
                  </div>

                  {/* <div className="mrg-btm-20 mrg-horizon-10">
                    <Popconfirm
                      title="Você deseja mesmo enviar para documentoscopia?"
                      onConfirm={handleSendDocumentoscopy}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <SquaredButton loading={loadingDocumentoscopy}>
                        <div>
                        <Onboarding width={40} height={40} />
                      </div>
                        Enviar para documentoscopia
                      </SquaredButton>
                    </Popconfirm>
                  </div> */}

                  {/* <div className="mrg-btm-20 mrg-horizon-10">
                    <SquaredButton
                      disabled={loadingDocumentoscopy}
                      onClick={openRejectExecutionModal}
                      className="danger mrg-horizon-10"
                    >
                      <div>
                        <Close width={40} height={40} />
                      </div>
                      Reprovar por má qualidade
                    </SquaredButton>
                  </div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      {/* <Waypoint
        topOffset="25%"
        onEnter={() => setWhichComponentIsActive('official-data-reprove-component')}
      /> */}
    </div>
  );
};

// OfficialDataReprove.propTypes = {
//   setWhichComponentIsActive: PropTypes.func.isRequired,
// };

export default OfficialDataReprove;
