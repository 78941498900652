import React, { useCallback, useMemo, createElement } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Divider, Form, Tooltip, Tag, Button, Switch } from 'antd';
import { Trash } from '@combateafraude/icons/general';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import Card from 'components/Card';
import MoneyInput from './MoneyInput';

import '../styles.less';

const { Title, Text } = Typography;

const CustomDataCard = ({ customData, formRef, isEditing, productType, currency }) => {
  const getDocs = useCallback(
    (type) => {
      const docs = customData?.docs?.[type] || [];

      return docs;
    },
    [customData]
  );

  const Icons = Object.assign(iconsGeneral || {}, iconsUsers || {}, iconsFace || {});

  const docsPf = useMemo(() => getDocs('pfDocs'), [getDocs]);
  const docsPj = useMemo(() => getDocs('pjDocs'), [getDocs]);
  const docsOthers = useMemo(() => getDocs('others'), [getDocs]);

  const renderType = (docs, title) => {
    return (
      docs?.length > 0 && (
        <>
          <Divider orientation="left">{title}</Divider>
          {docs.map((cd) => (
            <Form form={formRef}>
              <Row key={cd.key} className="custom-data-row" style={{ marginBottom: 4 }}>
                <Col flex={1}>
                  <Row>
                    <Col span={2}>
                      <Form.Item name={cd.serviceIsAvailable.key} valuePropName="checked">
                        <Switch
                          disabled={!isEditing}
                          size="small"
                          checked={cd.serviceIsAvailable.value}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={13}>
                      <Form.Item name={cd.source.key} className="no-error">
                        <Tooltip title={cd.source.value}>
                          <Text>{cd.title.value}</Text>
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    {currency === 'BRL' || !currency ? (
                      <Tooltip title="Valor Sugerido">
                        <Col span={4}>
                          <Form.Item name={cd.price.key} className="no-error">
                            <Tag>{cd.price.value}</Tag>
                          </Form.Item>
                        </Col>
                      </Tooltip>
                    ) : (
                      <Col span={4} />
                    )}
                    <Col span={3}>
                      <Tooltip title="Valor Cobrado">
                        <Form.Item name={cd.customPrice.key} className="no-error">
                          <MoneyInput
                            className="masked-input"
                            size="8"
                            disabled={!isEditing}
                            currency={currency}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={2}>
                      <Button
                        shape="circle"
                        type="link"
                        disabled={!isEditing}
                        icon={<Trash width={16} height={16} />}
                        className="no-border no-shadow btn-trash disabled"
                        onClick={() =>
                          formRef.setFieldsValue({ [cd.customPrice.key]: '' })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          ))}
        </>
      )
    );
  };

  return (
    (docsPf?.filter((doc) => doc.productType === productType).length > 0 ||
      docsPj?.filter((doc) => doc.productType === productType).length > 0 ||
      docsOthers?.filter((doc) => doc.productType === productType).length > 0 ||
      (!productType &&
        (docsPj.filter((doc) => doc.status === 'DEPRECATED').length > 0 ||
          docsPf.filter((doc) => doc.status === 'DEPRECATED').length > 0 ||
          docsOthers.filter((doc) => doc.status === 'DEPRECATED').length > 0))) && (
      <Card>
        <Row>
          <Col className="card-header">
            <div>
              {Object.keys(Icons || {}).includes(customData.category.icon) &&
                createElement(Icons[customData.category.icon])}
            </div>
            <Title level={3}>{customData.category.title}</Title>
          </Col>
        </Row>
        {renderType(
          productType
            ? docsPf.filter(
                (doc) => doc.productType === productType && doc.status !== 'DEPRECATED'
              )
            : docsPf.filter((doc) => doc.status === 'DEPRECATED'),
          'Pessoa Física'
        )}
        {renderType(
          productType
            ? docsPj.filter(
                (doc) => doc.productType === productType && doc.status !== 'DEPRECATED'
              )
            : docsPj.filter((doc) => doc.status === 'DEPRECATED'),
          'Pessoa Jurídica'
        )}
        {renderType(
          productType
            ? docsOthers.filter(
                (doc) => doc.productType === productType && doc.status !== 'DEPRECATED'
              )
            : docsOthers.filter((doc) => doc.status === 'DEPRECATED'),
          'Outros'
        )}
      </Card>
    )
  );
};

CustomDataCard.propTypes = {
  customData: PropTypes.shape({
    category: PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
    }),
    docs: PropTypes.shape({
      pfDocs: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          title: PropTypes.string,
          price: PropTypes.number,
        })
      ),
      pjDocs: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          title: PropTypes.string,
          price: PropTypes.number,
        })
      ),
    }),
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CustomDataCard;
