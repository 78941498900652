import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover } from 'antd';
import { AlertTriangle } from '@combateafraude/icons/general';

const { Text } = Typography;

const LabelWithInformation = ({ label, title, textInformation, ...rest }) => {
  return (
    <>
      {label}
      <Popover {...rest} title={title} content={<Text>{textInformation}</Text>}>
        <AlertTriangle width={18} height={18} style={{ color: '#F16E00' }} />
      </Popover>
    </>
  );
};

LabelWithInformation.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  textInformation: PropTypes.node.isRequired,
};

export default LabelWithInformation;
