import React, { useCallback } from 'react';
import { Row, Col, Form, Switch, Typography, Spin, message } from 'antd';
import { useParams } from 'react-router-dom';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import TooltipInfo from 'components/TooltipInfo';

const { Text } = Typography;

const CardUserConfigs = () => {
  const { tenantId } = useParams();
  const [form] = Form.useForm();
  const { customer, loadingCustomer, loadCustomer } = useCustomer();
  const { put, loading: loadingUpdate } = useFetch();

  const handleSubmit = useCallback(
    async (payload) => {
      try {
        await put({
          url: `/clients/${tenantId}`,
          payload,
        });

        loadCustomer({ tenantId });
      } catch (err) {
        message.error('Erro ao atualizar configurações.');
      }
    },
    [loadCustomer, put, tenantId]
  );

  return (
    <Card>
      <Row align="middle" justify="space-between" className="mrg-btm-15">
        <Col span={19}>
          <PageTitle
            title="Configurações do cliente"
            subtitle="Atualize as configurações do cliente."
          />
        </Col>
        <Col span={5} className="flex end-center">
          <Button
            onClick={form.submit}
            disabled={loadingCustomer}
            loading={loadingUpdate}
            className="mrg-right-5 btn-custom btn-custom-primary"
          >
            Salvar
          </Button>
        </Col>
      </Row>
      {customer ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={customer}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="cpfGroup"
                label={
                  <Text strong className="no-mrg">
                    Grupo de CPF
                  </Text>
                }
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                name="privateFaceset"
                label={
                  <Text strong className="no-mrg">
                    Banco privado de faces
                  </Text>
                }
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                name="cpfGroupManualRevision"
                label={
                  <>
                    <Text strong className="no-mrg">
                      Vínculo de faces personalizado
                    </Text>
                    <TooltipInfo title="Considerar somente o status da análise manual como mandatório no vínculo da face com o banco de faces do FaceAuthenticator. Quando habiltado, as execuções com status APROVADO (mesmo com facematch ou outras regras de validação inválidas) terão a selfie vinculada as faces do FaceAuthenticator." />
                  </>
                }
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

export default CardUserConfigs;
