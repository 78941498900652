import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Divider } from 'antd';

import { Edit, Plus, RotateCw, Trash } from '@combateafraude/icons/general';
import registerCreateModal from '../../../components/DenyListModal/RegisterModal';
import importCSVModal from '../../../components/DenyListModal/ImportCSVModal';
import MenuItemDropDown from './MenuItemDropdown';

const DenyListDropdown = ({ refreshListRef }) => {
  const { openModal: openRegisterModal, RegisterModal } = registerCreateModal({
    refreshListRef,
  });

  const { openModal: openImportCSVModal, ImportCSVModal } = importCSVModal({
    refreshList: refreshListRef,
  });

  return (
    <>
      {RegisterModal}
      {ImportCSVModal}
      <Menu>
        <Menu.Item key="1" onClick={openRegisterModal}>
          <MenuItemDropDown
            text="Adicionar manualmente"
            icon={<Edit width={20} height={20} />}
          />
        </Menu.Item>
        <Divider />
        <Menu.Item
          key="2"
          onClick={() => openImportCSVModal('Incluir')}
          className="no-mrg-btm"
        >
          <MenuItemDropDown
            text="Incluir via CSV"
            icon={<Plus width={20} height={20} />}
          />
        </Menu.Item>

        <Menu.Item key="3" onClick={() => openImportCSVModal('Substituir')}>
          <MenuItemDropDown
            text="Substituir via CSV"
            icon={<RotateCw width={20} height={20} />}
          />
        </Menu.Item>

        <Menu.Item
          key="4"
          onClick={() => openImportCSVModal('Remover')}
          className="gx-text-danger"
        >
          <MenuItemDropDown
            text="Remover via CSV"
            icon={<Trash width={20} height={20} />}
          />
        </Menu.Item>
      </Menu>
    </>
  );
};

DenyListDropdown.propTypes = {
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

export default DenyListDropdown;
