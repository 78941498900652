import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Typography,
  Form,
  Switch,
  InputNumber,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import { useCustomer } from 'hooks/customer';
import currency from 'utils/currencyFormatter';
import {
  CircleFalse,
  CheckCircle2,
  CheckSquare,
  File,
} from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';
import { toMaskedCnpj } from 'utils/formatters';
import statusList from 'pages/private/Customers/CustomerList/utils/statusList.json';

const { Option } = Select;
const { Text } = Typography;

const EditIncome = ({ isEditing, form, currencyUsed, loading }) => {
  const { customer } = useCustomer();

  const { get: getCustomers, loading: loadingCustomers } = useFetch();

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);
  const [hasMinimumInvoice, setHasMinimumInvoice] = useState(customer?.minimumInvoice);

  const [hasPlatformMaintenance, setHasPlatformMaintenance] = useState(
    customer?.platformMaintenance
  );

  const [hasInvoiceExpirationDay, setInvoiceExpirationDay] = useState(
    customer?.hasInvoiceExpirationDay
  );

  const [hasInvoiceDaysToExpire, setInvoiceDaysToExpire] = useState(
    customer?.hasInvoiceDaysToExpire
  );

  const [customersFilter, setCustomersFilter] = useState(
    [customer?.invoicePayerId] || []
  );

  const [hasNotBillable, setHasNotBillable] = useState(customer?.notBillable);

  const currencySign = currency.getCurrencySign(currencyUsed);

  const renderPlaceholder = useCallback(
    (placeholder) => (!isEditing ? '' : placeholder),
    [isEditing]
  );

  const toggleHasMinimumInvoice = useCallback(() => {
    setHasMinimumInvoice((state) => !state);
  }, []);

  const toggleHasPlatformMaintenance = useCallback(() => {
    setHasPlatformMaintenance((state) => !state);
  }, []);

  const toggleHasNotBillable = useCallback(() => {
    setHasNotBillable(!hasNotBillable);
  }, [hasNotBillable]);

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  const toggleInvoiceType = useCallback(
    (type) => {
      if (type === 'normal') {
        setInvoiceExpirationDay((state) => {
          setInvoiceDaysToExpire(() => state);
          form.setFieldsValue({
            hasInvoiceDaysToExpire: state,
            invoiceDaysToExpire: null,
            invoiceExpirationDay: null,
          });
          return !state;
        });
      } else {
        setInvoiceDaysToExpire((state) => {
          setInvoiceExpirationDay(() => state);
          form.setFieldsValue({
            hasInvoiceExpirationDay: state,
            invoiceDaysToExpire: null,
            invoiceExpirationDay: null,
          });
          return !state;
        });
      }
    },
    [form]
  );

  const handleValueParsing = (value) => {
    let newValue = '';
    if (`${value}`?.includes('-')) {
      newValue = currency.toNumtoNumberTwoDigitsber(`${value}`.replaceAll(`-`, ``));

      return `-${newValue || 0.0}`;
    }

    return currency.toNumberTwoDigits(value || 0.0);
  };

  const handleValueFormatting = useCallback(
    (value) => {
      let newValue = '';
      if (`${value}`?.includes('-')) {
        newValue = currency.toCurrencyTwoDigits(
          `${value}`.replaceAll(`-`, ``) || 0.0,
          currencySign
        );
        return `${newValue}`.replace(currencySign, `${currencySign} -`);
      }

      return currency.toCurrencyTwoDigits(value || 0.0, currencySign);
    },
    [currencySign]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const data = await getCustomers({
        url: '/clients',
        config: {
          params: {
            _offset: 0,
            _sort: 'fantasyName',
            _order: 1,
            _returnDocuments: false,
          },
        },
      });

      const customersFiltered = data?.docs
        ?.filter((client) => client.tenantId !== customer.tenantId)
        .filter((client) => client?.fantasyName || client?.companyName);

      setAllCustomers(customersFiltered || []);
      setCustomersSearched(customersFiltered || []);
    } catch (e) {
      // SILENCER
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim() === '') {
        setCustomersSearched(allCustomers);
        return;
      }

      const customersFiltered = allCustomers?.filter((client) => {
        const cnpjFormatted = cnpjFormattedToSearch(client?.cnpj);

        if (
          client?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          client?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          client?.tenantId?.includes(searchTerm) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return client;
        }

        return false;
      });

      setSearchCustomer(searchTerm);
      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  return (
    <>
      <div className="mrg-top-25 mrg-btm-15">
        <Text className="text-bold text-dark font-size-20">Faturamento</Text>
      </div>

      <Row className="fd-row">
        <Col span={12}>
          <Form.Item
            className="switch-label"
            label={
              <>
                Manutenção da plataforma
                <Form.Item name="platformMaintenance" valuePropName="checked">
                  <Switch
                    disabled={!isEditing || loading}
                    onClick={toggleHasPlatformMaintenance}
                    className="mrg-left-15"
                    size="small"
                  />
                </Form.Item>
                <Tooltip
                  title="Clientes marcados com esta opção terão de arcar com um valor para
                    manter o custo da plataforma"
                  size={14}
                />
              </>
            }
            rules={
              hasPlatformMaintenance && [
                {
                  required: true,
                  type: 'number',
                  message: 'Esse não é um valor válido',
                },
                {
                  min: 0.01,
                  message: `Valor mínimo: ${currencySign} 0.01`,
                  type: 'number',
                },
              ]
            }
            name="platformMaintenanceAmount"
          >
            <InputNumber
              className={isEditing ? 'no-arrow editing-highlight' : 'no-arrow'}
              disabled={!isEditing || !hasPlatformMaintenance || loading}
              formatter={handleValueFormatting}
              parser={handleValueParsing}
              placeholder={renderPlaceholder(customer?.minimumInvoiceAmount)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="switch-label"
            label={
              <>
                Valor mínimo utilizável
                <Form.Item name="minimumInvoice" valuePropName="checked">
                  <Switch
                    disabled={!isEditing || loading}
                    onClick={toggleHasMinimumInvoice}
                    className="mrg-left-15"
                    size="small"
                  />
                </Form.Item>
                <Tooltip
                  title="Clientes marcados com esta opção terão de arcar com um valor
                    mínimo todo mês que foi acordado entre o cliente e o Comercial"
                  size={14}
                />
              </>
            }
            rules={
              hasMinimumInvoice && [
                {
                  required: true,
                  type: 'number',
                  message: 'Esse não é um valor válido',
                },
                {
                  min: 0.01,
                  message: `Valor mínimo: ${currencySign} 0.01`,
                  type: 'number',
                },
              ]
            }
            name="minimumInvoiceAmount"
          >
            <InputNumber
              className={isEditing ? 'no-arrow editing-highlight' : 'no-arrow'}
              disabled={!isEditing || !hasMinimumInvoice || loading}
              formatter={handleValueFormatting}
              parser={handleValueParsing}
              placeholder={renderPlaceholder(customer?.minimumInvoiceAmount)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="switch-label"
            label={
              <>
                Dia do vencimento
                <Form.Item name="hasInvoiceExpirationDay" valuePropName="checked">
                  <Switch
                    disabled={!isEditing || loading}
                    onClick={() => toggleInvoiceType('normal')}
                    className="mrg-left-15"
                    size="small"
                  />
                </Form.Item>
              </>
            }
            rules={
              hasInvoiceExpirationDay && [
                {
                  required: true,
                  type: 'number',
                  message: 'Obrigatório',
                },
                {
                  min: 1,
                  max: 31,
                  type: 'number',
                  message: 'Dia do vencimento deve estar entre 1 e 31',
                },
              ]
            }
            name="invoiceExpirationDay"
          >
            <InputNumber
              className={isEditing ? 'no-arrow editing-highlight' : 'no-arrow'}
              disabled={!isEditing || !hasInvoiceExpirationDay || loading}
              placeholder={renderPlaceholder('Digite o dia')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="switch-label"
            label={
              <>
                Dias corridos para vencimento
                <Form.Item name="hasInvoiceDaysToExpire" valuePropName="checked">
                  <Switch
                    disabled={!isEditing || loading}
                    onClick={() => toggleInvoiceType('corridos')}
                    className="mrg-left-15"
                    size="small"
                  />
                </Form.Item>
              </>
            }
            name="invoiceDaysToExpire"
            rules={
              hasInvoiceDaysToExpire && [
                {
                  required: true,
                  type: 'number',
                  message: 'Obrigatório',
                },
                {
                  min: 1,
                  max: 365,
                  type: 'number',
                  message: 'Dias corridos para vencimento deve estar entre 1 e 365',
                },
              ]
            }
          >
            <InputNumber
              disabled={!isEditing || !hasInvoiceDaysToExpire || loading}
              placeholder={renderPlaceholder('Digite o dia')}
              className={isEditing ? 'editing-highlight' : ''}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="switch-label"
            label={
              <>
                Não faturar
                <Form.Item name="notBillable" valuePropName="checked">
                  <Switch
                    disabled={!isEditing || loading}
                    onClick={toggleHasNotBillable}
                    className="mrg-left-15"
                    size="small"
                  />
                </Form.Item>
                <Tooltip
                  title="Clientes marcados com esta opção não serão faturados"
                  size={14}
                />
              </>
            }
            name="notBillable"
          />
        </Col>
        <Col span={12}>
          <Form.Item name="invoicePayerId" label="Faturar para">
            <Select
              disabled={!isEditing || loading}
              allowClear
              showSearch
              suffixIcon
              filterOption={false}
              defaultActiveFirstOption={false}
              optionFilterProp="label"
              value={customersFilter || []}
              placeholder="Busque por nome, tenantId ou CNPJ..."
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
              loading={loadingCustomers}
              notFoundContent={
                !loadingCustomers && !customersSearched.length ? (
                  <span>
                    {searchCustomer
                      ? `Nenhum resultado encontrado para "${searchCustomer}"`
                      : 'Busque clientes por nome, tenantId ou CNPJ'}
                  </span>
                ) : null
              }
              onSearch={onCustomerSearch}
              onClear={() => setCustomersSearched(allCustomers)}
              onBlur={() => {
                setSearchCustomer(null);
                setCustomersSearched(allCustomers);
              }}
              onChange={(values) => {
                setCustomersFilter([values]);
              }}
            >
              {customersSearched?.map((c) => (
                <Option
                  key={c._id}
                  label={c.fantasyName || c.companyName}
                  value={c.tenantId}
                >
                  <div className="multiselect-option">
                    <span className="option-title">{c.fantasyName || c.companyName}</span>
                    <div className="flex start-center">
                      <span className="option-subtitle">{toMaskedCnpj(c.cnpj)}</span>
                      <section className="mrg-left-10">
                        <>
                          {c?.verified && (
                            <Tooltip title="Em produção">
                              <CheckSquare
                                width={15}
                                height={15}
                                className="gx-text-success"
                              />
                            </Tooltip>
                          )}
                          {c?.hasContract && (
                            <Tooltip title="Possui contrato">
                              <File width={20} height={20} className="gx-text-primary" />
                            </Tooltip>
                          )}
                          {c?.status && (
                            <Tag
                              className="mrg-left-5 font-size-10"
                              color={statusList[c?.status || 'lead'].color}
                            >
                              {c?.status === 'internal-customer'
                                ? 'Interno'
                                : statusList[c?.status || 'lead'].name}
                            </Tag>
                          )}
                        </>
                      </section>
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

EditIncome.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  form: PropTypes.shape.isRequired,
  currencyUsed: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditIncome;
