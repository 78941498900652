/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Typography, Alert, Button } from 'antd';
import {
  useQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
  BooleanParam,
} from 'use-query-params';
import _ from 'lodash';

import { useComponentNextList } from 'components/List';
import { SortSelect } from 'components/List/components';

import CardExecution from './components/CardExecution';
import OpenAllButton from './components/OpenAllButton';
import { creationDateDates } from '../ExecutionFilter/utils';

import './styles.less';

const { Text, Title } = Typography;

const ExecutionList = ({ setIsLoadingList, errorFilter }) => {
  const { componentList, data, updateParams, loading, error } = useComponentNextList({
    component: CardExecution,
    rowKey: '_id',
    showQuickJumper: true,
    allowEmptyListWorkaround: true,
  });

  useEffect(() => {
    setIsLoadingList(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const alertErrorExecutionLoad = useMemo(() => {
    return (
      <div id="alert-error-execution-load">
        <Alert
          message="Erro"
          showIcon
          description={`Erro ao tentar consultar os dados: ${
            error?.message || errorFilter
          }`}
          type="error"
          action={
            <>
              <Button size="small" danger href="/all-executions">
                Recarregar a página
              </Button>
              <Button
                size="small"
                onClick={() => {
                  window.history.back();
                }}
              >
                Voltar para a lista de execuções
              </Button>
            </>
          }
        />
      </div>
    );
  }, [error, errorFilter]);

  const formattedErrorStatusCode = error?.statusCode.toString();

  const hasExecutionError = ['500', '503', '401', '400'].includes(
    formattedErrorStatusCode
  );

  const [query, setQuery] = useQueryParams({
    creationDate: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    status: withDefault(ArrayParam, []),
    customers: withDefault(ArrayParam, []),
    queryTemplates: withDefault(ArrayParam, []),
    search: withDefault(ArrayParam, []),
    _documentscopyState: withDefault(ArrayParam, []),
    _order: StringParam,
    _sort: StringParam,
    fraud: BooleanParam,
  });
  const querySnapshot = useRef({});
  const [showCards, setShowCards] = useState(false);

  const fetch = useCallback(() => {
    const params = {};

    if (query.creationDate) {
      if (!query.startDate || !query.endDate) {
        const dateParams = creationDateDates(query.creationDate);
        setQuery(dateParams);
        return;
      }

      params._startCreatedDate = query.startDate;
      params._endCreatedDate = query.endDate;
    }

    if (query.status && !_.isEmpty(query.status)) {
      params.status = query.status.join('|');
      if (params?.status?.includes('FRAUD')) {
        if (!params.status.includes('REPROVADO')) {
          params.status += '|REPROVADO|REPROVED';
        }
        params.fraud = true;
      }
    }

    if (query.customers && !_.isEmpty(query.customers)) {
      params.tenantId = query.customers.join('|');
    }

    if (query.queryTemplates && !_.isEmpty(query.queryTemplates)) {
      params._templates = query.queryTemplates.join('|');
    }

    if (query.search && !_.isEmpty(query.search)) {
      params._search = query.search.join('|');
    }

    if (query._documentscopyState && !_.isEmpty(query._documentscopyState)) {
      params._documentscopyState = query._documentscopyState.join('|');
    }

    if (query._sort && query._order) {
      params._sort = query._sort;
      params._order = query._order;
    }

    if (!_.isEmpty(params)) {
      setShowCards(true);
      updateParams({
        url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/executions-master-opensearch`,
        config: {
          params,
        },
      });
    }
  }, [query, updateParams, setQuery, setShowCards]);

  useEffect(() => {
    // Não recarregar quando filtro de 'Intervalo personalizado' for selecionado (creationDate === 'custom')
    const changeCreationDate =
      querySnapshot.current.creationDate !== query.creationDate &&
      query.creationDate === 'custom';

    if (_.isEqual(querySnapshot.current, query)) return;
    querySnapshot.current = query;

    if (!changeCreationDate) {
      fetch();
    }
  }, [query, fetch]);

  return (
    <div id="execution-list-component">
      <Row align="middle" justify="center" className="no-mrg">
        <Col flex={1} className="page-title">
          <Title className="title">Histórico de consultas</Title>
          <Text className="subtitle">
            {showCards
              ? loading
                ? 'Carregando...'
                : 'Exibindo resultado com base nos filtros aplicados'
              : 'É necessário atualizar os filtros e consultar para exibir os resultados'}
          </Text>
        </Col>

        {hasExecutionError || errorFilter ? (
          alertErrorExecutionLoad
        ) : (
          <>
            <Col>
              <OpenAllButton data={data} />
              <SortSelect />
            </Col>
          </>
        )}
      </Row>
      {showCards && !hasExecutionError && !errorFilter ? componentList : ''}
    </div>
  );
};

export default ExecutionList;
