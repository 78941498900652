import React from 'react';
import { Typography } from 'antd';

import notify from 'assets/images/notify.svg';

import Wrapper from '../wrapper';

import './styles.less';

const { Title, Paragraph } = Typography;

const Unauthorized = () => {
  return (
    <Wrapper id="unauthorized-page">
      <div id="unauthorized-content" className="flex center fd-column">
        <img src={notify} alt="Não autorizado" />
        <Title className="title">
          Você não possui permissão para acessar essa página.
        </Title>
        <Paragraph className="subtitle">
          Solicite acesso a essa rota ao administrador do sistema.
        </Paragraph>
      </div>
    </Wrapper>
  );
};

export default Unauthorized;
