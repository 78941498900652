import React, { createContext, useCallback, useContext } from 'react';
import { useFetch } from 'services/hooks';

const CustomerConfigurationContext = createContext({});

const CustomerConfigurationsProvider = ({ children }) => {
  const {
    data: customerExecutionLimitData,
    clearData: clearCustomerExecutionLimit,
    loading: loadingCustomerExecutionLimit,
    get: getExecutionLimit,
  } = useFetch();

  const {
    data: customerFeatureFlagsData,
    clearData: clearCustomerFeatureFlags,
    loading: loadingCustomerFeatureFlags,
    get: getFeatureFlags,
  } = useFetch();

  const { loading: loadingDeleteCustomerExecutionLimit, delete: deleteExecutionLimit } =
    useFetch();

  const { loading: loadingPatchCustomerExecutionLimit, patch: patchExecutionLimit } =
    useFetch();

  const { loading: loadingDeleteCustomerFeatureFlags, delete: deleteFeatureFlags } =
    useFetch();

  const { loading: loadingPatchCustomerFeatureFlags, patch: patchFeatureFlags } =
    useFetch();

  const { loading: loadingPutCustomerFeatureFlags, put: putFeatureFlags } = useFetch();

  const loadCustomerExecutionLimit = useCallback(
    async ({ tenantId, clearData: clearDataParam = false }) => {
      try {
        await getExecutionLimit({
          url: `/clients/${tenantId}/execution-limit`,
          clearData: clearDataParam,
        });
      } catch (error) {
        //
      }
    },
    [getExecutionLimit]
  );

  const patchCustomerExecutionLimit = useCallback(
    async ({ tenantId, payload }) => {
      await patchExecutionLimit({
        url: `/clients/${tenantId}/execution-limit`,
        payload,
      });
    },
    [patchExecutionLimit]
  );

  const deleteCustomerExecutionLimit = useCallback(
    async ({ tenantId }) => {
      await deleteExecutionLimit({
        url: `/clients/${tenantId}/execution-limit`,
      });
    },
    [deleteExecutionLimit]
  );

  const loadCustomerFeatureFlags = useCallback(
    async ({ tenantId, clearData: clearDataParam = false }) => {
      try {
        await getFeatureFlags({
          url: `/clients/${tenantId}/feature-flags`,
          clearData: clearDataParam,
        });
      } catch (error) {
        //
      }
    },
    [getFeatureFlags]
  );

  const patchCustomerFeatureFlags = useCallback(
    async ({ tenantId, flag, active }) => {
      await patchFeatureFlags({
        url: `/clients/${tenantId}/feature-flags/${flag}`,
        payload: {
          active,
        },
      });
    },
    [patchFeatureFlags]
  );

  const putCustomerFeatureFlags = useCallback(
    async ({ tenantId, data }) => {
      await putFeatureFlags({
        url: `/clients/${tenantId}/feature-flags`,
        payload: data,
      });
    },
    [putFeatureFlags]
  );

  const deleteCustomerFeatureFlags = useCallback(
    async ({ tenantId }) => {
      await deleteFeatureFlags({
        url: `/clients/${tenantId}/feature-flags`,
      });
    },
    [deleteFeatureFlags]
  );

  return (
    <CustomerConfigurationContext.Provider
      value={{
        loadCustomerExecutionLimit,
        customerExecutionLimit: customerExecutionLimitData?.docs,
        clearCustomerExecutionLimit,
        loadingCustomerExecutionLimit,
        patchCustomerExecutionLimit,
        loadingPatchCustomerExecutionLimit,
        deleteCustomerExecutionLimit,
        loadingDeleteCustomerExecutionLimit,
        loadCustomerFeatureFlags,
        customerFeatureFlags: customerFeatureFlagsData?.docs,
        clearCustomerFeatureFlags,
        loadingCustomerFeatureFlags,
        patchCustomerFeatureFlags,
        loadingPatchCustomerFeatureFlags,
        deleteCustomerFeatureFlags,
        loadingDeleteCustomerFeatureFlags,
        putCustomerFeatureFlags,
        loadingPutCustomerFeatureFlags,
      }}
    >
      {children}
    </CustomerConfigurationContext.Provider>
  );
};

function useCustomerConfigs() {
  const context = useContext(CustomerConfigurationContext);

  if (!context) {
    throw new Error(
      'useCustomerConfigs must be used within an CustomerConfigurationContext'
    );
  }

  return context;
}

export { CustomerConfigurationsProvider, useCustomerConfigs };
