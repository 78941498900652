import React, { createContext, useCallback, useContext, useState, useRef } from 'react';

const ScrollContext = createContext({});

const ScrollProvider = ({ children }) => {
  const position = useRef(0);

  const [isAtTop, setIsAtTop] = useState(true);
  const [isScrollingDown, setIsSrollingDown] = useState(false);

  const resetScroll = useCallback(() => {
    setIsSrollingDown(false);
    setIsAtTop(true);
  }, []);

  const handleScroll = useCallback((e) => {
    if (e.target.id !== 'main-content') return;

    const { scrollTop, scrollHeight, offsetHeight } = e.target;
    const isBottom = scrollTop + offsetHeight === scrollHeight;

    setIsAtTop(scrollTop <= 10);
    setIsSrollingDown(scrollTop > position.current || isBottom);

    position.current = scrollTop;
  }, []);

  return (
    <ScrollContext.Provider
      value={{
        isAtTop,
        isScrollingDown,
        resetScroll,
        handleScroll,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

function useScroll() {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScroll must be used inside AuthContext');
  }

  return context;
}

export { ScrollProvider, useScroll };
