import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Pagination, Spin, Form, InputNumber, Button, message } from 'antd';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import _ from 'lodash';

import { useFetch } from 'services/hooks';

import EmptyMessage from 'components/EmptyMessage';
import scrollToTop from 'utils/scrollToTop';

import { initialFilterData } from '../utils';

const useComponentNextList = ({
  component: Component,
  hidePagination,
  rowKey,
  getParams,
  queryParams,
  customDataParser,
  customModal,
  customLoader,
  maxTags,
  showQuickJumper = false,
  allowEmptyListWorkaround = false,
}) => {
  const [form] = Form.useForm();
  const { get, error } = useFetch();

  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFivetimes, setLoadingFivetimes] = useState(false);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const [customParserData, setCustomParserData] = useState();
  const [customParserLoading, setCustomParserLoading] = useState(false);
  const currentRequestId = useRef(null);

  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || 1,
    pageSize: 15,
    total: 0,
    showTotal: (total, range) => `Exibindo ${range[0]} a ${range[1]} de ${total} itens`,
  });
  const [filterData, setFilterData] = useState(() => {
    return initialFilterData({ query, queryParams });
  });

  const handleRequest = useCallback(
    async ({ _next }) => {
      return get({
        url: params.url,
        config: {
          params: {
            _limit: 100,
            _next,
            ...filterData,
            ...params.config.params,
          },
        },
      });
    },
    [filterData, get, params]
  );

  const fetch = useCallback(
    async (current, requestId, clear) => {
      if (!params) return;

      try {
        setLoading(true);
        scrollToTop();

        const pageSize = pagination.pageSize || 20;

        const res = await handleRequest({ _next: clear ? undefined : next });

        const allDocs = [];

        if (allData?.docs && !clear) {
          allDocs.push(...allData.docs);
        }
        if (res?.docs) {
          allDocs.push(...res.docs);
        }
        setAllData({ ...res, docs: allDocs });
        setNext(res.next);
        const newData = allDocs.slice(
          pagination.pageSize * (current - 1),
          pagination.pageSize * current
        );

        setData(newData);
        setPagination((oldState) => ({
          ...oldState,
          current,
          pageSize,
          total: allDocs.length,
        }));

        if (customDataParser) {
          setCustomParserLoading(true);

          setCustomParserData(await customDataParser(res));

          setCustomParserLoading(false);
        }

        setLoading(false);
        // eslint-disable-next-line consistent-return
        return { next: res.next, requestId };
      } catch (e) {
        // SILENCER
        setLoading(false);
      }
    },
    [params, pagination, handleRequest, next, allData, customDataParser]
  );

  const getListFiveTimes = useCallback(async () => {
    setLoadingFivetimes(true);

    let _next = next;
    const newAllData = allData.docs || [];

    const requestId = Math.floor(Math.random() * 10000000);

    currentRequestId.current = requestId;

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 5; index++) {
      if (requestId !== currentRequestId.current || !_next) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      const res = await handleRequest({ _next });
      _next = res.next;

      newAllData.push(...(res?.docs || []));
      setPagination((oldState) => ({
        ...oldState,
        total: newAllData.length,
      }));
    }

    if (requestId === currentRequestId.current) {
      setNext(_next);
      setAllData({ next: _next, docs: newAllData });

      setPagination((oldState) => ({
        ...oldState,
        total: newAllData.length,
      }));
    }

    setLoadingFivetimes(false);
  }, [allData, handleRequest, next]);

  const handlePaginationChange = useCallback(
    (_page) => {
      const newPagination = {
        ...pagination,
        current: _page,
      };

      const newData = allData.docs.slice(
        newPagination.pageSize * (newPagination.current - 1),
        newPagination.pageSize * newPagination.current
      );

      setData(newData);
      setPagination(newPagination);
    },
    [allData, pagination]
  );

  const refreshList = useCallback(() => {
    fetch(1, null, true);
  }, [fetch]);

  const updateParams = useCallback((newParams) => {
    setAllData({ docs: [] });
    setData([]);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setParams(newParams);
  }, []);

  const onFinishGoToPage = useCallback(
    (values) => {
      const { newPage = 1 } = values || {};

      const newPagination = {
        ...pagination,
        current: newPage,
      };

      const newData = allData.docs.slice(
        newPagination.pageSize * (newPagination.current - 1),
        newPagination.pageSize * newPagination.current
      );

      setPagination((oldState) => ({ ...oldState, current: newPage }));

      setData(newData);
      form.resetFields();
    },
    [form, allData, pagination]
  );

  const emptyMessageData = useMemo(() => {
    const p = params?.config?.params || {};

    if (!_.isEmpty(p._search) || !_.isEmpty(filterData)) {
      return {
        description: 'Nenhum resultado encontrado.',
        type: 'search',
      };
    }

    return {
      description: 'Nenhum resultado encontrado.',
      type: 'empty',
    };
  }, [params, filterData]);

  useEffect(() => {
    if (!data) return;

    const isLastPage = allData?.docs?.length <= pagination.pageSize * pagination.current;

    if (isLastPage && next && !loadingFivetimes) {
      fetch(pagination.current);
    }
    setQuery({ page: pagination.current });
  }, [pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch(1, null, true);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setQuery({ page: 1 });
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  useEffect(() => {
    if (
      allowEmptyListWorkaround &&
      !loading &&
      query.page &&
      data?.length === 0 &&
      pagination?.current > 1 &&
      pagination?.current === query.page &&
      pagination?.total > 0
    ) {
      message.info(`Página (${query.page}) vazia, redirecionando para a primeira.`);

      const newPagination = {
        ...pagination,
        current: 1,
      };

      const newData = allData.docs.slice(
        newPagination.pageSize * (newPagination.current - 1),
        newPagination.pageSize * newPagination.current
      );

      setPagination((oldState) => ({ ...oldState, current: 1 }));

      setData(newData);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalItems = useMemo(() => allData?.length || 0, [allData]);

  const loadingList = useMemo(() => {
    return loading || customLoader;
  }, [customLoader, loading]);

  const componentList = useMemo(
    () => (
      <div className="gx-position-relative mrg-top-15">
        {(loading || loadingFivetimes) && (
          <div className="loading">
            <Spin className="mrg-top-30" />
          </div>
        )}
        {data?.length > 0 && (
          <>
            <div className="custom-footer-pagination" style={{ marginBottom: 10 }}>
              {showQuickJumper && (
                <div className="go-to-form">
                  <Form form={form} onFinish={onFinishGoToPage}>
                    <Form.Item
                      className="new-page-input"
                      name="newPage"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Button type="text" htmlType="submit">
                      Ir para página &#10095;
                    </Button>
                  </Form>
                </div>
              )}
              {!hidePagination && (
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  current={pagination.current}
                  showTotal={(total, range) =>
                    next
                      ? `Exibindo ${range[0]} a ${range[1]} de ${total}+ itens`
                      : `Exibindo ${range[0]} a ${range[1]} de ${total} itens`
                  }
                  onChange={(_page) => handlePaginationChange(_page)}
                  showSizeChanger={false}
                />
              )}
            </div>
            {data?.map((d) => (
              <Component
                key={d[rowKey]}
                data={d}
                customParserData={customParserData}
                customParserLoading={customParserLoading}
                customModal={customModal}
                refreshList={refreshList}
                maxTags={maxTags}
              />
            ))}
            <div className="custom-footer-pagination">
              {showQuickJumper && (
                <div className="go-to-form">
                  <Form form={form} onFinish={onFinishGoToPage}>
                    <Form.Item
                      className="new-page-input"
                      name="newPage"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Button type="text" htmlType="submit">
                      Ir para página &#10095;
                    </Button>
                  </Form>
                </div>
              )}
              {!hidePagination && (
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  current={pagination.current}
                  showTotal={(total, range) =>
                    next
                      ? `Exibindo ${range[0]} a ${range[1]} de ${total}+ itens`
                      : `Exibindo ${range[0]} a ${range[1]} de ${total} itens`
                  }
                  onChange={(_page) => handlePaginationChange(_page)}
                  showSizeChanger={false}
                />
              )}
            </div>
          </>
        )}
        {!data?.length > 0 && !loadingList && (
          <EmptyMessage
            show={!loadingList}
            description={emptyMessageData?.description}
            withCard
            type={emptyMessageData?.type}
          />
        )}
      </div>
    ),
    [
      loadingList,
      data,
      hidePagination,
      pagination,
      emptyMessageData,
      rowKey,
      customParserData,
      customParserLoading,
      customModal,
      refreshList,
      maxTags,
      handlePaginationChange,
      next,
      loading,
      form,
      onFinishGoToPage,
      showQuickJumper,
      loadingFivetimes,
    ]
  );

  return {
    componentList,
    data,
    loading,
    loadingFivetimes,
    setFilterData,
    refreshList,
    updateParams,
    getListFiveTimes,
    allData,
    totalItems,
    next,
    error,
  };
};

export default useComponentNextList;
