import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Switch,
  Checkbox,
  Spin,
  Popover,
  message,
  Typography,
} from 'antd';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

import { CircleFalse, Checkmark, Trash, ArrowDown } from '@combateafraude/icons/general';

import Button from 'components/Button';
import TooltipInfo from 'components/TooltipInfo';
import PageTitle from 'components/PageTitle';
import { ReportsSubheader } from 'components/Header/components/CustomerSubheader';
import EmptyMessage from 'components/EmptyMessage';

import currency from 'utils/currencyFormatter';

import Wrapper from '../../wrapper';

import CustomDataCard from './components/CustomDataCard';
import useValidationsPopover from './components/useValidationsPopover';
import customDataCategories from './utils/customDataCategories.json';

import './styles.less';

const { Option } = Select;
const { Text } = Typography;

const actionSelect = (
  <Select placeholder="Selecione uma ação" suffixIcon={<ArrowDown />} showSearch>
    <Option value="REPROVE">Reprovado</Option>
    <Option value="PENDING">Pendente</Option>
    <Option value="PROCESSING">Processando</Option>
    <Option value="PENDING_OCR">OCR pendente</Option>
    <Option value="DOCUMENTSCOPY">Documentoscopia</Option>
    <Option value="COMBATE_A_FRAUDE">Combate à Fraude</Option>
    <Option value="CPF_QUEUE">CPF na fila</Option>
  </Select>
);

const validCategories = [
  'basic_info',
  'onboarding',
  'finance',
  'deprecated',
  'driver',
  'criminal_background',
  'work',
  'media',
  'contact',
  'restrictive_lists',
  'justice',
];

const ReportCreate = () => {
  const { customer } = useCustomer();
  const { tenantId, reportId } = useParams();
  const { get: getReport, loading: loadingReport } = useFetch();
  const {
    data: validations,
    get: getValidations,
    loading: loadingValidations,
  } = useFetch();
  const { data: templates, get: getTemplates, loading: loadingTemplates } = useFetch();
  const {
    data: onboardingTemplates,
    get: getOnboardingTemplates,
    loading: loadingOnboardingTemplates,
  } = useFetch();
  const { data: customData, get: getCustomData, loading: loadingCustomData } = useFetch();

  const [form] = Form.useForm();

  const [report, setReport] = useState();
  const [sourcesAvailability, setSourcesAvailability] = useState('all');
  const [showKeywordsForProcesses, setShowKeywordsForProcesses] = useState(() => false);

  const customDataRef = useRef();

  const {
    isVisible: isRelatedRulesPopoverVisible,
    openRelatedRulesPopover,
    relatedRulesPopoverContent,
  } = useValidationsPopover({
    formRef: form,
    templates,
    validations,
    customData,
  });

  const findTemplatePrice = useCallback(
    (templateId) => {
      if (!templates || !templateId) return 0;

      const { docs } = templates?.templates || {};
      const template = docs.filter((t) => t._id === templateId);

      return template.length > 0 ? template[0].price : 0;
    },
    [templates]
  );

  const onTemplateChange = useCallback(
    (value) => {
      if (report && !report.price) {
        const price = findTemplatePrice(value);
        form.setFieldsValue({ price });
      }

      openRelatedRulesPopover(value);
    },
    [report, findTemplatePrice, form, openRelatedRulesPopover]
  );

  const fetch = useCallback(async () => {
    try {
      await Promise.all([
        getTemplates({
          url: '/reports/templates',
        }),
        getCustomData({
          url: `/reports/custom-data?tenantId=${tenantId}`,
        }),
        getValidations({
          url: '/reports/validations',
        }),
        getOnboardingTemplates({
          url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}/onboarding-builder/templates`,
          config: {
            params: {
              tenantId,
              _includeDefault: true,
              _all: true,
            },
          },
        }),
      ]);
    } catch (error) {
      message.error('Houve um problema ao buscar alguns dados.');
    }

    if (!reportId) {
      setReport({});
      return;
    }

    const response = await getReport({
      url: `/clients/${tenantId}/reports/${reportId}`,
    });

    const { parts = null } = response.report?.processesWithKeywords || {};

    if (response.report?.processesWithKeywords) {
      form.setFieldsValue({
        processesWithKeywords: {
          parts: parts?.map((part) => ({
            value: part,
            label: part,
            key: part,
          })),
        },
      });

      setShowKeywordsForProcesses(true);
    }

    const customDataStringArray = response.report.customData.map((cd) => cd.source);
    setReport({ ...response.report, customData: customDataStringArray });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantId,
    getCustomData,
    getReport,
    getTemplates,
    getValidations,
    getOnboardingTemplates,
    reportId,
  ]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetch();
  }, [fetch]);

  const fieldsChange = () => {
    const formCustomData = form.getFieldValue('customData');

    if (formCustomData) {
      setShowKeywordsForProcesses(
        formCustomData.includes('pj_processes_with_keywords') ||
          formCustomData.includes('pf_processes_with_keywords')
      );
      if (!showKeywordsForProcesses) {
        form.setFieldsValue({
          processesWithKeywords: {
            parts: [
              {
                value: 'RÉU',
                label: 'RÉU',
                key: 'RÉU',
              },
            ],
            searchAllFields: [],
            archived: false,
          },
        });
      }
    }
  };

  const calculateCustomData = useCallback(
    (changedValues) => {
      if (!changedValues?.customData) return;

      const formCustomData = changedValues.customData;
      if (!formCustomData) return;

      const { docs: localCustomData } = customData?.customData;
      if (!localCustomData) return;

      const checkedCustomData = localCustomData.filter((cd) =>
        formCustomData.includes(cd.source)
      );

      const totalValue = checkedCustomData.reduce(
        (acc, cd) => acc + (cd.price ? cd.price : 0),
        0
      );
      form.setFieldsValue({ calculatedValue: totalValue });
    },
    [form, customData]
  );

  const customDataFormatted = useMemo(() => {
    if (!customData) return [];

    const getDocs = (documents) => {
      let filteredDocuments = documents.filter((doc) => !doc?._onlyTransaction);

      if (sourcesAvailability !== 'all') {
        filteredDocuments = filteredDocuments.filter((doc) =>
          doc.availableOn.some((placeAvailable) => placeAvailable === sourcesAvailability)
        );
      }

      const pfDocs = filteredDocuments.filter((doc) => !doc.source.startsWith('pj_'));
      const pjDocs = filteredDocuments.filter((doc) => doc.source.startsWith('pj_'));

      return {
        pfDocs,
        pjDocs,
      };
    };

    const { docs: customDataDocs } = customData?.customData || [];

    const customDataDocsWithDeprecatedCategory = customDataDocs
      .filter((doc) => validCategories.includes(doc.category))
      .map((doc) => ({
        ...doc,
        category: doc.status === 'DEPRECATED' ? 'deprecated' : doc.category,
      }));
    const categoryKeys = customDataDocsWithDeprecatedCategory.map((d) => d.category);
    const uniqueKeys = _.uniqBy(categoryKeys);

    const result = uniqueKeys.map((category) => {
      const documents = customDataDocsWithDeprecatedCategory.filter((cd) =>
        cd.category.includes(category)
      );

      return {
        category: customDataCategories[category],
        docs: getDocs(documents),
      };
    });

    customDataRef.current = result;

    return result;
  }, [customData, sourcesAvailability]);

  const mappedValidationsWithRelatedCustomData = useMemo(() => {
    if (!validations || !customData) return {};

    const validationsArray = validations.validations.docs;
    const customDataArray = customData.customData.docs;

    const responseObject = {};

    validationsArray.forEach((validation) => {
      const responseCustomDataArray = customDataArray.filter((data) => {
        if (data?.status === 'DEPRECATED' || data?._onlyTransaction) {
          return false;
        }

        return data?.relatedRules?.some(
          (relatedRule) => relatedRule?.rule === validation?.rule
        );
      });
      responseObject[validation.rule] = responseCustomDataArray?.map(
        (rule) => `${rule?.source} (${rule?.title})`
      );
    });
    return responseObject;
  }, [validations, customData]);

  const showValidationsWithRelatedCustomData = useCallback(
    (validation) => {
      if (mappedValidationsWithRelatedCustomData[validation?.rule]?.length) {
        return (
          <TooltipInfo
            placement="right"
            title={() => {
              const validationsList =
                mappedValidationsWithRelatedCustomData[validation?.rule];
              return `Fontes relacionadas: ${validationsList?.join(', ') || ''}`;
            }}
          />
        );
      }

      return (
        <TooltipInfo
          color="#262626"
          placement="right"
          title="Não possui nenhuma regra relacionada"
        />
      );
    },
    [mappedValidationsWithRelatedCustomData]
  );

  const handleChangeSourcesAvailability = useCallback(
    (value) => {
      setSourcesAvailability(value);
    },
    [setSourcesAvailability]
  );

  const renderedDataCards = useMemo(
    () => (
      <>
        <Row>
          <Col span={20}>
            <PageTitle
              title="Dossiê (Consultas)"
              subtitle="Selecione as informações você deseja exibir em seu dossiê, as mesmas servem apenas para consulta e não impactam nas regras de aprovação."
            />
          </Col>
          <Col span={4}>
            <Form.Item
              className="availability"
              label="Disponibilidade"
              rules={[{ required: true }]}
            >
              <Select
                value={sourcesAvailability}
                onChange={handleChangeSourcesAvailability}
                suffixIcon={<ArrowDown />}
              >
                <>
                  <Option value="all" label="Todas as Fontes">
                    Todas as Fontes
                  </Option>
                  <Option value="trust" label="Trust">
                    Trust
                  </Option>
                  <Option value="panel" label="Painel">
                    Painel
                  </Option>
                </>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="customData">
          <Checkbox.Group className="checkbox-group">
            {customDataFormatted.find(
              (cdf) => cdf.docs.pfDocs.length !== 0 || cdf.docs.pjDocs.length !== 0
            ) ? (
              <Row>
                <Col span={12}>
                  {customDataFormatted.map(
                    (cdf, index) =>
                      [0, 1, 3, 4, 5, 6].includes(index) && (
                        <CustomDataCard
                          key={cdf.category.icon}
                          customData={cdf}
                          formRef={form}
                        />
                      )
                  )}
                </Col>
                <Col span={12}>
                  {customDataFormatted.map(
                    (cdf, index) =>
                      [2, 7, 8, 9, 10, 11].includes(index) && (
                        <CustomDataCard
                          key={cdf.category.icon}
                          customData={cdf}
                          formRef={form}
                        />
                      )
                  )}
                </Col>
              </Row>
            ) : (
              <EmptyMessage description="Nenhum resultado encontrado." withCard />
            )}
          </Checkbox.Group>
        </Form.Item>
      </>
    ),
    [customDataFormatted, form, sourcesAvailability, handleChangeSourcesAvailability]
  );

  const renderReportInfos = useMemo(
    () => (
      <>
        <PageTitle
          title="Informações do relatório"
          subtitle="Defina as configurações básicas de seu relatório."
        />
        <Row>
          <Col span={5}>
            <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Popover
              overlayClassName="custom-card-popover"
              content={relatedRulesPopoverContent}
              visible={isRelatedRulesPopoverVisible}
              placement="leftTop"
            >
              <Form.Item
                name="template"
                label="Template Base"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Selecione um template"
                  onChange={onTemplateChange}
                  optionFilterProp="label"
                  showSearch
                  suffixIcon={<ArrowDown />}
                >
                  {templates?.templates.docs.map((t) => (
                    <Option key={t._id} value={t._id} label={t.name}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Popover>
          </Col>
          <Col span={5}>
            <Form.Item name="onboardingTemplateId" label="Template de Onboarding">
              <Select
                placeholder="Selecione um template"
                optionFilterProp="label"
                showSearch
                defaultValue={onboardingTemplates?.data?.[0]?._id}
                suffixIcon={<ArrowDown />}
              >
                {onboardingTemplates?.data?.map((t) => (
                  <Option key={t._id} value={t._id} label={t.name}>
                    {t.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!customer?.verified && (
            <Col span={3}>
              <Form.Item
                name="maxExecutions"
                label="Max. Execuções"
                rules={[{ required: true }]}
              >
                <InputNumber className="flex flex-1 no-arrow" />
              </Form.Item>
            </Col>
          )}
          <Col span={3}>
            <Form.Item
              name="price"
              label="Valor por execução"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => currency.toCurrency(value, 'R$')}
                parser={(value) => currency.toNumber(value)}
                className="flex flex-1 no-arrow"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="calculatedValue" label="Valor calculado">
              <InputNumber
                formatter={(value) => currency.toCurrency(value || 0, 'R$')}
                parser={(value) => currency.toNumber(value || 0)}
                className="flex flex-1 no-arrow"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    ),
    [
      relatedRulesPopoverContent,
      isRelatedRulesPopoverVisible,
      onTemplateChange,
      templates,
      customer,
      onboardingTemplates,
    ]
  );

  const renderValidations = useMemo(
    () => (
      <div className="custom-card">
        <PageTitle
          title="Regras de compliance"
          subtitle="Crie regras automatizadas para a validação de suas consultas."
        />
        <Form.List name="validations">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'rule']}
                      fieldKey={[field.fieldKey, 'rule']}
                      label={index === 0 ? 'Validação' : undefined}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Selecione uma validação"
                        showSearch
                        suffixIcon={<ArrowDown />}
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.label
                            .toLowerCase()
                            .localeCompare(optionB.label.toLowerCase())
                        }
                      >
                        {validations?.validations?.docs?.map((v) => (
                          <Option key={v.rule} value={v.rule} label={v.title}>
                            {v.title}
                            {showValidationsWithRelatedCustomData(v)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'actionWhenInvalid']}
                      fieldKey={[field.fieldKey, 'actionWhenInvalid']}
                      label={index === 0 ? 'Ação quando inválido' : undefined}
                    >
                      {actionSelect}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'actionWhenPending']}
                      fieldKey={[field.fieldKey, 'actionWhenPending']}
                      label={index === 0 ? 'Ação quando pendente' : undefined}
                    >
                      {actionSelect}
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'enabled']}
                      fieldKey={[field.fieldKey, 'enabled']}
                      label={index === 0 ? 'Ativa?' : undefined}
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="Ativa" unCheckedChildren="Inativa" />
                    </Form.Item>
                  </Col>

                  <Col span={3} className="flex end">
                    <Form.Item>
                      <Button
                        htmlType="button"
                        className="btn-custom btn-custom-danger"
                        onClick={() => remove(field.name)}
                      >
                        <Trash width={20} height={20} />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={add} block className="mrg-btm-25">
                Adicionar mais uma regra
              </Button>
            </>
          )}
        </Form.List>
      </div>
    ),
    [showValidationsWithRelatedCustomData, validations]
  );

  const handleKeywordsInputKeyDown = useCallback(
    (e) => {
      // 'Enter' key is pressed
      if (e.keyCode === 13) {
        const { searchAllFields } = form.getFieldValue('processesWithKeywords');

        if (!e.target.value) {
          e.stopPropagation();
        } else if (
          searchAllFields.find(
            (field) => field.toLowerCase() === e.target.value.toLowerCase()
          )
        ) {
          e.stopPropagation();
          e.target.value = '';
        }
      }
    },
    [form]
  );

  const renderKeywordsForProcesses = useMemo(() => {
    return (
      <div id="custom-filter-component" className="custom-card">
        <PageTitle
          title="Palavras-chave para Processos"
          subtitle="Lista de palavras-chave para serem validadas na fonte de Processos."
        />
        <Row>
          <Col span={24}>
            <Form.Item
              name={['processesWithKeywords', 'searchAllFields']}
              rules={[{ required: true }]}
            >
              <Select
                mode="tags"
                placeholder="Adicione palavras-chave..."
                dropdownClassName="gx-d-none"
                className="execution-select"
                removeIcon={<CircleFalse />}
                onInputKeyDown={handleKeywordsInputKeyDown}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={['processesWithKeywords', 'archived']}
              valuePropName="checked"
            >
              <Checkbox>Buscar por casos arquivados?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="mrg-top-25">
          <Text className="text-bold text-dark font-size-18">Partes</Text>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item
              name={['processesWithKeywords', 'parts']}
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                labelInValue
                dropdownClassName="multiselect-filters"
                listHeight={320}
                dropdownAlign={{ offset: [0, 2] }}
                removeIcon={<CircleFalse />}
                menuItemSelectedIcon={<Checkmark />}
              >
                <Option key="REU" value="REU" label="REU">
                  REU
                </Option>
                <Option
                  key="REQDO / REQDA / REQUERIDO / REQUERIDA"
                  value="REQDO / REQDA / REQUERIDO / REQUERIDA"
                  label="REQDO / REQDA / REQUERIDO / REQUERIDA"
                >
                  REQDO / REQDA / REQUERIDO / REQUERIDA
                </Option>
                <Option
                  key="INDICIADO / INDICIADA"
                  value="INDICIADO / INDICIADA"
                  label="INDICIADO / INDICIADA"
                >
                  INDICIADO / INDICIADA
                </Option>
                <Option
                  key="APENADO / APENADA"
                  value="APENADO / APENADA"
                  label="APENADO / APENADA"
                >
                  APENADO / APENADA
                </Option>
                <Option
                  key="ACUSADO / ACUSADA"
                  value="ACUSADO / ACUSADA"
                  label="ACUSADO / ACUSADA"
                >
                  ACUSADO / ACUSADA
                </Option>
                <Option
                  key="DENUNCIADO / DENUNCIADA"
                  value="DENUNCIADO / DENUNCIADA"
                  label="DENUNCIADO / DENUNCIADA"
                >
                  DENUNCIADO / DENUNCIADA
                </Option>
                <Option
                  key="CONDENADO / CONDENADA"
                  value="CONDENADO / CONDENADA"
                  label="CONDENADO / CONDENADA"
                >
                  CONDENADO / CONDENADA
                </Option>
                <Option
                  key="REEDUCANDO / REEDUCANDA"
                  value="REEDUCANDO / REEDUCANDA"
                  label="REEDUCANDO / REEDUCANDA"
                >
                  REEDUCANDO / REEDUCANDA
                </Option>
                <Option key="AUTOR DO FATO" value="AUTOR DO FATO" label="AUTOR DO FATO">
                  AUTOR DO FATO
                </Option>
                <Option key="A FATO" value="A FATO" label="A FATO">
                  A FATO
                </Option>
                <Option
                  key="INFRATOR / INFRATORA"
                  value="INFRATOR / INFRATORA"
                  label="INFRATOR / INFRATORA"
                >
                  INFRATOR / INFRATORA
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }, [handleKeywordsInputKeyDown]);

  return (
    <Wrapper
      subheader={<ReportsSubheader formRef={form} customDataRef={customDataRef} />}
    >
      <div id="report-create-component">
        {report &&
        !loadingCustomData &&
        !loadingReport &&
        !loadingTemplates &&
        !loadingOnboardingTemplates &&
        !loadingValidations ? (
          <Form
            form={form}
            name="report_form"
            layout="vertical"
            initialValues={{
              ...report,
              price:
                report.price || report.totalPrice || findTemplatePrice(report.template),
              calculatedValue: calculateCustomData({ ...report }),
            }}
            onValuesChange={calculateCustomData}
            onFieldsChange={() => fieldsChange()}
          >
            {renderReportInfos}
            {renderedDataCards}
            {renderValidations}
            {showKeywordsForProcesses && renderKeywordsForProcesses}
          </Form>
        ) : (
          <Spin />
        )}
      </div>
    </Wrapper>
  );
};

export default ReportCreate;
